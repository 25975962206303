





























import { Component, Emit, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'FooterComponent'
})

export default class Footer extends Vue {
/** ******************************** Vue Data! **********************************/

    private copyRight: Date = new Date();

/** ******************************** Vue Computed **********************************/

    get userAuth () { return this.$store.getters['user/getUserAuth'] }

    get version () { return this.$store.getters.getVersion }

/** ******************************** Vue Emit Events! **********************************/

    @Emit('clickLogIn') login () {}
    @Emit('clickLogOut') logout () {}
}
