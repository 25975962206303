// AccuTerm Utilities
import { TermUtil } from '@/vendor/accuterm/client/js/termutil'

/**
 * Utility class with static methods for common functionality
 */

export default class Utils {

    private static _browser = {
        initialized: false,
        isMac: false,
        isIpad: false,
        isIphone: false,
        isIos: false,
        isAndroid: false,
        isMobile: false,
        isIE: false
    }
    
    private static _getBrowser() {
        if (!Utils._browser.initialized) {
            Utils._browser.initialized = true;
            Utils._browser.isMac = !!~navigator.userAgent.indexOf('Mac');
            Utils._browser.isIpad = !!~navigator.userAgent.indexOf('iPad');
            Utils._browser.isIphone = !!~navigator.userAgent.indexOf('iPhone');
            // iPadOS 13 userAgent does not contain iPad, so use alternate detection
            if (navigator.platform === 'MacIntel' && typeof (navigator as any).standalone !== 'undefined') {
                Utils._browser.isIpad = true;
                Utils._browser.isMac = false;
            }
            Utils._browser.isIos = Utils._browser.isIpad || Utils._browser.isIphone;
            Utils._browser.isAndroid = !!~navigator.userAgent.indexOf('Android');
            Utils._browser.isMobile = Utils._browser.isIos || Utils._browser.isAndroid;
            Utils._browser.isIE = Utils.detectIE();
        }
        return Utils._browser;
    }

    static isMac() { return Utils._getBrowser().isMac; }
    static isIpad() { return Utils._getBrowser().isIpad; }
    static isIphone() { return Utils._getBrowser().isIphone; }
    static isIos() { return Utils._getBrowser().isIos; }
    static isAndroid() { return Utils._getBrowser().isAndroid; }
    static isMobile() { return Utils._getBrowser().isMobile; }
    static isIE() { return Utils._getBrowser().isIE; }

    /**
     * Returns the Font Family from submodule TermUtil
     * payload is { font }
     */
    getFontFamily (payload: any) {
        return TermUtil.GetFontFamily(payload.font)
    }

    /**
     * Returns the Font Family from submodule TermUtil
     * payload is { style }
     */
    getThemeStyle (payload: any) {
        return TermUtil.GetThemeStyleTitle(payload.style)
    }

    /**
     * Returns true if version of IE shows up. Returns true if is not Internet Explorer or Microsoft Edge
     * (//codepen.io/gapcode/pen/vEJNZN)
     */
    static detectIE (): boolean {
        const ua = window.navigator.userAgent
        let version: boolean | number = false

        const msie = ua.indexOf('MSIE ')
        if (msie > 0) {
            // IE 10 or older => return version number
            version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
        }

        const trident = ua.indexOf('Trident/')
        if (trident > 0) {
            // IE 11 => return version number
            let rv = ua.indexOf('rv:')
            version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
        }

        const edge = ua.indexOf('Edge/')
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
        }

        // if it's not IE or Edge || if it's edge
        if (!version || version >= 12) {
            return false
        } else {
            return true
        }
    }
}
