


















































import { Component, Vue } from 'vue-property-decorator'
// Types
import { GUIDialogBtn, GUIDialogDef, GUIDialogResp, GUIDialogType } from '../../../types/enums'
import { GuisServiceType } from '../../../types/services/guis'
import { DialogState } from '../../../types/store/guis'
import MessageBox from './MessageBox.vue'
import InputBox from './InputBox.vue'
import FileDialog from './FileDialog.vue'
import FontDialog from './FontDialog.vue'
import ColorDialog from './ColorDialog.vue'
import PrinterDialog from './PrinterDialog.vue'
import AboutBox from './AboutBox.vue'

@Component({
  name: 'DialogComponent',
  components: { MessageBox, InputBox, FileDialog, FontDialog, ColorDialog, PrinterDialog, AboutBox }
})

export default class DialogBox extends Vue {
  
/** ******************************** Vue Data **********************************/


/** ******************************** Vue Computed **********************************/

    get guiService (): GuisServiceType { return this.$store.getters['guiGuis/getGuisService'] }
    get dlgBox (): DialogState { return this.$store.getters['guiGuis/getDialogBox'] }

    get visible (): boolean { return this.dlgBox.visible }
    set visible (val) { 
        let dgBox = { ... this.dlgBox };

        dgBox.visible = val;

        this.$store.dispatch('guiGuis/setDialogBox', dgBox)
    }

    get msgType () { return this.dlgBox.type === GUIDialogType.messageBox }
    get inpType () { return this.dlgBox.type === GUIDialogType.inputBox }
    get fileType () { return this.dlgBox.type === GUIDialogType.fileDialog }
    get fontType () { return this.dlgBox.type === GUIDialogType.fontDialog }
    get colorType () { return this.dlgBox.type === GUIDialogType.colorDialog }
    get printType () { return this.dlgBox.type === GUIDialogType.printerDialog }
    get aboutType () { return this.dlgBox.type === GUIDialogType.aboutBox }
    get colorValue() { return this.dlgBox.color }
    get fontValue() { return this.dlgBox.font }
    get buttons (): GUIDialogBtn { return this.dlgBox.buttons; }
    get defaultButton (): GUIDialogDef { return this.dlgBox.defBtn; }
    get answer (): string { return (this.dlgBox.answer !== null ? this.dlgBox.answer : '') }

    get abortButton () { 
        const show: boolean = this.buttons === GUIDialogBtn.gmbBtnAbortRetryIgnore; 
        const pressed: boolean = show && this.defaultButton === GUIDialogDef.gmbDefBtn1;
        return { show: show, pressed: pressed };
    }

    get cancelButton () { 
        const two: boolean = this.buttons === GUIDialogBtn.gmbBtnOKCancel || this.buttons === GUIDialogBtn.gmbBtnRetryCancel
        const three: boolean = this.buttons === GUIDialogBtn.gmbBtnYesNoCancel
        const show: boolean = two || three; 

        const pressed: boolean = (two && this.defaultButton === GUIDialogDef.gmbDefBtn2) || (three && this.defaultButton === GUIDialogDef.gmbDefBtn3);
        return { show: show, pressed: pressed };
    }

    get ignoreButton () { 
        const show: boolean = this.buttons === GUIDialogBtn.gmbBtnAbortRetryIgnore;
        const pressed: boolean = show && this.defaultButton === GUIDialogDef.gmbDefBtn3;
        return { show: show, pressed: pressed };
    }
    
    get noButton () { 
        const show: boolean = this.buttons === GUIDialogBtn.gmbBtnYesNo || this.buttons === GUIDialogBtn.gmbBtnYesNoCancel;
        const pressed: boolean = show && this.defaultButton === GUIDialogDef.gmbDefBtn2;
        return { show: show, pressed: pressed };
    }

    get okButton () {
        const show: boolean = this.buttons === GUIDialogBtn.gmbBtnOK || this.buttons === GUIDialogBtn.gmbBtnOKCancel;
        const pressed: boolean = show && this.defaultButton === GUIDialogDef.gmbDefBtn1;
        return { show: show, pressed: pressed };
    }

    get retryButton () { 
        const one: boolean = this.buttons === GUIDialogBtn.gmbBtnRetryCancel
        const two: boolean = this.buttons === GUIDialogBtn.gmbBtnAbortRetryIgnore
        const show: boolean = one || two; 

        const pressed: boolean = (one && this.defaultButton === GUIDialogDef.gmbDefBtn1) || (two && this.defaultButton === GUIDialogDef.gmbDefBtn2);
        return { show: show, pressed: pressed };
    }

    get yesButton () { 
        const show: boolean = this.buttons === GUIDialogBtn.gmbBtnYesNo || this.buttons === GUIDialogBtn.gmbBtnYesNoCancel;
        const pressed: boolean = show && this.defaultButton === GUIDialogDef.gmbDefBtn1;
        return { show: show, pressed: pressed };
    }    

    get closeButton(): boolean { return this.buttons === GUIDialogBtn.gmbBtnAbortRetryIgnore || this.buttons === GUIDialogBtn.gmbBtnYesNo }


/** ******************************** Vue Methods **********************************/

    responseHandler(e: any) {
        let response: GUIDialogResp

        switch (e.trigger) {
            case('abort') :
                response = GUIDialogResp.gmbAnsAbort
            break;
            case('cancel') :
                response = GUIDialogResp.gmbAnsCancel
            break;
            case('ignore') :
                response = GUIDialogResp.gmbAnsIgnore
            break;
            case('no') :
                response = GUIDialogResp.gmbAnsNo
            break;
            case('ok') :
                response = GUIDialogResp.gmbAnsOK
            break;
            case('retry') :
                response = GUIDialogResp.gmbAnsRetry
            break;
            case('yes') :
                response = GUIDialogResp.gmbAnsYes
            break;
            default :
                response = 0 // headerclose
            break;
        }

        this.visible = false;
        switch (this.dlgBox.type) {
            case(GUIDialogType.messageBox) :
                this.guiService.dialogService.msgResponseHandler(response);
            break
            case(GUIDialogType.inputBox) :
                this.guiService.dialogService.inputResponseHandler(this.answer);
            break
            case(GUIDialogType.fileDialog) :
                //this.guiService.fileDialogService.responseHandler(response);
            break
            case(GUIDialogType.fontDialog) :
                this.guiService.dialogService.fontResponseHandler(this.fontValue);
            break
            case(GUIDialogType.colorDialog) :
            this.guiService.dialogService.colorResponseHandler(this.colorValue);
            break
            case(GUIDialogType.printerDialog) :
                //this.guiService.printerDialogService.responseHandler(response);
            break
        }
        
    }

}
