// store
import store from './../store';
// types
import { GUIComponent, GUIScaleMode, GUIErrorCode, GUIErrorLevel, GUIProperty, GUIObjectType, AllProps, GuiProps, GUIDataType, RootProps, GUIAlignment, GUIBorderStyle, GridRowProps, GridColumnProps, GridProps } from './../types/enums';

import { deferredDeactivateType } from '../types/store/guis'
import { UtilsType } from './../types/utils';
import Profile from '../../../../types';
import { mapGetters } from 'vuex';

export default class Utils implements UtilsType {
    /**
     * Service Helper Functions
     */
/* TODO - functiopn not needed?    
    guisDeepCopy(components: Array<GUIComponent>): Array<GUIComponent> {

        return components.map(component => {
            return {
                props: { ...component.props },
                component: component.component,
                children: this.guisDeepCopy(component.children)
            }
        })
    }
*/
/* TODO - function not needed?    
    objectDeepCopy(obj: {[key: string]: any}): Object {

        let newObj: {[key: string]: any} = { }

        for (const property in obj) {

            if (obj[property].isArray()) {
                newObj[property] = this.arrayDeepCopy(obj[property])
            } else if (typeof obj[property] === 'object') {
                newObj[property] = this.objectDeepCopy(obj[property])
            } else {
                newObj[property] = obj[property]
            }
        }

        return newObj;
    }
*/
/* TODO - function not needed?    
    arrayDeepCopy(array: Array<any>) : Array<any> {
        return array.map(element => (element.isArray()) ? this.arrayDeepCopy(element) : element)
    }
*/
    // Case insensitive string compare - true if all elements
    // in the array equal, except for case.
    compareIDs(ids: Array<string>) {
        // change all elements to uppercase
        ids = ids.map(id => (id.toUpperCase()))

        return ids.every(id => id === ids[0])
    }
    
    // Test if object is a child of another object
    isChild(parentID: string, childID: string): boolean {
        let props: AllProps | undefined | null = store.getters['guiGuis/getProps'](childID);
        for (; props && props.parent; ) {
            if (props.parentID === parentID) {
                return true;
            }
            props = props.parent;
        }
        return false;
    }

    // Flatten nested components (children) into single array. 'd' is the depth to flatten.
    // Specify 'd' as Infinity to flatten all nested children.
    flattenChildren(root: GUIComponent, d = 1): Array<GUIComponent> {
         return d > 0 ? root.children.reduce((a: Array<GUIComponent>, b: GUIComponent) => b.children.length ? a.concat(b, this.flattenChildren(b, d - 1)) : a.concat(b), [])
                      : root.children.slice();
    }
      
    /**
     * CSS Helper Functions
     */
    getScale(props: AllProps) {
        return (props.app ? props.app.gpScale : GUIScaleMode.gsmDefault);
    }

    pixelConversion(amount: number, vh: string = 'h', scale: GUIScaleMode) {

        let pixels: number = amount;
        const guiScale: number = scale;
        
        switch (guiScale) {

            case GUIScaleMode.gsmPoints: // pt = point
                pixels = amount * 1.333333 
            break;
            case GUIScaleMode.gsmInches: // in = inch
                pixels = amount * 96
            break;
            case GUIScaleMode.gsmMillimeters: // mm = millimeters
                pixels = amount * 3.7795296
            break;
            case GUIScaleMode.gsmCentimeters: // cm = centimeters
                pixels = amount * 37.795296
            break;
            case GUIScaleMode.gsmCharacters: case GUIScaleMode.gsmDefault: // ch = characters
                // width (h = horizontal)
                if (vh === 'h') { 
                    pixels = amount * 8
                // height (v = vertical)
                } else if (vh === 'v') { 
                    pixels = amount * 16 
                }
            break;
            case GUIScaleMode.gsmTwips: // tw = twips
                pixels = amount * 0.0666667
            break;
        }

        const pixelFloor = Math.floor(pixels)

        // if pixels isn't an integer
        if (pixels - pixelFloor > 0) {
            pixels = (pixelFloor < 0.5) ? pixelFloor : Math.ceil(pixels)
        }

        return pixels;
    }

    scaleConversion(pixels: number, vh: string = 'h', scale: GUIScaleMode) {

        let amount: number = pixels;
        const guiScale: number = scale;
        
        switch (guiScale) {

            case GUIScaleMode.gsmPoints: // pt = point
                amount = pixels / 1.333333 
            break;
            case GUIScaleMode.gsmInches: // in = inch
                amount = pixels / 96
            break;
            case GUIScaleMode.gsmMillimeters: // mm = millimeters
                amount = pixels / 3.7795296
            break;
            case GUIScaleMode.gsmCentimeters: // cm = centimeters
                amount = pixels / 37.795296
            break;
            case GUIScaleMode.gsmCharacters: case GUIScaleMode.gsmDefault: // ch = characters
                // width (h = horizontal)
                if (vh === 'h') { 
                    amount = pixels / 8
                // height (v = vertical)
                } else if (vh === 'v') { 
                    amount = pixels / 16 
                }
            break;
            case GUIScaleMode.gsmTwips: // tw = twips
                amount = pixels / 0.0666667
            break;
        }

        return amount.toFixed(2)
    }

    controlPositionCSS(gpTop: GUIProperty.gpTop, gpLeft: GUIProperty.gpLeft) {

        let position: Partial<CSSStyleDeclaration> = {
            top: Number(gpTop) + 'px',
            left: Number(gpLeft) + 'px',
            position: 'absolute'
        }

        if (position.top === '0px' && position.left === '0px') {
            position.position = 'relative'
        }

        return position
    }

    controlSize(gpWidth: GUIProperty.gpWidth, gpHeight: GUIProperty.gpHeight) {

        let widthAndHeight: Partial<CSSStyleDeclaration> = {
            width: gpWidth + 'px',
            height: gpHeight + 'px'
        }

        return widthAndHeight
    }

    controlFont(props: AllProps) {
        let css: Partial<CSSStyleDeclaration> = {}
        if ('gpFontName' in props) { css.fontFamily = props.gpFontName }
        if ('gpFontSize' in props) {
            const fontSize = this.pixelConversion(props.gpFontSize, 'v', GUIScaleMode.gsmPoints)

            css.fontSize = fontSize + 'px'
        }
        if ('gpFontBold' in props) {
            if (props.gpFontBold === true) {
                css.fontWeight = 'bold'
            }
        }
        if ('gpFontItalic' in props) {
            if (props.gpFontItalic === true) { css.fontStyle = 'italic' }
        }
        if ('gpFontUnderline' in props) {
            if (props.gpFontUnderline === true) { css.textDecoration = 'underline' }
        }

        return css
    }

    controlBorder(props: AllProps) {
        let css: Partial<CSSStyleDeclaration> = {}
        if ('gpBorder' in props) {
            // 0 = no border
            // 1 = flat border
            if (props.gpBorder == GUIBorderStyle.gbsNone) { css.border = 'none' }
            if (props.gpBorder == GUIBorderStyle.gbsFlat) { css.border = '' } // default border
            if (props.gpBorder == GUIBorderStyle.gbs3D) { css.border = 'groove' }
        }

        return css
    }

    controlAlign(props: AllProps) {
        let css: Partial<CSSStyleDeclaration> = {}
        if ('gpAlign' in props) {
            if (props.gpAlign === GUIAlignment.galLeft) {
                css.textAlign = 'left'
            }
            if (props.gpAlign === GUIAlignment.galRight) {
                css.textAlign = 'right'
            }
            if (props.gpAlign === GUIAlignment.galCenter) {
                css.textAlign = 'center'
            }
        }

        return css
    }

    getImage(image: string) {
        let rtn = image;
        if (!(/^(http|https)\:.+/i.test(image))) {
            const profile: Profile = store.getters["guiGeneral/getProfile"]
            if (profile && profile.connection.toUpperCase() === 'WEBSOCKET') {
                const secure = (profile && profile.hasOwnProperty('SECURE')) ? !!(profile as any).SECURE : true; // default to secure connection
                rtn = (secure ? 'https' : 'http') + '://' + profile.host + ':' + profile.port + '/gui/';
                rtn += image.replace(/\\/g, '/').split('/').pop();
            }
        }
        return rtn;
    }

    getIcon(icon: string) {

        let bootstrapIcon: string = 'false';
        let iconName = icon.replace(/\\/g, '/').split('/').pop()!.toLowerCase();

        switch (iconName) {
            case 'arrowdown.ico': case 'arrowdown.bmp':
                bootstrapIcon = 'arrow-down';
                break;
            case 'arrowleft.ico': case 'arrowleft.bmp':
                bootstrapIcon = 'arrow-left';
                break;
            case 'arrowright.ico': case 'arrowright.bmp':
                bootstrapIcon = 'arrow-right';
                break;
            case 'arrowup.ico': case 'arrowup.bmp':
                bootstrapIcon = 'arrow-up';
                break;
            case 'bold.ico': case 'bold.bmp':
                bootstrapIcon = 'type-bold';
                break;
            case 'book.ico': case 'book.bmp':
                bootstrapIcon = 'book';
                break;
            case 'brush.ico': case 'brush.bmp':
                bootstrapIcon = 'brush';
                break;
            case 'button.ico': case 'button.bmp':
                bootstrapIcon = 'menu-button';
                break;
            case 'capture.ico': case 'capture.bmp':
                bootstrapIcon = 'server';
                break;
            case 'center.ico': case 'center.bmp':
                bootstrapIcon = 'text-center';
                break;
            case 'chart.ico': case 'chart.bmp':
                bootstrapIcon = 'bar-chart-line';
                break;
            case 'check.ico': case 'check.bmp':
                bootstrapIcon = 'check-square';
                break;
            case 'check2.ico': case 'check2.bmp':
                bootstrapIcon = 'check2-square';
                break;
            case 'clear.ico': case 'clear.bmp':
                bootstrapIcon = 'tag';
                break;
            case 'close.ico': case 'close.bmp':
                bootstrapIcon = 'box-arrow-in-up-left';
                break;
            case 'codetool.ico': case 'codetool.bmp':
                bootstrapIcon = 'journal-code';
                break;
            case 'copy.ico': case 'copy.bmp':
                bootstrapIcon = 'clipboard';
                break;
            case 'cut.ico': case 'cut.bmp':
                bootstrapIcon = 'scissors';
                break;
            case 'delete.ico': case 'delete.bmp':
                bootstrapIcon = 'x-square';
                break;
            case 'document.ico': case 'document.bmp':
                bootstrapIcon = 'file-earmark-text';
                break;
            case 'dollar.ico': case 'dollar.bmp':
                bootstrapIcon = 'cash';
                break;
            case 'down.ico': case 'down.bmp':
                bootstrapIcon = 'caret-down-fill';
                break;
            case 'download.ico': case 'download.bmp':
                bootstrapIcon = 'download';
                break;
            case 'drawing.ico': case 'drawing.bmp':
                bootstrapIcon = ''; 
                break;
            case 'envelope.ico': case 'envelope.bmp':
                bootstrapIcon = 'envelope'; 
                break;
            case 'exit.ico': case 'exit.bmp':
                bootstrapIcon = 'box-arrow-in-left';
                break;
            case 'find.ico': case 'find.bmp':
                bootstrapIcon = 'binoculars-fill';
                break;
            case 'globe.ico': case 'globe.bmp':
                bootstrapIcon = 'globe';
                break;
            case 'help.ico': case 'help.bmp':
                bootstrapIcon = 'question-square';
                break;
            case 'help2.ico': case 'help2.bmp':
                bootstrapIcon = '';
                break;
            case 'image.ico': case 'image.bmp':
                bootstrapIcon = 'file-image';
                break;
            case 'italic.ico': case 'italic.bmp':
                bootstrapIcon = 'type-italic';
                break;
            case 'left.ico': case 'left.bmp':
                bootstrapIcon = 'text-left';
                break;
            case 'listbox.ico': case 'listbox.bmp':
                bootstrapIcon = 'list-check';
                break;
            case 'lock.ico': case 'lock.bmp':
                bootstrapIcon = 'lock';
                break;
            case 'menu.ico': case 'menu.bmp':
                bootstrapIcon = 'menu-button-wide-fill';
                break;
            case 'misc1.ico': case 'misc1.bmp':
                bootstrapIcon = 'grid1x2';
                break;
            case 'misc2.ico': case 'misc2.bmp':
                bootstrapIcon = 'file-ruled';
                break;
            case 'misc3.ico': case 'misc3.bmp':
                bootstrapIcon = 'hdd-rack';
                break;
            case 'misc4.ico': case 'misc4.bmp':
                bootstrapIcon = 'hdd-network';
                break;
            case 'misc5.ico': case 'misc5.bmp':
                bootstrapIcon = 'diagram2';
                break;
            case 'misc6.ico': case 'misc6.bmp':
                bootstrapIcon = 'diagram2-fill';
                break;
            case 'misc7.ico': case 'misc7.bmp':
                bootstrapIcon = 'diagram3';
                break;
            case 'misc8.ico': case 'misc8.bmp':
                bootstrapIcon = 'diagram3-fill';
                break;
            case 'new.ico': case 'new.bmp':
                bootstrapIcon = 'file';
                break;
            case 'newfolder.ico': case 'newfolder.bmp':
                bootstrapIcon = 'folder-plus';
                break;
            case 'open.ico': case 'open.bmp':
                bootstrapIcon = 'folder-symlink';
                break;
            case 'paste.ico': case 'paste.bmp':
                bootstrapIcon = 'clipboard-plus';
                break;
            case 'pause.ico': case 'pause.bmp':
                bootstrapIcon = 'pause-fill';
                break;
            case 'percent.ico': case 'percent.bmp':
                bootstrapIcon = 'percent';
                break;
            case 'phone.ico': case 'phone.bmp':
                bootstrapIcon = 'telephone';
                break;
            case 'plug.ico': case 'plug.bmp':
                bootstrapIcon = 'plug';
                break;
            case 'prevfolder.ico': case 'prevfolder.bmp':
                bootstrapIcon = 'folder-minus';
                break;
            case 'preview.ico': case 'preview.bmp':
                bootstrapIcon = 'eye';
                break;
            case 'print.ico': case 'print.bmp':
                bootstrapIcon = 'printer';
                break;
            case 'properties.ico': case 'properties.bmp':
                bootstrapIcon = 'card-list';
                break;
            case 'record.ico': case 'record.bmp':
                bootstrapIcon = 'record-fill';
                break;
            case 'redo.ico': case 'redo.bmp':
                bootstrapIcon = 'arrow-clockwise';
                break;
            case 'right.ico': case 'right.bmp':
                bootstrapIcon = 'text-right';
                break;
            case 'run.ico': case 'run.bmp':
                bootstrapIcon = 'play-fill';
                break;
            case 'runjob.ico': case 'runjob.bmp':
                bootstrapIcon = 'lightning';
                break;
            case 'save.ico': case 'save.bmp':
                bootstrapIcon = 'file-earmark-arrow-down';
                break;
            case 'saveall.ico': case 'saveall.bmp':
                bootstrapIcon = 'archive';
                break;
            case 'screensize.ico': case 'screensize.bmp':
                bootstrapIcon = 'aspect-ratio';
                break;
            case 'search.ico': case 'search.bmp':
                bootstrapIcon = 'search';
                break;
            case 'send.ico': case 'send.bmp':
                bootstrapIcon = 'journal-arrow-up';
                break;
            case 'showme.ico': case 'showme.bmp':
                bootstrapIcon = 'question-square';
                break;
            case 'spellcheck.ico': case 'spellcheck.bmp':
                bootstrapIcon = 'spellcheck';
                break;
            case 'stop.ico': case 'stop.bmp':
                bootstrapIcon = 'stop-fill';
                break;
            case 'termtype.ico': case 'termtype.bmp':
                bootstrapIcon = 'terminal';
                break;
            case 'tools.ico': case 'tools.bmp':
                bootstrapIcon = 'tools';
                break;
            case 'underline.ico': case 'underline.bmp':
                bootstrapIcon = 'type-underline';
                break;
            case 'undo.ico': case 'undo.bmp':
                bootstrapIcon = 'arrow-counterclockwise';
                break;
            case 'unlock.ico': case 'unlock.bmp':
                bootstrapIcon = 'unlock';
                break;
            case 'updown.ico': case 'updown.bmp':
                bootstrapIcon = 'arrow-down-up';
                break;
            case 'upload.ico': case 'upload.bmp':
                bootstrapIcon = 'upload';
                break;
            case 'warn.ico': case 'warn.bmp':
                bootstrapIcon = 'exclamation-triangle';
                break;
            case 'weblink.ico': case 'weblink.bmp':
                bootstrapIcon = 'link';
                break;
            case 'webtool.ico': case 'webtool.bmp':
                bootstrapIcon = 'arrow-left-right';
                break;
            case 'xyloc.ico': case 'xyloc.bmp':
                bootstrapIcon = 'graph-down';
                break;
            case 'xysize.ico': case 'xysize.bmp':
                bootstrapIcon = 'graph-up';
                break;
        }

        return bootstrapIcon
    }

    componentCSS(props: AllProps) {

        let css: Partial<CSSStyleDeclaration> = {
            width: props.gpWidth + 'px',
            height: props.gpHeight + 'px'
        };

        if ('gpBackColor' in props) { css.backgroundColor = props.gpBackColor }
        if ('gpForeColor' in props) { css.color = props.gpForeColor }

        if ('gpAlign' in props) { css.textAlign = props.gpAlign }

        if ('gpWordWrap' in props) { css.wordWrap = 'normal' }

        if ('gpAltColor' in props) { css.color = ''; }
        if ('gpTransparent' in props) { css.opacity = props.gpTransparent };

        return css
    }

    getHexColor (color: string) {
        let colorName: string = color.toLowerCase();
        let hexColor: string  = ''
        switch (colorName) {
            case 'scrollbar':
                hexColor = '#c8c8c8';
                break;
            case 'desktop': 
            case 'menutext': 
            case 'windowtext': 
            case 'captiontext': 
            case 'btntext': 
            case 'inactivecaptiontext': 
            case 'infotext': 
            case 'buttonalternateface':
                hexColor = '#000000';
                break;
            case 'activecaption':
                hexColor = '#99b4d1';
                break;
            case 'inactivecaption':
                hexColor = '#d8cdbf';
                break;
            case 'menu': 
            case '3dface': 
            case 'menubar':
                hexColor = '#f0f0f0';
                break;
            case 'window': 
            case '3dhighlight': 
            case 'highlighttext':
                hexColor = '#ffffff';
                break;
            case 'windowframe':
                hexColor = '#646464';
                break;
            case 'activeborder':
                hexColor = '#b4b4b4';
                break;
            case 'inactiveborder':
                hexColor = '#f4f7fc';
                break;
            case 'appworkspace':
                hexColor = '#ababab';
                break;
            case 'highlight':
                hexColor = '#ffffff';
                break;
            case '3dshadow':
                hexColor = '#a0a0a0';
                break;
            case 'graytext':
                hexColor = '#6d6d6d';
                break;
            case '3ddkshadow':
                hexColor = '#696969';
                break;
            case '3dlight':
                hexColor = '#e3e3e3';
                break;
            case 'infobk':
                hexColor = '#ffffe1';
                break;
            case 'hotlight':
                hexColor = '#0066cc';
                break;
            case 'gradientactivecaption':
                hexColor = '#b9d1ea';
                break;
            case 'gradientinactivecaption':
                hexColor = '#d7e4f2';
                break;
            case 'menuhighlight':
                hexColor = '#3399ff';
                break;
            default:
                hexColor = '';
                break;
        }    
        return hexColor ;
    }

    getDataType(dataType: number) {
        let type = "";
        switch(dataType) {
            case GUIDataType.gdAny:
            case GUIDataType.gdAlpha:
            case GUIDataType.gdAlphaNumeric: 
              type = "text"
              break;
            case GUIDataType.gdBool: 
              type = "bool"
              break;
            case GUIDataType.gdCurrency: 
              type = "text"
              break;
            case GUIDataType.gdDate: 
              type = "date"
              break;
            case GUIDataType.gdFinancial: 
              type = "number"
              break;
            case GUIDataType.gdNumeric: 
              type = "number"
              break;
            case GUIDataType.gdPercent: 
              type = "text"
              break;
            case GUIDataType.gdPhone: 
              type = "tel"
              break;
            case GUIDataType.gdSSN: 
              type = "text"
              break;
            case GUIDataType.gdTime: 
              type = "time"
              break;
            case GUIDataType.gdZipCode: 
              type = "zip"
              break;
        }
        return type;
    }

    /**
     * DOM Helper Functions
     */

    getRelatedTargetID(target: HTMLElement, parentID: string, type: string): string | null {
        if (!target) {
            return null; // looks like sometimes parentElement can be null!
        } else if (target.hasAttribute(type)) {
            return target.getAttribute(type)!; // we literally check if it has that attribute above, but typescript still says this can be null
        } else if ((parentID && target.getAttribute('id') === parentID) || target.tagName === 'BODY') {
            return null;
        } else {
            // target should always have a parent element, since we return false if we hit the body tag (above)
            return this.getRelatedTargetID(target.parentElement!, parentID, type);
        }
    }

    getMousePositionRelativeToParent(e: any, parentForm: string) {
        let x = e.clientX;
        let y = e.clientY;
        
        if(e.path) {
            const parentFormDiv: HTMLElement = e.path.find((div: any) => div.id === parentForm);
            const bounds = parentFormDiv.getBoundingClientRect();

            x = e.clientX - bounds.left;
            y = e.clientY - bounds.top;
        }

        return { x: x, y: y }
    }

    /**
     * Deferred event helpers
     */
    
    handleDeferredFrmDeactivate(id: string): string {
        let rtn = '';
        const func: deferredDeactivateType | null = store.getters['guiGuis/getDeferredFrmDeactivate'];
        if (func) {
            rtn = func(id);
            store.dispatch('guiGuis/setDeferredFrmDeactivate', null);
        }
        return rtn;
    }
    handleDeferredCtlDeactivate(id: string): string {
        let rtn = '';
        const func: deferredDeactivateType | null = store.getters['guiGuis/getDeferredCtlDeactivate'];
        if (func) {
            rtn = func(id);
            store.dispatch('guiGuis/setDeferredCtlDeactivate', null);
        }
        return rtn;
    }

    /**
        Compare arguments and find sort order

        args: ['1', '2', ['4', 'D']]
        a,b: sort arguments
    */
    compareArguments(args: Array<string | Array<string>>, a: any, b: any): number {
        for(let arg of args) {
            let index = 1;
            let orderType = 'A';
            if(typeof(arg) === 'string') {
                index = parseInt(arg) - 1;
            } else if(typeof(arg) === 'object') {
                index = parseInt(arg[0]) - 1;
                orderType = arg[1];
            }
            const [less, greater]: Array<number> = orderType === 'A'? [-1, 1] : [1, -1];
            if(a[index] < b[index]) {
                return less
            } else if(a[index] > b[index]) {
                return greater
            }
        }

        return 0
    }

    
    setGridItems(props: any) {
            /* Transform each sub array into a object row
        so bootstrap-vue's table/grid can injest it
        [ // Table
            [a, b, c ...], // Row 1  
            [e, f, g ...], // Row 2
            ... // n number of rows
        ] // End table
        Converts to
        [ {0: a, 1: b, 2: c ...}, {0: e, 1: g, 2: f ...}, ...]
        */

        let items: Array<any> = [];
        let row: any = {}

        props.gpValue.forEach((item: any, j: number) => {    
            row = {}
            // Place the nested array in an object (to conform to bootstrap vue)
            props.columnInfo.forEach((info: GridColumnProps, i: number) => {
            let acCol = i + 1; // Accuterm numbering starts at 1,1 not 0,0
            let acRow = j + 1;
            let id = props.id +'-col-'+ acCol +'-row-'+ acRow;
            row[i] = {
                id: id,
                value: item[i] || '',
                col: i,
                row: j,
                editable: props.type === GUIObjectType.gxGridEditable && !info.readOnly,
                focused: acCol === props.gpColumn && acRow === props.gpRow,
                backColor: this.getGridBackColor(props, i, j),
                foreColor: this.getGridForeColor(props, i, j),
                icon: this.getGridIcon(props, i, j),
                hint: this.getGridHint(props, i, j),
                eventMask: props.gpEventMask,
                enabled: props.gpEnabled,
                columnInfo: props.columnInfo[i],
                wordWrap: props.gpWordWrap,
                gridId: props.id,
                rowId: props.id + "-row-" + j
            }
            })
            items.push(row)
        }); 

        // Append blank row for autoExtend
        if(props.gpStyle === 1) { // auto extendable
        row = {}
        let id = ""
        let rowIndex = props.gpValue.length
        props.columnInfo.forEach((info: GridColumnProps, i: number) => {
            let acCol = i + 1; // Accuterm numbering starts at 1,1 not 0,0
            let acRow = rowIndex + 1;
            id = props.id +'-col-'+ acCol +'-row-'+ acRow;

            row[i] = {
            id: id,
            value: '',
            col: i,
            row: rowIndex,
            editable: props.type === GUIObjectType.gxGridEditable && !info.readOnly,
            focused: acCol === props.gpColumn && acRow === props.gpRow,
            backColor: '',
            foreColor: '',
            icon: '',
            hint: '',
            eventMask: props.gpEventMask,
            enabled: props.gpEnabled,
            columnInfo: props.columnInfo[i],
            wordWrap: props.gpWordWrap,
            gridId: props.id
            }
        })
        items.push(row);
        props.gpRows = items.length
        }

        return items
    }

    getGridBackColor(props: any, col: number, row: number): string {
        let rtn: string = '';
        let gridRow: GridRowProps;
        if (row < props.rowInfo.length && (gridRow = props.rowInfo[row])) {
        if (col < gridRow.cellBackColor.length) {      
            rtn = gridRow.cellBackColor[col] || '';
        }
        if (!rtn) {
            rtn = gridRow.rowBackColor;
        }
        }
        if (!rtn) {
        if (col < props.columnInfo.length) {
            rtn = (props.columnInfo[col] as GridColumnProps).backColor;
        }
        }
        return rtn;
    }

    getGridForeColor(props: GridProps, col: number, row: number): string {
        let rtn: string = '';
        let gridRow: GridRowProps;
        if (row < props.rowInfo.length && (gridRow = props.rowInfo[row])) {
        if (col < gridRow.cellForeColor.length) {        
            rtn = gridRow.cellForeColor[col] || '';
        }
        if (!rtn) {
            rtn = gridRow.rowForeColor;
        }
        }
        if (!rtn) {
        if (col < props.columnInfo.length) {
            rtn = props.columnInfo[col].foreColor;
        }
        }
        if (!rtn) {
        rtn = props.gpForeColor
        }
        return rtn;
    }

    getGridHint(props: GridProps, col: number, row: number): string {
        let rtn: string | Array<string> = '';
        let gridRow: GridRowProps;
        if (row < props.rowInfo.length && (gridRow = props.rowInfo[row])) {
        if (col < gridRow.cellTip.length) {        
            rtn = gridRow.cellTip[col] || '';
        }
        if (!rtn) {
            rtn = gridRow.rowTip;
        }
        }
        if (!rtn) {
        if (col < props.columnInfo.length) {
            rtn = props.columnInfo[col].tip;
        }
        }
        if (!rtn) {
        rtn = props.gpHint;
        }
        return Array.isArray(rtn) ? rtn.join('\r\n') : rtn;
    }

    getGridIcon(props: GridProps, col: number, row: number): string {
        let rtn: string = '';
        let gridRow: GridRowProps;
        if (row < props.rowInfo.length && (gridRow = props.rowInfo[row])) {
        if (col < gridRow.cellIcon.length) {
            rtn = this.getIcon(gridRow.cellIcon[col]) || '';
        }
        }
        return rtn;
    }

    setGridFields(props: GridProps) {
        let fields: Array<any> = props.columnInfo.map((field: GridColumnProps, index: number) => {
        let headerCellClass = []
        let cellClass = ['cell']

        if (field.sizable) {
            headerCellClass.push('sizable')
            cellClass.push('sizable')
        }

        if (field.align === 1) {
            headerCellClass.push('text-right')
            cellClass.push('text-right')
        } else if (field.align === 2) {
            headerCellClass.push('text-center')
            cellClass.push('text-center')
        }
        if (field.width !== 0) {
            let rtn: any = {
            label: field.heading,
            key: index.toString(),
            type: field.fieldType,
            thStyle: { 
                "background-color": props.gpBackColor,
                "min-width": field.width +'px',
                position: "sticky",
                top: "-2px",
            },
            thClass: headerCellClass,
            tdClass: cellClass
            }
        

            if (index < props.gpFixedCols) {
            rtn.stickyColumn = true
            }

            return rtn
        } else return null
        })

        return fields 
    }

    validateUserInput(value:string, dataType: number) {
        let index = -1;
        switch(dataType) {
            case GUIDataType.gdAny:
                index = 0;
                break;
            case GUIDataType.gdAlpha:
                let alphaRegx = /^[a-zA-Z ]*$/
                index = value.search(alphaRegx);
                break;
            case GUIDataType.gdAlphaNumeric: 
                let alphaNumRegx = /^[ A-Za-z0-9,+-]*$/
                index = value.search(alphaNumRegx);
                break;
            case GUIDataType.gdBool: 
                let modifiedValue: string = value.toUpperCase();
                if( modifiedValue == '1' || modifiedValue == '0' || modifiedValue == '-1' || modifiedValue == 'T' || modifiedValue == 'Y' || modifiedValue == 'TRUE' || modifiedValue == 'YES' || modifiedValue == 'ON' || modifiedValue == 'ACTIVE'
                || modifiedValue == 'F' || modifiedValue == 'N' || modifiedValue == 'FALSE' || modifiedValue == 'NO' || modifiedValue == 'OFF' || modifiedValue == 'INACTIVE') {
                    index = 0;
                } 
                break;
            case GUIDataType.gdCurrency: 
                let regex = /^[$,\u00A3, \u20A0, \u20A1, \u20A2, \u20A3, \u20A4, \u20A5, \u20A6, \u20A7, \u20A8, \u20A9, \u20AA. \u20AB, \u20AC, \u20AD, \u20AE, \u20AF, \u20B0, \u20B1, \u20B2, \u20B3, \u20B4, \u20B5, \u20B6, \u20B7, \u20B8, \u20B9, \u20BA. \u20BB, \u20BC, \u20BD, \u20BE, \u20BF]?[\-+]?\.?(?:(?:\d+[,.])*\d+(?:\.\d+)?|\d+\.\d+|\d+)\.?$/
                index = value.search(regex);
                break;
            case GUIDataType.gdDate: 
                let date: Date = new Date(value);
                const text = Date.prototype.toString.call(date);
                if(text !== 'Invalid Date'){
                    index = 0;
                } else {
                    index = -1;
                }
                break;
            case GUIDataType.gdFinancial: 
                let financialRegex = /^[\-+]?\.?(?:(?:\d+[,.])*\d+(?:\.\d+)?|\d+\.\d+|\d+)\.?$/
                index = value.search(financialRegex);
                break;
            case GUIDataType.gdNumeric: 
                let numericRegex = /^[+-]?[0-9]+?$/
                index = value.search(numericRegex);
                break;
            case GUIDataType.gdPercent: 
                let percentRegx = /^-?\d+(\.\d+)?(%?)$/
                index = value.search(percentRegx);
                break;
            case GUIDataType.gdPhone: 
                let phoneRegx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/
                index = value.search(phoneRegx);
                break;
            case GUIDataType.gdSSN: 
                let ssnRegx = /^(?!000|666)[0-8][0-9]{2}-?(?!00)[0-9]{2}-?(?!0000)[0-9]{4}$/
                index = value.search(ssnRegx);
                break;
            case GUIDataType.gdTime: 
                let timeRegx = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])(:([0-5]?[0-9]))?[ ]?(AM|am|PM|pm|Am|Pm|aM|pM)?$/
                index = value.search(timeRegx);
                break;
            case GUIDataType.gdZipCode: 
                let zipRegx = /^\d{5}([- ])?(\d{4})?$/
                index = value.search(zipRegx);
                break;
            case GUIDataType.gdCountry:
            case GUIDataType.gdState:
                index = 0;
        }
        return index;
    }

    getErrorMessage(dataType: number): string {
        let msg = 'invalid'
        switch(dataType) {
            case GUIDataType.gdAny:
                
                break;
            case GUIDataType.gdAlpha:
                msg = 'Must be Alpha characters only.';
                break;
            case GUIDataType.gdAlphaNumeric: 
                msg = 'Must be AlphaNumberic characters only.';
                break;
            case GUIDataType.gdBool: 
                msg = 'Must be Boolean value. Valid values include: 0,1,-1,T,F,Y,N,TRUE,FALSE,YES,NO,ON,OFF,ACTIVE,INACTIVE';
                break;
            case GUIDataType.gdCurrency: 
                msg = 'Must be Currency value.';
                break;
            case GUIDataType.gdDate: 
                msg = 'Must be Date value.';
                break;
            case GUIDataType.gdFinancial: 
                msg = 'Must be Numeric.';
                break;
            case GUIDataType.gdNumeric: 
                msg = 'Must be Numeric characters only.';
                break;  
            case GUIDataType.gdPercent: 
                msg = 'Must be Percentage value';
                break;
            case GUIDataType.gdPhone: 
                msg = 'Must be valid Phone number with or without an extension number.';
                break;
            case GUIDataType.gdSSN: 
                msg = 'Must be valid Social Security number, ###-##-####.';
                break;
            case GUIDataType.gdTime: 
                msg = 'Must be valid Time value.';
                break;
            case GUIDataType.gdZipCode: 
                msg = 'Must be valid Zip Code number of 5 or 9 digits.';
                break;
            case GUIDataType.gdCountry:
            case GUIDataType.gdState:
                
        }

        return msg;
    }

}

