


































import { Component, Vue, Watch } from 'vue-property-decorator'
// Libraries
import Keyboard from 'simple-keyboard'
import 'simple-keyboard/build/css/index.css'

@Component({
    name: 'TermKeyboardComponent'
})

export default class TerminalKeyboard extends Vue {

/** ******************************** Vue Data! **********************************/
    
    $refs!: {
        fakeText: HTMLTextAreaElement
    }

    private prevShowKeyboard: number = 0;
    private ignoreInputEvent = false;
    private isMobile = false;

    // Our "extras" keyboard shows keys which:
    //  - may be handled by browser and not available to web page
    //  - are not commonly found on virtual keyboards (iOS, Android)
    //  - provide terminal functions not available on physical keyboard (break, copy, paste)
    private keyboardExpand !: Keyboard;
    private keyboardSpecial !: Keyboard;
    private keyboardFunction !: Keyboard;
    private keyboardControlPad !: Keyboard;

    // Modifiers
    // modifier state: 0 = not set, 1 = set by "extras" keyboard button, 2 = set by physical keyboard
    private modifierKeys: Array<any> = [
        { key: 'Shift', sKey: '{shiftleft}', state: 0, code: 1000 },
        { key: 'Ctrl', sKey: '{controlleft}', state: 0, code: 2000 }
    ];

    // Special Keys
    private specialKeys: Array<any> = [
        { key: 'F1', sKey: '{f1}', code: 112 },
        { key: 'F2', sKey: '{f2}', code: 113 },
        { key: 'F3', sKey: '{f3}', code: 114 },
        { key: 'F4', sKey: '{f4}', code: 115 },
        { key: 'F5', sKey: '{f5}', code: 116 },
        { key: 'F6', sKey: '{f6}', code: 117 },
        { key: 'F7', sKey: '{f7}', code: 118 },
        { key: 'F8', sKey: '{f8}', code: 119 },
        { key: 'F9', sKey: '{f9}', code: 120 },
        { key: 'F10', sKey: '{f10}', code: 121 },
        { key: 'F11', sKey: '{f11}', code: 122 },
        { key: 'F12', sKey: '{f12}', code: 123 },
        { key: 'Tab', sKey: '{tab}', code: 9 },
        { key: 'Esc', sKey: '{escape}', code: 27 },
        { key: 'Home', sKey: '{home}', code: 36 },
        { key: 'End', sKey: '{end}', code: 35 },
        { key: 'PgUp', sKey: '{pageup}', code: 33 },
        { key: 'PgDn', sKey: '{pagedown}', code: 34 },
        { key: '&larr;', sKey: '{arrowleft}', code: 37 },
        { key: '&uarr;', sKey: '{arrowup}', code: 38 },
        { key: '&rarr;', sKey: '{arrowright}', code: 39 },
        { key: '&darr;', sKey: '{arrowdown}', code: 40 },
        { key: 'Ins', sKey: '{insert}', code: 45 },
        { key: 'Del', sKey: '{delete}', code: 46 },
        { key: 'Bksp', sKey: '{backspace}', code: 8 },
        { key: 'Enter', sKey: '{enter}', code: 13 },
        { key: 'Break', sKey: '{break}', code: 3 },
        { key: 'Copy', sKey: '{copy}', code: 3 },
        { key: 'Paste', sKey: '{paste}', code: 22 }
    ];

/** ******************************** Vue Computed **********************************/

    get getViewport () { return this.$store.getters['terminal/getViewport'] }

/** ******************************** Vue Methods **********************************/

    isTargetTerminal(event: KeyboardEvent): boolean {
        const viewport = this.getViewport;
        if (event && event.target && ((viewport && viewport.contains(event.target)) || (event.target === this.$refs.fakeText))) {
            return true;
        }
        return false;
    }

    onKeyPress (button: string) {

        let specialKey = this.specialKeys.find(specialKey => button === specialKey.sKey)

        // Copy function
        if (button === '{copy}') {
            const selection = document.getSelection();
            const selectionString = (selection && selection.toString()) || '';
            if (selectionString) {            
              this.$emit('handleCopy', selectionString, true);
            }
        // Paste function (modal)
        } else if (button === '{paste}') {
            this.$emit('handlePaste')
        // If the pause/break' button is pushed
        } else if (button === '{break}') {
            this.$store.dispatch('terminal/breakKey')
        // Special Keys
        } else if (specialKey) {
            let keyCode = specialKey.code
            // Loop through the modifiers array
            this.modifierKeys.forEach(mod => {
                // Apply modifiers if they're on (+1000 or +2000)
                if (mod.state) {
                    keyCode = keyCode + mod.code
                }
            })
            this.$store.dispatch('terminal/sendFunctionKey', { key: keyCode })
        } else if (this.modifierKeys.findIndex(mod => button === mod.sKey) >= 0) {
            // ignore shift / control key press
        // All other keys
        } else {
            let text = button
            let ascii: number
            // Get the keycode and check if the control key is on (per Pete S)
            if ((text.length === 1) && (ascii = text.charCodeAt(0)) && ((ascii >= 0 && ascii <= 31) || (ascii >= 64 && ascii <= 95) || (ascii >= 97 && ascii <= 122)) && this.modifierKeys[1].state) {
                // control character
                ascii &= 0x1F
                this.$store.dispatch('terminal/sendFunctionKey', { key: (ascii + 2064) })
            } else {
              this.$store.dispatch('terminal/sendKeys', { key: text })
            }
        }                
        
        // Keep Simple Keyboard's input cleared out (so it doesn't stack up all the input)
        this.keyboardExpand.clearInput()
        this.keyboardSpecial.clearInput()
        this.keyboardFunction.clearInput()
        this.keyboardControlPad.clearInput()
        // Focus back on the terminal
        //this.$store.dispatch('terminal/focus')
    }

    onKeyReleased (button: string) {
        let index = this.modifierKeys.findIndex(mod => button === mod.sKey);
        if (index >= 0) {
            this.handleModifier(index);
            this.$store.dispatch('terminal/controlKey', { state: !!this.modifierKeys[1].state });
        } else {
            // extras keyboard shift & ctrl keys are not locking
            this.handleModifier(0, false, 1);
            this.handleModifier(1, false, 1);
            this.$store.dispatch('terminal/controlKey', { state: false });
        }
    }

    onToggleExpand(expanded?: boolean) {
        if (expanded === undefined) {
            expanded = this.showKeyboard !== 2;
            this.$store.dispatch('terminal/toggleKeyboard', expanded ? 2 : 1);
        }
        const el = this.keyboardExpand.getButtonElement('{toggle}') as HTMLElement;
        if (el) {
            el.innerHTML = '<span>' + (expanded ? '&#9650;' : '&#9660;') + '</span>'
        }
    }

    // Toggle shift & control modifier state
    //   index: modifierKeys index 0 = shift, 1 = control
    //   toggle: set or reset the modifier state (if undefined, toggle current state)
    //   mask: 1 = extras keyboard, 2 = physical keyboard (if undefined, assume mask = 1)
    handleModifier(index: number, toggle?: boolean, mask?: number) {
        let value: number;
        mask = mask || 1; // default = extras keyboard
        if (toggle === undefined) {
            value = this.modifierKeys[index].state ^ mask;
        } else if (toggle) {
            value = this.modifierKeys[index].state | mask;
        } else {
            value = this.modifierKeys[index].state & ~mask;
        }
        if (value !== this.modifierKeys[index].state) {
            this.modifierKeys[index].state = value;
            if (value) {
                this.keyboardControlPad.addButtonTheme(this.modifierKeys[index].sKey, 'button-highlight');
            } else {
                this.keyboardControlPad.removeButtonTheme(this.modifierKeys[index].sKey, 'button-highlight');
            }
        }
    }

    handleFakeFocus() {
        this.$refs.fakeText.value = '~';
    }

    handleFakeBlur() {
        this.$refs.fakeText.value = '~';
    }

// Special handling for Chrome and native soft keyboards (iOS, Android)
//
// When using native soft keyboard, keydown & keyup events have
// keyCode = 0 or 229, and there is no keypress event. To work
// around this, handle the input event and extract the last character
// in the textarea. To detect a backspace, always set the textarea
// value to '~'. When native soft backspace key is pressed the
// textarea value will be empty, so we know its a backspace.
//
// Note: there are cases where this may not work correctly, such
// as when using IME or predictive text, etc.

    handleFakeInput() {
        if (this.ignoreInputEvent) return false; // handled 'keypress' instead of 'input'
        // Create a synthetic keypress event
        const keyboardEvent = new KeyboardEvent('keypress', { bubbles: true });
        Object.defineProperty(keyboardEvent, 'charCode', { get: function() { return this.charCodeVal; } });
        Object.defineProperty(keyboardEvent, 'keyCode', { get: function() { return this.charCodeVal; } });
        Object.defineProperty(keyboardEvent, 'which', { get: function() { return this.charCodeVal; } });
        Object.defineProperty(keyboardEvent, 'ctrlKey', { get: function() { return this.ctrlKeyVal || false; } });
        // Whenever we process this event, we set the value of fake_text to a single tilde character.
        // If a character is entered, we return the last character from fake_text. If the backspace
        // key is pressed, the value of fake_text will be null, so return a backspace key.
        const value = this.$refs.fakeText.value;
        let keyCode = 0;
        if (value.length === 0) {
            keyCode = 8; // backspace
        } else {
            keyCode = value.charCodeAt(value.length - 1);
            // This is a HACK! Here we modify the character entered in fake_text
            // applying our extras keyboard control key modifier state.
            if (((keyCode >= 64 && keyCode <= 95) || (keyCode >= 97 && keyCode <= 122)) && this.modifierKeys[1].state) {
                keyCode &= 31; // convert to control code
                (keyboardEvent as any).ctrlKeyVal = true;
            }
        }
        (keyboardEvent as any).charCodeVal = keyCode;
        setTimeout(() => { this.$refs.fakeText.value = '~'; }, 1); // let browser process event, then reset contents
        // Send our synthesized event to any listeners
        document.body.dispatchEvent(keyboardEvent);
    }

/** ******************************** Vue Computed **********************************/

    get showKeyboard () { return this.$store.getters['terminal/getToggleKeyboard']; }
    get showFullKeyboard () { return this.showKeyboard === 2; }

/** ******************************** Vue Mounted! **********************************/

    mounted () {

        // Hack for mobile device keyboard
        this.isMobile = ((navigator.userAgent.search(/(iPod|iPhone|iPad)/) !== -1) ||
            (navigator.platform === 'MacIntel' && typeof (navigator as any).standalone !== 'undefined') || // iPadOS 13+
            (navigator.userAgent.search(/Android\s([0-9.]*)/i) !== -1));

        let display:{ [index: string]: string } = {};
        // loop through all the special keys
        this.specialKeys.forEach(specialKey => { display[specialKey.sKey] = specialKey.key })
        this.modifierKeys.forEach(modKey => { display[modKey.sKey] = modKey.key })
        let commonKeyboardOptions = {
            theme: 'simple-keyboard hg-theme-default',
            onKeyPress: this.onKeyPress,
            onKeyReleased: this.onKeyReleased,
            autoUseTouchEvents: false, // important! when this is 'true', app will not run on mobile devices!
            disableCaretPositioning: true,
            display: display,
            mergeDisplay: false,
            physicalKeyboardHighlight: true,
            physicalKeyboardHighlightBgColor: 'rgba(51, 51, 51, 0.7)',
            physicalKehyboardTextColor: 'white',
            physicalKeyboardHighlightPress: true,
            preventMouseDownDefault: true,
            preventMouseUpDefault: true,
            stopMouseDownPropagation: true,
            stopMouseUpPropagation: true,
            buttonTheme: [
                {
                class: 'special-button',
                buttons: '{copy} {paste} {break}'
                },
                {
                class: 'toggle-button',
                buttons: '{shiftleft} {controlleft}'
                }            
            ]
        }

        this.keyboardSpecial = new Keyboard('.simple-keyboard-special', {
            ...commonKeyboardOptions,
            layout: {
                default: [
                  '{break} {escape} {tab} {backspace} {arrowleft} {arrowup} {arrowdown} {arrowright} {copy} {paste}'
                ]
            }
        });
        
        this.keyboardFunction = new Keyboard('.simple-keyboard-function', {
            ...commonKeyboardOptions,
            layout: {
                default: [
                  '{f1} {f2} {f3} {f4} {f5} {f6} {f7} {f8} {f9} {f10} {f11} {f12}'
                ]
            }
        });

        this.keyboardControlPad = new Keyboard('.simple-keyboard-control', {
            ...commonKeyboardOptions,
            layout: {
                default: [
                  '{shiftleft} {controlleft} {insert} {delete} {home} {end} {pageup} {pagedown} {enter}'
                ]
            }
        });

        // Note: this instance needs to be last since the physical keyboard
        // options only work with first instance of SimpleKeyboard.
        this.keyboardExpand = new Keyboard('.simple-keyboard-expand', {
            theme: 'simple-keyboard hg-theme-default',
            onKeyPress: () => this.onToggleExpand(), // use this form so argument to onToggleExpand is undefined (that is by design)
            autoUseTouchEvents: false, // important! when this is 'true', app will not run on mobile devices!
            disableCaretPositioning: true,
            mergeDisplay: false,
            preventMouseDownDefault: true,
            preventMouseUpDefault: true,
            stopMouseDownPropagation: true,
            stopMouseUpPropagation: true,
            layout: {
                default: ['{toggle}']
            },
            display: {
                '{toggle}': '&#9660;'
            }
        });

        // Physical Keyboard support      
        document.addEventListener('keypress', event => {
            if (this.isTargetTerminal(event)) {
                this.ignoreInputEvent = true; // if we receive an 'input' event before next 'keydown', ignore it (we are handling 'keypress')
                const keyCode = event.charCode || 0;
                if (((keyCode >= 64 && keyCode <= 95) || (keyCode >= 97 && keyCode <= 122)) && this.modifierKeys[1].state) {
                    // We want to allow either our "extras" control key, or the physical control key, to
                    // generate control characters, so redirect this event to the onKeyPress handler.
                    event.preventDefault();
                    event.stopPropagation();
                    this.onKeyPress(String.fromCharCode(keyCode));
                }
            }
        });

        document.addEventListener('keydown', event => {
            if (this.isTargetTerminal(event)) {
                // track physical keyboard shift & ctrl keys
                if (event.key === 'Shift') {
                    this.handleModifier(0, true, 2);
                } else if (event.key === 'Control') {
                    this.handleModifier(1, true, 2);
                } else {
                    const keyCode = event.keyCode || 0;
                    if (this.specialKeys.find(key => keyCode === key.code)) {
                        // this key is handled by SimpleKeyboard (physicalKeyboardHighlightPress=true)
                        event.preventDefault();
                        event.stopPropagation();
                    } else {
                        this.ignoreInputEvent = false; // if we get an 'input' event before 'keypress', probably Chrome with soft keyboard
                    }
                }
            }
        });

        document.addEventListener('keyup', event => {
            if (this.isTargetTerminal(event)) {
                // track physical keyboard shift & ctrl keys
                if (event.key === 'Shift') {
                    this.handleModifier(0, false, 2);
                } else if (event.key === 'Control') {
                    this.handleModifier(1, false, 2);
                } else {
                    const keyCode = event.keyCode || 0;
                    if (this.specialKeys.find(key => keyCode === key.code)) {
                        // this key is handled by SimpleKeyboard
                        event.preventDefault();
                        event.stopPropagation();
                        // soft keyboard shift & ctrl keys are not locking
                        this.handleModifier(0, false, 1);
                        this.handleModifier(1, false, 1);
                    }
                }
            }
        });

        const appleCopy: HTMLElement = this.keyboardSpecial.getButtonElement('{copy}') as HTMLElement;
        appleCopy.addEventListener('touchstart', event => {
            const selection = document.getSelection();
            const selectionString = (selection && selection.toString()) || '';
            if (selectionString) {            
              console.log('apple selection: ' + selectionString);
            }
            
/*

const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
navigator.permissions.query(queryOpts as any).then(
    (perms) => {
        // Will be 'granted', 'denied' or 'prompt':
        console.log('clipboard-read permission: ' + perms.state);
        // Listen for changes to the permission state
        perms.onchange = () => {
            console.log('clipboard-read permission updated: ' + perms.state);
        }
    }
).then(
    (result) => {
        console.log('trying clipboard read text');
        try {
            navigator.clipboard.readText().then(
                (clipText) => {
//                        this.pasteClipboardValue = clipText;
                    console.log('copy then: ', clipText);
                }
            ).catch((e) => {
//                    this.cantPaste = true
                console.log('copy reject: ', e);
                }
            )
        } catch (e) {
            // reading from clipboard not supported
            console.log('copy error: ', e);
//                this.cantPaste = true
        }
//            this.$refs.pasteModal.show()
        });
*/        
    });
    
    }


/** ******************************** Vue Watch **********************************/

    // Watch the toggleKeyboard state
    @Watch('showKeyboard') onToggleKeyboard () {
        if (this.showKeyboard === 1 && this.prevShowKeyboard === 0) {
            // Only show soft keyboard when extras keyboard is first opened, not
            // when toggling between condensed and expanded "extras" keyboard.            
            this.onToggleExpand(false); // set to unexpanded state
            this.handleModifier(0, false, 3); // reset shift state
            this.handleModifier(1, false, 3); // reset control state
            
            // This is a bit of a kludge! We normally set focus to the fake_text textarea
            // to bring up the mobile virtual keyboard. However, when there is a selection
            // present on the terminal screen, focusing the textarea removes the selection
            // and makes our Copy button useless. We could use some tricks to save & restore
            // the selection, however, this has the undesired side effect of dismissing the
            // virtual keyboard on mobile devices. It does not seem to be possible to have
            // a selection on the terminal screen AND focus on fake_text at the same time!            
            // As a workaround, check if there is a selection and skip the fake_text focus
            // unless using a mobile device. For mobile devices, the device has its own
            // Copy / Select / Search menu, so user does not need our Copy button. If we
            // improperly misidentify a device, the virtual keyboard may not will not show
            // if a selection is present when the Keyboard is toggled.
            let selection: any;
            try { selection = document.getSelection(); } catch (e) {}
            if (this.isMobile || !selection || !selection.rangeCount) {
                const fakeText = this.$refs.fakeText;
                fakeText.focus(); // show virtual keyboard on mobile devices
            }

        } else if (this.showKeyboard === 0) {
            this.handleModifier(0, false, 3); // reset shift state
            this.handleModifier(1, false, 3); // reset control state
            const fakeText = this.$refs.fakeText;
            fakeText.blur(); // hide soft keyboard on mobile devices
        }
        this.prevShowKeyboard = this.showKeyboard;
    }

}
