
/*
 * AccuTerm Web GUI interface
 *
 * Copyright 2020 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { TermColors } from './termcolors.js';
import { DEBUG } from './globals.js';

/* jshint browser: true, jquery: true, expr: true, laxcomma: true, sub: true */
/* global DEBUG: false, console_log: false, debug_log_content: false */

var GUIFuncs = (function() {
    
    var guiconst = {

        guiVersion: 2.0, // version 2.0

        // commands
        gcContext: 0,
        gcInitialize: 1,
        gcCreate: 2,
        gcDelete: 3,
        gcSetProp: 4,
        gcGetProp: 5,
        gcMethod: 6,
        gcWaitEvent: 7,
        gcControl: 8,
        gcMsgBox: 9,
        gcInputBox: 10,
        gcMacro: 11,
        gcGetUpds: 12,
        gcCheckEvent: 13,
        gcOpenDialog: 14,
        gcSaveDialog: 15,
        gcFileDialog: 16,
        gcFontDialog: 17,
        gcColorDialog: 18,
        gcShutdown: 99,
    
        // object types
        gxRoot: -1,
        // application
        gxFirstApp: 0,
        gxMDI: 0,
        gxSDI: 1,
        gxLastApp: 1,
        // form
        gxFirstForm: 5,
        gxFormSizable: 5,
        gxFormFixed: 6,
        gxDialog: 7,
        gxSDISizable: 8,
        gxSDIFixed: 9,
        gxLastForm: 9,
        // controls
        gxFirstControl: 15,
        gxLabel: 15,
        gxEdit: 16,
        gxEditMultiline: 17,
        gxCommand: 18,
        gxOption: 19,
        gxCheck: 20,
        gxList: 21,
        gxListMultisel: 22,
        gxDrpDwnCbo: 23,
        gxDrpDwnList: 24,
        gxPicture: 25,
        gxFrame: 26,
        gxGrid: 27,
        gxGridEditable: 28,
        gxEditPassword: 29,
        gxTabgrp: 30,
        gxTab: 31,
        gxGauge: 32,
        gxTree: 33,
        gxCheckedList: 34,
        gxTimer: 35,
        gxBrowser: 36,
        gxLastControl: 36,
        // menus
        gxFirstMenu: 75,
        gxMenu: 75,
        gxPopup: 76,
        gxToolbar: 77,
        gxStatusbar: 78,
        gxLastMenu: 78,
        // internal use
        pxBorder: 998, // border container control for grid
        pxSizer: 999, // sizer control (design time only)
    
        // events (combine using, //or' to form event mask)
        geClose: 1,
        geActivate: 8,
        geDeactivate: 16,
        geClick: 32, // left-button click
        geDblClick: 64,
        geChange: 128,
        geValidate: 256,
        geLoadlist: 512, // combo/grid loads new dropdown list
        geValidateCell: 1024, // grid cell edit validation
        geValidateRow: 2048, // grid row change validation
        geContext: 4096, // right-button click
        geBtnClick: 8192, // grid cell button or edit control up/dn button clicked
        geActivateCell: 16384, // grid cell entered
        geResize: 0x8000, // form resized
        geColClick: 0x10000, // column clicked (grid or list)
        geDeactivateCell: 0x20000, // grid cell leave
        geActivateRow: 0x40000, // grid row entered
        geDeactivateRow: 0x80000, // grid row leave
        geHelp: 0x100000, // F1 key or built-in HELP menu ID
        geStatus: 0x200000, // status change (treeview node expanded or collapsed)
        geTimer: 0x400000, // timer timeout
        geDragDrop: 0x800000, // drag-and-drop
        geQuit: 0x40000000, // gui server terminated
        geEllipsis: 8192, //alias for geBtnClick
    
        // properties
        gpDefVal: 0, // default value
        gpValue: 1, // all controls should support this property
        gpLeft: 2,
        gpTop: 3,
        gpWidth: 4,
        gpHeight: 5,
        gpChanged: 6, // all forms must support this property!
        gpScale: 7, // app only
        gpEnabled: 8,
        gpVisible: 9,
        gpStyle: 10,
        gpBorder: 11,
        gpReadOnly: 12,
        gpTabStop: 13,
        gpBackColor: 14,
        gpForeColor: 15,
        gpFontName: 16,
        gpFontSize: 17,
        gpFontBold: 18,
        gpFontItalic: 19,
        gpHelpFile: 20, // app only
        gpHelpID: 21,
        gpCaption: 22, // may be same as value
        gpPicture: 23, // picture box, form or button
        gpRtnEqTab: 24, // app only
        gpAlign: 25,
        gpItems: 26,
        gpColumns: 27,
        gpRows: 28,
        gpDataType: 29,
        gpColumn: 30, // grid column
        gpRow: 31, // grid row
        gpColHeading: 32, // list/combo/grid heading row
        gpGridLines: 33, // list/combo/grid grid style
        gpColFieldType: 34, // grid column field type (text/combo/check)
        gpColDataType: 35, // grid column data type
        gpColItems: 36, // grid column item list
        gpColWidth: 37, // grid column width
        gpColAlign: 38, // list/combo/grid column alignment
        gpDataCol: 39, // combo column used as data
        gpArrange: 40, // option group arrangement
        gpDescription: 41, // app description
        gpAuthor: 42, // app author
        gpCopyright: 43, // app copyright
        gpVersion: 44, // app version
        gpLogo: 45, // about box logo file name
        gpAutoSelect: 46, // app only
        gpStatus: 47, // various status information (number of visible forms, list of child objects, etc.)
        gpWindowState: 48, // form window state (0:normal, 1:minimized, 2:maximized)
        gpHint: 49, // help hint (baloon help)
        gpExtension: 50, // private extension for 4GL integration
        gpMaxLen: 51, // max length for edit/combo/grid
        gpMaxLines: 52, // max lines for multiline edit
        gpMaxDrop: 53, // max rows for dropdown combo/list
        gpRequired: 54, // required field
        gpFixedCols: 55, // grid number of fixed (non-scrolling) columns
        gpIcon: 56, // app / form icon file name
        gpSelStart: 57, // edit control selection start (first char = 1) or grid selection start cell
        gpSelLength: 58, // edit control selection length or grid selection cols/rows
        gpHelpType: 59, // app help type (0:WinHelp, 1:Help event)
        gpTimeout: 60, // app busy message timeout, or timer interval
        gpMsgText: 61, // app busy message text
        gpState: 62, // node state (0:collapsed, 1:expanded)
        gpColSizable: 63, // grid column is resizable
        gpColHint: 64, // help hint for grid column
        gpAltColor: 65, // alternate row color for grid
        gpNoAutoTips: 66, // disable treeview autotips for truncated node text (app only)
        gpTransparent: 67, // transparent background (label, frame, checkbox)
        gpFontUnderline: 68, // underlined font style
        gpIconAlign: 69, // icon alignment for command button
        gpIconSize: 70, // icon size for command button, grid, list
        gpWordWrap: 71, // grid word-wrap style
        gpContent: 72, // browser content (html)
        gpDragID: 73, // drag-and-drop source ID
        gpDropIDs: 74, // drag-and-drop target ID list
        gpDragMode: 75, // grid drag mode (move active cell, select cells, drag cell, nothing)
        gpSelRange: 76, // edit or grid selection range
        gpColTabStop: 77, // grid column tab stop (orverrides default tab behavior)
        gpFocusStyle: 78, // grid focus style (0:default, 1:none, 2:thin, 3:thick)
        gpPasteMode: 79, // grid paste mode (0:single cell, 1:multi cell)
        gpWindowHandle: 98, // return form or app window handle (for sdi app, returns (7.1.2008)
        gpEventMask: 99,
        gpCustom: 100,
        // special purpose properties
        gpPrinterName: 201, // current printer name (1.3)
        gpOrientation: 202, // printer page orientation (1.3)
        gpPaperSource: 203, // printer paper source (1.3)
        gpPaperSize: 204, // printer paper size (1.3)
        gpPrintQuality: 205, // printer quality (1.3)
        gpPrintCopies: 206, // printer copies (1.3)
        gpPrintDuplexMode: 207, // printer duplex mode (1.3)
        gpPrintColorMode: 208, // printer color mode (1.3)
        // dummy property indexs
        gpObjectType: -1,
        gpObjectID: -2,
    
        // data types
        gdAny: 0,
        gdAlpha: 1,
        gdAlphaNumeric: 2,
        gdBool: 3,
        gdCountry: 4,
        gdCurrency: 5,
        gdDate: 6,
        gdFinancial: 7,
        gdNumeric: 8,
        gdPercent: 9,
        gdPhone: 10,
        gdSSN: 11,
        gdState: 12,
        gdTime: 13,
        gdZipCode: 14,
    
        // methods
        gmReset: 0, // all forms must support this method - resets all controls to default value
        gmClear: 1, // listbox / combobox
        gmShow: 2,
        gmHide: 3,
        gmActivate: 4,
        gmInsert: 5,
        gmRemove: 6,
        gmEnable: 7,
        gmDisable: 8,
        gmMove: 9,
        gmPrint: 10,
        gmHelp: 11,
        gmSort: 12,
        gmCopy: 13,
        gmCut: 14,
        gmPaste: 15,
    
       // scale mode
        gsmDefault: 0, // default = characters
        gsmTwips: 1, // 1 twip = 1/20 point = 1/1440 inch
        gsmPoints: 2, // 1 point = 1/72 inch
        gsmPixels: 3,
        gsmCharacters: 4,
        gsmInches: 5,
        gsmMillimeters: 6,
        gsmCentimeters: 7,
    
        // text alignment
        galLeft: 0, // default = left aligned
        galRight: 1, // right aligned
        galCenter: 2, // centered
        
        // border style
        gbsNone: 0, // no border
        gbsFlat: 1, // flat border
        gbs3D: 2, // 3D border
    
        // grid style
        glsNone: 0, // no grid lines
        glsHoriz: 1, // horizontal grid lines
        glsVert: 2, // vertical grid lines
        glsBoth: 3, // horizontal & vertical grid lines
    
        // grid column type
        gctLabel: 0,
        gctEdit: 1,
        gctCheck: 2,
        gctList: 3,
        gctCombo: 4,
        gctEllipsis: 5,
        gctIcon: 6,
        gctButton: 7,
    
        // toolbar / statusbar position
        gtbFloat: 0, // floating toolbar
        gtbTop: 1, // top of window
        gtbBottom: 2, // bottom of window
        gtbLeft: 3, // left edge of window
        gtbRight: 4, // right edge of window
        
        // option button arrangment
        garDown: 0,
        garAcross: 1,
    
        // window state
        gwsNormal: 0,
        gwsMinimized: 1,
        gwsMaximized: 2,

        // error level
        glvlNone: 0, // no error
        glvlWarn: 1, // warning
        glvlFail: 2, // command failed
        glvlFatal: 3, // fatal error - program must end

        // error codes
        grSuccess: 0, // success!
        grFirst: 5001,
        grFailure: 5001, // general failure
        grInvProp: 5002, // invalid property code (object does not support this property)
        grInvArg: 5003, // invalid property or argument value
        grNoID: 5004, // missing or invalid ID
        grInvVer: 5005, // invalid version or corrupt project
        grNoMac: 5006, // unable to record macro
        grNoEvent: 5007, // no objects to trigger event
        grObs1: 5008, // obsolete error (no gcEndEvent command)
        grCacheFail: 5009, // error accessing macro cache
        grNoForm: 5010, // must specify a form
        grObs2: 5011, // obsolete error (only one app allowed)
        grNoParent: 5012, // parent of control not found
        grNoCreate: 5013, // failed to create object
        grInvMenu: 5014, // invalid menu structure
        grNoPost: 5015, // unable to post events
        grInvFunc: 5016, // invalid function
        grNoDelete: 5017, // cannot delete main mdi app because shared mdi apps still exist
        grSuspended: 5018, // command not permitted while suspended
        grCancel: 5019, // user cancelled action
        grPending: 5020, // new command invalid until previous command has responded
        grCantActivate: 5021, // cannot activate hidden or disabled object (disabled/hidden ancestor may cause this error)
        grBadID: 5022, // ID is not valid for the object being created
        grExist: 5023, // an object of the specified ID already exists
        grNoExist: 5024, // an object of the specified ID does not exist
        grParentNotAllowed: 5025, // the object type being created cannot designate a parent
        grNoDisable: 5026, // unable to disable a visible dialog form
        grNoShow: 5027, // unable to show a disabled dialog form
        grNoHiddenApp: 5028, // unable to show dialog form when containing app is hidden
        grItemIDNotAllowed: 5029, // property or function does not support optional item ID
        grInvMeth: 5031, // invalid method code (object does not support this method)
        grNoFile: 5032, // file not found

        // design time warnings & errors
        grwFirst: 5051,
        grwOldVer: 5051, // project version older than designer
        grwNewVer: 5052, // project version newer than designer
        grwBadScale: 5053, // invalid scale mode found
        grwMethProp: 5054, // method in macro converted to property (warning)
        grwMethIgn: 5055, // method in macro ignored (warning)
        grwLast: 5055
    };    

    var GUIError = function(level, error, message, command, args) {
        var rtn = [level ? level : guiconst.glvlFail, command ? command : '', '', '', error, message ? message : ''];
        if (args instanceof Array) {
            if (args[0])
                rtn[2] = args[0]; // object id
            if (args[1])
                rtn[3] = args[1]; // property or method code
        }
        return [rtn]; // return array containing array
    };
    
    return {
        
        // Request is a nested array matching attribute, value, sub-value dynamic array. Convert
        // the request array into an array of IGUICmdxxx objects. May throw exception for bad
        // request format (not enough arguments, etc.) or invalid version.
        ParseRequest: function(reqs) {
            if (!(reqs instanceof Array))
                throw GUIError(guiconst.glvlFatal, guiconst.grFailure, 'invalid request');
            var i, j;
            var req;
            var cmd;
            var obj;
            var left, top, width, height;
            var id = '', pid, item;
            var parts;
            var val;
            var icon;
            var type = 0; // save type in gcCreate in case gcSetProp for popup menu needs to alter gpVisible
            for (i = j = 0; i < reqs.length; i++) {
                req = reqs[i];
                if (!(req instanceof Array)) {
                    req = [req];            
                }
                cmd = parseInt(req[0]);
                if (!Number.isNaN(cmd)) { // ignore comments & blank lines
                    switch(cmd) {
                        case guiconst.gcContext :
                            if (req.length < 2)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = { 
                                command: cmd,
                                newVersion: +req[1],
                                newScale: req.length >= 3 ? +req[2] : 0 // ignored when newVersion <= 0 (context pop)
                            };
                            break;
                        case guiconst.gcInitialize : 
                            if (req.length < 2)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            var hostver;
                            var appver = +req[1];
                            if (req.length >= 3)
                                hostver = +req[2];
                            else
                                hostver = appver;
                            if (hostver < 1.2 || appver < 1.0 || appver > hostver || appver > guiconst.guiVersion)
                                throw GUIError(guiconst.glvlFail, guiconst.grInvVer, '', cmd);
                            obj = {
                                command: cmd,
                                appVersion: appver,
                                hostVersion: hostver
                            };
                            break;
                        case guiconst.gcCreate :
                            if (req.length < 4)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            id = req[1];
                            parts = id.split('*');
                            pid = req[2];
                            if (pid)
                                pid = parts[0] + '*' + parts[1] + '*' + pid; // parent is control on form
                            else if (parts[0] && parts[1] && parts[2])
                                pid = parts[0] + '*' + parts[1]; // parent is form
                            else if (parts[0] && (parts[1] || parts[2]))
                                pid = parts[0]; // parent is app
                            else
                                pid = ''; // parent is root object
                            type = +req[3];
                            left = req.length >= 5 ? (req[4] === 'auto' ? 'auto' : +req[4]) : 'auto'; 
                            top = req.length >= 6 ? (req[5] === 'auto' ? 'auto' : +req[5]) : 'auto'; 
                            width = req.length >= 7 ? +req[6] : 0; 
                            height = req.length >= 8 ? +req[7] : 0;                             
                            obj = {command: cmd, guiobj: {
                                type: type,
                                id: req[1],
                                parent: pid,
                                left: left,
                                top: top,
                                width: width,
                                height: height,
                                events: req.length >= 9 ? +req[8] : 0
                            }};
                            break; 
                        case guiconst.gcDelete : 
                            if (req.length < 2)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            id = req[1];
                            obj = {command: cmd, id: id};    
                            break;
                        case guiconst.gcSetProp :
                            if (req.length < 3)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            item = '';
                            if (req[1]) { // reuse previous ID if not specified
                                parts = req[1].split('*');
                                if (parts.length > 3) {
                                    id = parts.slice(0, 3).join('*');
                                    item = parts[3];
                                } else {
                                    id = req[1]; // '*' is root object
                                }
                            }
                            if (req.length < 6)
                                val = '';
                            else if (req.length === 6)
                                val = req[5] instanceof Array ? [req[5]] : req[5];
                            else
                                val = req.slice(5);
                            var prop = +req[2];
                            if (prop === guiconst.gpForeColor ||
                                prop === guiconst.gpBackColor ||
                                prop === guiconst.gpAltColor) {
                                val = TermColors.XlateRGB(val); // convert CSS color to Windows 32 bit color
                            }
                            if (prop === guiconst.gpVisible && type === guiconst.gxPopup && val)
                                val = 0; // BUG in GUI designer leaves gpVisible = 1, but this causes problems in VUE component!                    
                            obj = {
                                command: cmd,
                                id: id,
                                property: prop,
                                col: req.length >= 4 ? +req[3] : 0,
                                row: req.length >= 5 ? +req[4] : 0,
                                item: item,
                                value: val
                            };
                            break;
                        case guiconst.gcGetProp :
                            if (req.length < 3)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            item = '';
                            if (req[1]) { // reuse previous ID if not specified
                                parts = req[1].split('*');
                                if (parts.length > 3) {
                                    id = parts.slice(0, 3).join('*');
                                    item = parts[3];
                                } else {
                                    id = req[1]; // '*' is root object
                                }
                            }
                            obj = {
                                command: cmd,
                                id: id,
                                property: +req[2],
                                col: req.length >= 4 ? +req[3] : 0,
                                row: req.length >= 5 ? +req[4] : 0,
                                item: item
                            };
                            break;
                        case guiconst.gcMethod :
                            if (req.length < 3)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            item = '';
                            if (req[1]) { // reuse previous ID if not specified
                                parts = req[1].split('*');
                                if (parts.length > 3) {
                                    id = parts.slice(0, 3).join('*');
                                    item = parts[3];
                                } else {
                                    id = req[1]; // '*' is root object
                                }
                            }
                            obj = {
                                command: cmd,
                                id: id,
                                method: +req[2],
                                item: item
                            };
                            if (req.length >= 4)
                                obj.args = req.slice(3);                            
                            break;
                        case guiconst.gcWaitEvent :
                            obj = {command: cmd};
                            break;
                        case guiconst.gcControl :
                            if (req.length < 2)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = {command: cmd, func: +req[1]};
                            break;
                        case guiconst.gcMsgBox : 
                            if (req.length < 4)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            val = +req[3];
                            icon = (val >> 4) & 7;
                            icon = (icon & 2) ? icon ^ 1 : icon;
                            obj = {command: cmd,
                                prompt: req[1],
                                title: req[2],
                                buttons: val & 7,
                                default: (val >> 8) & 3,
                                icon: icon,
                                helpId: req.length >= 5 ? req[4] : ''
                            };
                            break;
                        case guiconst.gcInputBox :
                            if (req.length < 4)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = {command: cmd,
                                prompt: req[1],
                                title: req[2],
                                default: req[3],
                                helpId: req.length >= 5 ? req[4] : ''
                            };
                            break;
                        case guiconst.gcMacro :
                            if (req.length < 2)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            val = +req[1];
                            switch(val) {
                                case 0:
                                    throw [[0, 0]]; // local macro cache not supported
                                case 1:
                                case 2:                                
                                    break; // run the macro
                                case 3:
                                    throw GUIError(guiconst.glvlWarn, guiconst.grCacheFail, '', cmd);
                                default:
                                    throw GUIError(guiconst.glvlFail, guiconst.grInvArg, '', cmd);
                            }
                            obj = {command: cmd};
                            if (req.length >= 3) obj.type = req[2];
                            if (req.length >= 4) obj.timestamp = req[3];
                            if (req.length >= 5) obj.macroid = req[4];
                            if (req.length >= 6) obj.program = req[5];
                            // rest of macro follows this request
                            break;
                        case guiconst.gcGetUpds :
                            if (req.length < 2)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            id = req[1];
                            obj = {command: cmd, id: id};                        
                            break;
                        case guiconst.gcCheckEvent :
                            if (req.length < 2)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = {command: cmd, timeout: +req[1]};                        
                            break;
                        case guiconst.gcOpenDialog :
                            if (req.length < 4)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = {command: guiconst.gcFileDialog,
                                defaultPath: req[1],
                                title: req[2],
                                extFilter: req[3],
                                style: 1 // TODO: add enum for file dialog style
                            };
                            break;
                        case guiconst.gcSaveDialog :
                            if (req.length < 4)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = {command: guiconst.gcFileDialog,
                                defaultPath: req[1],
                                title: req[2],
                                extFilter: req[3],
                                style: 0 // TODO: add enum for file dialog style
                            };
                            break;
                        case guiconst.gcFileDialog :
                            if (req.length < 5) 
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = {command: cmd,
                                defaultPath: req[1],
                                title: req[2],
                                extFilter: req[3],
                                style: +req[4]
                            };
                            break;
                        case guiconst.gcFontDialog :
                            if (req.length < 6)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = {command: cmd, font: {
                                name: req[1],
                                size: +req[2],
                                bold: !!req[3],
                                italic: !!req[4],
                                underline: !!req[5]
                            }};
                            break;
                        case guiconst.gcColorDialog :
                            if (req.length < 2)
                                throw GUIError(guiconst.glvlFatal, guiconst.grInvArg, 'insufficient arguments', cmd);
                            obj = {command: cmd, color: TermColors.XlateRGB(req[1])};
                            break;
                        case guiconst.gcShutdown :
                            obj = {command: cmd};
                            break;
                        default:
                            throw GUIError(guiconst.glvlFatal, guiconst.grInvFunc);
                    }
                }
                reqs[j++] = obj; // replace the element in the request array with GUI command object
            }
            reqs.splice(j, i - j); // remove comments, etc.
			return reqs;
        },
        
        BuildResponse: function(rsps) {
            if (!(rsps instanceof Array))
                return [GUIError(guiconst.glvl.Fatal, guiconst.grFailure, 'invalid response')];
            var i, j;
            var rsp;
            var cmd;
            var obj;
            var val;
            var item;
            var rtn = [];
            try {
                for (i = 0; i < rsps.length; i++) {
                    rsp = rsps[i];
                    if (rsp.error !== guiconst.grSuccess) {
                        // return error object
                        obj = GUIError(rsp.level, rsp.error, rsp.message, rsp.command, rsp.args);                       
                    } else {
                        cmd = rsp.command;
                        obj = 0; // no error
                        switch(cmd) {
                            case guiconst.gcInitialize : 
                                obj = [0, guiconst.guiVersion];
                                break;
                            case guiconst.gcGetProp:
                                val = rsp.value;
                                if (rsp.property === guiconst.gpForeColor ||
                                    rsp.property === guiconst.gpBackColor ||
                                    rsp.property === guiconst.gpAltColor) {
                                    val = TermColors.XlateBGR(val); // convert CSS color to Windows 32 bit color
                                }
                                if (val instanceof Array) {
                                    obj = [0].concat(val); // list of values
                                } else {
                                    obj = [0, val]; // simple value
                                }
                                break;
                            case guiconst.gcMethod:
                                if (rsp.value) {                                
                                    if (rsp.value instanceof Array) {
                                        obj = [0].concat(rsp.value); // list of values
                                    } else {
                                        obj = [0, rsp.value]; // simple value
                                    }
                                }                                
                                break;
                            case guiconst.gcGetUpds:
                                if (rsp.updates.length > 0) {
                                    // first, return list of update ids (control ID only, no app or form ID!)
                                    obj = [0];
                                    rsp.updates.forEach(function(item) {obj.push(item.id.split('*')[2])});
                                    rtn.push(obj);
                                    // next, return the 'getprop' result for each item in list
                                    rsp.updates.forEach(function(item) {
                                        if (item.value instanceof Array) {
                                            obj = [0].concat(item.value);
                                        } else {
                                            obj = [0, item.value];
                                        }
                                        rtn.push(obj);
                                    });
                                    obj = null; // ignore this after switch
                                }
                                break;
                            case guiconst.gcWaitEvent:
                            case guiconst.gcCheckEvent:
                                for (j = 0; j < rsp.events.length; j++) {
                                    var evt = rsp.events[j];
                                    if (evt.value) {
                                        // event need 2 response elements
                                        obj = [0, evt.id, [evt.event, 2]];
                                        if (evt.args)
                                            obj = obj.concat(evt.args);
                                        rtn.push(obj);
                                        obj = [0].concat(evt.value);
                                    } else {
                                        obj = [0, evt.id, [evt.event, 1]];
                                        if (evt.args)
                                            obj = obj.concat(evt.args);
                                    }
                                    rtn.push(obj);
                                }                                
                                obj = null; // ignore this after switch
                                break;
                            case guiconst.gcMsgBox:
                            case guiconst.gcInputBox:
                            case guiconst.gcFileDialog:
                                if (rsp.result instanceof Array) {
                                    obj = [0].concat(rsp.result);
                                } else {
                                    obj = [0, rsp.result];
                                }
                                break;
                            case guiconst.gcColorDialog:
                                if (rsp.result) {
                                    obj = [0, TermColors.XlateBGR(rsp.result)];
                                }                                
                                break;
                            case guiconst.gcFontDialog:
                                if (rsp.result) {
                                    obj = [0, rsp.result.name, rsp.result.size,
                                        rsp.result.bold ? 1 : 0,
                                        rsp.result.italic ? 1 : 0,
                                        rsp.result.underline ? 1 : 0];
                                }
                                break;
                            default: // nothing to return except error level (0)
                                break;
                        }
                    }
                    if (obj !== null)
                        rtn.push(obj);
                }
            } catch(e) {
                rtn = [GUIError(guiconst.glvlFatal, guiconst.grFailure, e.message || 'exception')];
            }
            return rtn;
        }

        // GUI TESTING
        /*
        ,
        TestGui: function(reqs) {
            // opts.request: array of GUI reqeusts
            // opts.callback: callback function accepts array of GUI responses
            var req;
            var rsp;
            var rsps = [];
            var cmd;
            var i;
            for (i = 0; i < reqs.length; i++) {
                req = reqs[i];
                cmd = req.command;
                switch(cmd) {
                    case guiconst.gcInitialize:
                        rsp = {command: cmd, error: 0, guiVersion: guiconst.guiVersion};
                        rsps.push(rsp);
                        break;
                    case guiconst.gcGetProp:
                        rsp = {command: cmd, error: 0, property: req.property, value: ''};
                        rsps.push(rsp);
                        break;
                    case guiconst.gcGetUpds:
                        rsp = {command: cmd, error: 0, updates: []};
                        rsps.push(rsp);
                        break;
                    case guiconst.gcMethod:
                        rsp = {command: cmd, error: 0, method: req.method};
                        rsps.push(rsp);
                        break;
                    case guiconst.gcWaitEvent:
                    case guiconst.gcCheckEvent:
                        rsp = {command: cmd, error: 0, events: [
                            {event: guiconst.geQuit, id: ''}
                        ]};
                        rsps.push(rsp);
                        break;
                    case guiconst.gcMsgBox:
                        rsp = {command: cmd, error: 0, result: 0};
                        rsps.push(rsp);
                        this.break;
                    case guiconst.gcInputBox:
                    case guiconst.gcOpenDialog:
                    case guiconst.gcSaveDialog:
                    case guiconst.gcFileDialog:
                    case guiconst.gcColorDialog:
                        rsp = {command: cmd, error: 0, result: ''};
                        rsps.push(rsp);
                        this.break;
                    case guiconst.gcFontDialog:
                        rsp = {command: cmd, error: 0, font:
                            {name: "Courier", size: 10, bold: false, italic: false, underline: false}
                        };
                        rsps.push(rsp);
                        this.break;
                    case guiconst.gcMacro:
                        rsp = {command: cmd, error: 0, result: 1};
                        rsps.push(rsp);
                        break;
                    case guiconst.gcContext:
                    case guiconst.gcCreate:
                    case guiconst.gcDelete:
                    case guiconst.gcSetProp:
                    case guiconst.gcControl:
                    case guiconst.gcShutdown:
                        rsp = {command: cmd, error: 0};
                        rsps.push(rsp);
                        break;
                    default:
                        rsp = {command: cmd, error: guiconst.grInvFunc, level: guiconst.glvlFatal};
                        rsps.push(rsp);
                }
            }
            return rsps;
        }
        */
    }; 
    
})();

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { GUIFuncs };
