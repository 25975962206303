import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
// State persistence package (vuex-persistedstate)
import createPersistedState from 'vuex-persistedstate';
// Types
import { RootState } from '@/types/store/'
// Utils
import Utils from '@/utils'
// Submodules
import { errors } from '@/store/errors'
import { gui } from '@/store/gui'
import { profiles } from '@/store/profiles'
import { terminal } from '@/store/terminal'
import { settings } from '@/store/config-settings'
import { user } from '@/store/user'

Vue.use(Vuex)

const userState = createPersistedState({
    paths: ['user.enterprise']
  })

const store: StoreOptions<RootState> = {
    state: {
        version: process.env.VUE_APP_VERSION || '0.0.0',
        environment: process.env.NODE_ENV,
        loading: true,
        browser: {
            ie: Utils.detectIE()
        }
    },
    getters: {
        getVersion (state) { return state.version },
        getEnvironment (state) { return state.environment },
        getLoading (state) { return state.loading },
        getBrowser (state) { return state.browser }
    },
    mutations: {
        toggleLoading (state, loading) { state.loading = loading }
    },
    actions: {
        toggleLoading ({ commit }, { loading }) { commit('toggleLoading', loading) }
    },
    modules: {
        errors,
        gui,
        profiles,
        terminal,
        user,
        settings
    },
    plugins: [userState]
}

export default new Vuex.Store<RootState>(store)
