









































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { IGUIEvent } from './../../types'
import { OptionProps, GUIArrange, GUIEventCode, OptionItemProps } from './../../types/enums'
import { OptionItem, CharacterUnderlineProps } from './../../types/components'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';
// Components
import CharacterUnderline from './../layout/CharacterUnderline.vue';

@Component({
  name: 'gxOptionComponent',
  components: { CharacterUnderline }
})

export default class gxOption extends Vue {
  /** ******************************** Vue Props **********************************/

   @Prop({ default: () => (new OptionProps()) }) private props!: OptionProps;

  /** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();

  public activated: boolean = false

  /** ******************************** Vue Computed **********************************/
  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }

  get inline () { return this.props.gpArrange === GUIArrange.garAcross };

  get options(): Array<OptionItemProps> { return this.props.gpItems }

  get columnsRows () {

    let cols: number = this.props.gpColumns > 0 ? this.props.gpColumns : 1
    let rows: number = this.props.gpRows > 0 ? this.props.gpRows : 1
    const optionsCount: number = this.options.length  

    const newCols = ((optionsCount - 1) / rows) + 1;
    const newRows = ((optionsCount - 1) / cols) + 1;
    
    if (this.inline) {

        if (cols <= 1) {
          if (cols < newCols) {
            cols = newCols
          }
        } else {
          if (rows < newRows) {
            rows = newRows
          }
        }

    } else {

        if (rows <= 1) {
          if (rows < newRows) {
            rows = newRows
          }
        } else {
          if (cols < newCols) {
            cols = newCols
          }
        }

    }

    return { rows: rows, cols: cols }
  }

  get optionsGrid() { 
    const options = this.options
    const optionsLength = options.length
    const columnsRows = this.columnsRows;
    
    let items: Array<Array<OptionItem>> = [];
    let value: number = 0;

    if (this.inline) {
      // Split the options array into row chunks based on col length
      for (let i = 0; i < optionsLength; i += columnsRows.cols) {
        // row chunk
        let row: Array<OptionItemProps> = options.slice(i, columnsRows.cols + i);
        // add the value property
        let newRow: Array<OptionItem> = row.map(col => {
          value++;
          return { ...col, value: value };
        })

        items.push(newRow);
      }
      // example of 9, 3 cols, 3 rows would return
      // [ 
      //    [ options[0], options[1], options[2] ],
      //    [ options[3], options[4], options[5] ],
      //    [ options[6], options[7], options[8] ],
      // ]
    } else {
      // Add empty rows
      for (let i = 0; i < columnsRows.rows; i++) {
        items.push([])
      }

      // Split the options array into col chunks based on number of rows
      for (let i = 0; i < optionsLength; i += columnsRows.rows) {
        // col chunk
        let col: Array<OptionItemProps> = options.slice(i, columnsRows.rows + i);
        // loop through the column
        for (let j = 0; j < col.length; j++) {
          value++;
          let c = { ...col[j], value: value };

          items[j].push(c)
        }
      }
      // example of 9, 3 cols, 3 rows would return
      // [ 
      //    [ options[0], options[3], options[6] ],
      //    [ options[1], options[4], options[7] ],
      //    [ options[2], options[5], options[8] ],
      // ]
    }
  
    return items
  }

  get captionText () { return this.props.gpCaption }

  get value () { return this.props.gpValue }
  set value(val) {
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: val }) 
  }

  get readOnly (): boolean { return this.props.gpReadOnly }

  get required (): boolean { return this.props.gpRequired }

  get visible (): boolean { return this.props.gpVisible }
  
  get optionHint() { return Array.isArray(this.props.gpHint) ? this.props.gpHint.join('\r\n') : this.props.gpHint; }

  get tabindex(): number { return this.props.gpTabStop && !this.props.gpReadOnly ? 0: -1}

  get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const font = this.utils.controlFont(this.props);
    const border = this.utils.controlBorder(this.props)
    const alignment = this.utils.controlAlign(this.props)

    const containerCSS: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor,
      marginBottom: '0px'
    }
    if (this.props.gpTransparent) {
        containerCSS.backgroundColor = 'transparent';
    }
    style.push(size, font, border, alignment, positioning, containerCSS)

    return style
  }

  get legendStyle (): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const font = this.utils.controlFont(this.props)

    const width: Partial<CSSStyleDeclaration> = {
      width: 'auto',
      marginBottom: '0px'
    }

    style.push(font, width)

    return style
  }

  get optionStyle(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    const margin: Partial<CSSStyleDeclaration> = { marginRight: '5px' }

    if (this.inline) {
      style.push({ display: 'inline-block'});
    }

    style.push(margin);

    return style

  }

  get enabled(): boolean { return this.props.gpEnabled }

  /** ******************************** Vue Methods **********************************/

  optionClickHandler(e: any) {
    
    let args = [e.target.value]
    
    if (this.props.gpEventMask & GUIEventCode.geClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  rightClickHandler(e: MouseEvent) {
    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        let index = this.value;
        if (index === 0) index = 1; // default to first button if nothing selected
        let option: any = this.$refs[index];
        if (Array.isArray(option)) option = option[0];
        (option as HTMLInputElement).focus();
console.log('Option Focus Me '+ this.value +' in '+ this.props.id);
      } catch(e) {
console.log('Option Focus Me error: ' + e);
      }
    }
  }

  activateHandler(e: any) {
    if (!this.activated) {
      this.activated = true;
      let prevControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
      if (!prevControlID) {
        // handle the deferred deactivate event
        prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
      }
      if (prevControlID !== this.props.id) {
console.log('Option activate ' + this.props.id + ' from ' + prevControlID);
        if (this.props.gpEventMask & GUIEventCode.geActivate) {
          const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID] }
          this.$store.dispatch('guiGuis/addEvent', activateEvent);
        }
        // Clear the changed property
        this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: false })
      } else console.log('   skipping Option activate ' + this.props.id + ' - ' + (prevControlID ? ' self' : ' null'));
      // Set focused in store and in parent form
      this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id })
    } 
  }

  deactivateHandler(e: any) {
    if (this.activated) {
      // if the current target doesn't contain the related
      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.activated = false
        const nextControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
        if (nextControlID) {
          this.handleDeactivateEvents(nextControlID);
        } else {
          this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring Option deactivate ' + this.props.id + ' related = null');
        }
      }
    }
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('Option deactivate ' + this.props.id + ' to ' + nextControlID);
      if (this.props.gpEventMask & GUIEventCode.geValidate && this.props.changed) {
        const validateEvent: IGUIEvent = { event: GUIEventCode.geValidate, id: this.props.id, args: [nextControlID], value: this.value }
        this.$store.dispatch('guiGuis/addEvent', validateEvent);
      }
      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
        this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
      }
    } else console.log('   skipping Option deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

  cuProps(props: any): CharacterUnderlineProps {
    return { app: props.app!.id, form: props.form!.id, control: props.id, type: props.type }
  }

  mounted() {
    this.$nextTick(() => { this.focus(); });
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus()
  }  
}

