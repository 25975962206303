






import { DialogState } from '../../../types/store/guis'
import { Sketch } from 'vue-color'
import { colorObject } from '../../../types'
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'ColorDialogComponent',
  components: {Sketch}
  })
export default class ColorDialog extends Vue{
  @Prop() public dialogBox: any;
  
  get colors() { return this.dialogBox.color; }

  updateValue (value: colorObject) {
    const colorBox: DialogState = {
        type: 4,
        visible: true,
        title: null,
        message: null,
        buttons: 1,
        defBtn: 0,
        answer: null,
        icon: null,
        extFilter: null,
        fileStyle: null,
        font: null,
        color: value,
        about: null
      }

      this.$store.dispatch('guiGuis/setDialogBox', colorBox)
  }

}
