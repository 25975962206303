













import { Component, Vue, Prop } from 'vue-property-decorator'
  // Types
  
  @Component({
    name: 'AboutBoxComponent'
  })
  
  export default class AboutBox extends Vue {
    
  /** ******************************** Vue Data **********************************/
  @Prop() public dialogBox: any;
  
  /** ******************************** Vue Computed **********************************/
    get description() { return this.dialogBox.about.description}
    get author() { return this.dialogBox.about.author}
    get copyright() { return this.dialogBox.about.copyright }
    get version() { return this.dialogBox.about.version}
    get logo() { return this.dialogBox.about.logo }
    
  /** ******************************** Vue Methods **********************************/
  
  }
  