/*
 * AccuTerm Web Main Application
 *
 * Copyright 2018 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { TermUtil } from './termutil.js';
import { TermSettings } from './termsettings.js';
import { AccuTerm } from './tdmain.js';
import { TerminalExport as Terminal } from './term.js';
import { TelnetProtocol } from './telnet.js';
import { wsSocket } from './wssock.js';
import { DEBUG, app } from './globals.js';

/* jshint browser: true, latedef: nofunc, expr: true, eqnull: true, sub: true, laxbreak: true */
/* global AccuTerm: false, Terminal: false, TermUtil: false, TelnetProtocol: false, SSHProtocol: false */
/* global DEBUG: false */
/* exported TermApp */
// global 'app' is necessary for Cordova
/* global app */

// for debugging...
 function getClassName(obj) {
	// jshint curly: false //
	if(typeof obj === "undefined") return "undefined";
	if(obj === null) return "null";
	if(obj.constructor && obj.constructor.toString) {
		var arr = obj.constructor.toString().match(/function\s*(\w+)/);
		if(arr && arr.length === 2) {
			return arr[1];
		}
	}
	return "undefined";
 }

// case insensitive string compare
function isEqualNoCase(a, b) {
  try { // in case either a or b is undefined
	  return a.toString().toLowerCase() === b.toString().toLowerCase();
  } catch(e) {}
  return false;
}

// events:
//   status:    terminal status has changed
//   beep:      emit terminal beep sound
//   cursor:    cursor position has been updated
//   clipboard: copy text to clipboard
//   browser:   open browser to URL
//   mailto:    send an email message
//   telephone: dial a phone number
 
// arguments:
//   div:       reference to <div> where terminal is rendered
//   profile:   instance of TermSettings class

function TermApp(div, profile) {

    DEBUG&&console.log('TermApp constructor');

    Terminal.EventEmitter.call(this); // initialize event emitter

    // connection states
    var CONN_NONE = 0,
        CONN_CONNECTING = 1,
        CONN_CONNECTED = 2,
        CONN_DISCONNECTING = 3,
        CONN_CLOSED = 4;

    // load a profile
    var termSettings; // use a clone for the working terminal settings (that way, we can compare current & updated settings)
    termSettings = new TermSettings(profile);

    DEBUG&&console.log('initial termSettings = ' + JSON.stringify(termSettings));

    // create the terminal session state object
    var appState = {
        termSettings: termSettings, // current terminal settings / profile
        connectionState: CONN_NONE, // current connection state
		connected: false,       // track connected state
        beeping: false,         // set while client is emitting terminal beep
		finalMsg: false         // when closing, final message has already been emitted
    };
    this.appState = appState; // make state & settings public

	// To remain compatible with older versions of FTVSINF, we need
	// to send version number >= 4. So, lets just start at 8.x.x,
	// since AccuTerm WEB is a companion app for AccuTerm 8.
	var version = (function() {
		if (typeof app !== 'undefined' && app.version) {
			return app.version || '0.0.0';
        }
		return '8.0.101';
	})();

	var product = (function() {
		if (typeof app !== 'undefined' && 
			typeof app.initialize === 'function' &&
			typeof app.bindEvents === 'function' &&
			typeof app.onDeviceReady === 'function')
			return 'Mobile';
		return 'Web';						
	})();

    this.openTerminal = function (options) {

        DEBUG&&console.log('openTerminal; options = ' + (options || ''));
        var terminal = new AccuTerm(termSettings);
        appState.terminal = terminal;

        terminal.open(div); // pass DOM div object to render terminal

        var textarea = document.getElementById("fake_text");
        if (textarea) {
            textarea.addEventListener('focus', function() {
                DEBUG&&console.log('caught fake_text focus');
                terminal.focus();
            });
        }
        Terminal._textarea = textarea;

        terminal.on('bell', (function () {
            if (!appState.beeping && appState.termSettings.terminalBell) {
                appState.beeping = true;
                this.emit('beep');
                setTimeout(function () {
                    appState.beeping = false;
                }, 500); // limit rate of beeps to 2/sec
            }
        }).bind(this));

        terminal.on('playsound', (function(e) {
            var filename = (e && e.filename) || '';
            DEBUG&&console.log('playsound: filename=' + filename);
            this.emit('beep', filename);
        }).bind(this));

        terminal.on('cursor', (function (e) {
            //DEBUG&&console.log('cursor: x=' + e.x + ' y=' + e.y);
			if(e.hasOwnProperty('pg')) {
	            this.emit('cursor', e.x + 1, e.y + 1, e.pg + 1);
			} else {
	            this.emit('cursor', e.x + 1, e.y + 1);
			}
        }).bind(this));

        // While this sort-of works, we cannot assume Ctrl+V is used for paste.
        // We need to implement the CopyPasteShortcut property in TermSettings,
        // and IF the settings specify using Ctrl+C/Ctrl+V (or alternatively,
        // Ctrl+Ins/Shift+Ins), we need to block sending the control key in the
        // keydown/keypress handler, then attempt to copy or paste. And don't
        // forget about copy. It may be best to fire an event and let the wrapper
        // handle this, maybe use the handleCopy & handlePaste methods in Terminal.vue.
        /*
        terminal.on('keydown', ( async function (e) {
            if(e.ctrlKey && e.code === "KeyV") {
                if(navigator.clipboard && navigator.clipboard.readText) {
                    navigator.clipboard.readText().then((text) => {
                        terminal.sendPasteText(text);
                    }).catch((err) => {
                        DEBUG&&console.log(err)
                        alert("Browser does not support pasting.");
                    })
                } else {
                    DEBUG&&console.log("Browser does not support pasting.");
                    alert("Browser does not support pasting.");
                }
            }
        }).bind(this));
        */

		terminal.on('colorchange', (function(e) {
			DEBUG&&console.log('colorchange: backcolor=' + e.backcolor);
			this.emit('colorchange', e.backcolor, appState.termSettings.themeStyle);
		}).bind(this));
        this.emit('colorchange', terminal.colors[256], appState.termSettings.themeStyle); // event not fired from Terminal constructor
		
		terminal.on('copy', (function (e) {
            DEBUG&&console.log('copy: text=' + e.text);
            this.emit('clipboard', e.text);            
        }).bind(this));
        
        terminal.on('browser', (function (e) {
            DEBUG&&console.log('browser: URL=' + e.URL);
            this.emit('browser', e.URL);            
        }).bind(this));
        
        terminal.on('mailto', (function (e) {
            DEBUG&&console.log('mailto: recip=' + e.recipient);
            this.emit('mailto', e); // e = {recipient: xx, subject: xx, body: xx}
        }).bind(this));
        
        terminal.on('telephone', (function (e) {
            DEBUG&&console.log('tel: number=' + e);
            this.emit('telephone', e); // e = phone number            
        }).bind(this));

		terminal.on('getinfo', (function (rtn) {
			if(typeof rtn !== 'object')
				rtn = {};
			rtn.version = version;
			rtn.product = product;					
        }));
        
        // process GUI request
        //  opts.request = request object containing array of GUI command objects
        //  opts.callback = function to call with response
        terminal.on('gui', (function (opts) {
            //DEBUG&&console.log('gui: ' + ((opts && opts.request && opts.request[0] && opts.request[0].command) || '?'));
            this.emit('gui', opts);
        }).bind(this));
        
        // display a "modal" message box with title, message and buttons defined
        // by the options object:
        //  options.title = message box title text
        //  options.message = message text (newlines are OK)
        //  options.button1 = text of first button caption
        //  options.button2 = text of second button caption (or undefined if only 1 button)
        //  options.callback = function to call with result (0 if click X, or button number clicked)
        terminal.on('msgbox', (function (options) {
            DEBUG&&console.log('msgbox: ' + (options || {}).toString());
            this.emit('msgbox', options);
        }).bind(this));


        var no_banner = (options && options.banner === null) || false;
        if (!no_banner) {
            var banner = (options && options.banner) || 'Welcome to AccuTerm ' + product + '!';
            terminal.write(banner + '\r\n');
        }

    };

    this._openSocket = function () {
        DEBUG&&console.log('TermApp._openSocket');
        appState.connectionState = CONN_NONE;
        //var socket = new GapTcpSocket(TermUtil.GetHostName(appState.termSettings.host), +appState.termSettings.port, {raw:true});
        var secure = appState.termSettings.hasOwnProperty("SECURE") ? !!appState.termSettings.SECURE : true; // default to secure connection
		var socket = new wsSocket(TermUtil.GetHostName(appState.termSettings.host), +appState.termSettings.port, {raw:true, secure:secure});
        DEBUG&&console.log("created new wsSocket: host=" + socket.host + " port=" + socket.port);
        socket.onopen = this._socketConnected.bind(this);
        socket.onclose = this._socketClosed.bind(this);
        socket.onerror = this._socketError.bind(this);
        appState.socket = socket;
    };

	this._closeSocket = function () {
        DEBUG&&console.log("TermApp._closeSocket");
		appState.connected = false;
        if (appState.socket) {
            appState.socket.close();
			appState.socket = null;
        }
	};
	
    this.closeConnection = function () {
        DEBUG&&console.log("TermApp.closeConnection");
        appState.terminal.removeAllListeners('data');
        if (appState.kaTimer) {
            clearTimeout(appState.kaTimer);
            appState.kaTimer = null;
        }
		appState.connected = false;
		if(appState.connectionState === CONN_CONNECTED) {
			appState.connectionState = CONN_CLOSED;
			appState.protocol && appState.protocol.shutdown && appState.protocol.shutdown();
		}
        appState.socket && appState.socket.close();
        appState.protocol = null;
    };

    this.openConnection = function () {
        DEBUG&&console.log('TermApp.openConnection: protocol=' + termSettings.connection);
        // open the connection
		appState.finalMsg = false;
        switch (termSettings.connection.toUpperCase()) {
/* TELNET & SSH are mobile only...
            case 'TELNET':
                this.openSocket();
                appState.protocol = new TelnetProtocol(
                    appState.terminal.write.bind(appState.terminal),
                    appState.socket.send,
                    this._onDisconnect.bind(this),
					function () {return termSettings;}
                );
                appState.socket.onmessage = appState.protocol.inbound;
                appState.terminal.on('data', appState.protocol.outbound);
                DEBUG&&console.log("TermApp.openConnection calling socket.connect; id=" + appState.socket.sockId);
                appState.connectionState = CONN_CONNECTING;
				appState.connected = true;
                this.emit('status', 'Connecting to ' + appState.termSettings.host);
                appState.socket.connect(termSettings.timeout * 1000);
                break;
            case 'SSH':
                this._openSocket();
                appState.protocol = new SSHProtocol(
                    appState.terminal.write.bind(appState.terminal),
                    appState.socket.send,
                    this._onDisconnect.bind(this),
                    function () {return termSettings;},
                    this.socketError.bind(this),
                    this.confirmHostKey.bind(this)
                );
                appState.socket.onmessage = appState.protocol.inbound;
                appState.terminal.on('data', appState.protocol.outbound);
                DEBUG&&console.log("TermApp.openConnection calling socket.connect; id=" + appState.socket.sockId);
                appState.connectionState = CONN_CONNECTING;
				appState.connected = true;
                this.emit('status', 'Connecting to ' + appState.termSettings.host);
                appState.socket.connect(termSettings.timeout * 1000);
                break; */
			case 'WEBSOCKET': // telnet over websocket
                this._openSocket();
                appState.protocol = new TelnetProtocol(
                    appState.terminal.write.bind(appState.terminal),
                    appState.socket.send,
                    this._onDisconnect.bind(this),
					function () {return termSettings;}
                );
                appState.socket.onmessage = appState.protocol.inbound;
                appState.terminal.on('data', appState.protocol.outbound);
                DEBUG&&console.log("TermApp.openConnection calling socket.connect");
                appState.connectionState = CONN_CONNECTING;
				appState.connected = true;
                this.emit('status', 'Connecting to ' + appState.termSettings.host);			
                appState.socket.connect(termSettings.timeout * 1000);
				break;
            case 'LOOPBACK':
                appState.protocol = new LoopbackProtocol(
                    appState.terminal.write.bind(appState.terminal),
                    undefined,
                    undefined,
					function () {return termSettings;}
                );
                appState.terminal.on('data', appState.protocol.outbound);
				appState.connected = true;
                DEBUG&&console.log("opening loopback connection");
                break;
            default:
                break;
        }
    };

	// handle protocol disconnect (either clean or with error message)
	this._onDisconnect = function (msg) {
        DEBUG&&console.log('TermApp._onDisconnect: msg=' + msg + '; state=' + appState.connectionState);
		appState.connected = false;
		if(msg && !appState.finalMsg) {
			appState.finalMsg = true;		
			this.emit('status', msg);
		}
		this._closeSocket();
	};

	// handle socket error (does not assume socket is closed, so we expect socketClosed after this)
    this._socketError = function (code, msg) {
        DEBUG&&console.log('TermApp._socketError: msg=' + msg + '; state=' + appState.connectionState);
        //TODO: display appropriate message
		if(msg && !appState.finalMsg) {
			appState.finalMsg = true;
			appState.connected = false; // this is the last 'status' event, so make sure he knows the connection is closed!
			this.emit('status', msg);
		}
    };

    this._socketConnected = function () {
        DEBUG&&console.log('TermApp._socketConnected');			   
        appState.connectionState = CONN_CONNECTED;
        appState.connected = true;
        if (appState.termSettings.keepaliveSec > 0 && appState.protocol && appState.protocol.sendKeepalive) {
            appState.kaTimer = setTimeout(this.sendKeepalive.bind(this), appState.termSettings.keepaliveSec * 1000);
        }
        this.emit('status', 'Connected to ' + appState.termSettings.host);
    };

    this._socketClosed = function (code) {
        DEBUG&&console.log('TermApp._socketClosed: code=' + (code || 0));
        appState.connectionState = CONN_CLOSED;
        appState.connected = false;
		appState.socket && appState.socket.removeAllListeners();
		appState.socket = null;
        appState.protocol = null;
        appState.terminal.removeAllListeners();
        appState.terminal.write('\r\n\Disconnected\r\n');
		if(!appState.finalMsg) {
			appState.finalMsg = true;	
			this.emit('status', 'Disconnected');
		}
    };

    this.showTerminal = function () {
        if (appState.terminal) {
            DEBUG&&console.log('showTerminal calling focus() on terminal object');
            appState.terminal.focus();
        }
    };

    this.containerResize = function (resizeViewport) {
        if (appState.terminal) {
            if (resizeViewport)
                appState.terminal.resizeTerminalViewport();
            appState.terminal.scrollViewToCursorPos();
        }
    };

    this.focus = function () {
        // dont steal focus from fake_text, used by mobile soft keyboard
        if (document.activeElement.id !== 'fake_text') {
            // set focus to the terminal div
            appState.terminal.element.focus();
        }
        // indicate terminal has focus
        appState.terminal.focus();
    };

    // Note: this does not actually remove focus from any element, but
    // indicates to the terminal that it does not have focus and should
    // not process keyboard events.
    this.blur = function () {
        // indicate terminal does not have focus
        appState.terminal.blur();
    };

    // options: 
    //  connect=false - disconnect
    //  connect=true - connect after updating settings
    //  resetConnection=true - disconnect, then connect after updating settings
    //  resetTerminal=true - reset the terminal before connecting
    this.updateSettings = function (newSettings, options) {
        DEBUG&&console.log('termapp.updateSettings');
        options = options || {};
        if (appState.kaTimer) {
            clearTimeout(appState.kaTimer);
            appState.kaTimer = null;
        }
        if (options.hasOwnProperty('connect') && options.connect === false) {
            DEBUG&&console.log('termapp.updateSettings: closing connection for protocol ' + termSettings.connection);
            this.closeConnection();
        }
        var reset_connection = options.resetConnection || false;
        var connect = reset_connection || options.connect;
        /* jshint eqeqeq: false */
        reset_connection |= ((termSettings.connection !== newSettings.connection) || (termSettings.host !== newSettings.host) || (termSettings.port != newSettings.port));
        /* jshint eqeqeq: true */
        if (reset_connection) {
            DEBUG&&console.log('termapp.updateSettings: reset connection (closing) for protocol ' + termSettings.connection);
            this.closeConnection();
        }
        var reset_terminal = options.resetTerminal || false;
        if (reset_terminal) {
            DEBUG&&console.log('termapp.updateSettings: reset terminal');
            appState.terminal.reset();
        }
        appState.terminal.updateSettings(newSettings); // this may change some settings to ensure they are valid
        termSettings = newSettings;
        appState.termSettings = termSettings;
        if (connect) {
            DEBUG&&console.log('termapp.updateSettings: opening connection for protocol ' + termSettings.connection);
            this.openConnection();
        } else if (appState.connectionState === CONN_CONNECTED && appState.termSettings.keepaliveSec > 0 && appState.protocol && appState.protocol.sendKeepalive) {
            appState.kaTimer = setTimeout(this.sendKeepalive.bind(this), appState.termSettings.keepaliveSec * 1000);
        }
    };

    this.terminalFunction = function (theFunc) {
        if (appState.terminal) {
            DEBUG&&console.log('terminalFunction: ' + theFunc);
            switch (theFunc) {
                case 'break':
                    if (appState.protocol && typeof appState.protocol.sendBreakKey === "function") {
                        appState.protocol.sendBreakKey(); // TODO: break over socket.io
                    }
                    break;
            }
        }
    };

    this.sendKeepalive = function () {
        if (appState.connectionState === CONN_CONNECTED && appState.termSettings.keepaliveSec > 0 && appState.protocol && appState.protocol.sendKeepalive) {
            appState.protocol.sendKeepalive();
            appState.kaTimer = setTimeout(this.sendKeepalive.bind(this), appState.termSettings.keepaliveSec * 1000);
        }
    };

    // Send function or keypad key (e.g. when using a button bar for function keys)
    this.sendFunctionKey = function (theKey) {
        if (appState.terminal) {
            //DEBUG&&console.log('sendFunctionKey: ' + theKey);
            appState.terminal.sendFunctionKey(theKey);
        }
    };

    // Set the state of the pseudo CTRL key (e.g. when a toggle button is used as the CTRL key)
    this.setControlKey = function (newState) {
        if (appState.terminal) {
            //DEBUG&&console.log('setControlKey: ' + newState);
            appState.terminal.setControlKey(newState);
        }
    };

    // Send keyboard keys (text, not key code, e.g. when using simple-keyboard)
    this.sendKeys = function (text) {
        if (appState.terminal) {
            //DEBUG&&console.log('sendKeys: ' + text);
            appState.terminal.sendKeys(text);
        }
    };

    this.pasteText = function (text) {
        if (appState.terminal) {
            appState.terminal.sendPasteText(text);
        }
    };

    this.scanText = function (text) {
        if (appState.terminal) {
            appState.terminal.sendScanText(text);
        }
    };

    this.sendMouseClick = function (ev) {
        function getCoords(ev) {
            var x, y, w, h, termViewport, history, term;
            // TODO: ignore browsers without pageX for now
            if (!document || !appState.terminal) return null;
            if (ev.pageX == null) {
                DEBUG&&console.log("getCoords: no pageX or touches[0].pageX property found");
                return null;
            } 
            // .terminal-viewport element (where all the scrolling is accounted for)
            termViewport = ev.currentTarget;
            
            x = ev.pageX;
            y = ev.pageY - termViewport.offsetTop; // Subtract any top offset (a.k.a. the header)

            history = ev.currentTarget.querySelectorAll('.history')[0];
            term = ev.currentTarget.querySelectorAll('.terminal')[0];
            
            //  Mouse click y position plus (distance scrolled from the top of the .terminal-viewport element minus the height of the .history element)    
            y = y + (termViewport.scrollTop - history.clientHeight);

            // convert to cols/rows
            //PJS: use font properties for column/row conversion	 
            //PJS: use Math.floor instead of Math.round for better precision
            if (appState.terminal.fontProps) {
                x = Math.floor(x / appState.terminal.fontProps.fontWidth);
                y = Math.floor(y / appState.terminal.fontProps.fontHeight);
            } else {
                w = term.clientWidth;
                h = term.clientHeight;
                x = Math.floor((x / w) * appState.terminal.cols);
                y = Math.floor((y / h) * appState.terminal.rows);
            }

            // be sure to avoid sending bad positions to the program
            if (x < 0 || y < 0 || x >= appState.terminal.cols || y >= appState.terminal.rows) {
                DEBUG&&console.log("getCoords: coordinates out of range - x=" + x + " y=" + y);
                return null; // could be in history or off edge of screen
            }

            return {
                x: x,
                y: y
            };
        }
        var button = ev.button != null ? +ev.button : ev.which != null ? ev.which - 1 : null;
        if (button !== null) {
            var coords = getCoords(ev);
            if (coords) {
                if (appState.terminal.sendMouseClick(button + 1, coords.x, coords.y)) {
                    ev.type === 'contextmenu' && ev.preventDefault(); // cancel the browser context menu
                }
            }
        }
    };
    
    this.resetTerminal = function() {
        appState.terminal.reset();
    };

	this.isConnected = function() {
		return this.appState.connected;
    };
    

    /*	
     // confirm that host key fingerprint is OK using "trust on first use" strategy
     this.confirmHostKey = function(fp) {
     var saved_fp,
     display_fp,
     host = TermUtil.GetHostName(appState.termSettings.host),
     hostkeysJSON = localStorage.getItem('ssh_hostkeys') || '',
     hostkeys,
     message;
     
     //DEBUG&&console.log('retrieved ssh_hostkeys from local storage: ' + hostkeysJSON);
     
     function hostKeyConfirmed(host, fp, upd) {
     if (upd) {
     // update host key in local storage
     hostkeys[host] = fp;
     hostkeysJSON = JSON.stringify(hostkeys);
     localStorage.setItem('ssh_hostkeys', hostkeysJSON);
     //DEBUG&&console.log('saved ssh_hostkeys in local storage: ' + hostkeysJSON);			
     }
     // send confirmation to SSH protocol 
     // jshint expr: true //
     appState.protocol && appState.protocol.confirmHostKey && appState.protocol.confirmHostKey(true); // continue connection
     }
     
     if (fp && host) {
     try {
     hostkeys = JSON.parse(hostkeysJSON) || {};
     } catch(e) {
     hostkeys = {};
     }
     saved_fp = hostkeys[host];
     if (saved_fp === undefined) {
     hostKeyConfirmed(host, fp, true); // new host key found - trust on first use
     } else if (fp === saved_fp) {
     // host key unchanged
     hostKeyConfirmed(host, fp, false);
     } else {
     // display dialog to confirm change in host key
     display_fp = '';
     for (var i = 0; i < fp.length; i += 2) {
     display_fp += fp.substr(i, 2);
     if (i < fp.length - 2) {
     display_fp += ':';
     }
     }			
     message = "The public key for host '" + host + "' has changed. The new key fingerprint is\r\n\r\n" +
     display_fp + "\r\n\r\nPress Save to accept the new key or Abort to abort the connection.";
     navigator.notification.confirm(message, function(buttonIndex) {
     // jshint eqeqeq: false //
     if (buttonIndex == 1) {
     // update the host key in local storage & send confirmation to SSH protocol
     hostKeyConfirmed(host, fp, true);
     } else {
     // abort the SSH connection
     // jshint expr: true //
     appState.protocol && appState.protocol.confirmHostKey && appState.protocol.confirmHostKey(false); // abort connection
     }
     }, 'Confirm', ['Save','Abort']);			
     }	
     } else {
     // return result to SSH protocol
     // jshint expr: true //
     appState.protocol && appState.protocol.confirmHostKey && appState.protocol.confirmHostKey(false); // abort connection if host or fingerprint are missing
     }
     };
     */
}

// Add events to TermApp
Terminal.inherits(TermApp, Terminal.EventEmitter);

// A protocol that echoes keys back to the screen
function LoopbackProtocol(_infunc, _outfunc, _discfunc, _settingsfunc) {
    var infunc = _infunc;
    var outfunc = _outfunc;
    var discfunc = _discfunc;
    var settingsfunc = _settingsfunc;
    this.inbound = function (data) {
        infunc(data);
    }.bind(this);
    // loop outgoing data back to terminal
    this.outbound = function (data) {
        infunc(data);
    }.bind(this);
}

// Socket.io protocol (passthru)
function PassthruProtocol(_infunc, _outfunc, _discfunc, _settingsfunc) {
    var infunc = _infunc;
    var outfunc = _outfunc;
    var discfunc = _discfunc;
    var settingsfunc = _settingsfunc;
    this.inbound = function (data) {
        infunc(data);
    }.bind(this);
    this.outbound = function (data) {
        outfunc(data);
    }.bind(this);
}


////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { getClassName, isEqualNoCase, TermApp, DEBUG };
