


















































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
// Types
import Profile from '@/types'
// Utilities
import Utils from '@/utils'

@Component({
    name: 'ProfileCardComponent'
})

export default class ProfileCard extends Vue {
/** ******************************** Vue Props! **********************************/

    @Prop() private profile!: Profile;
    @Prop() private profileIndex!: number;
    @Prop() private active!: boolean;

/** ******************************** Vue Data **********************************/

    private fullProfile!: Profile;
    private hasFullProfile: boolean = false;
    private isLoading: boolean = false;
    private logError = {
        type: 'component',
        show: true,
        message: '',
        friendly: 'We were unable to retrieve the AccuTerm profile details',
        script: 'components/profiles/ProfileCard.vue'
    }

    private showing: boolean = false;

    private detailID: string = this.profile.id + '-details';

    private connection: Array<any> = [];
    private terminalSettings: Array<any> = [];
    private terminalScreen: Array<any> = [];
    private terminalBackground: Array<any> = [];
    private terminalFont: Array<any> = [];

    private onOffSettings: Array<any> = [];

    private termUtil = new Utils();

/** ******************************** Vue Computed **********************************/

    get authToken () { return this.$store.getters['user/getAuthToken'] }

    get profileService () { return this.$store.getters['profiles/getProfileService'] }

/** ******************************** Vue Methods **********************************/

    getProfileDetails () {
        let logError
        this.isLoading = true
        if (this.profileService) {
            // Get the affiliated profile from the profile service
            this.profileService.getProfile(this.profile.id, this.authToken).then((response: Profile) => {
                this.fullProfile = { ...response }
                this.hasFullProfile = true
                this.isLoading = false
                this.handleProfileView()
                this.$root.$emit('bv::toggle::collapse', this.detailID)
                this.showing = true
            }).catch((error: any) => {
                this.isLoading = false
                this.logError.message = 'Unable to fetch profile details - ' + error.message
                this.$store.dispatch('errors/addError', { ...this.logError })
            })
        } else {
            this.isLoading = false
            this.logError.message = 'Profile service not working while attempting to fetch profile details'
            this.$store.dispatch('errors/addError', { ...this.logError })
        }
    }

    async goToTerminal () {
        await this.$store.dispatch('profiles/setActiveByID', { id: this.profile.id })
        this.$router.push({ name: 'Terminal' })
    }

    handleProfileView () {
        this.connection = [
            { setting: 'Host Name or IP Address', value: this.fullProfile.host },
            { setting: 'Host Type', value: this.fullProfile.hosttype },
            { setting: 'Port', value: this.fullProfile.port },
            { setting: 'Echo (duplex)', value: this.fullProfile.duplex },
            { setting: 'Connect Timeout (seconds)', value: this.fullProfile.timeout },
            { setting: 'Kepalive (seconds)', value: this.fullProfile.keepaliveSec }
        ]

        this.terminalSettings = [
            { setting: 'Terminal Emulation', value: this.fullProfile.termtype },
            { setting: 'Host Terminal Type', value: this.fullProfile.hosttermtype },
            { setting: 'Answerback', value: this.fullProfile.terminalAnswerBack }
        ]

        let themeStyle = this.termUtil.getThemeStyle({ style: this.fullProfile.themeStyle })

        this.terminalScreen = [
            { setting: 'Normal Screen Size', value: ' Rows: ' + this.fullProfile.normRows + ' x Columns: ' + this.fullProfile.normCols },
            { setting: 'Extended Screen Size', value: ' Rows: ' + this.fullProfile.extRows + ' x Columns: ' + this.fullProfile.extCols },
            { setting: 'Screen Pages', value: this.fullProfile.screenPages },
            { setting: 'History Rows', value: this.fullProfile.historyRows },
            { setting: 'Cursor Style', value: this.fullProfile.cursorStyle },
            { setting: 'Theme Style', value: themeStyle }
        ]
        this.terminalBackground = [
            { setting: 'Background Image', value: this.fullProfile.backgroundPicturePath },
            { setting: 'Background Image Opacity', value: this.fullProfile.backgroundPictureAlpha },
            { setting: 'Background Image Scaling', value: this.fullProfile.backgroundPictureMode }
        ]

        let fontFamily = this.termUtil.getFontFamily({ font: this.fullProfile.fontName })

        this.terminalFont = [
            { setting: 'Font Family', value: fontFamily },
            { setting: 'Font Size', value: this.fullProfile.fontSize },
            { setting: 'Host Character Set Encoding', value: this.fullProfile.charSet },
            { setting: 'Euro Character', value: this.fullProfile.euroChar }
        ]

        this.onOffSettings = [
            { setting: 'Allow Blinking', value: this.fullProfile.allowBlinking },
            { setting: 'Allow Underline', value: this.fullProfile.allowUnderline },
            { setting: 'Auto Print (like VT)', value: this.fullProfile.autoPrintLikeVT },
            { setting: 'Auto Wrap', value: this.fullProfile.autoWrap },
            { setting: 'Disconnect Warning', value: this.fullProfile.disconnectWarn },
            { setting: 'Mouse Input', value: this.fullProfile.mouseInput },
            { setting: 'Status Line', value: this.fullProfile.statusLine },
            { setting: '8 Bit', value: this.fullProfile.terminal8Bit }
        ]
    }

    hideProfileDetails () {
        this.$root.$emit('bv::toggle::collapse', this.detailID)
        this.showing = false
        // this.hasFullProfile = false
    }

    showProfileDetails () {
        this.$root.$emit('bv::toggle::collapse', this.detailID)
        this.showing = true
    }
}
