
























import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { GUIComponent, FrameProps, GUIObjectType, GUIEventCode } from './../../types/enums'
import { IGUIEvent } from './../../types'
import { UtilsType } from './../../types/utils'
import { CharacterUnderlineProps } from './../../types/components'
// Utilities 
import Utils from './../../utils/index';
// Components 
import CharacterUnderline from './../layout/CharacterUnderline.vue';

@Component({
  name: 'gxFrameComponent',
  components: { CharacterUnderline }
})

export default class gxFrame extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new FrameProps()) }) private props!: FrameProps;
  @Prop({ default: () => ([]) }) private children!: Array<GUIComponent>

  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();
  public clickCount: number = 0
  public clickTimer: any = null

  /** ******************************** Vue Computed **********************************/

  get showCaption () { return this.props.gpStyle === 0};

  get captionText (): string { return this.props.gpCaption };

  get showFrame (): boolean { return this.props.gpVisible };

  get tabindex(): number { return this.props.tabindex };

  get enabled(): boolean { return this.props.gpEnabled };

  get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const containerCSS = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
      }
    const font = this.utils.controlFont(this.props);
    const border = this.utils.controlBorder(this.props)
    const alignment = this.utils.controlAlign(this.props)
    if (this.props.gpTransparent) {
        containerCSS.backgroundColor = 'transparent';
    }
    style.push(size, font, border, alignment, containerCSS)
    return style
  }

  get legendStyle (): Partial<CSSStyleDeclaration>[] {
      let style: Partial<CSSStyleDeclaration>[] = []
      const font = this.utils.controlFont(this.props)
      const width = {
        width: 'auto'
      }
      style.push(font, width)
      return style
  }

  get divPosition(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    style.push(positioning)
    return style
  }

  /** ******************************** Vue Methods **********************************/
    get cuProps(): CharacterUnderlineProps {
      return { app: this.props.app!.id, form: this.props.form!.id, control: this.props.id, type: this.props.type }
    }


    clickHandler(e: any) {
      e.stopPropagation();
      
      this.clickCount++

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0
          // Single Click
          if (this.props.gpEventMask & GUIEventCode.geClick) {
            // send this event to the server
            const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id }
            this.$store.dispatch('guiGuis/addEvent', guiEvent);
          }
        }, 250)
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer)
        this.clickCount = 0
        // Single Click and...
        if (this.props.gpEventMask & GUIEventCode.geClick) {
          // send this event to the server
          const clickEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id }
          this.$store.dispatch('guiGuis/addEvent', clickEvent);
        }
        // Double Click
        if (this.props.gpEventMask & GUIEventCode.geDblClick) {
          // send this event to the server
          const dblClickEvent: IGUIEvent = { event: GUIEventCode.geDblClick, id: this.props.id }
          this.$store.dispatch('guiGuis/addEvent', dblClickEvent);
        }
      }
  }
    
  rightClickHandler(e: MouseEvent) {
    e.stopPropagation()  

    if (this.props.gpEventMask & GUIEventCode.geContext) {
      const guiEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }
}
