import { EnterpriseAuthServiceType } from '@/types/services/enterprise'
//import axios from 'axios';

// payload is value of enterprise query string variable (base64)
export default class EnterpriseAuthService implements EnterpriseAuthServiceType {
    async auth (payload: string) {

        const response = await fetch(process.env.VUE_APP_ENTERPRISE_VALID_ENDPOINT, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'text/plain'
            },
            body: payload // body data type must match "Content-Type" header
        })            
        return response.json() // parses JSON response into native JavaScript objects

        /*
         * This is an axios alternative to using fetch. Both versions appear to work
         * the same, however in one specific case, the axios version succeeds where
         * fetch fails. Actually it does not look like fetch fails, but somehow the
         * request is cancelled and not re-issued, and the test for 'enterprise' fails
         * and the app falls back to B2C authentication. This specific case occurs
         * with Firefox (v107 tested) on Windows (not MacOS or iOS) where the webapp
         * is contained in an <object> or <embed> element. If the webapp is contained
         * in an <iframe> Firefox works fine.
         * 
        const response = await axios.post(
            process.env.VUE_APP_ENTERPRISE_VALID_ENDPOINT,
            payload,
            {
                headers: {
                    'Content-Type': 'text/plain'
                }
            }
        );
        return response.data;
        */        
            
    }
}
