











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {BTable} from 'bootstrap-vue';
// Types
import { GUIEventCode, ListProps } from './../../../types/enums'
import { IGUIEvent } from './../../../types'
import { UtilsType } from './../../../types/utils'
// Utilities 
import Utils from './../../../utils/index';
import GridLabelIcon from './labelIcon.vue';
import Icon from './../../layout/Icon.vue';

@Component({
  name: 'gxListComponent',
  components: { GridLabelIcon, Icon }
})

export default class gxList extends Vue {
/** ******************************** Vue Props **********************************/
  @Prop({ default: () => (new ListProps()) }) public props!: ListProps;

  @Prop() public fields: any;
  @Prop() public items: any;
  @Prop() public hasIcons: any;
  @Prop() public iconStyle: any;

/** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();
  public clickCount: number = 0;
  public clickTimer: any = null;

  public fieldtype = 'GridLabelIcon'
  public activated: boolean = false

  public listGridId: string = this.props.id +"-list-grid";   

/** ******************************** Vue Computed **********************************/
  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }
  get visible (): boolean { return this.props.gpVisible }

  get showHeaders (): string {
    const found = this.props.columnInfo.find(element => element.heading !== '' || element.heading === null);
    let result = '';
    if (found === undefined) {
        result = 'd-none';
    } 
    return result;
  }

  get value(): number { return this.props.gpValue }

  set value(newValue: number) { 
    // Check if the change event is included in the prop events
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: newValue }) 
  }

  rowAttributes(e: any) {
    return {
      tabindex: "-1",
    }
  }

  get style(): Partial<CSSStyleDeclaration>[] { 
    let style: Partial<CSSStyleDeclaration>[] = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const font = this.utils.controlFont(this.props)
    const border = this.utils.controlBorder(this.props)
    const colors: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor || 'white',
      color: this.props.gpForeColor || 'black',
      overflow: 'auto'
    }
    style.push(size, border, colors, font)
    return style
  }

  get enabled(): boolean { return this.props.gpEnabled }

  get HorizontalVerticalLines (): boolean { return !!(this.props.gpGridLines & 1) }

  get NoVerticalHorizontalLines (): boolean { return (this.props.gpGridLines === 0) }

/** ******************************** Vue Methods **********************************/

  singleClickHandler (item: any, index: number) {
    let newValue = index + 1;
    this.value = newValue; 
    // Check if the change event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: [newValue] }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  doubleClickHandler(e: MouseEvent) {
    e.stopPropagation();

    if (this.props.gpEventMask & GUIEventCode.geDblClick) {
    // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geDblClick, id: this.props.id, args: [this.value] }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  rightClickHandler(e: MouseEvent) {
    e.stopPropagation();

    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const contextEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', contextEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  headClickHandler (value: any) {
    let args = [value + 1];

    if (this.props.gpEventMask & GUIEventCode.geColClick) {
      // send this event to the server
      const columnHeadlClickEvent: IGUIEvent = { event: GUIEventCode.geColClick, id: this.props.id, args: args  }
      this.$store.dispatch('guiGuis/addEvent', columnHeadlClickEvent);
    }
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
      let elem: BTable = (this.$refs[this.listGridId] as BTable);
      let elemChild: HTMLElement = elem.$el.children[1] as HTMLElement;
      if(elemChild && elemChild.children.length > 0) {
        (elemChild.children[0] as HTMLTableRowElement).focus();     
        (elemChild.children[0] as HTMLTableRowElement).style.outline = '-webkit-focus-ring-color auto 1px';
      }
console.log('List Focus Me ' + this.props.id);
      } catch(e) {
console.log('List Focus Me error: ' + e);
      }
    }
  }

  focusOut(e: any) {
      try {
        let elem: BTable = (this.$refs[this.listGridId] as BTable);
        if(elem) {
          let elemChild: HTMLElement = elem.$el.children[1] as HTMLElement;
          if(elemChild && elemChild.children.length > 0) {   
            (elemChild.children[0] as HTMLTableRowElement).style.outline = '';
          }
        }
      } catch(e) {
        console.log('List Focus out error: ' + e);
      }
  }

/** ******************************** Life Cycle Hooks **********************************/
  mounted () {  
    // Initial select
    if (this.value) {
      (this.$refs[this.listGridId] as BTable).selectRow(this.value - 1);
    }
    this.$nextTick(() => { this.focus(); });
    this.$el.addEventListener('focusout', this.focusOut);
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus();
  }  
  
  @Watch('value') updateSelection () {
    if (this.value) {
      (this.$refs[this.listGridId] as BTable).selectRow(this.value - 1);
    } else {
      (this.$refs[this.listGridId] as BTable).clearSelected();
    }
  }  

}
