











import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { BrowserProps, GUIEventCode } from './../../types/enums'
import { IGUIEvent } from './../../types'
import { UtilsType } from './../../types/utils'
// Utilities 
import Utils from './../../utils/index';

@Component({
  name: 'gxBrowser'
})

export default class gxBrowser extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new BrowserProps()) }) private props!: BrowserProps;

  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();

  private activated: boolean = false;

  /** ******************************** Vue Computed **********************************/

  get sourceUrl () { 
    let str = undefined;
    if ( this.props.gpValue !== ''){
      str = this.props.gpValue;
    }
    return str    
  };

  get htmlCode () {
    if (this.props.gpContent) { 
      // insert function to send topic click message to parent
      const scr = ['',
        '<script type="text/javascript">',
        'var id="' + this.props.id + '";',
        'function emitMessage(content) {',
        '  var contents = content.split(":");',
        '  if (contents.length === 2) {',
        '    var obj = {id: id};',
        '    obj[contents[0]] = contents[1];',
        '    window.parent.postMessage(JSON.stringify(obj), "*");',
        '  }',
        '  return false',
        '}',
        '<' + '/script>' 
      ].join('\n'); // not sure why < / script > is an unterminated string in typescript!
      let html = this.props.gpContent.replace(/<body>/i, '<body>' + scr);
      // fixup topic links to call our emitMessage function
      return html.replace(/(<a\s+.*)href\s*=\s*"(topic:\w+)"(.*>)/gi, '$1 href="#" onclick=\'return emitMessage("$2");\' $3');
    }
    return undefined;
  };

   get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft)
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
    const containerCSS: Partial<CSSStyleDeclaration> = { 
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
      }
       
    style.push(size)
    style.push(positioning)
    style.push(containerCSS)

    return style
  }

  get iframeStyle(): Partial<CSSStyleDeclaration>[] { 
    let style: Partial<CSSStyleDeclaration>[] = []
    const border = this.utils.controlBorder(this.props)
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight)
       
    style.push(border, size)

    return style
  }

  get enabled(): boolean { return this.props.gpEnabled }

  get tabIndex(): number { return this.props.gpTabStop? 0 : -1 }

  /** ******************************** Vue Methods **********************************/

  // Activate/Deactivate are tricky with iframe. We don't get normal focus/blur
  // events, so we attach focus & blur handlers to the window object. We test
  // the id of window.activeElement in the window.blur handler, and if it is
  // our iframe id, then we have just got focus. 
  //
  // On Chrome the element getting focus during window.blur is available in
  // document.activeElement. On Firefox, we need to wait a bit before activeElement
  // is updated. 
  
  // The setDeferredCtlDeactivate mechanism is used because
  // e.relatedTarget is never set. 

  // Note: when clicking from one iframe to another iframe, there is no blur or
  // focus events on the window object. The only way I have come up with to handle
  // this scenario is to start a polling timer in mouseEnter and watch for
  // document.activeElement to change to the current iframe, meaning the user
  // clicked there. Then fire deactivate on old iframe and activate on current
  // one. This is not implemented.
  
  windowBlurHandler(e: any) {
    setTimeout(this.activateHandler, 0); // Firefox needs a little time to set up activeElement
  }
  
  activateHandler(e: any) {
    // We are catching the window.blur event, and the FocusEvent does not
    // have a relatedTarget, so we use document.activeElement to see if this
    // iframe is focused.
    const focusControlID = this.utils.getRelatedTargetID(document.activeElement as HTMLElement, '', 'controlID');
    if (focusControlID === this.props.id) {
      if (!this.activated) {
        this.activated = true;
        // handle the deferred deactivate event
        const prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
        if (prevControlID !== this.props.id) {
          console.log('Browser activate ' + this.props.id + ' from ' + prevControlID);
          if (this.props.gpEventMask & GUIEventCode.geActivate) {
            const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID] }
            this.$store.dispatch('guiGuis/addEvent', activateEvent);
          }
        } else console.log('   skipping Browser activate ' + this.props.id + ' - self')
        // Set focused in store and in parent form
        this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
        this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id })
      } 
    }
  }

  deactivateHandler(e: any) {
    if (this.activated) {
      this.activated = false;
      // Always use deferred deactivate because window.focus FocusEvent does not
      // have relatedTarget so we do not know id of control being activated yet.
      this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents);
console.log('   deferring Browser deactivate ' + this.props.id);
    }
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('Browser deactivate ' + this.props.id + ' to ' + nextControlID);
      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
        this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
      }     
    } else console.log('   skipping Browser deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

  handleMessage(e: any) {
    // This is the "topic" link click handler. We have injected a small script into
    // the html contents to use postMessage to send the link info to the parent window.
    // The link <a> tag calls this script with the topic link info.
    // { data : { id: <id>, topic: <topic>} }
    try {
      const data = JSON.parse(e.data);
      if (data && data.id && data.id === this.props.id && data.topic) {
        console.log('Browser click ' + this.props.id + ' topic ' + data.topic);
        if (this.props.gpEventMask & GUIEventCode.geClick) {
          const clickEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: [data.topic] }
          this.$store.dispatch('guiGuis/addEvent', clickEvent);
        }
      }
    } catch(ex) {} // ignore if not correct format
  }

/** ******************************** Vue Life Cycle **********************************/

  mounted() {
    this.$nextTick(() => {
      // watch window.focus & window.blur to see when iframe gets / loses focus
      //window.addEventListener('blur', this.activateHandler as EventListenerOrEventListenerObject, false); // OK for Chrome, but Firefox needs more time
      window.addEventListener('blur', this.windowBlurHandler as EventListenerOrEventListenerObject, false);
      window.addEventListener('focus', this.deactivateHandler as EventListenerOrEventListenerObject, true);
      // setup handler for iframe postMessage
      window.addEventListener('message', this.handleMessage as EventListenerOrEventListenerObject);

    });
  }

}
