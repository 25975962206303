/*
 * AccuTerm Web Globals
 *
 * Copyright 2018 Zumasys, Inc.
 */

const app = {
    version: (typeof process !== 'undefined' && process.env && process.env.VUE_APP_VERSION) || '0.0.0',
    product: 'Web'
}

const DEBUG = false;

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { app, DEBUG };
