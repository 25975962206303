






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { IGUIEvent } from './../../../types'
import { GUIEventCode, DrpDwnListProps } from './../../../types/enums'
import { UtilsType } from './../../../types/utils'
import { BFormInput, BTable } from 'bootstrap-vue';
// Utilities 
import Utils from './../../../utils/index';
import GridLabelIcon from './labelIcon.vue';
import Icon from './../../layout/Icon.vue';

@Component({
  name: 'gxDrpDwnListComponent',
  components: { GridLabelIcon, Icon }
})

export default class gxDrpDwnList extends Vue {
/** ******************************** Vue Props **********************************/
  @Prop({ default: () => (new DrpDwnListProps()) }) public props!: DrpDwnListProps;

  @Prop() public fields: any;
  @Prop() public items: any;
  @Prop() public iconStyle: any;
  @Prop() public hasIcons: any;
  @Prop() public tableStyle: any;
  @Prop() public buttonStyle: any;

/** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();
  public text: string = '';
  public fieldtype = 'GridLabelIcon'

  public dropDownId: string = this.props.id +"-drop-down";   
  public listGridId: string = this.props.id +"-list-grid";
  public inputID: string = this.props.id +'-input'
  public showTable: boolean = false

/** ******************************** Vue Computed **********************************/
  get tabIndex(): number { return this.props.gpTabStop ? 0: -1} 
  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }
  get visible (): boolean { return this.props.gpVisible }

  get showHeaders () {
    const found = this.props.columnInfo.find(element => element.heading !== '' || element.heading === null);
    let result = '';
    if (found === undefined) {
        result = 'd-none';
    } 
    return result;
  }

  colStyle(index: number): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];
    if(this.props.gpColWidth[index]) {
      style.push({width: this.props.gpColWidth[0] + "px"})
    } else {
      style.push({width: "20px"})
    }
    return style;
  }

  get inputStyle (): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = []
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight);
    const font = this.utils.controlFont(this.props)
    let height: Partial<CSSStyleDeclaration> = { 
      height: size.height,
      padding: '0px' 
      }

      if(this.enabled){
          height.backgroundColor = 'white'
      } else {
        height.backgroundColor = ''
      }
    style.push( font, height);
    return style;
  }

  get style (): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight);
    const font = this.utils.controlFont(this.props)
    style.push(size, font);
    return style;
  }

  get enabled() { return this.props.gpEnabled }

  get value(): number { return this.props.gpValue }

  set value(newValue: number) { 
    // Check if the change event is included in the prop events
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: newValue }) 
  }

  get HorizontalVerticalLines (): boolean { return !!(this.props.gpGridLines & 1) }

  get NoVerticalHorizontalLines (): boolean { return (this.props.gpGridLines === 0) }

  get dataColumnNo(): number { 
    // DataCol is one-based, item is zero-based
    const dataColumn = (this.props.gpDataCol === 0) ? 0 : this.props.gpDataCol - 1; 

    return dataColumn; 
  }

  get required (): boolean { return this.props.gpRequired }

  get selectionStart(): number { return +this.props.gpSelStart }
  set selectionStart(selection: number) { 
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpSelStart', value: selection }) 
  }

  get selectionLength(): number { return +this.props.gpSelLength }
  set selectionLength(selection: number) {
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpSelLength', value: selection }) 
  }



/** ******************************** Vue Methods **********************************/

  handleBlur(e: FocusEvent) {
    let target: HTMLElement | null = e.relatedTarget as HTMLElement;
    let found = false;
    while(target) {
      if(target.id === this.props.id) {
        target = null;
        found = true;
      } else {
        target = target.parentElement
      }
    }
    if(!found) {
      this.showTable = false;
    }
  }

  handleKeys(e: KeyboardEvent) {
    switch(e.key) {
      case "ArrowDown":
          this.value = this.value < Object.keys(this.items).length - 1?  this.value + 1 :  Object.keys(this.items).length;
        break;
      case "ArrowUp":
          this.value = this.value > 1 ? this.value -1 : 1;
        break;
      case "Enter":
          this.showTable = !this.showTable;
        break;
    }

    if(this.showTable) {
      let elem: BTable = (this.$refs[this.listGridId] as BTable);
      let elemChild: HTMLElement = elem.$el.children[1] as HTMLElement;
      // Find the focused element in drop down table or pick first
      if(elemChild && elemChild.children.length >= 0) {
        (elemChild.children[this.value - 1] as HTMLTableElement).scrollIntoView();
      }
    }
  }

  handleButtonClick() {
    this.showTable = !this.showTable;
    (this.$refs[this.inputID] as HTMLInputElement).focus();
  }

  doubleClickHandler () {
    let args = [this.value];
    if (this.props.gpEventMask & GUIEventCode.geDblClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geDblClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
  }

  rightClickHandler(e: MouseEvent) {
    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const contextEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', contextEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  headClickHandler (value: any) {
      let args = [value + 1];
      if (this.props.gpEventMask & GUIEventCode.geColClick) {
      // send this event to the server
      const columnHeadlClickEvent: IGUIEvent = { event: GUIEventCode.geColClick, id: this.props.id, args: args  }
      this.$store.dispatch('guiGuis/addEvent', columnHeadlClickEvent);
    }
  }

  rowClickHandler(item: any, index: any) {

    let newValue = index + 1;
    let args = [newValue];
    this.value = newValue;
    // Check if the change event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }
    
    this.showTable = !this.showTable; //hide table
  }

  selectionHandler (e: any) {
    e.stopPropagation()

    const ct = e.currentTarget

    this.selectionStart = ct.selectionStart
    this.selectionLength = ct.selectionEnd - ct.selectionStart
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        const elem =this.$refs[this.inputID];
        (elem as BFormInput).focus();
console.log('DrpDwnList Focus Me '+ this.props.id);
      } catch(e) {
console.log('DrpDwnList Focus Me error: ' + e);
      }
    }
  }

/** ******************************** Life Cycle Hooks **********************************/

  mounted () {  
    const val = (this.value === 0) ? 0 : this.value - 1;
    // confirm there is a option to select
    if (this.items.length > 0) {
      (this.$refs[this.listGridId] as any).selectRow(val);

      this.text = this.items[val][this.dataColumnNo].value;
    }
    this.$nextTick(() => { this.focus(); });
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus();
  }

  @Watch('value') updateSelection () {
   const val = (this.value === 0) ? 0 : this.value - 1;
    // confirm there is a option to select
    if (this.items.length > 0) {
      (this.$refs[this.listGridId] as BTable).selectRow(val);
      
      this.text = this.items[val][this.dataColumnNo].value;
    }
  }

}
