











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { IGUIEvent } from './../../types';
import { CheckProps, GUIEventCode } from './../../types/enums';
import { UtilsType } from './../../types/utils';
import { CharacterUnderlineProps } from './../../types/components'
// Utilities
import Utils from './../../utils/index';
// Components
import CharacterUnderline from './../layout/CharacterUnderline.vue';

@Component({
  name: 'gxCheckComponent',
  components: {CharacterUnderline}
})
export default class gxCheck extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => new CheckProps() }) private props!: CheckProps;

  /** ******************************** Vue Data **********************************/

  public utils: UtilsType = new Utils();
  public activated: boolean = false

  public checkboxId: string = this.props.id + '-checkBox';

  /** ******************************** Vue Computed **********************************/
  get focused() { return this.$store.getters['guiGuis/getFocusedControl']; }

  get style(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight);
    const font = this.utils.controlFont(this.props);
    const containerCSS: Partial<CSSStyleDeclaration> = {
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
    };
    if (this.props.gpTransparent) {
        containerCSS.backgroundColor = 'transparent';
    }
    style.push(positioning);
    style.push(size);
    style.push(font);
    style.push(containerCSS);

    return style;
  }

  get cssProperties(): Partial<CSSStyleDeclaration>[] {
    let style: Partial<CSSStyleDeclaration>[] = [];

    const border = this.utils.controlBorder(this.props);
    const alignment = this.utils.controlAlign(this.props);
    const containerCSS: Partial<CSSStyleDeclaration> = {
      backgroundColor: this.props.gpBackColor,
      color: this.props.gpForeColor
    };

    style.push(border);
    style.push(alignment);
    style.push(containerCSS);

    return style;
  }

  get value(): boolean { return !!this.props.gpValue }
  
  set value(val) {
    let args = [val ? 1 : 0]

    // Check if the change event is included in the prop events
    if (this.props.gpEventMask & GUIEventCode.geClick) {
      // send this event to the server
      const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', guiEvent);
    }

    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: val ? 1 : 0 });
  }

  get readOnly (): boolean { return this.props.gpReadOnly }
  get label() { return Array.isArray(this.props.gpCaption) ? this.props.gpCaption.join('<br />') : this.props.gpCaption; }

  get enabled() { return this.props.gpEnabled; }

  get visible(): boolean { return this.props.gpVisible }

  get tabIndex(): number { return this.props.gpTabStop && !this.props.gpReadOnly ? 0: -1}

  get cuProps(): CharacterUnderlineProps {
    return { app: this.props.app!.id, form: this.props.form!.id, control: this.props.id, type: this.props.type }
  }

  /** ******************************** Vue Methods **********************************/


  check(e: any) {
    if(e.keyCode == 13){
      console.log('test')
      this.value = !this.props.gpValue;
    }
    
  }

  rightClickHandler(e: MouseEvent) {
    if (this.props.gpEventMask & GUIEventCode.geContext) {
      // send this event to the server
      const contextEvent: IGUIEvent = { event: GUIEventCode.geContext, id: this.props.id };
      this.$store.dispatch('guiGuis/addEvent', contextEvent);
    }

    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  doubleClickHandler () {
      // Double Click
    if (this.props.gpEventMask & GUIEventCode.geDblClick) {
      // send this event to the server
      const dblClickEvent: IGUIEvent = { event: GUIEventCode.geDblClick, id: this.props.id }
      this.$store.dispatch('guiGuis/addEvent', dblClickEvent);
    }
  }

  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        let elem: any = this.$refs[this.props.id];
        (elem as HTMLInputElement).focus();
console.log('Check Focus Me '+ this.value +' in '+ this.props.id);
      } catch(e) {
console.log('Check Focus Me error: ' + e);
      }
    }
  }

  activateHandler(e: any) {
    if (!this.activated) {
      this.activated = true;
      let prevControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
      if (!prevControlID) {
        // handle the deferred deactivate event
        prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
      }
      if (prevControlID !== this.props.id) {
console.log('Check activate ' + this.props.id + ' from ' + prevControlID);
        if (this.props.gpEventMask & GUIEventCode.geActivate) {
          const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID] }
          this.$store.dispatch('guiGuis/addEvent', activateEvent);
        }
        // Clear the changed property
        this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: false })
      } else console.log('   skipping Check activate ' + this.props.id + ' - self');
      // Set focused in store and in parent form
      this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id })
    } 
  }

  deactivateHandler(e: any) {
    if (this.activated) {
      this.activated = false
      const nextControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
      if (nextControlID) {
        this.handleDeactivateEvents(nextControlID);
      } else {
        this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring Check deactivate ' + this.props.id + ' related = null');
      }
    }
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('Check deactivate ' + this.props.id + ' to ' + nextControlID);
        if (this.props.gpEventMask & GUIEventCode.geValidate && this.props.changed) {
          const validateEvent: IGUIEvent = { event: GUIEventCode.geValidate, id: this.props.id, args: [nextControlID], value: this.value ? 1 : 0 }
          this.$store.dispatch('guiGuis/addEvent', validateEvent);
        }
        if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
          const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
          this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
        }
    } else console.log('   skipping Check deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

  mounted() {
    this.$nextTick(() => { this.focus(); });
    const thisInstance = this;
    this.$root.$on('clickEvent', function(val:any){
      thisInstance.value = val;
    })
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus()
  }  

}
