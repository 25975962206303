



















import { Component, Vue } from 'vue-property-decorator'
// Components
import Header from '@/components/layout/terminal/Header.vue'

@Component({
    name: 'TermFooterComponent'
})

export default class TermFooter extends Vue {
/** ******************************** Vue Data! **********************************/

    $refs!: {
        accutermFooter: HTMLElement
    }

/** ******************************** Vue Computed **********************************/

    get column () { return this.$store.getters['terminal/getStatusCol'] }
    get row () { return this.$store.getters['terminal/getStatusRow'] }
    get page () { return this.$store.getters['terminal/getStatusPage'] }
    get status () { return this.$store.getters['terminal/getStatusMessage'] }

    get activeProfile () { return this.$store.getters['profiles/getActiveProfile'] }


/** ******************************** Vue Methods! **********************************/

    toggleTermKeyboard () {
        const ref: any = this.$root.$refs;
        const hdr: any = ref.HeaderComponent;
        hdr.toggleTermKeyboard();
    }

/** ******************************** Vue Mounted! **********************************/

    mounted () {
        // Refs
        const footer = this.$refs.accutermFooter
    }
}
