// Store
import store from '../../store'
// Types
import { IGUICmdInputBox, IGUICmdMsgBox, IGUICmdFileDialog, IGUICmdFontDialog, IGUICmdColorDialog, IGUIRspError,
    IGUIRspMsgBox, IGUIRspInputBox, IGUIRspFileDialog, IGUIRspFontDialog, IGUIRspColorDialog, colorObject, IGUIFontObject, IGUICmdAboutBox } from '../../types'
import { GUICommandCode, GUIErrorCode, GUIErrorLevel, GUIDialogResp, GUIDialogType, GUIDialogBtn, GUIDialogDef } from '../../types/enums'
import { GuisServiceDialogBoxType } from '../../types/services/guis'
import { DialogState } from '../../types/store/guis'

export default class GuisServiceDialogBox implements GuisServiceDialogBoxType {

    msgBox(command: IGUICmdMsgBox) {

        const msgBox: DialogState = {
            type: GUIDialogType.messageBox,
            visible: true,
            title: command.title,
            message: command.prompt,
            buttons: command.buttons,
            defBtn: command.default,
            answer: null,
            icon: command.icon,
            extFilter: null,
            fileStyle: null,
            font: null,
            color: null,
            about: null
        }

        store.dispatch('guiGuis/setDialogBox', msgBox)
        
        // response triggered by component interaction
    }

    inputBox(command: IGUICmdInputBox) {

        const inpBox: DialogState = {
            type: GUIDialogType.inputBox,
            visible: true,
            title: command.title,
            message: command.prompt,
            buttons: GUIDialogBtn.gmbBtnOKCancel,
            defBtn: GUIDialogDef.gmbDefBtn1,
            answer: command.default,
            icon: null,
            extFilter: null,
            fileStyle: null,
            font: null,
            color: null,
            about: null
        }

        store.dispatch('guiGuis/setDialogBox', inpBox)

        //response triggered by component interaction
    }

    fileDialog(command: IGUICmdFileDialog) {

        const fileBox: DialogState = {
            type: GUIDialogType.fileDialog,
            visible: true,
            title: command.title,
            message: null,
            buttons: GUIDialogBtn.gmbBtnOKCancel,
            defBtn: GUIDialogDef.gmbDefBtn1,
            answer: command.defaultPath,
            icon: null,
            extFilter: command.extFilter,
            fileStyle: command.style,
            font: null,
            color: null,
            about: null
        }

        store.dispatch('guiGuis/setDialogBox', fileBox)

        //let response: IGUIRspFileDialog | IGUIRspError

        //const response: IGUIRspFileDialog = { command: command.command, error: GUIErrorCode.grSuccess, result: '' }
        
        //response = { command: command.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'FileDialog' command has not been implemented" }

        //store.dispatch('guiResponse/setResponse', response)
    }

    fontDialog(command: IGUICmdFontDialog) {

        const fontBox: DialogState = {
            type: GUIDialogType.fontDialog,
            visible: true,
            title: null,
            message: null,
            buttons: GUIDialogBtn.gmbBtnOKCancel,
            defBtn: GUIDialogDef.gmbDefBtn1,
            answer: null,
            icon: null,
            extFilter: null,
            fileStyle: null,
            font: command.font,
            color: null,
            about: null
        }

        store.dispatch('guiGuis/setDialogBox', fontBox)

        // let response: IGUIRspFontDialog | IGUIRspError

        // const font : IGUIFontObject = { name: '', size: 1, bold: false, italic: false, underline: false }
        // response = { command: command.command, error: GUIErrorCode.grSuccess, font: font }

        // response = { command: command.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'FontDialog' command has not been implemented" }
        
        // store.dispatch('guiResponse/setResponse', response)
    }

    colorDialog(command: IGUICmdColorDialog) {

        const colorBox: DialogState = {
            type: GUIDialogType.colorDialog,
            visible: true,
            title: null,
            message: null,
            buttons: GUIDialogBtn.gmbBtnOKCancel,
            defBtn: GUIDialogDef.gmbDefBtn1,
            answer: null,
            icon: null,
            extFilter: null,
            fileStyle: null,
            font: null,
            color: command.color,
            about: null
        }

        store.dispatch('guiGuis/setDialogBox', colorBox)

        // let response: IGUIRspColorDialog | IGUIRspError;
        
        // //response = { command: command.command, error: GUIErrorCode.grSuccess, result: '' }
        
        // response = { command: command.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'ColorDialog' command has not been implemented" }

        // store.dispatch('guiResponse/setResponse', response)
    }

    aboutBox(command: IGUICmdAboutBox) {

        const aboutBox: DialogState = {
            type: GUIDialogType.aboutBox,
            visible: true,
            title: null,
            message: null,
            buttons: GUIDialogBtn.gmbBtnOK,
            defBtn: GUIDialogDef.gmbDefBtn1,
            answer: null,
            icon: null,
            extFilter: null,
            fileStyle: null,
            font: null,
            color: null,
            about: command.about
        }

        store.dispatch('guiGuis/setDialogBox', aboutBox)
    }
    msgResponseHandler(resp: GUIDialogResp) {

        let response: IGUIRspMsgBox | IGUIRspError = { command: GUICommandCode.gcMsgBox, error: GUIErrorCode.grSuccess, result: resp }

        // potential error message
        //response = { command: command.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'MsgBox' command has not been implemented" }

        store.dispatch('guiResponse/setResponse', response)
    }

    inputResponseHandler(resp: string) {

        let response: IGUIRspInputBox | IGUIRspError = { command: GUICommandCode.gcInputBox, error: GUIErrorCode.grSuccess, result: resp }

        // potential error message
        //response = { command: command.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'MsgBox' command has not been implemented" }

        store.dispatch('guiResponse/setResponse', response)
    }

    fileResponseHandler(resp: string) {

        let response: IGUIRspFileDialog | IGUIRspError = { command: GUICommandCode.gcFileDialog, error: GUIErrorCode.grSuccess, result: resp }

        // potential error message
        //response = { command: command.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'MsgBox' command has not been implemented" }

        store.dispatch('guiResponse/setResponse', response)
    }

    fontResponseHandler(resp: IGUIFontObject) {
        if(resp.name == null)
            resp.name = ""
        if(Number.isNaN(resp.size))
            resp.size = 8
        let response: IGUIRspFontDialog | IGUIRspError = { command: GUICommandCode.gcFontDialog, error: GUIErrorCode.grSuccess, result: resp }

        // potential error message
        //response = { command: command.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'MsgBox' command has not been implemented" }

        store.dispatch('guiResponse/setResponse', response)
    }

    colorResponseHandler(resp: colorObject) {
        let response: IGUIRspColorDialog | IGUIRspError
        if(typeof(resp) === 'string')
            response = { command: GUICommandCode.gcColorDialog, error: GUIErrorCode.grSuccess, result: resp }
        else
            response = { command: GUICommandCode.gcColorDialog, error: GUIErrorCode.grSuccess, result: resp.hex }

        // potential error message
        //response = { command: command.command, error: GUIErrorCode.grUnimplemented, level: GUIErrorLevel.glvlFail, message: "The 'MsgBox' command has not been implemented" }

        store.dispatch('guiResponse/setResponse', response)
    }

}