









































import { Component, Emit, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'HeaderComponent'
})

export default class Header extends Vue {
/** ******************************** Vue Data! **********************************/

    private showTermNav: boolean = false;

    $refs!: {
        layoutHeader: HTMLElement,
        navToggleKeyboard: HTMLElement
    }

/** ******************************** Vue Created! **********************************/

    created () {
        this.toggleTermNav(this.$route.name)
    }

/** ******************************** Vue Computed **********************************/

    get getUserAuth () { return this.$store.getters['user/getUserAuth'] }
    get getUserName () { return this.$store.getters['user/getUserName'] }

    get getConnectionStatus () { return this.$store.getters['terminal/getConnectionStatus'] }
    get accutermStyle () { return this.$store.getters['terminal/getAccuTermStyle'] }

    get hasProfile (): boolean { return this.$store.getters['profiles/getHasProfile'] }

    get getEnterprise () { return this.$store.getters['user/getEnterprise'] }

    get showHeader () { return this.$store.getters['settings/getShowHeader'] }

    get showKeyboard () { return this.$store.getters['terminal/getToggleKeyboard'] }

    // return true if logoHeader is a full URL
    get logoUrl() { 
        let logos = this.$store.getters['settings/getLogoHeader'];
        return logos && logos.includes('http')
    }

    // return URL of logoHeader when logo is a static asset on enterprise server
    get logoSvr() {
        const svc = this.$store.getters['settings/getSettingsService'];
        if (svc) {
            const svr = svc.endpoint;
            const logos = this.logos;
            if (svr && logos) {
                return svr + '/' + logos;
            }
        }
        return null;
    }

    // return logoHeader from config settings
    get logos() { return this.$store.getters['settings/getLogoHeader'] }

    // return home route if no home link URL
    get toHome() { return this.linkHome ? '' : { name: 'Home' } }

    // return home link URL from config settings
    get linkHome() { return this.$store.getters['settings/getLinkHome'] }

    get hasGUI() { return this.$store.getters['guiGuis/hasGuis'] }

    get hasProfileParam() { return this.$store.getters['profiles/getHasProfileParam'] || false }

/** ******************************** Vue Methods **********************************/

    toggleTermKeyboard () {
        // update our data property
        const toggle = this.showKeyboard ? 0 : 1;
        // Update the store
        this.$store.dispatch('terminal/toggleKeyboard', toggle)
    }

    toggleTermNav (name: string | null | undefined) {
        this.showTermNav = (name === 'Terminal')
    }

    connectionStatusText () {
        return (this.getConnectionStatus) ? 'Disconnect' : 'Connect'
    }

    toggleConnection () {
        // If connected
        if (this.getConnectionStatus) {
            this.$store.dispatch('terminal/disconnect')
        } else {
            this.$store.dispatch('terminal/connect')
        }
    }

    reset () {
        this.$store.dispatch('terminal/reset')
    }


/** ******************************** Vue Mounted! **********************************/

    mounted () {
        // Refs
        const layoutHeader = this.$refs.layoutHeader
        const navToggleKeyboard = this.$refs.navToggleKeyboard
        this.$root.$refs.HeaderComponent = this; // export this component so Footer.vue can call our methods
    }

/** ******************************** Vue Emit Events! **********************************/

    @Emit('clickLogIn') login () {}
    @Emit('clickLogOut') logout () {}

/** ******************************** Vue Watch **********************************/

    @Watch('$route.path') onPathChange () {
        this.toggleTermNav(this.$route.name)
    }

    // Watch the showHeader setting
    @Watch('showHeader', { immediate: true }) onShowHeader () {
        this.$store.dispatch('terminal/containerResize', false);
    }

}
