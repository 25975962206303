
/*
 * AccuTerm Mobile Main Terminal Driver
 *
 * Copyright 2015 Zumasys, Inc.
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { TermUtil } from './termutil.js';
import { isEqualNoCase } from './termapp.js';
import { TermSettings } from './termsettings.js';
import { TerminalExport as Terminal } from './term.js';
import { TermColors } from './termcolors.js';
import { WyseEmulator } from './tdwyse.js';
import { Charmap } from './charmap.js';
import { AnsiEmulator } from './tdansi.js';
import { PCEmulator } from './tdpc.js';
import { TTYEmulator } from './tdtty.js';
import { PtrEmulator } from './tdprint.js';
import { makeAccuTermProxy } from './tdproxy.js';
import { GUIFuncs } from './tdgui.js';
import { DEBUG } from './globals.js';

/* jshint browser: true, jquery: true, expr: true, laxcomma: true, sub: true */
/* global Terminal: false, AnsiEmulator: false, WyseEmulator: false, PCEmulator: false, TTYEmulator: false, TermUtil: false, TermColors: false, Charmap: false, TermSettings: false, GUIFuncs: false */
/* global DEBUG: false, console_log: false, debug_log_content: false */
/* global BasicExtension: false */


// Terminal constructor accepts an 'options' argument, which is an object
// with the following keys, used as the initial settings for the terminal:
//    colors: Terminal.colors,
//    convertEol: false,
//    termName: 'xterm',
//    cursorBlink: true,
//    visualBell: false,
//    popOnBell: false,
//    scrollback: 1000,
//    screenKeys: false,
//    debug: false,
//    useStyle: false,
//    applicationCursor: false,
//    applicationKeypad: false,
//    backspaceDel: true,
//    eightBitControls: false,
//    screenPages: 1,
//    wraparoundMode: false,
//    normCols: 80,
//    normRows: 24,
//    extCols: 132,
//    extRows: 24,
//    scrMode: 0,
//    answerback: ''

// AccuTerm is a subclass of Terminal
function AccuTerm(settings) {

    this.kbdmap = [];
    this.charmap = new Charmap(); // we need a character set map

    // save reference to initial settings, sanitized
    this.settings = (settings instanceof TermSettings) ? settings : new TerminalSettings(settings);
    this.settings.sanitize();

    // The attrColor array maps an attribute to background & foreground color. The primary index [x] is formed by or'ing
    // the attribute bits as defined in term.js rendering engine. The background [x][0] and foreground [x][1] colors are
    // indexes to the first 16 ANSI colors. The array is initiailized with the default AccuTerm 7 colors.
    this.attrColor = TermColors.AttrColors(this.settings.themeStyle || 'default');
    this.colors = TermColors.ColorPalette(this.settings.themeStyle, TermUtil.isAnsi(this.settings.termtype), this.attrColor[0][0], this.attrColor[0][1]);

    // call superclass constructor
    Terminal.call(this, this._settings_to_options(this.settings));

    // initialize the keyboard map
    this.initUserKbd();

    // set initial termtype
    this.setTermtype(this.settings.termtype || 'TTY');

    // set the initial emulator state
    this.state = this.NORMAL;

    // clear the deferred data buffer (accumulates unprocessed data while in SUSPENDED state)
    this.deferredData = '';

    // initialize InAppBrowser properties
    this.iab = null; // InAppBrowser reference
    this.iabcb = null; // InAppBrowser exit callback

    // saved screen states
    this.saved_screen_state = [];

    // host mouse mode
    this.hostMouse = 0; // mouse reporting disabled
    
    // slave printer mode (0=off, 1=copy print, 2=transparent print)
    this.printMode = 0;
    
    // filter mode (0=off, 1=script+screen, 2=script only
    this.filterMode = 0;

    // internal keyboard lock counter
    this.intKbdLock = 0;

    // client/server message buffer
    this.msgbuf = '';

    // GUI TESTING
    /*
    if (GUIFuncs.TestGui) {
        this.on('gui', (function (opts) {
            DEBUG&&console.log('test gui: ' + ((opts && opts.request && opts.request[0] && opts.request[0].command) || '?'));
            opts.callback(GUIFuncs.TestGui(opts.request));
        }).bind(this));
    }
    */
}

AccuTerm.prototype = Object.create(Terminal.prototype, {
    // define common state constants
    NORMAL: {value: 0, enumerable: true},
    ESCAPED: {value: 1, enumerable: true},
    PRIVATE: {value: 999, enumerable: true},
    ESCSTX: {value: 1000, enumerable: true},
    ESCSTXCR: {value: 1001, enumerable: true},
    ESCSTXCRX: {value: 1002, enumerable: true},
    ESCSTXPCNT: {value: 1003, enumerable: true},
    ESCSTXUC: {value: 1004, enumerable: true},
    ESCSTXUF: {value: 1005, enumerable: true},
    ESCSTXLA: {value: 1006, enumerable: true},
    ESCSTXPKT: {value: 1007, enumerable: true},
    SUSPENDED: {value: 1999, enumerable: true}
});
AccuTerm.prototype.constructor = AccuTerm;

AccuTerm.prototype.getTermtype = function () {
    return this._termtype;
};

AccuTerm.prototype.setTermtype = function (termtype) {
    // Issue #75
    // The advanceCursor function is changed to Terminal.prototype.advanceCursor by
    // AnsiEmulator constructor, so reset it to AccuTerm.prototype.advanceCursor before
    // calling any of the emulator constructors to be sure we have the default version
    // of this function when it is not overridden.
    this.advanceCursor = AccuTerm.prototype.advanceCursor;
    termtype = termtype.toUpperCase();
    switch (termtype) {
        case 'VT100':
        case 'VT220':
        case 'VT320':
        case 'VT420':
        case 'LINUX':
        case 'XTERM':
            this._emulator = new AnsiEmulator(this, termtype);
            break;
        case 'ADDSVP':
        case 'VPA2E':
        case 'WYSE50':
        case 'WYSE60':
            this._emulator = new WyseEmulator(this, termtype);
            break;
        case 'PCMON':
            this._emulator = new PCEmulator(this, termtype);
            break;
        case 'TTY':
            /* fall through */
        default:
            termtype = 'TTY';
            this._emulator = new TTYEmulator(this);
            break;
    }
    this._termtype = this.termName = this.options.termName = termtype;
};

AccuTerm.prototype.updateSettings = function (newSettings) {
    // Note: this may seem odd, but many settings are stashed in
    // this.options (they're already in this.settings!), but it
    // requires fewer changes in term.js to do it this way. Term.js
    // hard reset grabs its initial settings from this.options.

    this.printer_off(true); // make sure printing is cancelled when changing settings
    
    var prevColors = this.colors.slice(0);
    var prevAttrs = this.attrColor.slice(0);
    var prevSettings = this.settings || {};
    this.settings = (newSettings instanceof TermSettings) ? newSettings : new TermSettings(newSettings);
    this.settings.sanitize();
    if (!isEqualNoCase(this._termtype, this.settings.termtype)) {
        this.setTermtype(this.settings.termtype);
        this.settings.termtype = this._termtype; // ensure settings termtype is valid
    }
    if (prevSettings.fontSize !== this.settings.fontSize) {
        this.fontPoints = this.settings.fontSize;
        this.updateFont(); // same as updateBkgndPict
        this.updateImages(); // make sure images are scaled & positioned
    }
    if (prevSettings.normCols !== this.settings.normCols ||
        prevSettings.normRows !== this.settings.normRows ||
        ((!isEqualNoCase(prevSettings.screenSize, 'NORMAL')) && isEqualNoCase(this.settings.screenSize, 'NORMAL'))) {
        if (!this.scrMode ||
            ((!isEqualNoCase(prevSettings.screenSize, 'NORMAL')) && isEqualNoCase(this.settings.screenSize, 'NORMAL'))) {
            this.resize(this.settings.normCols, this.settings.normRows);
            this.scrMode = 0;
        } else {
            this.scrMode = 1;
        }
    }
    if (prevSettings.extCols !== this.settings.extCols ||
        prevSettings.extRows !== this.settings.extRows ||
        ((!isEqualNoCase(prevSettings.screenSize, 'EXTENDED')) && isEqualNoCase(this.settings.screenSize, 'EXTENDED'))) {
        if (this.scrMode ||
            ((!isEqualNoCase(prevSettings.screenSize, 'EXTENDED')) && isEqualNoCase(this.settings.screenSize, 'EXTENDED'))) {
            this.resize(this.settings.extCols, this.settings.extRows);
            this.scrMode = 1;
        } else {
            this.scrMode = 0;
        }
    }
    if (prevSettings.terminalAppMode !== this.settings.terminalAppMode ||
        prevSettings.terminalKeypadCodes !== this.settings.terminalKeypadCodes ||
        prevSettings.terminalCursorCodes !== this.settings.terminalCursorCodes ||
        prevSettings.bkspSendsDel !== this.settings.bkspSendsDel ||
        prevSettings.terminal8Bit !== this.settings.terminal8Bit) {
        this.allowApplicationMode = this.settings.terminalAppMode;
        this.applicationKeypad = this.settings.terminalAppMode && this.settings.terminalKeypadCodes;
        this.applicationCursor = this.settings.terminalAppMode && this.settings.terminalCursorCodes;
        this.backspaceDel = this.settings.bkspSendsDel;
        this.eightBitControls = this.settings.terminal8Bit;
        this.initDfltKbd(this.applicationCursor, this.applicationKeypad, this.backspaceDel, this.eightBitControls);
    }
    this.initUserKbd(true); // update the user-defined keys
    this.answerBack = this._decodeKeyStr(this.settings.terminalAnswerBack); // decode arrow format, uses host character set
    this.allowUnderline = this.settings.allowUnderline;
    this.allowBlinking = this.settings.allowBlinking;
    this.wraparoundMode = this.settings.autoWrap;
    this.terminalBell = this.settings.terminalBell;
    if (prevSettings.screenPages !== this.settings.screenPages) {
        if (this.curPage >= this.settings.screenPages) {
            this.switchPage(0); // ensure we're on a valid page
        }
        this.screenPages = this.settings.screenPages;
    }
    if (prevSettings.historyRows !== this.settings.historyRows) {
        this.resizeHistory(this.settings.historyRows);
    }
    /* jshint expr: true */
    if (prevSettings.protectAttribute !== this.settings.protectAttribute) {
        this._emulator.updateTagAttr && this._emulator.updateTagAttr(this.settings.protectAttribute);
    }
    if (prevSettings.themeStyle !== this.settings.themeStyle || this.settings.themeStyle === 'custom') {
        // The attrColor array maps an attribute to background & foreground color. The primary index [x] is formed by or'ing
        // the attribute bits as defined in term.js rendering engine. The background [x][0] and foreground [x][1] colors are
        // indexes to the first 16 ANSI colors. The array is initiailized with the default AccuTerm colors.
		if (this.settings.themeStyle === 'custom') {
			this.attrColor = TermColors.AttrColors(this.settings.customColors);
			this.colors = TermColors.ColorPalette(this.settings.customPalette, TermUtil.isAnsi(this.settings.termtype), this.attrColor[0][0], this.attrColor[0][1]);			
		} else {
            this.attrColor = TermColors.AttrColors(this.settings.themeStyle);
            this.colors = TermColors.ColorPalette(this.settings.themeStyle, TermUtil.isAnsi(this.settings.termtype), this.attrColor[0][0], this.attrColor[0][1]);
        }
    }
    if (prevSettings.charSet !== this.settings.charSet || prevSettings.euroChar !== this.settings.euroChar) {
        this.charmap = new Charmap(TermUtil.XlateCharSet(this.settings.charSet, this.settings.termtype), this.settings.euroChar);
        this._emulator.updateCharset && this._emulator.updateCharset();
    }
    this.options = this._settings_to_options(this.settings); // Terminal uses options as its initial settings when doing a reset    
    
    // Handle updated colors
    var chg_colors = false;
    for (var i = 0; i <= 257; i++) {
        if (this.colors[i] !== prevColors[i]) {
            chg_colors = true;
            break;
        }
    }
    if(!chg_colors) {
        for (var i = 0; i < 64; i++) {
            if (this.attrColor[i][0] !== prevAttrs[i][0] || this.attrColor[i][1] !== prevAttrs[i][1]) {
                chg_colors = true;
                break;
            }
        }
    }
    if (prevSettings.backgroundPicturePath !== this.settings.backgroundPicturePath ||
        prevSettings.backgroundPictureMode !== this.settings.backgroundPictureMode ||
        prevSettings.backgroundPictureAlpha !== this.settings.backgroundPictureAlpha) {
        this.bkgndPict = this.settings.backgroundPicturePath;
        this.bkgndAlpha = this.settings.backgroundPictureAlpha;
        this.bkgndMode = this.settings.backgroundPictureMode;
        chg_colors = true;
    }
    if (chg_colors) {
        this.updateColors(); // refresh the screen with updated colors or updated background image
    }
    if (prevColors[256] !== this.colors[256] || prevColors[257] !== this.colors[257]) {
        this._handle_color_change(this.colors[256], this.colors[257]); // notify client that background color has changed
    }

    // These settings are accessed directly from the settings object:
    //  lockFKeys
    //  normCols, normRows, extCols, extRows
    //  scannerEnable, scannerEOL

    // TODO: implement the following settings:
    //  font
    //  mouseInput
    //  sessionTitle
    //  statusLine

};

// Initialize the keyboard with default key definitions
AccuTerm.prototype.initDfltKbd = function () {
    if (this._emulator && this._emulator.kbdinit) {
        this._emulator.kbdinit.apply(this._emulator, arguments);
    }
};

// Initialize the keyboard with user key definitions
AccuTerm.prototype.initUserKbd = function (clear) {
    var key, def;
    if (clear) {
        // remove user-defined key definitions      
        for (key in this.kbdmap) {
            if (this.settings.keyboard.hasOwnProperty(key)) {
                def = this.kbdmap[key];
                if (def && def.user) {
                    delete def.user;
                }
            }
        }
    }
    // add user-defined key definitions
    for (key in this.settings.keyboard) {
        if (this.settings.keyboard.hasOwnProperty(key)) {
            if (/key\d+/.test(key)) {
                def = this.kbdmap[key] || {};
                def.user = this.settings.keyboard[key];
                this.kbdmap[key] = def;
            }
        }
    }
};

AccuTerm.prototype.isKbdLocked = function() {
    return !!this.kbdLock || !!this.intKbdLock;
};

// Override term.js:Terminal.reset()
AccuTerm.prototype.reset = function () {
    this._iab_exit_unhandle(); // remove any InAppBrowser exit event listener
    this.deferredData = ''; // clear any unprocesed data
    this.filterMode = 0;
    this.intKbdLock = 0;
    this.msgbuf = '';
    this._pvt_capture_stop();
    this._pvt_diag_stop();
    delete this.msgboxrtn;
    delete this.inputstruct;
    delete this.waitforstruct;
    this._pvt_vba_stop();
    this.clearImages(); // clear all images
    this.cols = this.settings.normCols;
    this.rows = this.settings.normRows;
    this.hostMouse = 0; // disable mouse reporting
    this.controlKey = false; // reset pseudo CTRL key
    this.printer_off(true); // cancel slave printing
    Terminal.prototype.reset.call(this);
    /* jshint expr: true */
    this._emulator && this._emulator.reset();
    /* jshint expr: false */
};

// Override term.js:Terminal.write()
AccuTerm.prototype.write = function (data) {
    var initial_y = this.y;
    //DEBUG&&console.log('AccuTerm.prototype.write: len=' + data.length);
    //DEBUG&&console.log('   ' + JSON.stringify(data));
    if (!(this._emulator instanceof AnsiEmulator)) {
        try {
            //this.updateRange(this.y);
            if (this.ybase !== this.ydisp) {
                this.ydisp = this.ybase;
                this.maxRange();
            }
        } catch(e) {
            DEBUG&&console.log('AccuTerm.prototype.write exception updating max range: ' + e);
        }
    }

    if (this.charmap.utf8) {
        data = this.charmap.utf8In(data); // decode UTF8 from host to Unicode
    }

    try {
        for (var i = data.length; i > 0; data = data.slice(-i)) {
            var prev_state = this.state;
            if (this.state === this.SUSPENDED) {
                this.deferredData += data;
                break; // exit from write loop until this._resume_processing() callback gets called
            } else if (this.filterMode) {
                this.deferredData += data;
                break;
            } else if (this.state >= this.PRIVATE) {
                // process private control & escape sequences
                i = this.private(data);
            } else {
                if (!this._emulator) {
                    return;
                }
                i = +(this._emulator.write(data)) || 0;
            }
            /*if (i === 0) {
                break;
            }*/
            if (this.diagCtx && this.diagCtx.mode && this.state !== this.SUSPENDED && !this.filterMode) {
                this._pvt_diag_update(data.substr(0, data.length - i), 0);  
                if(prev_state < this.PRIVATE && this.state >= this.PRIVATE) {
                    // Switching to private sequence - remove ESC STX from checksum and save stats.
                    // It is tempting to do just not call update for ESC STX, but we could get ESC
                    // in a previous call to write and would have to remove it anyway in that case.
                    this._pvt_diag_save_stats('\x1b\x02');
                }
            }
            if (this.captureCtx && this.captureCtx.src === 0 && this.captureCtx.flt === 0) {
                this._pvt_capture_data(data.substr(0, data.length - i));
            }
        }
    } catch(e) {
        DEBUG&&console.log('AccuTerm.prototype.write exception processing incoming data: ' + e);
    }
        
    try {
        if (!(this._emulator instanceof AnsiEmulator)) {
            this.updateRange(initial_y);
            this.updateRange(this.y);
            //PJS this.refresh(this.refreshStart, this.refreshEnd);
            //PJS this.resetRange(); // reset refresh range
        }
    } catch(e) {
        DEBUG&&console.log('AccuTerm.prototype.write exception updating refresh range: ' + e);      
    }
};

// write a character on the terminal screen (cloned from the term.js:Terminal.write())
AccuTerm.prototype.writech = function (ch, attr, flags) {
    var j
        , y = this.y
        , lastCol = this.lastCol(y)
        , cell
        , wc
        , fix;

    ch = this.charmap.charIn(ch);

    // check if wide character will fit on line
    wc = Terminal.isWide(ch);
    if (wc && (lastCol - this.x + 1) < 2) {
        // wide character cannot be displayed in last column - need at least two columns!
        ch = ' '; // TODO - should we use substitution character?
        wc = false; // clear the wide character flag
    }

    // store character in screen buffer
    j = y + this.ybase;
    cell = this.lines[j][this.x];
    if (cell) {
        fix = !!(cell[2] && (cell[2] & 4) && this._emulator.updateEmbeddedAttr); // does cell have embedded attribute? do we have an update function?
        cell = [attr, ch];
        if (flags) {
            cell[2] = flags;
        }
        this.lines[j][this.x] = cell;
        if (fix) {
            this._emulator.updateEmbeddedAttr(this.x, y);
        }
    }

    // do this before & after advancing the cursor, since we might wrap or scroll
    this.updateRange(this.y);

    // advance cursor
    this.advanceCursor();

    // insert dummy space after wide character
    if (wc) {
        this.lines[this.y + this.ybase][this.x] = [attr, ' '];
        this.advanceCursor();
    }

    this.updateRange(this.y);
};

AccuTerm.prototype.insertch = function (ch, attr, flags) {
    this.insertChars([1]);
    this.writech(ch, attr, flags);
};

// Advance cursor to next position, handling scrolling region, autowrap & no scroll mode
AccuTerm.prototype.advanceCursor = function () {
    var y = this.y
        , lastCol = this.lastCol(y);
    this.x++;
    if (this.x > lastCol) {
        if (!this.wraparoundMode) {
            this.x = lastCol;
        } else {
            this.x = 0;
            if (y < this.scrollBottom) {
                this.y++; // cursor above bottom of scrolling region
            } else if (y === this.scrollBottom) {
                if (!this.noScrollMode) {
                    this.scroll(); // cursor on last row of scrolling region (or last row of screen)
                } else {
                    this.y = this.scrollTop; // wrap to top of scrolling region  if scrolling disabled
                }
            } else if (this.scrollBottom < this.rows - 1) {
                this.y++; // cursor below bottom of scrolling region and above last row of screen
            } else {
                this.y = this.scrollTop; // cursor on last row of screen - wrap to top of scrolling region
            }
        }
    }
    //adjustTermWindow();
};

AccuTerm.prototype.clearImages = function() {
    this._pvt_image_func('C');
};

// Override term.js:Terminal.eraseAttr()
AccuTerm.prototype.eraseAttr = function () {
    if (this._emulator && this._emulator.eraseAttr) {
        return this._emulator.eraseAttr();
    }
    return this.defAttr;
};

// Override term.js:Terminal.keyPress()
AccuTerm.prototype.keyPress = function (ev) {
    DEBUG&&console.log("AccuTerm.keyPress: key=" + ev.key + " keyCode=" + ev.keyCode + " which=" + ev.which + " ctrl=" + ev.ctrlKey + " shift=" + ev.shiftKey + " alt=" + ev.altKey + " meta=" + ev.metaKey);
    var keyCode, keyStr;
    var raw = true;
    this._cancel(ev);
    if (ev.charCode) {
        keyCode = ev.charCode;
        /* jshint eqnull:true */
    } else if (ev.which == null) {
        keyCode = ev.keyCode;
    } else if (ev.which !== 0 && ev.charCode !== 0) {
        keyCode = ev.which;
    } else {
        return false;
    }
    if (ev.keyCode && ev.keyCode === 8) {
        // special handling for backspace key for Android 4.4 Chromium issue 118639
        return this.keyDown(ev);
    } else {
        if (!keyCode || ev.altKey || ev.metaKey) {
            return false;
        }
        if (keyCode === 10 && this.isAndroid && this.androidVersion >= 1.0) {
            // Android bluetooth keyboard numeric keypad enter key sends LF instead of CR
            keyCode = 253; // numpad enter key
            if (ev.shiftKey)
                keyCode += 1000; // shift
            keyStr = this.getKeyStr('key' + keyCode, true);
        } else {
            keyStr = String.fromCharCode(keyCode);
            raw = false;
        }
        this.emit('keypress', keyStr, ev);
        this.emit('key', keyStr, ev);
        this.showCursor();
        if (!this.isKbdLocked() && this.state !== this.SUSPENDED) {
            // check if alpha key and CTRL toggle button is ticked
            if (((keyCode >= 0 && keyCode <= 31) || (keyCode >= 64 && keyCode <= 95) || (keyCode >= 97 && keyCode <= 122)) &&
                (this.controlKey || ev.ctrlKey)) {
                keyCode &= 31; // convert to control code
                keyStr = this.getKeyStr('key' + (keyCode + 2064), true); // is key programmed?
                if (!keyStr) {
                    keyStr = String.fromCharCode(keyCode); // control character
                }
            }
            raw ? this.sendRaw(keyStr) : this.send(keyStr); // programmed keys use host character set, no translation
        }
        return false;
    }
};

// Override term.js:Terminal.keyDown()
AccuTerm.prototype.keyDown = function (ev) {
    DEBUG&&console.log("AccuTerm.keyDown: key=" + ev.key + " keyCode=" + ev.keyCode + " which=" + ev.which + " ctrl=" + ev.ctrlKey + " shift=" + ev.shiftKey + " alt=" + ev.altKey + " meta=" + ev.metaKey);
    var keyCode = ev.keyCode || 0;
    if (keyCode === 0 || keyCode === 229) {
        return true; // Chrome soft keyboard sets keyCode = 0 or 229 (composing)
    }
    if (ev.shiftKey) {
        keyCode += 1000;
    }
    if (ev.ctrlKey) {
        keyCode += 2000;
    }
    if (ev.altKey || ev.metaKey) {
        keyCode += 4000;
    }
    var keyStr = this.getKeyStr('key' + keyCode, true);
    if (!keyStr) {
        if (keyCode >= 2064 && keyCode <= 2095) {
            // physical keyboard has "real" CTRL key, but webview won't
            // translate CTRL+key into control character, so synthesize it.
            keyStr = String.fromCharCode(keyCode - 2064); // convert to control character
        } else if (keyCode === 32) {
            keyStr = ' '; // Safari on iPad with Magic Keyboard spacebar not sending keyPress event!
        } else {
            // let keyPress handle the key event
            return true;
        }
    }
    this.emit('keydown', ev);
    this.emit('key', keyStr, ev);
    this.showCursor();
    if (!this.isKbdLocked() && this.state !== this.SUSPENDED) {
        this.sendRaw(keyStr); // programmed keys use host character set, no translation
    }
    return this._cancel(ev);
};

AccuTerm.prototype.sendFunctionKey = function (theKey) {
    var keyStr = this.getKeyStr(theKey, true);
    if (!keyStr) {
        const rslt = theKey.match(/^key(\d+)$/);
        const keyCode = (rslt && +rslt[1]) || 0;
        if (keyCode >= 2064 && keyCode <= 2095) {
            // physical keyboard has "real" CTRL key, but webview won't
            // translate CTRL+key into control character, so synthesize it.
            keyStr = String.fromCharCode(keyCode - 2064); // convert to control character
        }
    }
    if (keyStr) {
        this.showCursor();
        if (!this.isKbdLocked() && this.state !== this.SUSPENDED) {
            this.sendRaw(keyStr); // programmed keys use host character set, no translation
        }
    }
};

AccuTerm.prototype.sendKeys = function(text) {
    if (!this.isKbdLocked() && this.state !== this.SUSPENDED) {
        this.send(text);
    }
};

AccuTerm.prototype.sendPasteText = function (text) {
    if (!this.isKbdLocked() && this.state !== this.SUSPENDED) {
        if (!this.settings.pasteAllowCurlyQuotes) {
            if (/[\u201c\u201d\u2018\u2019\u2013\u2014\u2212]/.test(text)) {
                // translate curly quotes and other special characters to ASCII equivalents
                text = text.replace(/[\u201c\u201d]/g, '"');
                text = text.replace(/[\u2018\u2019]/g, "'");
                text = text.replace(/[\u2013\u2014\u2212]/g, '-');
            }         
        }
        var lineEnds = {CR: '\x0d', LF: '\x0a', CRLF: '\x0d\x0a', TAB: '\x09', USER: (this.settings.pasteLineUser ? String.fromCharCode(this.settings.pasteLineUser) : '')};
        var lineEnd = lineEnds[this.settings.pasteLine] || '';
        var lines = text.split(/\r?\n/);
        if (!this.settings.pasteLineEnd) {
            lines.push(''); // add final EOL
        }
        this.send(lines.map((value) => value.trimEnd()).join(lineEnd)); // trim trailing spaces from each line
        if (this.settings.pasteText) {
            var textEnds = {EOF: '\x1a', USER: (this.settings.pasteTextUser ? String.fromCharCode(this.settings.pasteTextUser) : '')};
            var textEnd = textEnds[this.settings.pasteText] || '';
            if (textEnd) {
                this.send(textEnd);
            }
        }
    }
};

// return true if mouse event sent to host, in case caller wants to cancel the event
AccuTerm.prototype.sendMouseClick = function (btn, x, y) {
    var out = '';
    var button = [0, 1, 3, 2][(btn < 1 || btn > 3) ? 0 : btn]; // map MouseEvent.which to AccuTerm button
    DEBUG&&console.log("tdmain.sendMouseClick: btn=" + button + " x=" + x + " y=" + y + " hostmouse=" + !!this.hostMouse + " kbdlock=" + this.isKbdLocked() + " state=" + this.state); 
    function zeroPad(num, places) {
        var npad = places - num.toString().length + 1;
        return Array(+(npad > 0 && npad)).join('0') + num.toString();
    }
    if (this.hostMouse && button && !this.isKbdLocked() && this.state !== this.SUSPENDED) {
        // TODO: ANSI locator report
        // TODO: mouse pattern matching
        if (this.hostMouse === 1 && TermUtil.isAnsi(this._termtype)) {
            // send AccuTerm ANSI mouse position
            out += this.eightBitControls ? '\x9b' : '\x1b\x5b';
            out += (button + 100);
            out += '~';
            out += this.eightBitControls ? '\x9b' : '\x1b\x5b';
            out += (y + 1);
            out += ';';
            out += (x + 1);
            out += 'R';
        } else if (this.hostMouse === 1) {
            // send AccuTerm ASCII mouse position
            out += '\x02';
            out += String.fromCharCode(112 + button - 1);
            out += '\x0d';
            out += zeroPad(x, 3);
            out += '.';
            out += zeroPad(y, 2);
            out += '\x0d';
        } else if (this.hostMouse === 2) {
            // send SystemBuilder mouse position
            if (button === 3) {
                button = 4; // translate to SB compatible button number
            }
            out += '\x02~\x0d';
            out += button;
            out += ';';
            out += x;
            out += ';';
            out += y;
            out += '\x0d';
        }
        DEBUG&&console.log("tdmain.sendMouseClick: out=" + escape(out));
        this.send(out);
    }
    return !!this.hostMouse; // host eats mouse event
};

AccuTerm.prototype.sendScanText = function (text) {
    if (!this.isKbdLocked() && this.state !== this.SUSPENDED && this.settings.scannerEnable) {
        var lines = text.split(/\r?\n/);
        if (this.settings.scannerEOL) {
            lines.push(''); // add final EOL
        }
        this.send(lines.join('\r'));
    }
};

AccuTerm.prototype.setControlKey = function (newState) {
    this.controlKey = !!newState;
};

// emulate slave printer; if mode is true, enable transparent print, else enable copy print
AccuTerm.prototype.printer_on = function (mode, exit_strings) {
    if (!mode) return; // TODO: implement copy print mode
    if (this.printMode) return; // already set
    if (!exit_strings || exit_strings.length === 0) return; // must have at least 1 exit string for transparent mode
    this._save_emulator = this._emulator;
    this._emulator = new PtrEmulator(this, exit_strings);
    this.printMode = 2; // transparent mode
};

// cancel transparent or copy print mode; if mode is true, close any open print job
AccuTerm.prototype.printer_off = function (mode) {
    if (!this.printMode) return; // not enabled
    if (this.printMode === 2) {
        if (mode) {
            // TODO: close the print job            
        }   
        this._emulator = this._save_emulator;
        this._save_emulator = null;
    }
    this.printMode = 0;
};

AccuTerm.prototype.write_printer = function(data) {
    if (this.printMode !== 2) return; // TODO: only transparent implemented so far
    // TODO: send to real printer?
    if (this.captureCtx && this.captureCtx.src === 1) {
        // capture printer data
        if (this.captureCtx.flt) {
            // text mode = filter control characters
            var ch, i;
            for (i = 0; i < data.length; i++) {
                ch = data.charCodeAt(i);
                if ((ch >= 32 && ch <= 126) || (ch >= 128)) {
                    this._pvt_capture_data(String.fromCharCode(ch));
                }
            }
        } else {
            this._pvt_capture_data(data);
        }
    }
};

// handle private control character - returns true if switching to private state or false to process normally
AccuTerm.prototype.ctlchr = function (ch) { /*jshint unused:false */
    // If we return true here, we need to set this.state to a value >= PRIVATE
    return false;
};

// handle private escape sequence - returns true if switching to private state or false to process normally
AccuTerm.prototype.escseq = function (ch) {
    if (ch === '\x02') {
        // ESC STX ... introduces private sequence
        this.state = this.ESCSTX;
        return true;
    }
    return false;
};

// handle private sequence - returns number of unprocessed characters
AccuTerm.prototype.private = function (data) {
    var len = data.length,
        i,
        ch,
        n,
        info,
        version,
        product,
        caps;
    for (i = 0; i < len; i++) {
        ch = data[i];
        switch (this.state) {
            case this.ESCSTX:
                //DEBUG&&console.log('private sequence: ESC STX ' + ch);
                this.stxfunc = ch;
                this.stxcmd = '';
                switch (ch) {
                    case '%':
                        this.state = this.ESCSTXPCNT;
                        break;
                    case 'C':
                        this.state = this.ESCSTXUC;
                        break;
                    case 'a':
                    case 'b':
                        this.state = this.ESCSTXLA;
                        break;
                    case '&':
                    case '*':
                    case '=':
                    case '<':
                    case '>':
                    case 'A':
                    case 'B':
                    case 'D':
                    case 'F':
                    case 'P':
                    case 'R':
                    case 'U':
                    case 'Y':
                    case 'Z':
                    case 'd':
                    case 'f':
                    case 'h':
                    case 'i':
                    case 'j':
                    case 'm':
                    case 'p':
                    case 'r':
                    case 'w':
                    case 'y':
                        this.state = this.ESCSTXCR;
                        break;
                    case 's':
                        this.state = this.ESCSTXCRX;
                        break;
                    case '?':
                        // send capabilities string
                        info = {};
                        this.emit('getinfo', info);
                        product = (info.product ? info.product : 'WEB').toUpperCase();
                        caps = (this.isAndroid ? '41' : (this.isIpad ? '51' : (this.isIphone ? '52' : '30'))) + '*' + // platform=30,41,51,52
                            (product === 'MOBILE' ? '10' : '11') + '*' + // product ID 10=mobile, 11=web
                            '1' + '*' + // license=1 (single user)
                            'B' + // B=border effects
                            'C' + // C=capture (clipboard only)
                            'E' + // E=execute commands
                            'G' + // G=GUI support
                            'I' + // I=image display
                            'J' + // J=save/restore screenblock
                            'L' + // L=background image
                            'P' + // P=packetized client/server message format
                            'R' + // R=reliable transport (AIX may not support this!)
                            'V' + // V=host capabilities (ESC STX '=') supported
                            'Y'   // Y=query state
                        if (Basic && BasicExtension)
                            caps += 'S'; // S=VBA Scripting
                        caps += '*g'; // GUI server
                        caps += '\r';
                        this.send(caps);
                        this.state = this.NORMAL;
                        return (len - i - 1);
                    case '0':
                    case '1':
                    case '2':
                        this.hostMouse = ch.charCodeAt(0) - 48;
                        this.state = this.NORMAL;
                        return (len - i - 1);
                    case 'E':
                        this._pvt_screen_mode(1);
                        this.state = this.NORMAL;
                        return (len - i - 1);
                    case 'N':
                        this._pvt_screen_mode(0);
                        this.state = this.NORMAL;
                        return (len - i - 1);
                    case 'I':
                        // send identification string
                        info = {};
                        this.emit('getinfo', info);
                        version = info.version ? info.version : '0.0.0';                        
                        product = (info.product ? info.product : 'WEB').toUpperCase();
                        this.send('ACCUTERM/' + product + ' ' + version + ' 0 ' + product + '\r');
                        this.state = this.NORMAL;
                        return (len - i - 1);
                    case 'S':
                    case 'T':
                        // file transfer status
                        this.send('\r');
                        this.state = this.NORMAL;
                        return (len - i - 1);
                    case 'W':
                        //TODO: save configuration
                        this.state = this.NORMAL;
                        return (len - i - 1);
                    case 'e':
                    case 'n':
                    case 'o':
                        //TODO: send / resend message / packet
                        //this.send('\r');
                        this.state = this.NORMAL;
                        return (len - i - 1);
                    case 'L': // capslock on (ignore)
                    case 'M': // capslock off (ignore)
                    case 'X': // end program (ignore)
                        /* fall through */
                    default:
                        // unimplemented private sequence
                        this.state = this.NORMAL;
                        return (len - i - 1);
                }
                break;
            case this.ESCSTXPCNT:
                if (/^[0-9]$/.test(ch)) {
                    //TODO: return desired path
                    this.send('\r');
                }
                this.state = this.NORMAL;
                return (len - i - 1);
            case this.ESCSTXUC:
                if (ch === 'X') {
                    this._pvt_capture_stop();
                    this.state = this.NORMAL;
                    return (len - i - 1);
                }
                this.stxcmd += ch;
                this.state = this.ESCSTXCR;
                break;
            case this.ESCSTXLA:
                this.stxfunc += ch; // append sub-command
                this.state = this.ESCSTXCRX;
                break;
            case this.ESCSTXCRX:
                if (ch === '\x02') {
                    this.intKbdLock = 1; // lock the keyboard during message packet receipt
                    this.state = this.ESCSTXPKT;
                    break;
                }
                this.state = this.ESCSTXCR;
                /* fall through */
            case this.ESCSTXCR:
                // append to command string until CR
                if (ch === '\x0d') {
                    // process command (stxfunc)
                    this.stxcmd = this.charmap.charIn(this.stxcmd); // convert from host character set to Unicode before processing
                    this.state = this.NORMAL;
                    switch (this.stxfunc.charAt(0)) { // remove the sub-command
                        case '&':
                            //TODO: query settings
                            this.send('\r');
                            break;
                        case '*':
                            // diagnostic functions
                            this._pvt_diag(this.stxcmd);
                            break;
                        case '=':
                            // save host capabilities
                            this._pvt_host_caps(this.stxcmd); 
                            break;
                        case '<':
                        case '>':
                            // execute command line
                            this._pvt_exec_cmd(this.stxfunc, this.stxcmd);
                            break;
                        case 'A':
                            n = (+this.stxcmd || 0) & 0xf;
                            this._emulator.setForeground && this._emulator.setForeground(TermColors.XlateColor(n));
                            break;
                        case 'B':
                            n = (+this.stxcmd || 0) & 0xf;
                            this._emulator.setBackground && this._emulator.setBackground(TermColors.XlateColor(n));
                            break;
                        case 'C':
                            this._pvt_capture_start(this.stxcmd);
                            break;
                        case 'D':
                            // TODO: file download
                            break;
                        case 'F':
                            this._pvt_pgm_fkey(this.stxcmd);
                            break;
                        case 'P':
                        case 'R':
                            // interpret VBA script from host                            
                            this._pvt_vba_script(this.stxfunc, this.stxcmd);
                            break;
                        case 'U':
                            // TODO: file upload
                            break;
                        case 'Y':
                            this._pvt_paste(this.stxcmd);
                            break;
                        case 'Z':
                            // call DLL N/A
                            break;
                        case 'a':
                        case 'b':
                            this.send('ERR: 1 unsupported function\r'); // prevent autoserver request from hanging
                            break;
                        case 'd':
                            // TODO: start server mode (ACK/NAK mode)
                            break;
                        case 'f':
                            // TODO: start server mode (streaming mode)
                            break;
                        case 'h':
                            // TODO: add entry to mouse pattern table
                            break;
                        case 'i':
                            this._pvt_image_func(this.stxcmd);
                            break;
                        case 'j': // save/restore screen
                            this._pvt_screen_state(this.stxcmd);
                            break;
                        case 'm':
                        case 'w':
                            this._pvt_play_sound(this.stxcmd);
                            break;
                        case 'p':
                            // printer mode
                            this._pvt_printer_mode(this.stxcmd);
                            break;
                        case 'r':
                            // TODO: draw border around block
                            break;
                        case 'y':
                            this._pvt_status_inquiry(this.stxcmd);
                            break;
                    }
                    return (len - i - 1);
                }
                this.stxcmd += ch;
                break;
            case this.ESCSTXPKT:
                // append to packet buffer until CR (note: this could be a very long string!)
                if (ch === '\x0d') {
                    this._pvt_ext_service(this.stxfunc, this.stxcmd); // process the command (stxfunc)
                    return (len - i - 1);
                }
                this.stxcmd += ch; // append to packet buffer
                break;
            default:
                this.state = this.NORMAL;
                break;
        }
    }
    return 0;
};

AccuTerm.prototype.getKeyStr = function(key, run_vba) {
	function udk(that, val, arrow) {
		if(arrow)
			val = that._decodeKeyStr(val);
		if (val.charAt(0) === '[') {
			if (run_vba) {
				that._pvt_vba_script('', val.slice(1, -1));
			}
			return '';
		} else {
			return val;
		}
	}
	var def = this.kbdmap[key];
	if (def) {
		// user-defined key overrides everything if keyboard programming is locked
		if (def.user && this.settings.lockFKeys) {
			return udk(this, def.user, true);
		}
		// host-programmed key overrides user-defined key if keyboard programming is unlocked
		if (def.host) {
			return udk(this, def.host, false);
		}
		// user-defined key overrides default, unless host reset key to default (def.host===null)
		if (def.user && def.host !== null) {
			return udk(this, def.user, true);
		}
		// default key
		return def.dflt || '';
	}
	return '';
};

// decode AccuTerm's arrow-prefixed string
AccuTerm.prototype._decodeKeyStr = function (keyStr) {
    var out = '', i, ln, ch;
    out = '';
    ln = keyStr.length;
    for (i = 0; i < ln; i++) {
        ch = keyStr.charAt(i);
        if (ch === '^') {
            // convert caret prefix to control character        
            i++;
            if (i < ln) {
                ch = keyStr.charAt(i);
                if (ch === '?') {
                    ch = '\x7f';
                } else if (ch !== '^') {
                    ch = String.fromCharCode(ch.charCodeAt(0) & 0x1f);
                } else {
                    ch = '';
                }
            }
        } else if (ch === '\\') {
            // convert backslash prefix for 8th bit
            i++;
            if (i < ln) {
                ch = keyStr.charAt(i);
                if (ch === '^') {
                    i++;
                    if (i < ln) {
                        ch = keyStr.charAt(i);
                        if (ch === '<') {
                            ch = '\xdc';
                        } else if (ch === '>') {
                            ch = '\xde';
                        } else if (ch === '?') {
                            ch = '\xff';
                        } else {
                            ch = String.fromCharCode((ch.charCodeAt(0) & 0x1f) | 0x80);
                        }
                    } else {
                        ch = '';
                    }
                } else if (ch !== '\\') {
                    ch = String.fromCharCode(ch.charCodeAt(0) | 0x80);
                }
            } else {
                ch = '';
            }
        }
        if (ch) {
            out += ch;
        }
    }
    return out;
};

AccuTerm.prototype._encodeKeyStr = function(keyStr) {
    var out = '';
    var sq, ch;
    var i;
    var n = keyStr.length;
    for (i = 0; i < n; i++) {
        ch = keyStr.charAt(i);
        sq = ch.charCodeAt(0);
        // convert non-printable chars to printable
        if ((sq >= 32 && sq <= 91) || (sq === 93) || (sq >= 95 && sq <= 126)) {
            //normal 7-bit ASCII character that does not need to be escaped - unchanged
        } else if ((sq >= 0 && sq <= 29) || (sq === 31)) {
            ch = String.fromCharCode(94) + String.fromCharCode(sq + 64);
        } else if (sq === 30) {
            ch = String.fromCharCode(94) + String.fromCharCode(126);
        } else if (sq === 92) {
            ch = String.fromCharCode(92) + String.fromCharCode(92);
        } else if (sq === 94) {
            ch = String.fromCharCode(94) + String.fromCharCode(94);
        } else if (sq === 127) {
            ch = String.fromCharCode(94) + String.fromCharCode(63);
        } else if ((sq >= 128 && sq <= 155) || (sq === 157) || (sq === 159)) {
            ch = String.fromCharCode(92) + String.fromCharCode(94) + String.fromCharCode(sq - 64);
        } else if (sq === 156 || sq === 158) {
            ch = String.fromCharCode(92) + String.fromCharCode(94) + String.fromCharCode(sq - 32);
        } else if (sq >= 160 && sq <= 255) {
            //normal 8-bit ASCII character that does not need to be escaped - unchanged
        } else {          
            // Unicode character above U+00FF (no escape)
        }
        out += ch;
    }
    if (sq === 32) {
       // preserve trailing blank
       out += String.fromCharCode(94);
    }    
    return out;
};

AccuTerm.prototype._pvt_pgm_fkey = function (theData) {
    var i, ln, shift = 0, vkey = -1, kpd = false, ch, key, def;
    ln = theData.length;
    for (i = 0; i < ln; i++) {
        ch = theData.charAt(i);
        switch (ch) {
            case 'N':
                shift = 0; // NORMAL
                break;
            case 'C':
                shift = (shift | 2) & ~4; // CTRL (clears ALT)
                break;
            case 'A':
                shift = (shift | 4) & ~2; // ALT (clears CTRL)
                break;
            case 'S':
                shift |= 1; // SHIFT
                break;
            case 'U':
                shift &= ~1; // unshifted (clears SHIFT)
                break;
            case 'T':
                vkey = 0; // ignore button bar text
                break;
            case 'K':
                kpd = true; // keypad
                break;
            case '0':
                vkey = kpd ? 8 : 112; // Backspace/F1
                break;
            case '1':
                vkey = kpd ? 9 : 113; // Tab/F2
                break;
            case '2':
                vkey = kpd ? 45 : 114; // Insert/F3
                break;
            case '3':
                vkey = kpd ? 46 : 115; // Delete/F4
                break;
            case '4':
                vkey = kpd ? 36 : 116; // Home/F5
                break;
            case '5':
                vkey = kpd ? 35 : 117; // End/F6
                break;
            case '6':
                vkey = kpd ? 33 : 118; // PageUp/F7
                break;
            case '7':
                vkey = kpd ? 34 : 119; // PageDn/F8
                break;
            case '8':
                vkey = kpd ? 37 : 120; // Left/F9
                break;
            case '9':
                vkey = kpd ? 39 : 121; // Right/F10
                break;
            case ':':
                vkey = kpd ? 38 : 122; // Up/F11
                break;
            case ';':
                vkey = kpd ? 40 : 123; // Down/F12
                break;
            case '<':
                vkey = kpd ? 27 : 0; // Escape/undefined
                break;
            case '=':
                vkey = kpd ? 13 : 0; // Return/undefined
                break;
            case '>':
                vkey = kpd ? 253 : 0; // Keypad Enter/undefined
                break;
            default:
                // key contents begins here
        }
        if (vkey === 0) {
            return; // unexpected lead-in or undefined key code
        } else if (vkey > 0) {
            key = 'key' + (vkey + (shift * 1000));
            def = this.kbdmap[key] || {};
            def.host = this._decodeKeyStr(theData.slice(i + 1));
            this.kbdmap[key] = def;
            return;
        }
    }
};

AccuTerm.prototype._pvt_exec_cmd = function (theFunc, theCmd) {
    //TODO: execute command line: tel, web, email, etc.
    var parser,
        result,
        scheme,
        authority,
        path,
        query,
        fragment,
        params = {},
        queries,
        split,
        recip,
        subject,
        body,
        phonenum,
        i;

    DEBUG && console.log('execute command: ' + theCmd);

    // strip quotes (desktop version allows commands in quotes)
    if (theCmd.length > 2 && theCmd.charAt(0) === '"' && theCmd.charAt(theCmd.length-1) === '"' && theCmd.indexOf('"',1) === theCmd.length-1) {
        theCmd = theCmd.slice(1,theCmd.length-1); // if there are embedded commas, just leave it alone!
    }
    // validate the command line
    if (/^www\..+/i.test(theCmd)) {
        theCmd = 'http://' + theCmd; // assume http if www
    }
    if (!(/^(http|https|mailto|tel)\:.+/.test(theCmd))) {
        DEBUG&&console.log('AccuTerm._pvt_exec_cmd: illegal command - ' + theCmd);
        return this.state; // ignore illegal commands
    }

    // Based on the regex in RFC2396 Appendix B.
    parser = /^(?:([^:\/?\#]+):)?(?:\/\/([^\/?\#]*))?([^?\#]*)(?:\?([^\#]*))?(?:\#(.*))?/;
    result = theCmd.match(parser);
    scheme = result[1] || '';
    authority = result[2] || '';
    path = result[3] || '';
    query = result[4] || '';
    fragment = result[5] || '';
    // convert query string to object
    queries = query.split('&');
    for (i = 0; i < queries.length; i++) {
        split = queries[i].split('=');
        params[split[0]] = decodeURIComponent(split[1]);
    }

    switch (scheme) {
        case 'http':
        case 'https':
            if (typeof openBrowser === 'undefined') {
                // emit the 'browser' event (web version)
                // ** do not suspend, no way to determine when browser page is closed! **
                //var cb;
                //if (theFunc === '>') {
                //    //TODO: client MUST call the finished callback to resume processing!
                //    cb = this._resume_processing.bind(this);
                //    this._suspend_processing();
                //}
                this.emit('browser', {URL: theCmd /*, finished: cb */ });
            } else {
                // use the in-app browser (mobile version)
                this.iab = openBrowser(theCmd, (theFunc === '<'));
                if (theFunc === '>') {
                    // wait for browser window to close before processing any more input
                    if (this.iab) {
                        this.iabcb = this._iab_exit_handler.bind(this);
                        this.iab.addEventListener('exit', this.iabcb);
                        this._suspend_processing();
                    }
                }
            }
            break;
        case 'mailto':
            //TODO: allow multiple recipients & cc recipients
            recip = path || params['to'] || 'no recipient';
            subject = params['subject'] || 'no subject!';
            body = params['body'] || 'no body!';
            DEBUG && console.log('mailto: to=' + recip + ' subject=' + subject + ' body=' + body);
            this.emit('mailto', {recipient: recip, subject: subject, body: body});
            break;
        case 'tel':
            phonenum = path || '';
            i = phonenum.indexOf(';');
            if (i >= 0) {
                phonenum = phonenum.slice(0, i); // strip the tag
            }
            DEBUG&&console.log('tel: number=' + phonenum);
            this.emit('telephone', phonenum);
            break;
    }
};

AccuTerm.prototype._iab_exit_handler = function () {
    this._iab_exit_unhandle();
    this._resume_processing();
};

AccuTerm.prototype._iab_exit_unhandle = function () {
    try {
        if (this.iab && this.iabcb) {
            this.iab.removeEventListener('exit', this.iabcb);
            this.iab = null;
            this.iabcb = null;
        }
    } catch (e) {
    }
};

AccuTerm.prototype._suspend_processing = function () {
    //DEBUG&&console.log('tdmain: processing suspended; state=' + this.state);
    if (this.state !== this.SUSPENDED) {
        this.state = this.SUSPENDED;
        this.deferredData = '';
    }
};

AccuTerm.prototype._resume_processing = function () {
    //DEBUG&&console.log('tdmain: processing resumed; state=' + this.state);
    if (this.state === this.SUSPENDED) {
        var data = this.deferredData;
        this.deferredData = '';
        this.state = this.NORMAL;
        this.write(data);
    }
};

// TODO: handle other capture modes (text only, printed data, to file)
AccuTerm.prototype._pvt_capture_start = function (theCmd) {
    //TODO: parse capture string & begin capture
    var opts = theCmd.split(';'); // separate the options from the path (ignore path on mobile)
    opts = opts[0] || '';
    if (opts.indexOf('C') < 0) {
        return; // only clipboard option is supported on mobile
    }
    this.captureCtx = {
        buf: '',
        dest: 'clipboard',
        src: opts.indexOf('P') >= 0 ? 1 : 0, // capture printer data?
        flt: opts.indexOf('T') >= 0 ? 1 : 0  // capture text only? // TODO: text-mode capture
    };
};

AccuTerm.prototype._pvt_capture_stop = function () {
    if (this.captureCtx) {
        var text = this.captureCtx.buf.replace(/(^C[OANC][PR]?T?;.*\r)/, ''); // remove the "start capture" string from the beginning
        if (text.substr(-2) === "\x1b\x02")
            text = text.slice(0, -2); // remove the "stop capture" escape sequence from end
        if (this.captureCtx.dest === 'clipboard') {
            this.emit('copy', {text: text});
        }
        delete this.captureCtx;
    }
};

AccuTerm.prototype._pvt_capture_data = function(data) {
    if (this.captureCtx) {
        this.captureCtx.buf += data;
    }
};

AccuTerm.prototype._pvt_screen_mode = function (mode) {
    switch(mode) {
        case 0: // normal mode (80 columns)
            if (this.cols !== this.settings.normCols || this.rows !== this.settings.normRows) {
                this.scrMode = 0;
                this.resize(this.settings.normCols, this.settings.normRows);
            }
            break;
        case 1: // extended mode (132 columns)
            if (this.cols !== this.settings.extCols || this.rows !== this.settings.extRows) {
                this.scrMode = 1;
                this.resize(this.settings.extCols, this.settings.extRows);
            }
            break;
        case 2: // TODO variable mode
            break;
    }
};

AccuTerm.prototype._pvt_screen_state = function (theCmd) {

    var params
        , i
        , id
        , left
        , top
        , right
        , bottom
        , width
        , height
        , page
        , lines = []
        , screen_state = {};

    switch (theCmd.substr(0, 1)) {
        case 'S':
            // save screen state
            params = theCmd.split(',');
            id = params[1] || '';
            if (id.length > 0) {
                left = +params[2] || 0;
                top = +params[3] || 0;
                width = +params[4] || this.cols;
                height = +params[5] || this.rows;
                page = params[6];
                page = (page === undefined || page === '' || page < 0) ? this.curPage : +page;
                right = left + width - 1;
                bottom = top + height - 1;
                for (i = 0; i < this.rows; i++) {
                    lines.push(this.blankLine());
                }
                this._pvt_copy_rectangle(page, left, top, right, bottom, lines, 0, 0);
                /* jshint expr: true */
                this._emulator.saveState && this._emulator.saveState(screen_state);
                screen_state.x = this.x;
                screen_state.y = this.y;
                screen_state.noScrollMode = this.noScrollMode;
                screen_state.insertMode = this.insertMode;
                screen_state.wraparoundMode = this.wraparoundMode;
                //TODO: save mouse mode
                screen_state.lines = lines;
                screen_state.left = left;
                screen_state.top = top;
                screen_state.width = width;
                screen_state.height = height;
                this.saved_screen_state[id] = screen_state;
            }
            break;
        case 'R':
            // restore screen state
            params = theCmd.split(',');
            id = params[1] || '';
            if (id.length && (!!(screen_state = this.saved_screen_state[id]))) {
                left = (params[2] === undefined || params[2] === '') ? screen_state.left : (+params[2] || 0);
                top = (params[3] === undefined || params[3] === '') ? screen_state.top : (+params[3] || 0);
                page = params[4];
                page = (page === undefined || page === '' || page < 0) ? this.curPage : +page;
                width = Math.min(screen_state.width, this.cols - left);
                height = Math.min(screen_state.height, this.rows - top);
                this._pvt_copy_rectangle(screen_state.lines, 0, 0, width - 1, height - 1, page, left, top);
                if (params[5] === '1') {
                    this.x = screen_state.x || this.x;
                    this.y = screen_state.y || this.y;
                    this.noScrollMode = screen_state.noScrollMode || this.noScrollMode;
                    this.insertMode = screen_state.insertMode || this.insertMode;
                    this.wraparoundMode = screen_state.wraparoundMode || this.wraparoundMode;
                    //TODO: restore mouse mode
                    /* jshint curly: false */
                    this._emulator.restoreState && this._emulator.restoreState(screen_state);
                }
            }
            break;
        case 'D':
            // delete screen state
            params = theCmd.split(',');
            id = params[1] || '';
            if (id.length && this.saved_screen_state[id]) {
                delete this.saved_screen_state[id];
            }
    }

};

AccuTerm.prototype._pvt_copy_rectangle = function (src, ls, ts, rs, bs, dst, ld, td) {

    // src = source page number or lines array
    // dst = destination page numberor lines array

    var srcline
        , dstline
        , srclines
        , dstlines
        , srcbase
        , dstbase
        , ps = null
        , pd = null
        , x
        , y
        , nx
        , ny
        , dx = 1
        , dy = 1
        , xd
        , yd
        , xs
        , ys;

    /* jshint curly: false */

    // check parameters
    if (ls < 0)
        ls = 0;
    if (rs >= this.cols)
        rs = this.cols - 1;
    if (ts < 0)
        ts = 0;
    if (bs >= this.rows)
        bs = this.rows - 1;
    if (rs < ls || bs < ts || ld < 0 || ld >= this.cols || td < 0 || td >= this.rows)
        return;

    // get number of columns to copy
    nx = rs - ls + 1;
    if (this.cols - ld < nx) {
        nx = this.cols - ld;
        rs = ls + nx - 1;
    }

    // get number of rows to copy
    ny = bs - ts + 1;
    if (this.rows - td < ny) {
        ny = this.rows - td;
        bs = ts + ny - 1;
    }

    if (ld > ls) {
        // copy from right to left in case overlapping source & destination
        ls = ls + nx - 1;
        ld = ld + nx - 1;
        dx = -1;
    }
    if (td > ts) {
        // copy from bottom to top in case overlapping source & destination
        ts = ts + ny - 1;
        td = td + ny - 1;
        dy = -1;
    }

    // setup source & destination lines arrays
    if (src instanceof Array) {
        srclines = src;
        srcbase = 0;
    } else {
        ps = src;
        if (src < 0) {
            ps = 0;
        } else if (src >= this.pages) {
            ps = this.pages - 1;
        }
        if (ps === this.curPage) {
            srclines = this.lines;
            srcbase = this.ybase;
        } else {
            srclines = (this.screenPage[ps] && this.screenPage[ps].lines) || [];
            srcbase = 0;
        }
    }
    if (dst instanceof Array) {
        dstlines = dst;
        dstbase = 0;
    } else {
        pd = dst;
        if (pd < 0) {
            pd = 0;
        } else if (pd >= this.pages) {
            pd = this.pages - 1;
        }
        if (pd === this.curPage) {
            dstlines = this.lines;
            dstbase = this.ybase;
        } else {
            dstlines = (this.screenPage[pd] && this.screenPage[pd].lines) || [];
            dstbase = 0;
        }
    }

    y = ny;
    yd = td + dstbase;
    ys = ts + srcbase;
    while (y--) {
        srcline = srclines[ys] || this.blankLine();
        dstline = dstlines[yd] || this.blankLine();
        x = nx;
        xd = ld;
        xs = ls;
        while (x--) {
            dstline[xd] = srcline[xs] || [this.defAttr, ' '];
            xd += dx;
            xs += dx;
        }
        ys += dy;
        yd += dy;
    }

    if (pd === this.curPage) {
        this.updateRange(td);
        this.updateRange(td + ((ny - 1) * dy));
        //this.refresh(this.refreshStart, this.refreshEnd);
        //this.resetRange(); // reset refresh range
    }

};

AccuTerm.prototype._pvt_status_inquiry = function (theCmd) {

    var params
        , id
        , screen_state
        , rsp;

    switch (theCmd.substr(0, 1)) {
        case 'j':
            // saved screen status
            rsp = '0';
            params = theCmd.split(',');
            id = params[1] || '';
            if (id.length && this.saved_screen_state[id]) {
                rsp = '1';
            }
            this.send(rsp + '\r');
            break;
    }
};

AccuTerm.prototype._pvt_printer_mode = function (theCmd) {
    // TODO: add more printer control modes, like changing printer name, print mode, etc
    switch (theCmd.substr(0, 1)) {
        case '0': // printer off
            this.printer_off(false);
            break;
        case '1': // copy print
            this.printer_on(false);
            break;
        case '2': // transparent print
            this.printer_on(true, ["\x1b\x02p0\x0d", "\x1b\x02pX\x0d"]);
            break;
        case 'X': // close print job
            this.printer_off(true);
            break;
    }
};

AccuTerm.prototype._pvt_image_func = function (theCmd) {
    // TODO: display image on screen
    var params = theCmd.split(',');
    var fname, col, row, wd, ht, aspect, border;
    switch (theCmd.substr(0, 1)) {
        case 'B':
            // background picture (TODO: scale mode)
            this.bkgndPict = params[1] || '';
            this.bkgndAlpha = Math.min(Math.max(params[3] || 0, 0), 100);
            this.updateColors();
            break;
        case 'L':
            fname = params[1] || '';
            col = params[2] || 0;
            row = params[3] || 0;
            wd = params[4] || 0;
            ht = params[5] || 0;
            aspect = params[6] || 0;
            border = params[7] || 'N';
            if(fname && col && row) {
                this.addImage(fname, col, row, wd, ht, aspect, border);
            }
            break;      
        case 'D':
            fname = params[1] || '';
            if (fname) {
                this.deleteImage(fname);
            }
            break;          
        case 'C':
            this.deleteImage('');
            break;
        default:
            // TODO: add other image functions
    }   
};

AccuTerm.prototype._pvt_play_sound = function (theCmd) {
    if (theCmd) {
        this.emit('playsound', {filename:theCmd});        
    }
};

AccuTerm.prototype._pvt_host_caps = function (theCmd) {
    var params = theCmd.split('*');
    this.HostCaps = params[0] || '';
    var siz = params[1] || '0';
    if (/^(0|[1-9]\d*)$/.test(siz)) {
        if (siz == 0)
            siz = 80;
        else if (siz < 32)
            siz = 32;
        else if(siz > 9999)
            siz = 9999;
        else siz += 0;
        this.HostBufferSize = siz;
    }
};

AccuTerm.prototype._pvt_paste = function(theCmd) {
    // Paste, Paste From (theCmd is file path to paste)
    // Some browsers do not allow script to read clipboard contents (Firefox, Safari).
    // Others require a user action to allow reading clipboard, and some will prompt.
    // So, to keep things simple and consistent, just return an empty string as
    // clipboard contents.
    //
    // This function handles private escape sequence ESC STX Y, as well as session.Paste
    // method from scripts (scripts do not send EOF character at end).
    if (theCmd !== undefined) {
        this.send('\x1A'); // end-of-auto-paste
    }
}

// handle external services (GUI)
AccuTerm.prototype._pvt_ext_service = function(theFunc, theCmd) {
    var failure = 'unsupported function';
    var msg;
    var req;
    if (theFunc === "bg" || theFunc === "ag") { // GUI service ('ag' only used for shutdown)
        msg = this._pvt_decode_packet(theCmd); // decode the message string into nested array of AM, VM, SVM
        if (msg === null) {
            failure = 'invalid format';
        } else {
            DEBUG&&console.log("GUI request", msg);
            DEBUG&&console.log("req = " + theCmd);
            try {
                req = GUIFuncs.ParseRequest(msg);
            } catch(errobj) {
                req = null;
                if (errobj instanceof Array)
                    failure = errobj;
                else
                    failure = (errobj.message || 'exception');
            }
            if (req) {
                //DEBUG&&console.log("GUI request", req);
                var opts = {};
                opts.request = req;
                opts.callback = this._gui_response_handler.bind(this);
                this._suspend_processing();
                if (--this.intKbdLock < 0) this.intKbdLock = 0; // OK to do this, suspended state also locks keyboard
                this.emit('gui', opts); // pass this to gui engine (keyboard is locked until we get a response from gui engine)
                return; // state should now be SUSPENDED, unless opts.callback called before we get here!
            }
        }
    }
    // return error response
    if (this.stxfunc.charAt(0) === 'a')
        this.send('OK\r'); // non-streaming protocol requires OK to ack the request
    if (failure instanceof Array)
        msg = this._pvt_encode_packet(failure);
    else
        msg = 'ERR: 1 ' + failure + '\r';
    this.send(msg); // prevent server from hanging by sending a response
    if (--this.intKbdLock < 0) this.intKbdLock = 0; // must unlock - locked when begin processing ESC STX . . STX
    this.state = this.NORMAL;
};

AccuTerm.prototype._pvt_decode_packet = function (msg) {
    // GUI
    // We are only supporting the streaming protocol ESC STX 'bg' STX ... CR
    // except for GUI shutdown which uses ESC STX 'ag' STX ... CR.
    // Entire message should be here, and we are not using checksums.

    // Recursive function to scan delimited string for elements. For each element,
    // scan the element for the next level delimiter, up to 3 levels (AM, VM, SVM).
    // Return nested array of elements. If an elelment does not contain any next-level
    // delimiters, then it is a simple string, else it is an array of next level
    // elements.
    var ctl = {lvl: 0, once: 0, 
        regex: [
            new RegExp('(.*?)\\^<AM>|.+$', 'g'),
            new RegExp('(.*?)\\^<VM>|.+$', 'g'),
            new RegExp('(.*?)\\^<SM>|.+$', 'g')
        ],
        dcd: (function(val) {
            // Decode any control characters using arrow prefix,
            // then translate from host char set to Unicode.
            return this.charmap.charIn(this._decodeKeyStr(val));
        }).bind(this)
    };
    function avsscan(val) {
        ctl.lvl++;
        if (ctl.lvl >= 4) {
            ctl.lvl--;
            return ctl.dcd(val);
        }
        if (typeof(val) !== 'string') {
            if (ctl.once++ === 0)
                DEBUG&&console.log('avsscan: val not a string at level ' + ctl.lvl);
            ctl.lvl--;
            return '';
        }
        var n = 0;
        var rslt = '';
        var avs;
        var itms = val.matchAll(ctl.regex[ctl.lvl - 1]);
        for (var match of itms) {
            if (match[1] === undefined) {
                // last (or only) element in string
                avs = match[0];
                if (n) {
                    rslt.push(avsscan(avs));
                    n++;
                } else {
                    rslt = avsscan(avs);
                    if (rslt instanceof Array)
                        rslt = [rslt]; // nested array
                }
            } else {
                // next (or first) elelement in string
                avs = match[1];
                if (n === 0) {
                    rslt = [avsscan(avs)];
                } else {
                    rslt.push(avsscan(avs));
                }
                n++;
            }
        }
        ctl.lvl--;
        return rslt;
    }
    
    var n = 0;
    var match;
    var pkttyp = msg.charAt(0);
    switch(pkttyp) {
        case 'Q':
            match = msg.match(/^Q([0-9]{8})/);
            if (match === null) {
                DEBUG&&console.log("decode_packet: invalid packet header" + msg.substr(0, 9));
                return null;
            }
            // TODO: check UTF8 encoding (is message length bytes or chars?)
            if (parseInt(match[1]) !== msg.length - (1 + 8)) {
                DEBUG&&console.log("decode_packet: invalid packet length: " + parseInt(match[1]) + ' <> ' + (msg.length - (1 + 8)));
                return null;
            }
            n = 9; // size of packet header
            break;
        case 'E':
            match = msg.match(/^E([0-9]{4})/);
            if (match === null) {
                DEBUG&&console.log("decode_packet: invalid packet header" + msg.substr(0, 5));
                return null;
            }
            // TODO: check UTF8 encoding (is message length bytes or chars?)
            if (parseInt(match[1]) !== msg.length - (1 + 4)) {
                DEBUG&&console.log("decode_packet: invalid packet length: " + parseInt(match[1]) + ' <> ' + (msg.length - (1 + 4)));
                return null;
            }
            n = 5; // size of packet header
            break;
        default:
            // only packet type 'Q' and 'E' supported (full message in single packet, no checksum)
            DEBUG&&console.log("decode_packet: invalid packet type '" + pkttyp + "'");
            return null;
    }
    var result = avsscan(msg.substr(n));
    if (typeof(result) === 'string')
        result = [result];
    return result;
};

AccuTerm.prototype._pvt_encode_packet = function (data) {
    // GUI
    // We are only supporting the streaming protocol ESC STX 'bg' STX ... CR
    // so we can build a single response string with embedded CR as needed
    // to handle max input buffer size on server. No checksum or message length.

    // Recursive function to build delimited string for nested arrays. For each element,
    // if it is an array, recursively call this function, then join the array elements
    // with the delimiter for this level.
    var ctl = {lvl: 0, once: 0,
        delim: ['^<AM>', '^<VM>', '^<SM>'],
        enc: (function(val) {
            // Translate from Unicode to host char set, then
            // encode any control characters using arrow prefix.
            return this._encodeKeyStr(this.charmap.charOut(val));
        }).bind(this)
    }
    function avsbld(elem, idx, arr) {
        ctl.lvl++;
        if (elem instanceof Array) {
            elem.forEach(avsbld);
            elem = elem.join(ctl.delim[ctl.lvl]);
        } else if (typeof(elem) === 'string') {
            elem = ctl.enc(elem);
        } else if (typeof(elem) === 'number') {
            elem = elem.toString(); // we can bypass the encoding call because result of toString never has problem characters
        } else if (typeof(elem) === 'boolean') {
            elem = elem ? '1' : '0';
        } else if (elem === null) {
            elem = '';
        } else {
            if (ctl.once++ === 0)
                DEBUG&&console.log("decode_packet:avsbld: found '" + typeof(elem) + " expected 'string' or 'array'");
            elem = '';
        }
        arr[idx] = elem;        
        ctl.lvl--;
    }

    if (data instanceof Array) {
        data.forEach(avsbld);
        data = data.join(ctl.delim[0]);
    }
    // TODO: check UTF8 encoding (is length bytes or chars?),
    // should not split UTF8 sequence across packets.
    var msg = '';
    var i, n;
    var pktsiz = this.HostBufferSiz || 80;
    var pkthdr;
    // This is a bit of a cheat. gcShutdown switches off the streaming protocol and
    // reverts back to ack/nak protocol, but we don't fully support ack/nak protocol
    // here. But we need to send ack (OK) for the shutdown message, which is a very
    // short message (1 byte), so we won't need any more ack's. So just send the
    // initial ack followed by the response to gcShutdown.
    if (this.stxfunc.charAt(0) === 'a')
        msg = 'OK\r';
    for (i = 0; i < data.length; i += n) {
        n = data.length - i;
        if (n > pktsiz) {
            n = pktsiz;
            pkthdr = 'M' + ('000' + n).slice(-4);
        } else {
            pkthdr = 'E' + ('000' + n).slice(-4);
        }
        msg += pkthdr + data.substr(i, n) + '\r';
    }
    return msg;
};

AccuTerm.prototype._gui_response_handler = function(rsp) {
    var msg;
    //DEBUG&&console.log("GUI response:", rsp);
    try {
        msg = GUIFuncs.BuildResponse(rsp);
    } catch(errobj) {
        if (errobj instanceof Array)
            msg = errobj; // return the GUI error
        else
            msg = errobj.message || 'exception'; // not a GUI error
    }
    if (msg instanceof Array) {
        var out = this._pvt_encode_packet(msg);
        DEBUG&&console.log("rsp =", out);
        this.send(out);
    } else {
        this.send('ERR: 1 ' + msg + '\r'); // prevent server from hanging by sending a response
    }
    this._resume_processing();
};

AccuTerm.prototype._pvt_diag = function (theCmd) {
    var ctx = this.diagCtx || { mode: 0, echo_data: '', echo_time: 0, skip: 0,
                                stats: [[0,0,0],[0,0,0]],
                                save_stats: [[0,0,0],[0,0,0]]};
    switch (theCmd.substr(0, 1)) {  
        case '0': // diag off
            this._pvt_diag_stop();
            break;
        case '2': // send stats to host
            var fletch_in = (ctx.save_stats[0][2] << 8) | ctx.save_stats[0][1];
            var fletch_out = (ctx.save_stats[1][2] << 8) | ctx.save_stats[1][1];
            this.send('diag:len_in=' + ctx.save_stats[0][0] + ';chk_in=' + fletch_in + ';len_out=' + ctx.save_stats[1][0] + ';chk_out=' + fletch_out + '\r');
            // fall thru
        case '1': // verify data sent and received
            ctx.mode |= 1;
            ctx.stats = [[0,0,0],[0,0,0]];
            ctx.save_stats = [[0,0,0],[0,0,0]];
            ctx.skip = 2 + theCmd.length;
            this.diagCtx = ctx;
            break;
        case '3': // enable echo mode
            ctx.mode |= 2;
            var params = theCmd.split(';');
            ctx.echo_time = params[1] || 1000;
            ctx.skip = 2 + theCmd.length;
            this.diagCtx = ctx;
            break;
    }
};

AccuTerm.prototype._pvt_diag_stop = function() {
    if (this.diagCtx) {
        if (this.diagCtx.echo_timer)
            clearTimeout(this.diagCtx.echo_timer);
        delete this.diagCtx;
    }
};

AccuTerm.prototype._pvt_diag_echo = function () {
    if (this.diagCtx.echo_timer)
        clearTimeout(this.diagCtx.echo_timer);
    this.send(this.diagCtx.echo_data);
    this.diagCtx.echo_data = '';
};

AccuTerm.prototype._pvt_diag_update = function (theData, which) {
    var i, j, m;
    var len = theData.length;
    var k = +!!which;
    if (this.diagCtx.skip >= len) {
        this.diagCtx.skip -= len;
        return;
    }
    
    if (this.diagCtx.mode & 2) {
        if (k === 0) {
            if (this.diagCtx.echo_timer)
                clearTimeout(this.diagCtx.echo_timer);
            this.diagCtx.echo_data += theData.slice(this.diagCtx.skip);
            if (this.diagCtx.length > 0x100000)
                this.diagCtx.echo_data = this.diagCtx.echo_data.slice(-0x100000); // don't go overboard!    
            this.diagCtx.echo_timer = setTimeout(this._pvt_diag_echo.bind(this), this.diagCtx.echo_time || 1000);
        }
    }
    
    // The stats array is 2x3, containing the inbound [0], and outbound [1] stats.
    // For each direction, length [k][0], fletcher checksum1 [k][1] and checksum2 [k][2].
    if(this.diagCtx.mode & 1) {
        i = this.diagCtx.skip;
        this.diagCtx.skip = 0;
        this.diagCtx.stats[k][0] += (len - i);
        while (i < len) {
            m = i + 254;
            if (m > len) m = len;
            for (j = i; j < m; j++) {
                this.diagCtx.stats[k][2] += this.diagCtx.stats[k][1] += theData.charCodeAt(j);
            }
            this.diagCtx.stats[k][1] %= 0xff;
            this.diagCtx.stats[k][2] %= 0xff;
            i = m;
        }
    }
};

AccuTerm.prototype._pvt_diag_save_stats = function (theData) {
    if (this.diagCtx.mode & 1) {
        // clone of inbound stats
        var stats = this.diagCtx.stats[0].slice();
        // un-checksum
        for(var i = theData.length - 1; i >= 0 && stats[0] > 0; i--) {
            stats[2] = (stats[2] + 0xff - stats[1]) % 0xff;
            stats[1] = (stats[1] + 0xff - (theData.charCodeAt(i) % 0xff)) % 0xff; // subtract mod 0xff
            stats[0]--; // un-length
        }
        // save stats with theData removed from the length & checksum
        this.diagCtx.save_stats[0] = stats;
        this.diagCtx.save_stats[1] = this.diagCtx.stats[1].slice();
    }
};

AccuTerm.prototype._pvt_vba_script = function (theFunc, theCmd) {
    if (theCmd.substr(0, 23) === "'TEST.RAW.INPUT script\x19") {
        // fake TEST.RAW.INPUT script
        this._setInputMode(1);
        setTimeout(function() {
            var X = '', Z, i;
            Z = theCmd.match(/<> \d+/g); // find all the "skips"
            for (i = 0; i < 256; i++) {
                if (!Z || (Z.indexOf('<> ' + i) < 0)) {
                    X += String.fromCharCode(i);                    
                }
            }
            this.send(X);
            this._script_exit();
        }.bind(this), 1000);        
    } else if (theCmd.substr(0, 24) === "'TEST.RAW.OUTPUT script\x19") {
        // fake TEST.RAW.OUTPUT script
        this.filterMode = 1;
        setTimeout(function() {
            var X = '', Z = '';
            this._setInputMode(2);
            for ( ; ; ) {
                // since we don't have a way to run this in parallel, and block until
                // input is available, we just use a 1000ms timeout to wait for data
                // to buffer up in deferredData...
                Z = this.deferredData.substr(0, 1); // Z = InitSession.ReadText(0,1,2)
                this.deferredData = this.deferredData.substr(1);
                if (!Z.length) break;
                X += Z.charCodeAt(0) + ','; 
            }
            this.send(X + '\r');
            this._script_exit();
        }.bind(this), 1000);
    } else if(theCmd === "'DUMPDEBUGLOG'") {
        // Special "script" to dump debug log to screen. Use console_log()
        // instead of console.log to save interesting events.
        if(DEBUG) {
            if(debug_log_content && debug_log_content.length > 0) {
                this.write("\r\n*** DEBUG LOG START***\r\n");
                this.write(debug_log_content.join("\r\n"));
                this.write("\r\n*** DEBUG LOG END***\r\n");
            } else {
                this.write("\r\n*** DEBUG LOG IS EMPTY ***\r\n");
            }       
        }
    } else if(Basic && BasicExtension) {
        
        if (!this.vba_context) {
            // set up the script context
            var common = []; // create the 'common' collection (using closure to maintain this)
            var ext = BasicExtension;
            var prxy = makeAccuTermProxy(this, ext.constants, common);
            // built-in objects for AccuTerm VBA environment
            var objs = {};
            objs['accuterm'] = prxy;
            objs['activesession'] = prxy.activesession;
            objs['initsession'] = prxy.initsession;
            objs['sessions'] = prxy.sessions;
            objs['common'] = prxy.common;
            ext['objects'] = objs;
            // extension functions
            var funcs = ext.functions;
            funcs['clipboard'] = this._clipboard.bind(this);
            funcs['clipboard'].minLength = 0;
            funcs['msgbox'] = this._msgbox.bind(this);
            funcs['msgbox'].minLength = 1; // arguments 2 & 3 are optional
            funcs['pause'] = function(secs) { secs = +secs || 0; throw {name:'yield', delay:(secs>0?secs*1000:0)} };
            funcs['random'] = function(min, max) { return (Math.random() * (max - min)) + min };
            funcs['shell'] = this._pvt_vba_shell.bind(this);
            funcs['shell'].minLength = 1;
            funcs['sleep'] = function(ms) { ms = +ms || 0; throw {name:'yield', delay:(ms>0?ms:0)} };
            // Create the script options object. This needs to be a member
            // so we can set the 'quitting' property to cancel a script.
            this.vba_context = {                
                dialect: 'VBA',
                do_yield: true,
                do_throw: true,
                quitting: false,
                extender: ext,
                at_finish: this._script_exit.bind(this)
            };
        }
        this.vba_context.quitting = false; // reset in case previous script was cancelled
        // fixup script and execute
        theCmd = "call main()\nsub main()\n" + theCmd.replace(/\x19/g, "\n") + "\nend sub";
        if (theFunc === 'R')
            this._setInputMode(1); // defer processing of input until script Input or WaitFor consumes it
        try {
            if(!Basic(theCmd, null, this.vba_context)) {
                this._script_exit(); // need to call script_exit when script terminates
            }
        } catch(e) {
            this._script_exit(e);
        }
    }

};

AccuTerm.prototype._pvt_vba_stop = function() {
    if (this.vba_context)
        this.vba_context.quitting = true;
};

AccuTerm.prototype._pvt_vba_shell = function(cmd, style) {
    if (!this._pvt_exec_cmd('<', cmd)) {
        throw('Invalid command line');
    }
    return 660; // return positive value above 32
}

AccuTerm.prototype._script_exit = function(e) {
    if (e) {
        DEBUG&&console.log('_pvt_vba_script exception: ' + e.toString());
        this.emit('msgbox', {title: "Script error", message: e.toString(), button1: "OK", callback: function() {} });
    }
    this._setInputMode(0);
};

AccuTerm.prototype._setInputMode = function(mode) {
    mode = +mode || 0;
    if (mode === 0) {
        if (this.filterMode !== 0) {
            this.filterMode = 0;
            if (this.deferredData.length) {
                var data = this.deferredData;
                this.deferredData = '';
                this.write(data);
            }    
        }
    } else if (mode === 1 || mode === 2) {
        this.filterMode = mode;            
    }
};

// which: 0=default, 1=user, 2=host
AccuTerm.prototype._getFKey = function(which, vkey, shift) {
    if (vkey >= 0 && vkey <= 255 && shift >= 0 && shift <= 7) { // TODO: key captions
        var def = this.kbdmap['key' + (vkey + (shift * 1000))] || {};
        switch(which) {
            case 0: return def.dflt || '';
            case 1: return this._decodeKeyStr(def.user || ''); // user-defined keys stored in arrow-prefix format
            case 2: return def.host || '';
        }
        return '';
    }
};
AccuTerm.prototype._setFKey = function(which, vkey, shift, value) {
    if (vkey >= 0 && vkey <= 255 && shift >= 0 && shift <= 7) { // TODO: key captions
        var def = this.kbdmap['key' + (vkey + (shift * 1000))] || {};
        switch(which) {
            case 0: def.dflt = value; break;
            case 1: def.user = this._encodeKeyStr(value); break; // user-defined keys stored in arrow-prefix format
            case 2: def.host = value; break;
        }
        this.kbdmap['key' + (vkey + (shift * 1000))] = def;
    }
};

AccuTerm.prototype._input = function(mode, maxlen, timeout) {
    var data, n = -1;
    if (maxlen === undefined)
        maxlen = 80;
    if (maxlen <= 0)
        maxlen = 32767;
    if (timeout === undefined)
        timeout = 30;
    if (this.inputstruct === undefined) {
        this.inputstruct = {saveFilterMode: this.filterMode, expires: (new Date()).valueOf() + (timeout * 1000)};
        this._setInputMode(1);
    }
    if(mode) {
        // read line of data
        n = this.deferredData.search(/\r\n|\n|\r/);
        if (n >= 0) {
            data = this.deferredData.substr(0, n);
            if (this.deferredData.search(/\r\n/) === n)
                n++;
            n++;
        }
    } else {
        // read raw data
        if (this.deferredData.length >= maxlen) {
            n = maxlen;
            var data = this.deferredData.substr(0, n);
        }
    }
    if (n >= 0) {
        if (this.filterMode === 1) {
            var oldFilterMode = this.filterMode;
            this.filterMode = 0; // must change to 0 to allow write() to process normally
            var towrite = this.deferredData.substr(0, n);
            this.deferredData = this.deferredData.substr(n);
            this.write(towrite);
            this.filterMode = oldFilterMode;
        } else {
            this.deferredData = this.deferredData.substr(n);
        }
        this._setInputMode(this.inputstruct.saveFilterMode);
        delete this.inputstruct;
        return data;
    } else if ((new Date()).valueOf() >= this.inputstruct.expires) {
        data = this.deferredData; // return whatever we have
        this.deferredData = '';
        this._setInputMode(this.inputstruct.saveFilterMode); // restore filter mode
        delete this.inputstruct;
        return data;
    }    
    throw { name: 'retry', delay: 50 }; // try again after 50ms
};

AccuTerm.prototype._output = function(data) {
    this.send(data);
};

AccuTerm.prototype._emulate = function(data) {
    var oldFilterMode = this.filterMode;
    this.filterMode = 0; // must change to 0 to allow write() to process normally
    this.write(data);
    this.filterMode = oldFilterMode;
};

AccuTerm.prototype._waitfor = function(cmp, timeout) {

    // Scan data received from host
    //  Wait for any of the target strings & return
    //  the index of the first target that matches.
    //  If Timeout occurs before any target matches,
    //  return 0.
    var c, i, j, k, n, m, s;
    var rc = 0;
    if (this.waitforstruct === undefined) {
        // initialize waitfor matching
        n = arguments.length - 2;
        if (n <= 0)
            return 0;
        // fill match array with target strings
        var match = [];
        k = 0;
        for (i = 0; i < n; i++) {
            s = arguments[i + 2].toString();
            if (cmp)
                s = s.toLowerCase();
            m = s.length;       
            if (m > 0)
                k++;
            match.push({text: s, position: 0});
        }
        if (k === 0)
            return 0; // nothing to match
        this.waitforstruct = {match: match, saveFilterMode: this.filterMode, expires: (new Date()).valueOf() + (timeout * 1000)};
        this._setInputMode(1);
    }
    m = this.deferredData.length;
    n = this.waitforstruct.match.length;
    for(j = 0; j < m; j++) {
        c = this.deferredData.substr(j, 1);
        if (cmp)
            c = c.toLowerCase();
        for (i = 0; i < n; i++) {
            if (this.waitforstruct.match[i].text.length) {
                if (c === this.waitforstruct.match[i].text.substr(this.waitforstruct.match[i].position, 1)) {
                    this.waitforstruct.match[i].position++;
                    if (this.waitforstruct.match[i].position === this.waitforstruct.match[i].text.length) {
                        // OK - found a match!
                        rc = i + 1;
                        break;
                    }
                } else {
                    this.waitforstruct.match[i].position = 0;
                }
            }
        }
        if (rc)
            break;
    }
    if (j > 0 && this.filterMode === 1) {
        // synchronous mode - pass consumed characters to emulator now
        this.filterMode = 0; // must clear this for emulator to process data
        this.write(this.deferredData.substr(0, j));
        this.filterMode = 1;
    }
    if (j > 0) {
        this.deferredData = this.deferredData.substr(j);
    }   
    if (rc) {
        this._setInputMode(this.waitforstruct.saveFilterMode); // restore filter mode
        delete this.waitforstruct;
        return rc;
    } else if ((new Date()).valueOf() >= this.waitforstruct.expires) {
        this._setInputMode(this.waitforstruct.saveFilterMode); // restore filter mode
        delete this.waitforstruct;
        return 0;
    }    
    throw {name: 'retry', delay: 50}; // try again after 50ms
};

AccuTerm.prototype._msgbox = function(msg, type, title) {
    var rtn = 0;
    var xrsp = [1, 1, 1, 1];
    if (this.msgboxrtn === undefined) {
        // display msgbox
        var opts = {message: msg,
                    title: title || '',
                    button1: '',
                    button2: '',
                    button3: ''};
        type = (+type || 0) & 15;
        switch(type) {
            case 1:
                opts.button1 = 'OK';
                opts.button2 = 'Cancel';
                xrsp = [2, 1, 2, 2];
                break;
            case 2:
                opts.button1 = 'Abort';
                opts.button2 = 'Retry';
                opts.button3 = 'Ignore';
                xrsp = [5, 3, 4, 5];
                break;
            case 3:
                opts.button1 = 'Yes';
                opts.button2 = 'No';
                opts.button3 = 'Cancel';
                xrsp = [2, 6, 7, 6];
                break;
            case 4:
                opts.button1 = 'Yes';
                opts.button2 = 'No';
                xrsp = [7, 6, 7, 7];
                break;
            case 5:
                opts.button1 = 'Retry';
                opts.button2 = 'Cancel';
                xrsp = [2, 4, 2, 2];
                break;
            default:
                opts.button1 = 'OK';
                xrsp = [1, 1, 1, 1];
        }
        opts.callback = (function(btn) {
            btn = (+btn || 0);                
            this.msgboxrtn = (btn >= 0 && btn <= 3) ? xrsp[btn] : -1; // use closure to get xrsp
        }).bind(this);
        this.msgboxrtn = 0; // retry while 0
        this.emit('msgbox', opts);
    }
    if (this.msgboxrtn === 0) {
        // wait for user to dismiss dialog box
        throw {name: 'retry', delay: 50}; 
    } else if (this.msgboxrtn !== undefined) {
        // user has dismissed dialog, return the selection 
        rtn = +this.msgboxrtn || 0;
        if (rtn < 0)
            rtn = 0;            
        delete this.msgboxrtn;
    }
    return rtn;
};
AccuTerm.prototype._msgbox.minLength = 1; // VBA 1 argument required

AccuTerm.prototype._clipboard = function (text) {
    if (text !== undefined) {
        this.emit('copy', {text: text});
    }
    return ''; // reading from clipboard not supported by all browsers!
};
AccuTerm.prototype._clipboard.minLength = 0; // VBA no arguments required

AccuTerm.prototype._gettext = function (col, row, cols, mode) {

    // col, row: start position of text to return (default = cursor position)
    // cols: number of columns to return (default = entire line)
    // mode: 0 = all ASCII, 1 = non-protected ASCII, 2 = include Unicode chars, 3 = all non-protected including Unicode
    // Mode to return Unicode is designed to return line-drawing characters and other symbols
    // src = source page number or lines array
    // dst = destination page numberor lines array

    var line, flags, x, ch, cell, rtn = '';

    // check parameters
    if (col === undefined)
        col = this.x;
    else if (col < 0)
        col = 0;
    else if (col >= this.cols)
        col = this.cols;
    if (cols === undefined)
        cols = this.cols;
    if (col + cols > this.cols)
        cols = this.cols - col;
    if (row === undefined)
        row = this.y;
    if (cols <= 0 || row < -this.historyRows || row >= this.rows)
        return rtn;
    mode = mode || 0;
    line = row + this.ybase < 0 ? this.blankLine() : this.lines[row + this.ybase];
    for (x = col; cols-- > 0; x++) {
        ch = ' ';
        cell = line[x] || [this.eraseAttr(), ' '];
        flags = cell[2] || 0;
        if(!((mode & 0x1) && (flags & 0x1))) {
            if(!(mode & 0x2) || cell[1] <= '\xFF') {
                ch = cell[1];
            } 
        }
        rtn += ch;
    }
    return rtn;
};
AccuTerm.prototype._gettext.minLength = 0; // VBA no arguments required

AccuTerm.prototype._settings_to_options = function (settings) {
    var mode, cols, rows;
    mode = isEqualNoCase(settings.screenSize, 'EXTENDED') ? 1 : 0;
    if (mode) {
        cols = +settings.extCols || 132;
        rows = +settings.extRows || 24;
    } else {
        cols = +settings.normCols || 80;
        rows = +settings.normRows || 24;
    }
    if (settings) {
        return {
            scrMode: mode,
            cols: cols,
            rows: rows,
            normCols: +settings.normCols || 80,
            normRows: +settings.normRows || 24,
            extCols: +settings.extCols || 132,
            extRows: +settings.extRows || 24,
            termName: settings.termtype,
            scrollback: +settings.historyRows || 0,
            answerBack: this._decodeKeyStr(settings.terminalAnswerBack), // decode arrow format, uses host character set
            allowApplicationMode: !!(+settings.terminalAppMode || false),
            applicationCursor: !!((+settings.terminalAppMode && +settings.terminalCursorCodes) || false),
            applicationKeypad: !!((+settings.terminalAppMode && +settings.terminalKeypadCodes) || false),
            backspaceDel: !!(+settings.bkspSendsDel || false),
            eightBitControls: !!((settings.termtype !== 'VT100' && +settings.terminal8Bit) || false),
            wraparoundMode: settings.hasOwnProperty('autoWrap') ? !!(+settings.autoWrap || false) : true,
            screenPages: +settings.screenPages || 1,
            allowUnderline: settings.hasOwnProperty('allowUnderline') ? !!(+settings.allowUnderline || false) : true,
            allowBlinking: settings.hasOwnProperty('allowBlinking') ? !!(+settings.allowBlinking || false) : true,
            fontName: settings.fontName || '',
            fontSize: settings.fontSize || 10,
            bkgndPict: settings.backgroundPicturePath || '',
            bkgndAlpha: settings.backgroundPictureAlpha || 0,
            bkgndMode: settings.backgroundPictureMode || 0,
            screenKeys: true,
            useStyle: true,
            cursorStyle: isEqualNoCase(settings.cursorStyle, "UNDERLINE") ? 3 : 0,
            colors: TermColors.ColorPalette(settings.themeStyle || 'default', TermUtil.isAnsi(settings.termtype || 'TTY'), this.attrColor[0][0], this.attrColor[0][1])
        };
    }
};

AccuTerm.prototype._cancel = function (ev) {
    if (ev.preventDefault) {
        ev.preventDefault();
    }
    ev.returnValue = false;
    if (ev.stopPropagation) {
        ev.stopPropagation();
    }
    ev.cancelBubble = true;
    return false;
};

AccuTerm.prototype._handle_color_change = function (backcolor, forecolor) {
    this.emit('colorchange', {backcolor: backcolor, forecolor: forecolor});
};

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { AccuTerm };
