










































import { Component, Watch, Vue } from 'vue-property-decorator'
// Components
import Header from './components/layout/Header.vue'
import Errors from './components/layout/Errors.vue'
import Footer from './components/layout/Footer.vue'

@Component({
    name: 'AppComponent',
    components: {
        Header, Errors, Footer
    }
})

export default class App extends Vue {
/** ******************************** Vue Computed **********************************/

    get loading () { return this.$store.getters.getLoading }

    get profilesLoading () { return this.$store.getters['profiles/getLoading'] }
    get profiles () { return this.$store.getters['profiles/getProfiles'] }
    get hasProfile (): boolean { return this.$store.getters['profiles/getHasProfile'] }
    get hasActiveProfile (): boolean { return this.$store.getters['profiles/getHasActiveProfile'] }

    get getUserAuth () { return this.$store.getters['user/getUserAuth'] }
    get userLoading () { return this.$store.getters['user/userLoading'] }

    get hasErrors () { return this.$store.getters['errors/getHasErrors'] }

    get errorStyle () {
        if (this.$route.name === 'Terminal' || this.$route.name === 'Login') {
            return 'terminal-errors'
        } else {
            return ''
        }
    }

    get hasAccuTerm () { return this.$store.getters['terminal/hasAccuTerm'] }
    get accutermConnectionStatus () {
        if (this.hasAccuTerm) {
            return this.$store.getters['terminal/getConnectionStatus']
        } else {
            return false
        }
    }

    get showHeader() {
        return this.getUserAuth && this.$route.name !== 'GUI'
    }

    get showHeaderSpacer () {
        const routes = ['Terminal', 'Login', 'GUI']
        
        return !(routes.includes(this.$route.name!))
    }

/** ******************************** Vue Methods **********************************/

    checkLoading (loading: any) {
        // make the store loading object into an array of just values
        const values = Object.values(loading)
        let loaded = true
        // Loop through all the store loading object values
        for (const value of values) {
            if (!value) {
                loaded = false
            }
        }

        return loaded
    }

    login () { this.$store.dispatch('user/login') }

    logout () { this.$store.dispatch('user/logout') }

    handleStoreLoading () {
        const userStoreLoaded = this.checkLoading(this.userLoading)
        const profilesStoreLoaded = this.checkLoading(this.profilesLoading)
        if (userStoreLoaded && profilesStoreLoaded) {
            this.$store.dispatch('toggleLoading', { loading: false })
            // For smooter ux we're redirecting to the terminal if there's only one profile
            
            if (this.hasActiveProfile && this.profiles.length === 1) {
                // Make sure we're not already on the terminal page/component
                if (this.$router.currentRoute.name !== 'Terminal') {
                    this.$router.push({ name: 'Terminal' })
                }
            } else {
                if (this.$router.currentRoute.name === 'Login') {
                    //console.log('App.vue handleStoreLoading route from ' + this.$router.currentRoute.name + ' to Profiles')
                    this.$router.push({ name: 'Profiles' })
                }
            }
        } else if (this.hasErrors) {
            this.$store.dispatch('toggleLoading', { loading: false })
        }
    }

/** ******************************** Vue Mounted! **********************************/

    mounted () {
        // Resize the accuterm container when the window is resized
        window.onresize = () => {
            this.$store.dispatch('terminal/containerResize', true)
        }

        // dev.to/chromiumdev/sure-you-want-to-leavebrowser-beforeunload-event-4eg5
        // developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        window.addEventListener('beforeunload', (e) => {
            // Check if the user is connected
            if (this.accutermConnectionStatus) {
                // Firefox
                e.preventDefault()
                // Chrome
                e.returnValue = 'Are you sure you want to leave?'
            }
        })

        window.addEventListener('unload', (e) => {
            if (this.accutermConnectionStatus) {
                this.$store.dispatch('terminal/disconnect')
            }
        })

        this.handleStoreLoading()
        const enterprise = this.$store.getters['user/getEnterprise']
        if (enterprise && this.$route.name === 'Home') {
            if (this.hasActiveProfile) {
                this.$router.push({ name: 'Terminal' })
            } else {
                //console.log('App.vue mounted route from ' + this.$router.currentRoute.name + ' to Profiles')
                this.$router.push({ name: 'Profiles' })
            }
        }
    }

/** ******************************** Vue Watch **********************************/

    // Watch for the profile store loading to change
    @Watch('userLoading') handleUserLoading () {
        this.handleStoreLoading()
    }

    // Watch for the profile store loading to change
    @Watch('profilesLoading') handleProfileLoading () {
        this.handleStoreLoading()
    }

    // If an error comes up turn off the loader
    // This is also handled in the errors store (as errors get added prior to this component loading)
    @Watch('hasErrors') handleErrorLoading () {
        if (this.hasErrors) {
            this.$store.dispatch('toggleLoading', { loading: false })
        }
    }

    @Watch('hasActiveProfile') handleHasActiveProfile () {
        const enterprise = this.$store.getters['user/getEnterprise']
        // If enterprise and route is home, reroute to either Terminal or Profiles
        if (enterprise) {
            if (this.hasActiveProfile && this.$route.name !== 'Terminal') {
                this.$router.push({ name: 'Terminal' })
            } else if (!this.hasActiveProfile && this.$route.name !== 'Profiles') {
                //console.log('App.vue hasActiveProfile route from ' + this.$router.currentRoute.name + ' to Profiles')
                this.$router.push({ name: 'Profiles' })
            }
        }
    }
}
