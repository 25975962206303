import { Component, Vue } from 'vue-property-decorator'
// store
import store from '../../store'
// Types
import { IGUIEvent, GUICommand, IGUIAboutObject } from '../../types'
import { GUIEventCode, MenuProps, GUIErrorCode, GUIErrorLevel, GUICommandCode, GUIMethod, GUIObjectType } from '../../types/enums'
import { GuisStdMenuType } from '../../types/services/guis'
// Services
import GuisServiceMethod from './method'
import GuisServiceDialogBox from './dialog'

export default class GuisStdMenu implements GuisStdMenuType {

    /**
     * Standard Menu Functions
     */
    public methodService: GuisServiceMethod = new GuisServiceMethod();
    public dialogService = new GuisServiceDialogBox();

    // handle built-in menu functions
    StdMenuFunction(props: MenuProps, id: string) {

        let focused: string;

        id = id.toUpperCase();
        switch(id) {

            case '*ABOUT':
                const about: IGUIAboutObject = {description: props.app!.gpDescription, author: props.app!.gpAuthor, copyright: props.app!.gpCopyright, version: props.app!.gpVersion, logo: props.app!.gpLogo}
                const args: any = {command: GUICommandCode.gcAboutBox, about: about};
                this.dialogService.aboutBox(args);
                break;

            case '*EXIT':
                let guiEvent: IGUIEvent | null = null;
                if (props.form) {
                    guiEvent = { event: GUIEventCode.geClose, id: props.form.id };
                } else if (props.app && props.app.type === GUIObjectType.gxMDI) {
                    guiEvent = { event: GUIEventCode.geClose, id: props.app.id };
                }
                if (guiEvent) {
                    store.dispatch('guiGuis/addEvent', guiEvent);
                }
                break;

            case '*PRINT':
                if (props.form) {
                    this.methodService.print( {
                        command: GUICommandCode.gcMethod,
                        id: props.form.id,
                        method: GUIMethod.gmPrint,
                        args: [0] // print form
                    });
                }
                break;
        
            case '*PRINTSETUP':
                if (props.form) {
                    this.methodService.print( {
                        command: GUICommandCode.gcMethod,
                        id: props.form.id,
                        method: GUIMethod.gmPrint,
                        args: [2] // print setup (currently this returns an error because cannot open printer setup dialog in browser)
                    });
                }
                break;
            case '*CUT':
                focused = store.getters['guiGuis/getFocusedControl'];
                if (focused) {
                    this.methodService.cut( {
                        command: GUICommandCode.gcMethod,
                        id: focused,
                        method: GUIMethod.gmCut
                    });
                }
                break;
            case '*COPY':
                focused = store.getters['guiGuis/getFocusedControl'];
                if (focused) {
                    this.methodService.copy( {
                        command: GUICommandCode.gcMethod,
                        id: focused,
                        method: GUIMethod.gmCopy
                    });
                }
                break;
            case '*PASTE':
                focused = store.getters['guiGuis/getFocusedControl'];
                if (focused) {
                    this.methodService.paste( {
                        command: GUICommandCode.gcMethod,
                        id: focused,
                        method: GUIMethod.gmPaste
                    });
                }
                break;
            case '*SELECTALL':
                focused = store.getters['guiGuis/getFocusedControl'];
                if (focused) {
                    store.dispatch('guiGuis/updateProperty', { id: focused, property: 'gpSelStart', value: 0 });               
                    store.dispatch('guiGuis/updateProperty', { id: focused, property: 'gpSelLength', value: 999999 });               
                }
                break;
            case '*HELP':
                // TODO
                break;
            case '*WINDOW':
                store.dispatch('guiGuis/setToggleNavBar');               
                break;
            case '*TILE':
            case '*TILEVERT':
            case '*TILEHORIZ':
            case '*CASCADE':
                // ignore - unsupported in browser MDI implementation
                break;        
        }
    }
}
