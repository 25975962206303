// Import Types
import { Component } from 'vue';
// GUICommand
import { IGUIObject, IGUICmdInit, IGUICmdCtx, IGUICmdCreate, IGUICmdDelete, IGUICmdSetProp, IGUICmdGetProp, IGUICmdGetUpds, IGUICmdMethod, IGUICmdWaitEvent, IGUICmdCheckEvent, IGUICmdMsgBox, IGUICmdInputBox, IGUICmdFileDialog, IGUICmdFontDialog, IGUICmdColorDialog, IGUICmdControl, IGUICmdShutdown } from './'
// GUIResponse 
import { IGUIRspError, IGUIRspSuccess, IGUIRspInit, IGUIRspGetProp, IGUIRspGetUpds, IGUIRspMethod, IGUIRspEvent, IGUIRspMsgBox, IGUIRspInputBox, IGUIRspFileDialog, IGUIRspFontDialog, IGUIRspColorDialog } from './'
import { IGUIApp } from './'


/**
 * AccuTerm GUI Interfaces
 */
export const enum GUIConstants {
    guiVersion = 2.0, // version 2.0 (desktop version is 1.4)
    guiScale = 4
}

// Special object IDs:
//  * = root object
//      root object properties:
//          gpStatus - sub-property selected by col argument:
//              col = -1: set or get status panel message text
//                  Note: the status panel is the panel shown on
//                  the text screen that started the GUI app. It
//                  Normally displays "This session is running a
//                  GUI application".
//              col = 0: returns the number visible forms.
//              col = 1: returns the ID of the active control.
//              col = 2: return a list of app objects.
//  &SCREEN = screen object
//      Screen object properties: row argument specifies scale mode.
//          gpWidth - returns the screen width in specified scale.
//          gpHeight - returns the screen height in specified scale.
//          Note: desktop version returns full screen size if col
//          argument is 0, or useable screen size if col is 1.
//  &PRINTER = printer object
//      Printer object properties: see gpPrinter... properties.
//      Also gpWidth & gpHeight.

export const GUICSpecialObjectID = ['&SCREEN', '&PRINTER'] // this does not contain '*' (root), since we have a real props object for root 

export const enum GUICommandCode {
    // commands
    gcContext = 0,
    gcInitialize = 1,
    gcCreate = 2,
    gcDelete = 3,
    gcSetProp = 4,
    gcGetProp = 5,
    gcMethod = 6,
    gcWaitEvent = 7,
    gcControl = 8,
    gcMsgBox = 9,
    gcInputBox = 10,
    gcMacro = 11,
    gcGetUpds = 12,
    gcCheckEvent = 13,
    gcOpenDialog = 14,
    gcSaveDialog = 15,
    gcFileDialog = 16,
    gcFontDialog = 17,
    gcColorDialog = 18,
    gcAboutBox = 19,
    gcShutdown = 99,
}

// Pretty much a carbon copy of GUICommandCode, but easier than an enum to iterate through 
export const GUICommandCodeArray = [GUICommandCode.gcContext, GUICommandCode.gcInitialize, GUICommandCode.gcCreate, GUICommandCode.gcDelete, GUICommandCode.gcSetProp, GUICommandCode.gcGetProp, GUICommandCode.gcMethod, GUICommandCode.gcWaitEvent, GUICommandCode.gcControl, GUICommandCode.gcMsgBox, GUICommandCode.gcInputBox, GUICommandCode.gcMacro, GUICommandCode.gcGetUpds, GUICommandCode.gcCheckEvent, GUICommandCode.gcOpenDialog, GUICommandCode.gcSaveDialog, GUICommandCode.gcFileDialog, GUICommandCode.gcFontDialog, GUICommandCode.gcColorDialog, GUICommandCode.gcAboutBox, GUICommandCode.gcShutdown]

 export const enum GUIObjectType {
    // object types
    gxRoot = -1,
    // application
    gxMDI = 0,
    gxSDI = 1,
    // form
    gxFormSizable = 5,
    gxFormFixed = 6,
    gxDialog = 7,
    gxSDISizable = 8,
    gxSDIFixed = 9,
    // controls
    gxLabel = 15,
    gxEdit = 16,
    gxEditMultiline = 17,
    gxCommand = 18,
    gxOption = 19,
    gxCheck = 20,
    gxList = 21,
    gxListMultisel = 22,
    gxDrpDwnCbo = 23,
    gxDrpDwnList = 24,
    gxPicture = 25,
    gxFrame = 26,
    gxGrid = 27,
    gxGridEditable = 28,
    gxEditPassword = 29,
    gxTabgrp = 30,
    gxTab = 31,
    gxGauge = 32,
    gxTree = 33,
    gxCheckedList = 34,
    gxTimer = 35,
    gxBrowser = 36,
    // menus
    gxMenu = 75,
    gxPopup = 76,
    gxToolbar = 77,
    gxStatusbar = 78,
    // internal use
    pxBorder = 998, // border container control for grid
    pxSizer = 999, // sizer control (design time only)
}

/**
 * The GUI object type ranges 
 */
export class GUIObjectTypeRanges {
    root: Array<GUIObjectType> = [GUIObjectType.gxRoot]
    guis: Array<GUIObjectType> = [GUIObjectType.gxMDI, GUIObjectType.gxSDI]
    forms: Array<GUIObjectType> = [GUIObjectType.gxFormSizable, GUIObjectType.gxFormFixed, GUIObjectType.gxDialog, GUIObjectType.gxSDISizable, GUIObjectType.gxSDIFixed]
    controls: Array<GUIObjectType> = [GUIObjectType.gxLabel, GUIObjectType.gxEdit, GUIObjectType.gxEditMultiline, GUIObjectType.gxCommand, GUIObjectType.gxOption, GUIObjectType.gxCheck, GUIObjectType.gxList, GUIObjectType.gxListMultisel, GUIObjectType.gxDrpDwnCbo, GUIObjectType.gxDrpDwnList, GUIObjectType.gxPicture, GUIObjectType.gxFrame, GUIObjectType.gxGrid, GUIObjectType.gxGridEditable, GUIObjectType.gxEditPassword, GUIObjectType.gxTabgrp, GUIObjectType.gxTab, GUIObjectType.gxGauge, GUIObjectType.gxTree, GUIObjectType.gxCheckedList, GUIObjectType.gxTimer, GUIObjectType.gxBrowser]
    menus: Array<GUIObjectType> = [GUIObjectType.gxMenu, GUIObjectType.gxPopup, GUIObjectType.gxToolbar, GUIObjectType.gxStatusbar]
    internals: Array<GUIObjectType> = [GUIObjectType.pxBorder, GUIObjectType.pxSizer]
}

/**
 * The GUI object type, which dynamically builds gui apps
 */
export interface GUIComponent {
    props: AllProps,
    component?: Component,
    children: Array<GUIComponent>
}

export interface GUIComponentSettings extends IGUIObject {
    version: number,
    scale: number,
    visible: boolean,
    zIndex: number,
}

export const enum GUIEventCode {
    // events (combine using, //or' to form event mask)
    geClose = 1,
    geActivate = 8,
    geDeactivate = 16,
    geClick = 32, // left-button click
    geDblClick = 64,
    geChange = 128,
    geValidate = 256,
    geLoadlist = 512, // combo/grid loads new dropdown list
    geValidateCell = 1024, // grid cell edit validation
    geValidateRow = 2048, // grid row change validation
    geContext = 4096, // right-button click
    geBtnClick = 8192, // grid cell button or edit control up/dn button clicked
    geActivateCell = 16384, // grid cell entered
    geResize = 0x8000, // form resized
    geColClick = 0x10000, // column clicked (grid or list)
    geDeactivateCell = 0x20000, // grid cell leave
    geActivateRow = 0x40000, // grid row entered
    geDeactivateRow = 0x80000, // grid row leave
    geHelp = 0x100000, // F1 key or built-in HELP menu ID
    geStatus = 0x200000, // status change (treeview node expanded or collapsed)
    geTimer = 0x400000, // timer timeout
    geDragDrop = 0x800000, // drag-and-drop
    geQuit = 0x40000000, // gui server terminated
    geEllipsis = geBtnClick, // alias
}

export const enum GUIProperty {
    // properties
    gpDefVal = 0, // default value
    gpValue = 1, // all controls should support this property
    gpLeft = 2,
    gpTop = 3,
    gpWidth = 4,
    gpHeight = 5,
    gpChanged = 6, // all forms must support this property!
    gpScale = 7, // app only
    gpEnabled = 8,
    gpVisible = 9,
    gpStyle = 10,
    gpBorder = 11,
    gpReadOnly = 12,
    gpTabStop = 13,
    gpBackColor = 14,
    gpForeColor = 15,
    gpFontName = 16,
    gpFontSize = 17,
    gpFontBold = 18,
    gpFontItalic = 19,
    gpHelpFile = 20, // app only
    gpHelpID = 21,
    gpCaption = 22, // may be same as value
    gpPicture = 23, // picture box, form or button
    gpRtnEqTab = 24, // app only
    gpAlign = 25,
    gpItems = 26,
    gpColumns = 27,
    gpRows = 28,
    gpDataType = 29,
    gpColumn = 30, // grid column
    gpRow = 31, // grid row
    gpColHeading = 32, // list/combo/grid heading row
    gpGridLines = 33, // list/combo/grid grid style
    gpColFieldType = 34, // grid column field type (text/combo/check)
    gpColDataType = 35, // grid column data type
    gpColItems = 36, // grid column item list
    gpColWidth = 37, // grid column width
    gpColAlign = 38, // list/combo/grid column alignment
    gpDataCol = 39, // combo column used as data
    gpArrange = 40, // option group arrangement
    gpDescription = 41, // app description
    gpAuthor = 42, // app author
    gpCopyright = 43, // app copyright
    gpVersion = 44, // app version
    gpLogo = 45, // about box logo file name
    gpAutoSelect = 46, // app only
    gpStatus = 47, // various status information (number of visible forms, list of child objects, etc.)
    gpWindowState = 48, // form window state (0=normal, 1=minimized, 2=maximized)
    gpHint = 49, // help hint (baloon help)
    gpExtension = 50, // private extension for 4GL integration
    gpMaxLen = 51, // max length for edit/combo/grid
    gpMaxLines = 52, // max lines for multiline edit
    gpMaxDrop = 53, // max rows for dropdown combo/list
    gpRequired = 54, // required field
    gpFixedCols = 55, // grid number of fixed (non-scrolling) columns
    gpIcon = 56, // app / form icon file name
    gpSelStart = 57, // edit control selection start (first char = 1) or grid selection start cell
    gpSelLength = 58, // edit control selection length or grid selection cols/rows
    gpHelpType = 59, // app help type (0=WinHelp, 1=Help event)
    gpTimeout = 60, // app busy message timeout, or timer interval
    gpMsgText = 61, // app busy message text
    gpState = 62, // node state (0=collapsed, 1=expanded)
    gpColSizable = 63, // grid column is resizable
    gpColHint = 64, // help hint for grid column
    gpAltColor = 65, // alternate row color for grid
    gpNoAutoTips = 66, // disable treeview autotips for truncated node text (app only)
    gpTransparent = 67, // transparent background (label, frame, checkbox)
    gpFontUnderline = 68, // underlined font style
    gpIconAlign = 69, // icon alignment for command button
    gpIconSize = 70, // icon size for command button, grid, list
    gpWordWrap = 71, // grid word-wrap style
    gpContent = 72, // browser content (html)
    gpDragID = 73, // drag-and-drop source ID
    gpDropIDs = 74, // drag-and-drop target ID list
    gpDragMode = 75, // grid drag mode (move active cell, select cells, drag cell, nothing)
    gpSelRange = 76, // edit or grid selection range
    gpColTabStop = 77, // grid column tab stop (orverrides default tab behavior)
    gpFocusStyle = 78, // grid focus style (0=default, 1=none, 2=thin, 3=thick)
    gpPasteMode = 79, // grid paste mode (0=single cell, 1=multi cell)
    gpWindowHandle = 98, // return form or app window handle (for sdi app, returns (7.1.2008)
    gpEventMask = 99,
    gpCustom = 100,
    // special purpose properties
    gpPrinterName = 201, // current printer name (1.3)
    gpOrientation = 202, // printer page orientation (1.3)
    gpPaperSource = 203, // printer paper source (1.3)
    gpPaperSize = 204, // printer paper size (1.3)
    gpPrintQuality = 205, // printer quality (1.3)
    gpPrintCopies = 206, // printer copies (1.3)
    gpPrintDuplexMode = 207, // printer duplex mode (1.3)
    gpPrintColorMode = 208, // printer color mode (1.3)
    // dummy property indexs
    gpObjectType = -1,
    gpObjectID = -2,
}

export const enum GUIDataType {
    // data types
    gdAny = 0,
    gdAlpha,
    gdAlphaNumeric,
    gdBool,
    gdCountry,
    gdCurrency,
    gdDate,
    gdFinancial,
    gdNumeric,
    gdPercent,
    gdPhone,
    gdSSN,
    gdState,
    gdTime,
    gdZipCode,
}

export const enum GUIScaleMode {
    // scale mode
     gsmDefault = 0, // default = characters
     gsmTwips = 1, // 1 twip = 1/20 point = 1/1440 inch
     gsmPoints = 2, // 1 point = 1/72 inch
     gsmPixels = 3,
     gsmCharacters = 4,
     gsmInches = 5,
     gsmMillimeters = 6,
     gsmCentimeters = 7,
}

export const enum GUIDialogIcon {
    // Message Box Icons
    gmbNoIcon = 0,
    gmbIconRedX = 1, // Red "X"
    gmbIconExcl = 2, // exclamation mark
    gmbIconQuestion = 3, // question mark
    gmbIconInfo = 4, // information (i)
}
export const enum GUIDialogBtn {
    // Message Box Buttons
    gmbBtnOK = 0,
    gmbBtnOKCancel = 1,
    gmbBtnAbortRetryIgnore = 2,
    gmbBtnYesNoCancel = 3,
    gmbBtnYesNo = 4,
    gmbBtnRetryCancel = 5,
}
 export const enum GUIDialogDef {
    // Message Box Default Button
    gmbDefBtn1 = 0,
    gmbDefBtn2 = 1,
    gmbDefBtn3 = 2,
}
export const enum GUIDialogResp {
    // Message Box Responses
    gmbAnsOK = 1,
    gmbAnsCancel = 2,
    gmbAnsAbort = 3,
    gmbAnsRetry = 4,
    gmbAnsIgnore = 5,
    gmbAnsYes = 6,
    gmbAnsNo = 7,
}
export const enum GUIDialogType {
    messageBox = 0,
    inputBox = 1,
    fileDialog = 2,
    fontDialog = 3,
    colorDialog = 4,
    printerDialog = 5,
    aboutBox = 6
}

/**
 * The GUI Object Type Properties
 * Defines all the properties available to each GUI Object Type (gui apps, forms, controls)
 */


// Union property types
export type GenericValue = string | number | boolean;
export type SimpleValue<T> = T;
export type ListValue<T> = T[];
export type TableValue<T> = T[][];
export type PropsValue<T> = SimpleValue<T> | ListValue<T> | TableValue<T> 
export type GenericPropsValue = PropsValue<GenericValue>
export type StatusValue = number | string | Array<string>;

 export class gxProps {
    [key: string]: any
    type: GUIObjectType = 0
    typeName: string = ''
    typeFamily: string = ''

    id: string = '' 
    parent: gxProps | null = null // every object except the root has a parent
    app: GuisProps | null = null // potential parent to a form, grandparent to a control or great grandparent to a control within container control
    form: FormsProps | null = null // potential parent to a control, grandparent to a control within container control
    parentID: string = ''

    tabindex: number = -1
    zIndex: number = 0 // defines the z-index/layering order of guis/forms/controls
    changed: boolean = false; // trigger for the validate event
    focused: string = ''; // Child form/control in focus
    focusable: boolean = true; // is gui/form/control focusable

    gpLeft: GenericValue = 0 // string = 'auto' only applies to forms
    gpTop: GenericValue = 0 // string = 'auto' only applies to forms
    gpWidth: number = 0
    gpHeight: number = 0
    gpChanged: boolean = false
    gpEnabled: boolean = true
    gpVisible: boolean = true
    gpEventMask: number = 0 // events 
    gpDataType: GUIDataType = GUIDataType.gdAny
    gpExtension: PropsValue<string> = '' // private extension for 4GL integration
    gpCustom: PropsValue<string> = '' // developer custom property
}
/**
 * Root Type Properties
 */
export class RootProps extends gxProps {
    type = GUIObjectType.gxRoot
    typeName = 'gxRoot'
    typeFamily = 'root'
    id = '*'
    focusable = false
    gpStatus: StatusValue = 0 // various status information (number of visible forms, list of child objects, etc.)
}

/**
 * GUI Types Properties
 */
export class GuiProps extends gxProps { 
    typeFamily = 'app'
    parentID = '*'
    gpScale: GUIScaleMode = GUIScaleMode.gsmDefault
    gpLeft: GenericValue = 'auto'
    gpTop: GenericValue = 'auto'
    gpBackColor: string = '#F0F0F0'
    gpStyle: number = 0
    gpHelpFile: string = '' // app only
    gpHelpType: number = 0 // app help type (0=WinHelp, 1=Help event)
    gpHelpID: string = ''
    gpCaption: string = '' // may be same as value
    gpPicture: string = '' // picture box, form or button
    gpIcon: string = '' // app / form icon file name
    gpRtnEqTab: number = 0 // app only
    gpDescription: string = '' // app description
    gpCopyright: string = '' // app copyright
    gpAuthor: string = '' // app author
    gpVersion: string = '' // app version
    gpLogo: string = '' // about box logo file name
    gpTimeout: number = 0 // app busy message timeout, or timer interval
    gpMsgText: string = '' // app busy message text
    gpAutoSelect: boolean = false // app only
    gpNoAutoTips: boolean = false // disable treeview autotips for truncated node text (app only)
    gpStatus: StatusValue = 0 // various status information (number of visible forms, list of child objects, etc.)
    gpWindowState: GUIWindowState = GUIWindowState.gwsNormal // form window state (0=normal, 1=minimized, 2=maximized)

    hotKeys: Array<HotKey> = []
}

export class MDIProps extends GuiProps { 
    type = GUIObjectType.gxMDI
    typeName = 'gxMDI'
    gpVisible: boolean = false // MDI apps are hidden initially
}
export class SDIProps extends GuiProps {
    type = GUIObjectType.gxSDI
    typeName = 'gxSDI'
    gpVisible: boolean = true // SDI apps are visible initially
}

export type GuisProps = MDIProps | SDIProps
/**
 * Form Types Properties
 */
export class FormProps extends gxProps { 
    typeFamily = 'form'
    gpLeft: GenericValue = 'auto'
    gpTop: GenericValue = 'auto'
    gpBackColor: string = '#F0F0F0'
    gpForeColor: string = '#000000'
    gpFontName: string = 'Arial'
    gpFontSize: number = 8 // in points
    gpFontBold: boolean = false
    gpFontItalic: boolean = false
    gpFontUnderline: boolean = false
    gpHelpID: string = ''
    gpVisible: boolean = true
    gpBorder: GUIBorderStyle = GUIBorderStyle.gbsNone
    gpPicture: string = '' // picture box, form or button
    gpCaption: string = '' // may be same as value
    gpIcon: string = '' // app / form icon file name
    gpStatus: StatusValue = 0 // various status information (number of visible forms, list of child objects, etc.)
    gpWindowState: GUIWindowState = GUIWindowState.gwsNormal // form window state (0=normal, 1=minimized, 2=maximized)
    gpDropIDs: Array<string> = [] // drag-and-drop target ID list

    hotKeys: Array<HotKey> = []
}

export interface HotKeyBase { 
    trigger: string // id of control to trigger/focus/activate
    key: string // actual key 
    keyCode: number // key code
    menuId ?: string
    shortCutKey: Array<string>;
}

export interface HotKey extends HotKeyBase {
    typeFamily: string
    app: string // parent gui app id
    form: string | null // parent form id
    control: string // control id
}

export class FormSizableProps extends FormProps {
    type = GUIObjectType.gxFormSizable
    typeName = 'gxFormSizable'
}
export class FormFixedProps extends FormProps {
    type = GUIObjectType.gxFormFixed
    typeName = 'gxFormFixed'
}
export class DialogProps extends FormProps {
    type = GUIObjectType.gxDialog
    typeName = 'gxDialog'
}
export class SDISizableProps extends FormProps {
    type = GUIObjectType.gxSDISizable
    typeName = 'gxSDISizable'
}
export class SDIFixedProps extends FormProps {
    type = GUIObjectType.gxSDIFixed
    typeName = 'gxSDIFixed'
}

export type FormsProps = FormSizableProps | FormFixedProps | DialogProps | SDISizableProps | SDIFixedProps
/**
 * Control Types Properties
 */
export class ControlProps extends gxProps { 
    typeFamily = 'control'

    gpLeft: number = 0
    gpTop: number = 0
    gpDefVal: PropsValue<GenericValue> = ''
    gpValue: PropsValue<GenericValue> = ''
    gpBorder: GUIBorderStyle = GUIBorderStyle.gbs3D
    gpTabStop: boolean = false
    gpBackColor: string = '#F0F0F0'
    gpForeColor: string = '#000000'
    gpFontName: string = 'Arial'
    gpFontSize: number = 8 // in points
    gpFontBold: boolean = false
    gpFontItalic: boolean = false
    gpFontUnderline: boolean = false // underlined font style
    gpHelpID: string = ''
    gpHint: string | Array<string> = '' // help hint (baloon help)
    gpDragID: string = '' // drag-and-drop source ID
    gpDropIDs: Array<string> = [] // drag-and-drop target ID list

    hotKeys: HotKeyBase[] = []
}

export class LabelProps extends ControlProps {
    type = GUIObjectType.gxLabel
    typeName = 'gxLabel'
    focusable = false

    gpDefVal: string | Array<string> = ''
    gpValue: string | Array<string> = ''
    gpCaption: string | Array<string> = '' // synonym for gpValue
    gpBorder: GUIBorderStyle = GUIBorderStyle.gbsNone
    gpTransparent: boolean = false // transparent background (label, frame, checkbox)
    gpAlign: GUIAlignment = GUIAlignment.galLeft
    gpStyle: number = 0
    // inherited default properties:
    //  form: gpForeColor, gpFontXxxx
    //  parent: gpBackColor
}

export class BaseEditProps extends ControlProps { 
    gpBackColor: string = '#FFFFFF'
    gpTabStop: boolean = true
    gpReadOnly: boolean = false
    gpAlign: GUIAlignment = GUIAlignment.galLeft
    gpMaxLen: number = 0 // max length for edit/combo/grid
    gpMaxLines: number = 0 // max lines for multiline edit
    gpRequired: boolean = false // required field
    gpSelStart: number = 0 // edit control selection start (first char = 1) or grid selection start cell
    gpSelLength: number = 0 // edit control selection length or grid selection cols/rows
    gpSelRange: Array<number> = [] // edit or grid selection range
    // inherited default properties:
    //  form: gpForeColor, gpFontXxxx
}

export class EditProps extends BaseEditProps {
    type = GUIObjectType.gxEdit
    typeName = 'gxEdit'
    gpDefVal: string = ''
    gpValue: string = ''
    gpStyle: number = 0 // normal
}

export class EditPasswordProps extends BaseEditProps {
    type = GUIObjectType.gxEditPassword
    typeName = 'gxEditPassword'
    gpDefVal: string = ''
    gpValue: string = ''
    gpStyle: number = 2 // password
}

export class EditMultilineProps extends BaseEditProps {
    type = GUIObjectType.gxEditMultiline
    typeName = 'gxEditMultiline'
    gpDefVal: Array<string> = []
    gpValue: Array<string> = []
    gpStyle: number = 1 // multiline
}

export class CommandProps extends ControlProps {
    type = GUIObjectType.gxCommand
    typeName = 'gxCommand'
    gpBackColor: string = '#F0F0F0'
    gpForeColor: string = '#000000'
    gpCaption: string | Array<string> = ''
    gpTabStop: boolean = true
    gpStyle: number = 0
    gpPicture: string = '' // picture box, form or button
    gpIcon: string = '' // app / form icon file name
    gpIconAlign: number = 0 // icon alignment for command button (0 = left, 1 = right, 2 = center, 3 = top, 4 = bottom)
    gpIconSize: number = 0 // icon size for command button, grid, list
    // inherited default properties:
    //  form: gpFontXxxx
}

export class OptionItemProps {
    caption: string = ''; // option button caption
    enabled: boolean = true; // option button is enabled?
    tip: string | Array<string> = ''; // help hint for button
    static create(): OptionItemProps { return new OptionItemProps(); };
}
export class OptionProps extends ControlProps {
    type = GUIObjectType.gxOption
    typeName = 'gxOption'
    gpDefVal: number = 0
    gpValue: number = 0
    gpTabStop: boolean = true
    gpTransparent: boolean = false // transparent background (label, frame, checkbox)
    gpCaption: string = '' // control caption -- individual option caption is gpItems[].caption
    gpReadOnly: boolean = false
    gpRequired: boolean = false // required field
    gpArrange: GUIArrange = GUIArrange.garDown // option group arrangement
    gpColumns: number = 0
    gpRows: number = 0
    gpItems: Array<OptionItemProps> = []
    gpEnabled: boolean = true // control enabled -- individual option enabled is gpItems[].enabled
    gpHint: string | Array<string> = '' // control help hint -- individual option hint is gpItems[].tip
    // inherited default properties:
    //  form: gpForeColor, gpFontXxxx
    //  parent: gpBackColor
}

export class CheckProps extends ControlProps {
    type = GUIObjectType.gxCheck
    typeName = 'gxCheck'
    gpDefVal: number = 0
    gpValue: number = 0
    gpBorder: GUIBorderStyle = GUIBorderStyle.gbsNone
    gpReadOnly: boolean = false
    gpTabStop: boolean = true
    gpTransparent: boolean = false // transparent background (label, frame, checkbox)
    gpAlign: GUIAlignment = GUIAlignment.galLeft
    gpCaption: Array<string> = [''] 
    // inherited default properties:
    //  form: gpForeColor, gpFontXxxx
    //  parent: gpBackColor
}

export class PictureProps extends ControlProps {
    type = GUIObjectType.gxPicture
    typeName = 'gxPicture'
    focusable = false

    gpDefVal: string = '' // same as gpPicture
    gpValue: string = '' // same as gpPicture
    gpStyle: number = 0
    gpPicture: string = '' // picture box, form or button
    gpStatus: StatusValue = 0 // various status information (number of visible forms, list of child objects, etc.)
}

export class FrameProps extends ControlProps {
    type = GUIObjectType.gxFrame
    typeName = 'gxFrame'
    focusable = false

    gpDefVal: string = '' // same as gpCaption
    gpValue: string = '' // same as gpCaption
    gpPicture: string = '' // picture box, form or button
    gpCaption: string = '' // may be same as value
    gpTransparent: boolean = false // transparent background (label, frame, checkbox)
    gpStyle: number = 0 // 0 = normal frame, 1 = frame without caption
    gpStatus: StatusValue = 0 // various status information (number of visible forms, list of child objects, etc.)
    // inherited default properties:
    //  form: gpForeColor, gpFontXxxx
    //  parent: gpBackColor
}

export class TabgrpProps extends ControlProps {
    type = GUIObjectType.gxTabgrp
    typeName = 'gxTabgrp'
    gpDefVal: number = 0
    gpValue: number = 0
    gpTabStop: boolean = true
    gpStyle: number = 0 // 0 = single row of tabs, 1 = stacked tabs
    gpStatus: StatusValue = 0 // various status information (number of visible forms, list of child objects, etc.)
    // inherited default properties:
    //  form: gpBackColor, gpForeColor, gpFontXxxx
}

export class TabProps extends ControlProps {
    type = GUIObjectType.gxTab
    typeName = 'gxTab'
    focusable = false

    gpBorder: GUIBorderStyle = GUIBorderStyle.gbsNone
    gpCaption: string = '' // may be same as value
    gpIcon: string = '' // app / form icon file name
    gpStatus: StatusValue = 0 // various status information (number of visible forms, list of child objects, etc.)
    // inherited default properties:
    //  form: gpBackColor, gpForeColor, gpFontXxxx
}

export class GaugeProps extends ControlProps {
    type = GUIObjectType.gxGauge
    typeName = 'gxGauge'
    focusable = false

    gpDefVal: number = 0
    gpValue: number = 0
    gpBackColor: string = '#F0F0F0'
    gpForeColor: string = '#3399FF'
    gpStyle: number = 0
    // inherited default properties:
    //  form: gpFontXxxx
}

export class TreeItemProps {
    id: string = ''; // node id
    level: number = 0; // node level
    caption: string = ''; // node caption
    icon: string = ''; // node icon file name or URL
    state: boolean = false; // node state (collapsed = false, expanded = true)
    tip: string = ''; // node help hint for button
    path: string = ''; // path to node
    static create(): TreeItemProps { return new TreeItemProps(); };
}

export class TreeProps extends ControlProps {
    type = GUIObjectType.gxTree
    typeName = 'gxTree'
    gpDefVal: string | Array<string> = ''
    gpValue: string | Array<string> = ''
    gpBackColor: string = '#FFFFFF'
    gpTabStop: boolean = true
    gpStyle: number = 0
    gpItems: Array<TreeItemProps> = []
    gpState: number = 0 // TreeItemProps state 
    // TODO: item properties: gpCaption, gpIcon, gpState, gpHint
    // inherited default properties:
    //  form: gpForeColor, gpFontXxxx
}

export class TimerProps extends ControlProps {
    type = GUIObjectType.gxTimer
    typeName = 'gxTimer'
    focusable = false
    
    gpStyle: number = 0
    gpTimeout: number = 0 // timer interval
}

export class BrowserProps extends ControlProps {
    type = GUIObjectType.gxBrowser
    typeName = 'gxBrowser'
    gpDefVal: string = ''
    gpValue: string = ''
    gpContent: string = '' // browser content (html)
}

export class ListColumnProps {
    [key: string]: any;
    heading: string = '';
    dataType: GUIDataType = GUIDataType.gdAny;
    width: number = 120;
    align: GUIAlignment = GUIAlignment.galLeft;
    static create(): ListColumnProps { return new ListColumnProps(); };
}

export class BaseListProps extends ControlProps {
    columnInfo: Array<ListColumnProps> = [new ListColumnProps()]
    gpBackColor: string = '#FFFFFF'
    gpTabStop: boolean = true
    gpReadOnly: boolean = false
    gpRequired: boolean = false // required field
    gpColumns: number = 1
    gpDataCol: number = 0 // combo column used as data
    gpGridLines: GUIGridStyle = GUIGridStyle.glsNone // list/combo/grid grid style
    gpColHeading: Array<string> = [] // list/combo/grid heading row -- moved to columnInfo[].heading
    gpColDataType: Array<number> = [] // grid column data type -- moved to columnInfo[].dataType
    gpColAlign: Array<number> = [] // list/combo/grid column alignment -- moved to columnInfo[].align
    gpColWidth: Array<number> = [] // grid column width -- moved to columnInfo[].width
    gpItems: TableValue<string> = []
    gpStyle: number = 0
    gpIcon: Array<string> = [] // app / form icon file name
    gpIconSize: number = 0 // icon size for command button, grid, list
    // inherited default properties:
    //  form: gpForeColor, gpFontXxxx
}

export class ListProps extends BaseListProps {
    type = GUIObjectType.gxList
    typeName = 'gxList'
    gpDefVal: number = 0
    gpValue: number = 0 
}
export class CheckedListProps extends BaseListProps {
    type = GUIObjectType.gxCheckedList
    typeName = 'gxCheckedList'
    gpDefVal: Array<number> = []
    gpValue: Array<number> = []
}

export class ListMultiselProps extends BaseListProps {
    type = GUIObjectType.gxListMultisel
    typeName = 'gxListMultisel'
    gpDefVal: Array<number> = []
    gpValue: Array<number> = []
}

export class DrpDwnListProps extends BaseListProps {
    type = GUIObjectType.gxDrpDwnList
    typeName = 'gxDrpDwnList'
    gpDefVal: number = 0
    gpValue: number = 0
    gpMaxDrop: number = 8 // max rows for dropdown combo/list
}

export class DrpDwnCboProps extends BaseListProps {
    type = GUIObjectType.gxDrpDwnCbo
    typeName = 'gxDrpDwnCbo'
    gpDefVal: string = ''
    gpValue: string = ''
    gpMaxDrop: number = 8 // max rows for dropdown combo/list
    gpMaxLen: number = 0 // max length for edit/combo/grid

}

// TODO: add column colors to GridColumnProps?
export class GridColumnProps {
    [key: string]: any;
    heading: string = '';
    fieldType: GUIGridColType = GUIGridColType.gctEdit;
    dataType: GUIDataType = GUIDataType.gdAny;
    width: number = 120;
    align: GUIAlignment = GUIAlignment.galLeft;
    readOnly: boolean = false;
    required: boolean = false;
    maxLen: number = 0;
    sizable: boolean = false;
    tabStop: boolean = true;
    items: Array<string> = []; // array of items in this column's dropdown list; if the dropdown list has multiple columns, the columns are separated by '|'
    dataCol: number = 0;
    backColor: string = '';
    foreColor: string = '';
    tip: string | Array<string> = '';
    static create(): GridColumnProps { return new GridColumnProps(); };
}

export class GridRowProps {
    [key: string]: any;
    rowChanged: boolean = false;
    rowBackColor: string = '';
    rowForeColor: string = '';
    rowTip: string = '';
    cellChanged: Array<boolean> = [];
    cellBackColor: Array<string> = [];
    cellForeColor: Array<string> = [];
    cellTip: Array<string | Array<string>> = [];
    cellIcon: Array<string> = [];
}

export type GridValue = Array<GridRowProps>;

export class GridProps extends ControlProps {
    type = GUIObjectType.gxGrid
    typeName = 'gxGrid'
    columnInfo: Array<GridColumnProps> = [new GridColumnProps()];
    fields: Array<GridField> = [];
    items: Array<GridItemRow> = [];
    rowInfo: Array<GridRowProps> = [];
    gpBackColor: string = '#FFFFFF' // control back color -- column back color is columnInfo[].backColor, row back color is rowInfo[row].rowBackColor, cell back color is rowInfo[row].cellBackColor[col]
    gpForeColor: string = '#000000' // control back color -- column fore color is columnInfo[].foreColor, row fore color is rowInfo[row].rowForeColor, cell fore color is rowInfo[row].cellForeColor[col]
    gpTabStop: boolean = true
    gpDefVal: TableValue<string> = []
    gpValue: TableValue<string> = []
    gpAltColor: string = '' // alternate row color for grid
    gpGridLines: GUIGridStyle = GUIGridStyle.glsBoth // list/combo/grid grid style
    gpStyle: number = 0
    gpColumns: number = 1
    gpRows: number = 0
    gpFixedCols: number = 0 // grid number of fixed (non-scrolling) columns
    gpColHeading: Array<string> = [] // list/combo/grid heading row -- moved to columnInfo[].heading
    gpColDataType: number = 0 // grid column data type -- moved to columnInfo[].dataType
    gpColFieldType: Array<number> = [] // grid column field type (text/combo/check) -- moved to columnInfo[].fieldType
    gpColWidth: Array<number> = [] // grid column width -- moved to columnInfo[].width
    gpColAlign: Array<number> = [] // list/combo/grid column alignment -- moved to columnInfo[].align
    gpDataCol: Array<number> = [] // combo column used as data -- moved to columnInfo[].dataCol
    gpColItems: TableValue<string> = [] // grid column item list -- moved to columnInfo[].items
    gpReadOnly: Array<boolean> = [] // set if column is read-only -- moved to columnInfo[].readOnly
    gpRequired: Array<boolean> = [] // column is a required field -- moved to columnInfo[].required
    gpMaxLen: Array<number> = [] // max length for edit/combo/grid -- moved to columnInfo[].maxLen
    gpColSizable: Array<boolean> = [] // grid column is resizable -- moved to columnInfo[].sizable
    gpHint: string | Array<string> = '' // control help hint -- column hint is columnInfo[].tip, row hint is rowInfo[row].rowTip, cell hint is rowInfo[row].cellTip[col]
    gpColHint: Array<string> = [] // help hint for grid column -- moved to columnInfo[].tip
    gpColTabStop: Array<number> = [] // grid column tab stop (orverrides default tab behavior) -- moved to columnInfo[].tabStop
    gpIcon: TableValue<string> = [] // cell icon is rowInfo[row].cellIcon[col]
    gpColumn: number = 0 // grid column
    gpRow: number = 0 // grid row
    gpWordWrap: boolean = false // grid word-wrap style
    gpSelStart: Array<number> | number = 0  // edit control selection start (first char = 1) or grid selection start cell
    gpSelLength: Array<number> | number = 0 // edit control selection length or grid selection cols/rows
    gpSelRange: Array<number> = [] // edit or grid selection range (TODO: should this be a range object?)
    gpFocusStyle: number = 3 // grid focus style (0=default, 1=none, 2=thin, 3=thick)
    gpPasteMode: number = 0 // grid paste mode (0=single cell, 1=multi cell)
    gpDragMode: number = 0 // grid drag mode (move active cell, select cells, drag cell, nothing)
    // inherited default properties:
    //  form: gpForeColor, gpFontXxxx
}

export class GridEditableProps extends GridProps {
    type = GUIObjectType.gxGridEditable
    typeName = 'gxGridEditable'
}

export class GridField {
    label: string = '';
    key: string = '';
    type: GUIGridColType = GUIGridColType.gctEdit;
    thStyle: Partial<CSSStyleDeclaration> = {};
    thClass: Array<string> = [];
    tdClass: Array<string> = [];
}

export class GridItem {
    id: string = '';
    value: string | number = '';
    col: number = 0;
    row: number = 0;
    editable: boolean = false;
    focused: boolean = false;
    backColor: string = '';
    foreColor: string = '';
    icon: string = '';
    hint: string = '';
    eventMask: number = 0;
    enabled: boolean = false;
    columnInfo: GridColumnProps = new GridColumnProps();
    wordWrap: boolean = false;
    gridId: string = '';
  }
  
  export interface GridItemRow {
    [key: number]: GridItem
  }

export type ControlsProps = LabelProps | EditProps | EditPasswordProps | EditMultilineProps | CommandProps | OptionProps | CheckProps | PictureProps | FrameProps | TabgrpProps | TabProps | GaugeProps | TreeProps | TimerProps | BrowserProps | ListProps | CheckedListProps | ListMultiselProps | DrpDwnCboProps | DrpDwnListProps | GridProps | GridEditableProps

/**
 * Menu Types Properties
 */
export class MenuItemProps {
    id: string = ''; 
    level: number = 0; 
    caption: string = ''; 
    icon: string = ''; // icon file name or URL
    enabled: boolean = true;
    visible: boolean = true;
    shortcut: string = '';
    causesValidation: boolean = false;
    tip: string = ''; // node help hint for button
    panelWidth: number = 0; // 0=auto, -1=spring, else actual panel width
    panelAlign: GUIAlignment = GUIAlignment.galLeft;
    static create(): MenuItemProps { return new MenuItemProps(); };
}

export class BaseMenuProps extends gxProps { 
    typeFamily = 'menu'
    parentID: string = ''
    gpFontName: string = 'Arial'
    gpFontSize: number = 9
    gpLeft: number = 0
    gpTop: number = 0
    gpAlign: GUIToolbarPosition = GUIToolbarPosition.gtbFloat // toolbar alignment: 0=float, 1=top, 2=bottom, 3=left, 4=right
    gpItems: Array<MenuItemProps> = [] // TODO: should this be array of menuitem objects?
    gpStyle: number = 0
    // Menu color & font properties use "system" colors & font (e.g. Segoe UI)
    hotKeys: HotKeyBase[] = []
}

export class MenuProps extends BaseMenuProps {
    type = GUIObjectType.gxMenu
    typeName = 'gxMenu'
    focusable = false
}
export class PopupProps extends BaseMenuProps {
    type = GUIObjectType.gxPopup
    typeName = 'gxPopup'
    gpVisible: boolean = false
}
export class ToolbarProps extends BaseMenuProps {
    type = GUIObjectType.gxToolbar
    typeName = 'gxToolbar'
    focusable = false
    gpAlign: GUIToolbarPosition = GUIToolbarPosition.gtbTop
}
export class StatusbarProps extends BaseMenuProps {
    type = GUIObjectType.gxStatusbar
    typeName = 'gxStatusbar'
    focusable = false
    gpAlign: GUIToolbarPosition = GUIToolbarPosition.gtbBottom
}

export type MenusProps = MenuProps | PopupProps | ToolbarProps | StatusbarProps

/**
 * Internal Types Properties
 */
export class InternalProps extends gxProps { 
    typeFamily = 'internal'
}

export class BorderProps extends InternalProps {}
export class SizerProps extends InternalProps {}

export type InternalsProps = BorderProps | SizerProps

export type AllProps = RootProps | 
                        MDIProps | SDIProps | 
                        FormSizableProps | FormFixedProps | DialogProps | SDISizableProps | SDIFixedProps | 
                        LabelProps | EditProps | EditPasswordProps | EditMultilineProps | CommandProps | OptionProps | CheckProps | PictureProps | FrameProps | TabgrpProps | TabProps | GaugeProps | TreeProps | TimerProps | BrowserProps | ListProps | CheckedListProps | ListMultiselProps | DrpDwnCboProps | DrpDwnListProps | GridProps | GridEditableProps |
                        MenuProps | PopupProps | ToolbarProps | StatusbarProps | 
                        BorderProps | SizerProps


                        

export const enum GUIMethod {
    // methods
    gmReset = 0, // all guis & forms must support this method - resets all controls to default value
    gmClear = 1, // listbox / combobox
    gmShow = 2,
    gmHide = 3,
    gmActivate = 4,
    gmInsert = 5,
    gmRemove = 6,
    gmEnable = 7,
    gmDisable = 8,
    gmMove = 9,
    gmPrint = 10,
    gmHelp = 11,
    gmSort = 12,
    gmCopy = 13,
    gmCut = 14,
    gmPaste = 15,
}

export const enum GUIBorderStyle {
    // border style
    gbsNone = 0, // no border
    gbsFlat = 1, // flat border
    gbs3D = 2, // 3D border
}

export const enum GUIGridStyle {
    // grid style
    glsNone = 0, // no grid lines
    glsHoriz = 1, // horizontal grid lines
    glsVert = 2, // vertical grid lines
    glsBoth = 3, // horizontal & vertical grid lines
}

export const enum GUIGridColType {
    // grid column type
    gctLabel = 0,
    gctEdit = 1,
    gctCheck = 2,
    gctList = 3,
    gctCombo = 4,
    gctEllipsis = 5,
    gctIcon = 6,
    gctButton = 7,
}

export const enum GUIAlignment {
    // text alignment
    galLeft = 0, // default = left aligned
    galRight = 1, // right aligned
    galCenter = 2, // centered
}

export const enum GUIToolbarPosition {
    // toolbar / statusbar position
    gtbFloat = 0, // floating toolbar
    gtbTop = 1, // top of window
    gtbBottom = 2, // bottom of window
    gtbLeft = 3, // left edge of window
    gtbRight = 4, // right edge of window
}

export const enum GUIArrange {
    // option button arrangment
    garDown = 0,
    garAcross = 1,
}

export const enum GUIWindowState {
    // window state
    gwsNormal = 0,
    gwsMinimized = 1,
    gwsMaximized = 2,
}

export const enum GUIControlFunc {
    gcfSuspend = 1,
    gcfResume = 2,
}

export const enum GUIErrorLevel {
    glvlNone = 0, // no error
    glvlWarn = 1, // warning
    glvlFail = 2, // command failed
    glvlFatal = 3, // fatal error - program must end
}

export const enum GUIErrorCode {
    // error codes
    grSuccess = 0, // success!
    grFirst = 5001,
    grFailure = 5001, // general failure
    grInvProp = 5002, // invalid property code (object does not support this property)
    grInvArg = 5003, // invalid argument value (a property value or method argument is not valid)
    grNoID = 5004, // missing or invalid ID
    grInvVer = 5005, // invalid version or corrupt project
    grNoMac = 5006, // unable to record macro
    grNoEvent = 5007, // no objects to trigger event
    grObs1 = 5008, // obsolete error (no gcEndEvent command)
    grCacheFail = 5009, // error accessing macro cache
    grNoForm = 5010, // must specify a form
    grObs2 = 5011, // obsolete error (only one app allowed)
    grNoParent = 5012, // parent of control not found
    grNoCreate = 5013, // failed to create object
    grInvMenu = 5014, // invalid menu structure
    grNoPost = 5015, // unable to post events
    grInvFunc = 5016, // invalid function
    grNoDelete = 5017, // cannot delete main mdi app because shared mdi apps still exist
    grSuspended = 5018, // command not permitted while suspended
    grCancel = 5019, // user cancelled action
    grPending = 5020, // new command invalid until previous command has responded
    grCantActivate = 5021, // cannot activate hidden or disabled object (disabled/hidden ancestor may cause this error)
    grBadID = 5022, // ID is not valid for the object being created
    grExist = 5023, // an object of the specified ID already exists
    grNoExist = 5024, // an object of the specified ID does not exist
    grParentNotAllowed = 5025, // the object type being created cannot designate a parent
    grNoDisable = 5026, // unable to disable a visible dialog form
    grNoShow = 5027, // unable to show a disabled dialog form
    grNoHiddenApp = 5028, // unable to show dialog form when containing app is hidden
    grItemIDNotAllowed = 5029, // property or function does not support optional item ID
    grUnimplemented = 5030, // property, function, object or otherwise not implemented
    grInvMeth = 5031, // invalid method code (object does not support this method)
    grNoFile = 5032, // file not found

    // design time warnings & errors
    grwFirst = 5051,
    grwOldVer = 5051, // project version older than designer
    grwNewVer = 5052, // project version newer than designer
    grwBadScale = 5053, // invalid scale mode found
    grwMethProp = 5054, // method in macro converted to property (warning)
    grwMethIgn = 5055, // method in macro ignored (warning)
    grwLast = 5055,

    // Note: desktop version can return Windows error codes, including
    // 5, 9, 53, 91, 380 & 438. We are using the GUI error codes instead.
    // Equivalency is (more or less):
    //  grInvArg    5   Invalid procedure call or argument
    //  grInvArg    9   Subscript out of range
    //  grNoFile    53  File not found
    //  grNoExist   91  Object variable or With block variable not set
    //  grInvArg    380 Invalid property value
    //  grInvProp   438 Object doesn't support this property or method
    //  grInvMeth   438 Object doesn't support this property or method

}

export class GUIRspErrorType implements IGUIRspError {
    constructor(command: GUICommandCode, error: GUIErrorCode, level: GUIErrorLevel, message?: string, args?: (string|number)[]) {
        this.command = command;
        this.error = error;
        this.level = level;
        this.message = message || '';
        this.args = args || [];
    }
    command: GUICommandCode; // copy from the command property of the command object
    error: GUIErrorCode; // one of the error codes
    level: GUIErrorLevel; // error severity
    message?: string; // optional error message
    args?: (string|number)[]; // optional arguments, may be useful for display in error message
}

// GUIError
export class GUIError extends Error {
    constructor(message: string, errorCode: GUIErrorCode) {
        super(message);
        this.name = 'GUIError';
        this.errorCode = errorCode;
    }
    errorCode: GUIErrorCode;
}


export const shortCutKeys = ["ctrl+a", "ctrl+b", "ctrl+c", "ctrl+d", "ctrl+e", "ctrl+f", "ctrl+g", "ctrl+h", "ctrl+i", "ctrl+j", "ctrl+k", "ctrl+l", "ctrl+m", "ctrl+n", "ctrl+o", "ctrl+p", "ctrl+q", "ctrl+r", "ctrl+s", "ctrl+t", "ctrl+u", "ctrl+v", "ctrl+w", "ctrl+x", "ctrl+y", "ctrl+z", "f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8", "f9", "f10", "f11", "f12", "ctrl+f1", "ctrl+f2", "ctrl+f3", "ctrl+f4", "ctrl+f5", "ctrl+f6", "ctrl+f7", "ctrl+f8", "ctrl+f9", "ctrl+f10", "ctrl+f11", "ctrl+f12", "shift+f1", "shift+f2", "shift+f3", "shift+f4", "shift+f5", "shift+f6", "shift+f7", "shift+f8", "shift+f9", "shift+f10", "shift+f11", "shift+f12", "ctrl+shift+f1", "ctrl+shift+f2", "ctrl+shift+f3", "ctrl+shift+f4", "ctrl+shift+f5", "ctrl+shift+f6", "ctrl+shift+f7", "ctrl+shift+f8", "ctrl+shift+f9", "ctrl+shift+f10", "ctrl+shift+f11", "ctrl+shift+f12", "ctrl+insert", "shift+insert", "delete", "shift+delete", "alt+backspace", "ctrl+delete", "ctrl+shift+delete", "insert", "ctrl+shift+insert", "home", "ctrl+home", "shift+home", "ctrl+shift+home", "end", "ctrl+end", "shift+end", "ctrl+shift+end", "pageup", "ctrl+pageup", "shift+pageup", "ctrl+shift+pageup", "pagedown", "ctrl+pagedown", "shift+pagedown", "ctrl+shift+pagedown", "escape", "shift+escape"]

export const regex = /((^&[A-Za-z0-9])(.*))|((.*&&.*?)(&[A-Za-z0-9])(.*))|(([^&]+)(&[A-Za-z0-9])(.*))/;