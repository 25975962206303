/*
 * AccuTerm IO Telnet Protocol
 *
 * Copyright 2020 Zumasys, Inc.
 * 
 * Telnet Protocol
 * 
 */

////////////////////////////////////////////////////////////
// Import Dependencies!
////////////////////////////////////////////////////////////

import { TermUtil } from './termutil.js';
import { DEBUG } from './globals.js';

/* global TermUtil: false */
/* jshint expr: true */
/* global DEBUG: false */
/* exported TelnetProtocol */

// The Telnet protocol
function TelnetProtocol(_infunc, _outfunc, _discfunc, _settingsfunc, _serverfunc) {

    DEBUG&&console.log('TelnetProtocol constructor');

    // define & initialize private state object
    this._tnst = {
        /* jshint -W009 */
        infunc: _infunc, // callback for inbound data
        outfunc: _outfunc, // callback for outbound data
        discfunc: _discfunc, // callback for protocol-initiated disconnect
        settingsfunc: _settingsfunc, // callback to retrieve current settings
        infuncx: null,
        outfuncx: null,
        state: this.TNST_DATA,
        buf: new Array(),
        tnopts: new Array(), // array of [0...1]
        initial_options: true,
        icrlast: false,
        optcmd: 0,
        optparm: null,
		started: false, // server only
		waiting_for_subopt: 0 // server only
    };

    var settings = (_settingsfunc && _settingsfunc()) || {};
    this._tnst.tnopts[0] = new Array(); // array of [0...TNO_MAX-1] host-to-client (inbound) option states
    this._tnst.tnopts[1] = new Array(); // array of [0...TNO_MAX-1] client-to-host (outbound) option states
    var i;
    for (i = 0; i < this.TNO_MAX; i++) {
        this._tnst.tnopts[0][i] = 0;
        this._tnst.tnopts[1][i] = 0;
    }

    // Methods
    this.inbound = TelnetProtocol.prototype._inbound.bind(this);
    this.outbound = TelnetProtocol.prototype._outbound.bind(this);

    // initialize default processing functions
    this._tnst.infuncx = this._defaultInfunc.bind(this);
    this._tnst.outfuncx = this._defaultOutfunc.bind(this);

	if (typeof _serverfunc === 'function') {
		this.isserver = true;
		this._tnst.serverfunc = _serverfunc;
	} else this.isserver = false;
	
    // initialize options
	if(!this.isserver) {
		var opt_binary = (settings.telnetBinary ? this.TNX_SUPPORT | this.TNX_DESIRE : this.TNX_SUPPORT);
		this._telnetChangeOption(this.TNO_BINARY, 0, opt_binary, ~0);
		this._telnetChangeOption(this.TNO_BINARY, 1, opt_binary, ~0);
		this._telnetChangeOption(this.TNO_ECHO, 0, this.TNX_SUPPORT | this.TNX_DESIRE, ~0); // request ECHO on server
		this._telnetChangeOption(this.TNO_SGA, 0, this.TNX_SUPPORT | this.TNX_DESIRE, ~0);
		this._telnetChangeOption(this.TNO_SGA, 1, this.TNX_SUPPORT | this.TNX_DESIRE, ~0);
		this._telnetChangeOption(this.TNO_TERMTYPE, 1, this.TNX_SUPPORT | this.TNX_DESIRE, ~0);
		// check if username prefix included in host name
		this._tnst.username = (typeof(TermUtil) !== 'undefined' && TermUtil.GetUserName(settings.host)) || '';
		if (this._tnst.username) {
			this._telnetChangeOption(this.TNO_NEWENV, 1, this.TNX_SUPPORT | this.TNX_DESIRE, ~0); // we want to send the USER environment varialbe
		}
	} else {
		this._telnetChangeOption(this.TNO_BINARY, 0, this.TNX_SUPPORT | this.TNX_DESIRE, ~0); // BINARY client to server
		this._telnetChangeOption(this.TNO_BINARY, 1, this.TNX_SUPPORT | this.TNX_DESIRE, ~0); // BINARY server to client
		this._telnetChangeOption(this.TNO_ECHO, 1, this.TNX_SUPPORT | this.TNX_DESIRE, ~0);
		this._telnetChangeOption(this.TNO_SGA, 0, this.TNX_SUPPORT | this.TNX_DESIRE, ~0);
		this._telnetChangeOption(this.TNO_SGA, 1, this.TNX_SUPPORT | this.TNX_DESIRE, ~0);
		this._telnetChangeOption(this.TNO_TERMTYPE, 0, this.TNX_SUPPORT | this.TNX_DESIRE, ~0); // request TERMTYPE from client
		this._telnetChangeOption(this.TNO_NEWENV, 0, this.TNX_SUPPORT, ~0); // allow client to send environment varialbe to server
		this.infuncx = this._startServer.bind(this); // start server upon receipt of client data
		// telnet server initiates option negotiation
		this._telnetNegotiateOptions();
	}

}

TelnetProtocol.prototype = {
    // telnet states
    TNST_DATA: 0,
    TNST_CMND: 1,
    TNST_OPTN: 2,
    TNST_SUB: 3,
    TNST_SUBPARM: 4,
    TNST_SUBIAC: 5,
    // telnet commands
    TNC_IAC: 255,
    TNC_DONT: 254,
    TNC_DO: 253,
    TNC_WONT: 252,
    TNC_WILL: 251,
    TNC_SB: 250,
    TNC_AYT: 246,
    TNC_AO: 245,
    TNC_IP: 244,
    TNC_BREAK: 243,
    TNC_DMARK: 242,
    TNC_NOP: 241,
    TNC_SE: 240,
    // telnet options
    TNO_BINARY: 0,
    TNO_ECHO: 1,
    TNO_SGA: 3,
    TNO_TERMTYPE: 24,
    TNO_TERMTYPE_IS: 0,
    TNO_TERMTYPE_SEND: 1,
    TNO_NEWENV: 39,
    TNO_NEWENV_IS: 0,
    TNO_NEWENV_SEND: 1,
    TNO_NEWENV_INFO: 2,
    TNO_NEWENV_VAR: 0,
    TNO_NEWENV_VALUE: 1,
    TNO_NEWENV_ESC: 2,
    TNO_NEWENV_USERVAR: 3,
    TNO_MAX: 40,
    // telnet option mask
    TNX_INUSE: 1, // option currently in use
    TNX_DESIRE: 2, // option is supported and we want to use it if the server supports it
    TNX_REQUEST: 4, // option request from client in progress
    TNX_ACK: 8, // option request acknowledged by server
    TNX_RESP: 16, // option request from server has been responded to
    TNX_SUPPORT: 128, // option is supported, but we wont request it from the server

    // common character strings
    IAC: String.fromCharCode(255),
    IAC2: String.fromCharCode(255, 255),
    DEL: String.fromCharCode(127),
    BS: String.fromCharCode(8),
    CR: String.fromCharCode(13),
    CRNUL: String.fromCharCode(13, 0),
    LF: String.fromCharCode(10),
    // common global replace regular expressions
    reIAC: new RegExp('\xFF', 'g'),
    reCRNUL: new RegExp('\x0D\x00', 'g'),
    reCR: new RegExp('\x0D', 'g')

};

TelnetProtocol.prototype._inbound = function (data) {

    // process incoming data, forward to infunc

    /* DEBUGGING
     DEBUG&&console.log("TelnetProtocol.inbound: " + data.length);
     var ii;
     var ccc = [];
     for(ii = 0; ii < data.length; ii++) {
     ccc.push(data.charCodeAt(ii).toString(16));
     }
     DEBUG&&console.log('content=' + ccc);
     DEBUGGING */

    var i, j;
    var cc;
    var check_options = this._tnst.initial_options;

    for (i = 0; i < data.length; ) {
        switch (this._tnst.state) {
            case this.TNST_DATA:
                j = data.indexOf(this.IAC, i);
                if (j < 0) {
                    if (i === 0) {
                        this._tnst.infuncx(data);
                    } else {
                        this._tnst.infuncx(data.slice(i));
                    }
                    i = data.length;
                } else {
                    if (j > i)
                    {
                        this._tnst.infuncx(data.slice(i, j));
                    }
                    this._tnst.state = this.TNST_CMND;
                    i = j + 1;
                }
                break;
            case this.TNST_CMND:
                cc = data.charCodeAt(i);
                switch (cc) {
                    case this.TNC_IAC:
                        this._tnst.infuncx(this.IAC);
                        this._tnst.state = this.TNST_DATA;
                        break;
                    case this.TNC_DONT:
                    case this.TNC_DO:
                    case this.TNC_WONT:
                    case this.TNC_WILL:
                        this._tnst.optcmd = cc;
                        this._tnst.state = this.TNST_OPTN;
                        break;
                    case this.TNC_SB:
                        this._tnst.state = this.TNST_SUB;
                        break;
                    case this.TNC_IP:
                    case this.TNC_BREAK:
                        if(this.isserver)
                            this._tnst.serverfunc('break', cc);
                        // fall thru
                    default:
                        this._tnst.state = this.TNST_DATA;
                        break;
                }
                i++;
                break;
            case this.TNST_OPTN:
                this._telnetOption(this._tnst.optcmd, data.charCodeAt(i));
                check_options = true; // after processing input data, check if we need to re-negotiate any options
                this._tnst.state = this.TNST_DATA;
                i++;
                break;
            case this.TNST_SUB:
                /* jshint -W009 */
                this._tnst.optcmd = data.charCodeAt(i); // the option for the following parameter
                this._tnst.optparm = new Array();
                this._tnst.state = this.TNST_SUBPARM;
                i++;
                break;
            case this.TNST_SUBPARM:
                cc = data.charCodeAt(i);
                if (cc === this.TNC_IAC) {
                    this._tnst.state = this.TNST_SUBIAC;
                } else {
                    if (this._tnst.optparm.length < 1024) {
                        this._tnst.optparm.push(cc);
                    } else {
                        this._tnst.optparm = null;
                        this._tnst.state = this.TNST_DATA; // revert to data state if parameter is too long!
                    }
                }
                i++;
                break;
            case this.TNST_SUBIAC:
                cc = data.charCodeAt(i);
                if (cc === this.TNC_SE) {
                    this._telnetOptionParameter();
                    this._tnst.optparm = null;
                    this._tnst.state = this.TNST_DATA;
                } else {
                    // Here we have <IAC> <something> but not <IAC> <SE>. Not sure from the spec what to do
                    // except in the case of <IAC> <IAC>, which treats the first <IAC> as an escape and stores
                    // the 2nd <IAC> as data. Nothing documented for other cases, so we'll just store the 2nd
                    // character as data.
                    if (this._tnst.optparm.length < 1024) {
                        this._tnst.optparm.push(cc);
                        this._tnst.state = this.TNST_SUBPARM;
                    } else {
                        this._tnst.optparm = null;
                        this._tnst.state = this.TNST_DATA; // revert to data state if parameter is too long!
                    }
                }
                i++;
                break;
            default:
                this._tnst.state = this.TNST_DATA; // punt!
                break;
        }
    }
    if (check_options) {
        check_options = this._telnetNegotiateOptions();
    }
	if (this._tnst.waiting_for_subopt) {
		check_options = true;	
	}
	if (!check_options && this._tnst.state === this.TNST_DATA && this.isserver && !this._tnst.started) {
		// option negotiation complete, start the server session 
		DEBUG&&console.log("TelnetProtocol option negotiation complete - start server terminal session");
		this._startServer();
	}
};

// process outgoing data, forward to outfunc
TelnetProtocol.prototype._outbound = function (data) {
    if (this._tnst.initial_options) {
        // before sending anything to the host, make sure we begin option negotiation
        this._telnetNegotiateOptions();
    }
    if (data.indexOf(this.IAC) >= 0) {
        // replace any single IAC with double IAC
        data = data.replace(this.reIAC, this.IAC2);
    }

    /* DEBUGGING
     DEBUG&&console.log('telnet outbound -> outfuncx; len='+data.length);
     var ii;
     var ccc = [];
     for(ii = 0; ii < data.length; ii++) {
     ccc.push(data.charCodeAt(ii).toString(16));
     }
     DEBUG&&console.log('content=' + ccc);
     DEBUGGING */

    this._tnst.outfuncx(data);
};

TelnetProtocol.prototype._telnetOption = function (optcmd, optnbr) {
    var dir = (optcmd === this.TNC_DO || optcmd === this.TNC_DONT) & 1; // True for outbound option, False for inbound
    var val = (optcmd === this.TNC_WILL || optcmd === this.TNC_DO); // True if affirmative, False if negative
    if (this._tnst.tnopts[dir][optnbr] & this.TNX_REQUEST) {
        // we sent a request to server so this is an acknowledgement
        if (val) {
            this._telnetChangeOption(optnbr, dir, this.TNX_INUSE | this.TNX_ACK, this.TNX_REQUEST);
        } else {
            this._telnetChangeOption(optnbr, dir, this.TNX_ACK, this.TNX_INUSE | this.TNX_REQUEST);
        }
    } else {
        // received request from server
        var oldopt = this._tnst.tnopts[dir][optnbr];
        if (this._tnst.tnopts[dir][optnbr] & this.TNX_SUPPORT) {
            if (val) {
                this._telnetChangeOption(optnbr, dir, this.TNX_INUSE, 0);
            } else {
                this._telnetChangeOption(optnbr, dir, 0, this.TNX_INUSE);
            }
        } else {
            this._telnetChangeOption(optnbr, dir, 0, this.TNX_INUSE);
        }
        // skip response if no change in option setting and already responded
        var newopt = this._tnst.tnopts[dir][optnbr];
        if ((!(oldopt & this.TNX_RESP)) || ((oldopt ^ newopt) & this.TNX_INUSE)) {
            var rspcmd = (newopt & this.TNX_INUSE) ? (dir ? this.TNC_WILL : this.TNC_DO) : (dir ? this.TNC_WONT : this.TNC_DONT);
            //DEBUG&&console.log('TelnetProtocol.telnetOption response=' + rspcmd + ' option=' + optnbr);
            this._tnst.outfunc(String.fromCharCode(this.TNC_IAC, rspcmd, optnbr));
            // if change in option setting, clear "responded" flag, else set it
            if (!((oldopt ^ newopt) & this.TNX_INUSE)) {
                this._telnetChangeOption(optnbr, dir, this.TNX_RESP, 0);
            } else {
                this._telnetChangeOption(optnbr, dir, 0, this.TNX_RESP);
            }
        }
    }
};

TelnetProtocol.prototype._telnetNegotiateOptions = function () {
    var optnbr, dir, result = false;
    this._tnst.initial_options = false;
    for (optnbr = 0; optnbr < this.TNO_MAX; optnbr++) {
        for (dir = 0; dir < 2; dir++) {
            /*jshint -W018 */
            if ((this._tnst.tnopts[dir][optnbr] & (this.TNX_DESIRE | this.TNX_SUPPORT)) !== this.TNX_SUPPORT) {
                if ((!!(this._tnst.tnopts[dir][optnbr] & this.TNX_INUSE)) !== (!!(this._tnst.tnopts[dir][optnbr] & this.TNX_DESIRE))) {
                    if ((this._tnst.tnopts[dir][optnbr] & (this.TNX_ACK | this.TNX_REQUEST)) === 0) {
                        var optcmd = (this._tnst.tnopts[dir][optnbr] & this.TNX_DESIRE) ? (dir ? this.TNC_WILL : this.TNC_DO) : (dir ? this.TNC_WONT : this.TNC_DONT);
                        //DEBUG&&console.log('TelnetProtocol.telnetNegotiateOptions response='+optcmd+' option='+optnbr);
                        this._tnst.outfunc(String.fromCharCode(this.TNC_IAC, optcmd, optnbr));
                        this._telnetChangeOption(optnbr, dir, this.TNX_REQUEST, 0); // set the Request flag (Ack flag already clear)
						result = true; // continue negotiating
                    } else if ((this._tnst.tnopts[dir][optnbr] & (this.TNX_ACK | this.TNX_REQUEST)) === this.TNX_REQUEST) {
						//DEBUG&&console.log('TelnetProtocol.telnetNegotiateOptions waiting ACK for option ' + optnbr + (dir?" outgoing":" incoming"));
						result = true; // continue negotiating (waiting for ACK from previous REQUEST)
					}
                }
            }
            /*jshint +W018 */
        }
    }
	return result;
};

TelnetProtocol.prototype._telnetChangeOption = function (optnbr, dir, setmsk, clrmsk) {
    if (optnbr >= 0 && optnbr < this.TNO_MAX) {
        dir &= 1; // ensure 0 (inbound) or 1 (outbound)
        var oldopt = this._tnst.tnopts[dir][optnbr];
        var newopt = oldopt & ~clrmsk;
        newopt |= setmsk;
        this._tnst.tnopts[dir][optnbr] = newopt;
        // check if option INUSE changed
        if (oldopt ^ newopt) {
			//DEBUG&&console.log("TelnetProtocol.telnetChangeOption " + optnbr + " for " + (dir?"outgoing":"incoming") + " set=" + setmsk.toString(16) + " clr=" + clrmsk.toString(16));
            switch (optnbr) {
				case this.TNO_BINARY:					
	                this._telnetChangeBinaryOption(dir);
					break;
				case this.TNO_ECHO:					
					// TODO: for server, check for change in ECHO option
					break;
				case this.TNO_TERMTYPE:
					if(this.isserver && dir === 0 && this._tnst.tnopts[dir][this.TNO_TERMTYPE] & this.TNX_INUSE)
						this._telnetRequestTermtype();
					break;
				case this.TNO_NEWENV:
					if(this.isserver && dir === 0 && this._tnst.tnopts[dir][this.TNO_NEWENV] & this.TNX_INUSE)
						this._telnetRequestEnviron();
					break;
            }
        }
    }
};

// binary option changed - update infuncx or outfuncx
TelnetProtocol.prototype._telnetChangeBinaryOption = function (dir) {
	if(!this.isserver || this._tnst.started) {
		if (dir === 0) {
			//DEBUG&&console.log('TelnetProtocol.telnetChangeBinaryOption for incoming data: ' + !!(this._tnst.tnopts[0][this.TNO_BINARY] & this.TNX_INUSE));
			this._tnst.infuncx = (this._tnst.tnopts[0][this.TNO_BINARY] & this.TNX_INUSE) ? this._tnst.infunc : this._defaultInfunc.bind(this);
		} else {
			//DEBUG&&console.log('TelnetProtocol.telnetChangeBinaryOption for outgoing data: ' + !!(this._tnst.tnopts[1][this.TNO_BINARY] & this.TNX_INUSE));
			this._tnst.outfuncx = (this._tnst.tnopts[1][this.TNO_BINARY] & this.TNX_INUSE) ? this._tnst.outfunc : this._defaultOutfunc.bind(this);
		}
	}
    if(this.isserver) {
        this._tnst.serverfunc('binary', (this._tnst.tnopts[0][this.TNO_BINARY] & this.TNX_INUSE || this._tnst.tnopts[1][this.TNO_BINARY] & this.TNX_INUSE) ? true : false);
    }
};

// server requests termtype from client
TelnetProtocol.prototype._telnetRequestTermtype = function() {
    //DEBUG&&console.log('TelnetProtocol.telnetRequestTermtype send');
	this._tnst.outfunc(String.fromCharCode(this.TNC_IAC, this.TNC_SB, this.TNO_TERMTYPE, this.TNO_TERMTYPE_SEND, this.TNC_IAC, this.TNC_SE));
	this._tnst.waiting_for_subopt = this.TNO_TERMTYPE;
};

TelnetProtocol.prototype._telnetOptionParameter = function () {
    var cc, i;
    var resp = null;
    try {
        switch (this._tnst.optcmd) {
            case this.TNO_TERMTYPE:
				if (!this.isserver) {
					// client sends termtype to server
					if (this._tnst.optparm.length === 1 && this._tnst.optparm[0] === this.TNO_TERMTYPE_SEND) {
						var settings = (this._tnst.settingsfunc && this._tnst.settingsfunc()) || {};
						var termtype = (typeof(TermUtil) !== 'undefined' && TermUtil.HostTermType(settings.termtype, settings.hosttype, settings.hosttermtype)) || settings.hosttermtype || '';
						resp = String.fromCharCode(this.TNO_TERMTYPE_IS);
						for (i = 0; i < termtype.length; i++) {
							cc = termtype.charCodeAt(i);
							if (cc >= 32 && cc < 127)
							{
								resp += String.fromCharCode(cc);
							}
						}
					}
				} else {
					// server receives termtype from client
					if (this._tnst.optparm.length > 1 && this._tnst.optparm[0] === this.TNO_TERMTYPE_IS) {
						this._tnst.optparm.shift();
						this._tnst.serverfunc('term', String.fromCharCode.apply(null, this._tnst.optparm));
					}
				}
                break;
            case this.TNO_NEWENV:
				if (!this.isserver) {
					// client sends environment variables to server				
					if (this._tnst.optparm.length >= 1 && this._tnst.optparm[0] === this.TNO_NEWENV_SEND) {
						// send environment variables (USER) to host
						resp = this._telnetEnvironOption();
					}
				} else {
					// server receives environment variables from client
					if (this._tnst.optparm.length > 1 && this._tnst.optparm[0] === this.TNO_NEWENV_IS) {
						this._telnetEnvironOptionServer(this._tnst.optparm);
					}
				}
                break;
        }
        if (resp) {
            //DEBUG&&console.log('TelnetProtocol.telnetOptionParameter sending response for option ' + this._tnst.optcmd + '; len=' + resp.length);
            this._tnst.outfunc(String.fromCharCode(this.TNC_IAC, this.TNC_SB, this._tnst.optcmd) + resp + String.fromCharCode(this.TNC_IAC, this.TNC_SE));
        }
		if (this._tnst.optcmd === this._tnst.waiting_for_subopt) {
			this._tnst.waiting_for_subopt = 0;
		}
    } catch (e) {
        DEBUG&&console.log("exception in telnetOptionParameter: " + e);
    }
};

// server requests environment from client
TelnetProtocol.prototype._telnetRequestEnviron = function() {
    //DEBUG&&console.log('TelnetProtocol.telnetRequestEnviron send');
	this._tnst.outfunc(String.fromCharCode(this.TNC_IAC, this.TNC_SB, this.TNO_NEWENV, this.TNO_NEWENV_SEND, this.TNC_IAC, this.TNC_SE));
	this._tnst.waiting_for_subopt = this.TNO_NEWENV;
};

// telnet environment variable option
TelnetProtocol.prototype._telnetEnvironOption = function () {
    var varlist, resp = String.fromCharCode(this.TNO_NEWENV_IS);
    if (this._tnst.username) {
        varlist = this._tnst.optparm;
        var idx = 1;
        if (varlist.length === 1) {
            // server did not specify a list of variables so just send the USER environment variable
            return this._telnetSendEnviron(this.TNO_NEWENV_VAR, null);
        } else {
            // scan list for VAR or USERVAR
            for (var n = 0; idx < varlist.length; idx += n) {
                var typ = varlist[idx++];
                if (typ === this.TNO_NEWENV_VAR || typ === this.TNO_NEWENV_USERVAR) {
                    for (n = 0; idx + n < varlist.length; n++) {
                        if (varlist[idx + n] === this.TNO_NEWENV_ESC) {
                            if (idx + n + 1 < varlist.length) {
                                n++; // skip ESC unless last byte
                            }
                        } else if (varlist[idx + n] === this.TNO_NEWENV_VAR || varlist[idx + n] === this.TNO_NEWENV_USERVAR) {
                            break;
                        }
                    }
                    resp += this._telnetSendEnviron(typ, String.fromCharCode.apply(String, varlist.slice(idx, idx + n))); // use apply to convert array elements into string to get varname
                } else {
                    return null; // bad var list - return empty response
                }
            }
        }
    }
    return resp;
};

// Send environment variable
TelnetProtocol.prototype._telnetSendEnviron = function (typ, varname) {
    switch (typ) {
        case this.TNO_NEWENV_VAR:
            if (!varname || (varname.toUpperCase() === "USER")) {
                // we are assuming that username does not contain any special characters (not escaping special characters)
                return String.fromCharCode(typ) + (varname || "USER") + String.fromCharCode(this.TNO_NEWENV_VALUE) + this._tnst.username;
            }
            break;
        case this.TNO_NEWENV_USERVAR:
            break;
        default:
            return null;
    }
    return String.fromCharCode(typ) + varname;
};

// Receive environment variables
TelnetProtocol.prototype._telnetEnvironOptionServer = function (data) {
	var env = {};
	var i, k = 0;
	var pair = ['', ''];
	if(data.length > 1 && (data[0] === this.TNO_NEWENV_VAR || data[0] === this.TNO_NEWENV_USERVAR)) {
		data.push(this.TNO_NEWENV_VAR); // append sentinal
		for(i = 1; i < data.length; i++) {
			switch (data[i]) {
				case this.TNO_NEWENV_VAR:
				case this.TNO_NEWENV_USERVAR:
					if(k === 0 || pair[0].length === 0) {
						// this is an undefined variable - ignore
					} else {
						try {
							env[pair[0]] = pair[1];							
						} catch(e) {}
					}
					pair = ['', ''];
					k = 0;
					break;
				case this.TNO_NEWENV_VALUE:
					if(k !== 0) return; // bad option format!
					k = 1;
					break;
				case this.TNO_NEWENV_ESC:
					if(++i >= data.length) return; // bad option format!
					if(data[i] !== this.TNO_NEWENV_VAR && data[i] !== this.TNO_NEWENV_USERVAR && data[i] !== this.TNO_NEWENV_VALUE && data[i] !== this.TNO_NEWENV_ESC) return; // bad option format!
					// fall thru
				default:
					pair[k] += String.fromCharCode(data[i]);				
			}		
		}
		if(Object.keys(env).length > 0) {
			this._tnst.serverfunc('env', env);
		}
	}
};

// telnet text-mode input processing - remove NUL after CR
TelnetProtocol.prototype._defaultInfunc = function (data) {
    //DEBUG&&console.log('TelnetProtocol.defaultInFunc len=' + data.length);
    if (this._tnst.icrlast) {
        // last message ended in CR, if this message begins with NUL, remove it
        if (data.indexOf(this.NUL) === 0) {
            data = data.slice(1);
        }
    }
    this._tnst.icrlast = data.length && (data.lastIndexOf(this.CR) === data.length - 1); // set flag if message ends with CR
    if (data.indexOf(this.CRNUL) >= 0) {
        data = data.replace(this.reCRNUL, this.CR);
    }
    this._tnst.infunc(data); // send to terminal

};

// telnet text-mode output processing - add NUL after each CR
TelnetProtocol.prototype._defaultOutfunc = function (data) {
    if (data.indexOf(this.CR) >= 0) {
        data = data.replace(this.reCR, this.CRNUL);
    }
    //DEBUG&&console.log('TelnetProtocol.defaultOutfunc calling outfunc; len=' + data.length);
    this._tnst.outfunc(data); // send to host
};

// server only - start the terminal session on the server
TelnetProtocol.prototype._startServer = function(data) {
	// no more options or responses expected, or client data has started
	DEBUG&&console.log("TelnetProtocol.startServer");
	this._tnst.started = true;
	// set up correct input / output functions
	this._telnetChangeBinaryOption(0);
	this._telnetChangeBinaryOption(1);
	// start the server
	this._tnst.serverfunc('start');
	// pass client data to server
	if(data)
		this._tnst.infuncx(data);
};

// pass optional TNC_IP / TNC_BREAK to override settings breakKey/breakKeyCode 
TelnetProtocol.prototype.sendBreakKey = function (tnc) {
    var resp = null;
    if(tnc) {
        resp = String.fromCharCode(this.TNC_IAC, tnc);
    } else {
        var settings = (this._tnst.settingsfunc && this._tnst.settingsfunc()) || {};
        var brktyp = (settings.breakKey && settings.breakKey.toUpperCase()) || 'DEFAULT';
        var brkchr = settings.breakKeyCode;
        //DEBUG&&console.log('TelnetProtocol.sendBreakKey: which=' + brktyp);
        switch (brktyp) {
            case 'DISABLED':
                break;
            case 'INTERRUPT':
                resp = String.fromCharCode(this.TNC_IAC, this.TNC_IP);
                break;
            case 'BREAK':
                resp = String.fromCharCode(this.TNC_IAC, this.TNC_BREAK);
                break;
            case 'CONTROL':
                if (/^([0-9]\d*)$/.test(brkchr) && brkchr >= 0 && brkchr <= 255) {
                    resp = String.fromCharCode(brkchr);
                }
                break;
        }
    }
    if (resp) {
        this._tnst.outfunc(resp);
    }
};

TelnetProtocol.prototype.sendKeepalive = function () {
    //DEBUG&&console.log('TelnetProtocol.sendKeepalive');
    this._tnst.outfunc(String.fromCharCode(this.TNC_IAC, this.TNC_NOP));
};

// make this a node module when used on the server
if(typeof module !== 'undefined' && module.exports) {
	module.exports = TelnetProtocol;
}

////////////////////////////////////////////////////////////
// Exports!
////////////////////////////////////////////////////////////

export { TelnetProtocol };