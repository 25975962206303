
























import { Component, Vue, Watch } from 'vue-property-decorator'
// Types
import { GUIComponent, GUIEventCode, GuisProps, FormsProps, GUIWindowState } from './../../types/enums'
import { GuisServiceType } from './../../types/services/guis'

@Component({
    name: 'SidebarComponent'
})

export default class Sidebar extends Vue {

/** ******************************** Vue Computed **********************************/

    get guiService (): GuisServiceType { return this.$store.getters['guiGuis/getGuisService'] }
    get guisComponents () {
        const root: GUIComponent = this.$store.getters['guiGuis/getGuisRoot'];
        return root.children; 
    }
    get focusedForm(): string { return this.$store.getters['guiGuis/getFocusedForm']; }
    get toggle(): boolean { return this.$store.getters['guiGuis/getToggleNavBar'] }

/** ******************************** Vue Method **********************************/

    activate(component: GUIComponent): void {
        if(component.props.gpWindowState === GUIWindowState.gwsMinimized) {
            this.$store.dispatch('guiGuis/updateProperty', {id: component.props.id, property: 'gpWindowState', value: GUIWindowState.gwsNormal }) 
        }
        this.guiService.methodService.activateComponentTree(component)
        this.$root.$emit('bv::toggle::collapse', 'guiSidebar');
    }

    listVisibleAndEnabledForms() {
        let list: Array<GUIComponent> = [];
        this.guisComponents.forEach(x => {
            list.push(... x.children.filter(y => this.guiService.methodService.activatable(y))); // remove any forms that aren't visible nor enabled
        });
        return list;
    }

/** ******************************** Vue Watch and Emit Events! **********************************/

    @Watch('toggle') p () { 
        this.$root.$emit('bv::toggle::collapse', 'guiSidebar');
    }

}
