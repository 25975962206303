














import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { IGUIEvent } from '../../../types';
import { GridItem, GUIEventCode } from '../../../types/enums';
import { UtilsType } from '../../../types/utils';
// Utilities 
import Utils from '../../../utils/index';

@Component({
  name: 'GridLabelComponent'
})

export default class GridLabel extends Vue {
/** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new GridItem()) }) public item!: GridItem;
  @Prop({ default: 0 }) public type!: number; // always 0, 5, 6 or 7

/** ******************************** Vue Data **********************************/ 

  public utils: UtilsType = new Utils();
  public hasFocus: boolean = false;

/** ******************************** Vue Computed **********************************/

  get isLabel() { return this.type === 0 }
  get hasEllipsis() { return this.type === 5 }
  get hasIcon() { return this.type === 6 }
  get isCustom() { return this.type === 7 }

  get col (): number { return this.item.col }
  get row (): number { return this.item.row }

  get labelName () { return this.item.value }

  get id(): string { return this.item.id }

  get tabIndex(): number { return this.item.columnInfo.tabStop && !this.item.columnInfo.readOnly ? 0: -1}

  get gridId(): string { return this.item.gridId }

  get style (): Partial<CSSStyleDeclaration> {
    let css: { [k: string]: string } = {};

    css.display = 'flex'
    css.alignItems = 'center'

    if (this.item.wordWrap === false) {
      css.overflow = 'hidden';
      css.whiteSpace = 'nowrap';
    }

    let color: string;
    color = this.item.backColor;
  
    css.backgroundColor = 'transparent';

    if (color) css.backgroundColor = color; 
    color = this.item.foreColor;
    if (color) css.color = color;  

    return css;
  }

  get iconName () { return this.hasEllipsis ? 'three-dots' : this.item.icon }

/** ******************************** Vue Methods **********************************/

  handleBlur(e: any) {
    if(!e.currentTarget!.contains(e.relatedTarget)){
      this.hasFocus = false
    }
  }

  focusInner() {
    console.log('focusInner  ' + this.id);
    (this.$refs[this.id + "-inner"] as HTMLDivElement).focus();
  }

  buttonCLick(e: MouseEvent) {
    e.stopPropagation();
    let newRow = this.row+1
    let newColumn = this.col+1
    let args: any = [newColumn, newRow]
    if (this.item.eventMask & GUIEventCode.geBtnClick) {
      // send this event to the server
      const buttonClickEvent: IGUIEvent = { event: GUIEventCode.geBtnClick, id: this.gridId, args: args }
      this.$store.dispatch('guiGuis/addEvent', buttonClickEvent);
    }  
    this.focusInner();
  }

  buttonClick() {
    let newRow = this.row+1
    let newColumn = this.col+1
    let args: any = [newColumn, newRow]
    if (this.item.eventMask & GUIEventCode.geBtnClick) {
      // send this event to the server
      const buttonClickEvent: IGUIEvent = { event: GUIEventCode.geBtnClick, id: this.gridId, args: args }
      this.$store.dispatch('guiGuis/addEvent', buttonClickEvent);
    }  
    this.focusInner();
  }

  keyHandler(e: KeyboardEvent) {
    switch(e.code) {
      case ("Enter"):
        this.buttonClick()
      break;
    }
  }

}
