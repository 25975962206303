// Store
import store from '@/store'
// Router
import router from '@/router/index'
// AccuTerm Web Library
import { TermApp } from '@/vendor/accuterm/client/js/termapp'
import Profile from '@/types'
import { TerminalApp, TerminalService, TerminalAppTest } from '@/types/services/terminal'
import { GUIComponent } from '@/vendor/accuterm-gui/src/types/enums'

// payload is generally TermApp instance
export default class AccuTermService implements TerminalService {

    private resizeTerminalViewport: boolean = false;
    private throttleTimeoutId: number | null = null;
    private debounceTimeoutId?: number;

    // Handle terminal div resize
    private resizeTerminalHeight(terminal: TerminalApp, resizeViewport: boolean) {
        let terminalHeight = window.innerHeight;
        const terminalElement = document.getElementById('terminal');
        const headerElement = document.getElementById('header');
        const headerRect = headerElement ? headerElement.getBoundingClientRect() : null;
        if (headerRect) {
            terminalHeight -= headerRect.height;
        }
        const footerElement = document.getElementById('footer-wrap');
        const footerRect = footerElement ? footerElement.getBoundingClientRect() : null;
        if (footerRect) {
            terminalHeight -= footerRect.height;
        }
        if (terminalHeight < 100) {
            terminalHeight = 100;
        }
        if (terminalElement) {
            terminalElement.style.height = terminalHeight + 'px';
        }
        // iOS Safari is slow updating the DOM to reflect the new size of
        // the visualViewport and/or terminal viewport div height, so wait
        // a little before recalculating terminal viewport scroll position.
        //terminal.containerResize(resizeViewport);
        this.debouncedContainerResize(terminal, resizeViewport);
    }

    private throttledResizeTerminalHeight(terminal: TerminalApp, resizeViewport: boolean) {
        this.resizeTerminalViewport ||= resizeViewport;
        if (!this.throttleTimeoutId) {
            this.throttleTimeoutId = setTimeout(() => {
                this.throttleTimeoutId = null;
                this.resizeTerminalHeight(terminal, this.resizeTerminalViewport);
                this.resizeTerminalViewport = false;   
            }, 200);
        }
    }    
    
    private debouncedContainerResize(terminal: TerminalApp, resizeViewport: boolean) {
        clearTimeout(this.debounceTimeoutId);
        this.debounceTimeoutId = setTimeout(() => {
            terminal.containerResize(resizeViewport);
        }, 300);
    }

    breakKey (terminal: TerminalApp) {
        terminal.terminalFunction('break')
    }

    connect (terminal: TerminalApp) {
        terminal.openConnection()
    }

    containerResize (payload: { terminal: TerminalApp, resizeViewport: boolean }) {
        // this may get called before terminal has been created, so check before calling
        if (payload.terminal) {
            this.throttledResizeTerminalHeight(payload.terminal, payload.resizeViewport);
        }
    }

    disconnect (terminal: TerminalApp) {
        terminal.closeConnection()
    }

    focus (terminal: TerminalApp) {
        terminal.focus()
    }

    blur (terminal: TerminalApp) {
        terminal.blur()
    }
    
    getViewport (terminal: TerminalApp) {
        return terminal.appState.terminal.viewport
    }

    initiateTerm (payload: { term: HTMLElement, profile: Profile }) {
        return new TermApp(payload.term, payload.profile)
    }

    openTerm (terminal: TerminalApp) {
        const banner = store.getters['settings/getBanner'];
        const options: any = {};
        if (banner) {
            options.banner = banner;
        }
        terminal.openTerminal(options);
    }

    paste (payload: { terminal: TerminalApp, text: string }) {
        payload.terminal.pasteText(payload.text)
    }

    reset (terminal: TerminalApp) {
        terminal.resetTerminal()
    }

    sendClick (payload: { terminal: TerminalApp, e: MouseEvent }) {
        payload.terminal.sendMouseClick(payload.e)
    }

    // key is just the key value a, b, c, d...
    sendKeys (payload: { terminal: TerminalApp, key: string }) {
        payload.terminal.sendKeys(payload.key)
    }

    // key is represented by a number
    sendFunctionKey (payload: { terminal: TerminalApp, key: number }) {
        payload.terminal.sendFunctionKey('key' + payload.key)
    }

    // set state of "extras" control key
    setControlKey (payload: { terminal: TerminalApp, state: boolean }) {
        payload.terminal.setControlKey(payload.state)
    }

    updateSettings (payload: { terminal: TerminalApp, profile: Profile, options: { resetTerminal: boolean, resetConnection: boolean } }) {
        payload.terminal.updateSettings(payload.profile, payload.options)
    }

    // Listeners
    // payload is { accuTerm, beep (element) }
    audioListener (payload: { terminal: TerminalApp, beep: HTMLMediaElement }) {
        payload.terminal.on('beep', () => {
            payload.beep.play()
        })
    }

    browserListener (terminal: TerminalApp) {
        terminal.on('browser', (url: string) => {
            window.open(url, '_blank')
        })
    }

    clipboardListener (terminal: TerminalApp) {
        terminal.on('clipboard', (text: string) => {
            //const stext = text.length > 60 ? text.substr(0, 60) + ' ...' : text
            store.dispatch('terminal/setClipboardTerm', { term: text })
        })
    }

    colorChangeListener (payload: { terminal: TerminalApp, term: HTMLElement }) {
        // Theme color change
        payload.terminal.on('colorchange', (backcolor: string, themeStyle: string) => {
            payload.term.setAttribute('class', themeStyle)
            payload.term.style.backgroundColor = backcolor
        })
    }

    cursorListener (terminal: TerminalApp) {
        terminal.on('cursor', (x: number, y: number, pg: number) => {
            store.dispatch('terminal/setStatus', { col: x, row: y, page: pg })
        })
    }

    emailListener (terminal: TerminalApp) {
        terminal.on('mailto', (recipient: string, subject: string, body: string) => {
            let mailto = 'mailto:' + recipient + '?subject=' + encodeURI(subject) + '&body=' + encodeURI(body)
            window.open(mailto, '_blank')
        })
    }

    guiListener (terminal: TerminalApp) {
        terminal.on('gui', (request: any) => {
            // console.log('Accuterm.io Terminal Service Request:', request.request)
            const root: GUIComponent | undefined = store.getters['guiGuis/getComponent']('*');
            if (!root) {
                store.dispatch('guiGuis/createRoot')
            }
            store.dispatch('gui/setRequest', request.request)
            store.dispatch('gui/setCallback', request.callback)
            // redirect to the gui
            if (router.currentRoute.name !== 'GUI') {
                router.push({ name: 'GUI' })
            }
        })
    }

    messageBoxListener (terminal: TerminalApp) {
        // options.title = message box title text
        // options.message = message text (newlines are OK)
        // options.button1 = text of first button caption
        // options.button2 = text of second button caption (or undefined if only 1 button)
        // options.button3 = text of third button caption (or undefined)
        // options.callback = function to call with result (0 if click X, or button number clicked)
        terminal.on('msgbox', (options: any) => {
            store.dispatch('terminal/setMessageBox', options)
        })
    }

    phoneListener (terminal: TerminalApp) {
        terminal.on('telephone', (phoneNumber: string) => {
            window.open(phoneNumber, '_self')
        })
    }

    statusListener (terminal: TerminalApp) {
        terminal.on('status', (msg: string) => {
            store.dispatch('terminal/toggleConnection', terminal.isConnected())
            store.dispatch('terminal/setStatusMessage', msg)
        })
    }
}
