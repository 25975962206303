
































































































































import { Component, Watch, Vue } from 'vue-property-decorator'
// Types
import { BModal } from 'bootstrap-vue'
// Utils
import Utils from '@/utils'
// Components
import AccuTerm from '@/components/terminal/AccuTerm.vue'
import TermFooter from '@/components/terminal/Footer.vue'
import Keyboard from '@/components/terminal/Keyboard.vue'
// ResizeObserver
import { install } from 'resize-observer';
if (!window.ResizeObserver) install();

@Component({
    name: 'TerminalComponent',
    components: {
       AccuTerm, TermFooter, Keyboard
    }
})

export default class Terminal extends Vue {

/** ******************************** Vue Data! **********************************/

    private isProfileID: boolean = false;
    private copyClipboardValue: string = '';
    private pasteClipboardValue: string = '';
    private footerObserver = new window.ResizeObserver(this.handleFooterResize as any);

    $refs!: {
        copyModal: BModal,
        copyClipboard: HTMLTextAreaElement,
        pasteModal: BModal,
        pasteClipboard: HTMLTextAreaElement,
        termMessageBoxModal: BModal,
        termCertFailModal: BModal,
        footerWrap: HTMLDivElement
    }

/** ******************************** Vue Computed **********************************/

    get getClipboardTerm () { return this.$store.getters['terminal/getClipboardTerm'] }
    get messageBox () { return this.$store.getters['terminal/messageBox'] }

    get hasActiveProfile (): boolean { return this.$store.getters['profiles/getHasActiveProfile'] }
    get hasProfile (): boolean { return this.$store.getters['profiles/getHasProfile'] }

    get isNotConnected (): boolean { 
        let status = this.$store.getters['terminal/getStatusMessage'];
        return status === 'connection to host failed' || status === 'TLS handshake failure';
    }

    get getHostName (): string { 
        const { host, port } = this.$store.getters['profiles/getActiveProfile'];
        return `https://${host}:${port}`
    }

    get showKeyboard () { 
        return this.$store.getters['terminal/getToggleKeyboard'] 
    }

    get showKeyboardAsBool (): boolean {
        const toggle = this.showKeyboard;
        if (toggle) return true;
        return false; 
    }
    
    set showKeyboardAsBool (newval: boolean) {
        //console.log('setShowKeyBoardAsBool ' + newval);
    }

    get CtrlOrCmd(): string {
        return (/mac os x/i.test(navigator.userAgent) ? '⌘' : 'Ctrl');
    }

/** ******************************** Vue Methods! **********************************/

    handleFooterResize() {
        this.$store.dispatch('terminal/containerResize', false);
    }

    viewportHandler() {
        const layoutViewport: any = document.getElementById('app-wrap');
        if (layoutViewport) {
            const offsetX = window.visualViewport.offsetLeft;
            const offsetY = window.visualViewport.height -
                        layoutViewport.getBoundingClientRect().height +
                        window.visualViewport.offsetTop;
            console.log('viewportHandler:', offsetX, offsetY);
            layoutViewport.style.transform = 'translate(' + 
                                        offsetX + 'px,' +
                                        offsetY + 'px) ' +
                                        'scale(' + 1 / visualViewport.scale + ')';
            this.$store.dispatch('terminal/containerResize', false);
        }
    }

    /** ******************************** Clipboard **********************************/

    /** ******************************** Paste **********************************/

    handlePaste () {
        // Note: some browsers (Firefox, Safari) do not support clipboard access from web pages.
        // Others will prompt for permissions (Chrome), but only support this when using https.
        try {
            navigator.clipboard.readText().then(
                (clipText) => {
                    //console.log('handlePaste then: ', clipText);
                    this.$store.dispatch('terminal/paste', { text: clipText })
                }
            ).catch((e) => {
                    // reading from clipboard not supported
                    //console.log('handlePaste reject: ', e);
                    this.showPasteModal();
                }
            )
        } catch (e) {
            // reading from clipboard not supported
            //console.log('handlePaste error: ', e);
            this.showPasteModal();
        }
    }

    closePasteModal () { this.$refs.pasteModal.hide() }

    showPasteModal () {
        this.$refs.pasteModal.show()
    }

    handlePasteClipboard () {
        // Use the store to paste into the terminal
        this.$store.dispatch('terminal/paste', { text: this.pasteClipboardValue })
        this.$store.dispatch('terminal/focus')
    }

    focusPasteClipboard () {
        this.$store.dispatch('terminal/blur')
        this.$refs.pasteClipboard.focus()
        this.$refs.pasteClipboard.select()
    }

    clearPasteClipboard () {
        this.pasteClipboardValue = ''
        this.$store.dispatch('terminal/focus')
    }

    /** ******************************** Copy **********************************/

    handleCopy (text: string, clearSelection? : boolean) {
        try {
            // try clipboard API writeText first
            navigator.clipboard.writeText(text)
            .then(() => {
                //console.log('handleCopy writeText success');
                if (clearSelection) {
                    const sel = window.getSelection();
                    sel && sel.removeAllRanges();
                }
            })
            .catch((e) => {
                //console.log('handleCopy writeText failed: ', e);
                // use modal dialog fallback
                this.showCopyModal(text);
            });
        } catch (e) {
            //console.log('handleCopy navigator.clipboard failed: ', e);
            // use modal dialog fallback
            this.showCopyModal(text);
        }
    }

    closeCopyModal () { this.$refs.copyModal.hide() }

    showCopyModal (text: string) {
        // Set the value
        this.copyClipboardValue = text;
        this.$refs.copyModal.show();
    }

    handleCopyClipboard () {
        // Copy the selected text
        this.$refs.copyClipboard.select()
        document.execCommand('copy')
        this.$store.dispatch('terminal/focus')
    }

    focusCopyClipboard () {
        this.$store.dispatch('terminal/blur')
        this.$refs.copyClipboard.focus()
        this.$refs.copyClipboard.select()
    }

    clearCopyClipboard () {
        this.copyClipboardValue = ''
        this.$store.dispatch('terminal/focus')
    }

    /** ******************************** Message Box **********************************/

    handleMessageBox (selectedButton: number) {
        this.messageBox.callback(selectedButton)
        this.$refs.termMessageBoxModal.hide()
    }

/** ******************************** Vue Created! **********************************/

    created () {
        // If there is a $route parameters id in the url
        this.isProfileID = !!(this.$route.params.id)

        if (this.isProfileID && this.hasProfile) {
            this.$store.dispatch('profiles/setActiveByID', { id: this.$route.params.id })
        }
    }

/** ******************************** Vue Mounted! **********************************/

    mounted () {
        // Refs
        if (Utils.isIos() && window.visualViewport) {
            visualViewport.addEventListener('scroll', this.viewportHandler);
            visualViewport.addEventListener('resize', this.viewportHandler);
        }
        this.footerObserver.observe(this.$refs.footerWrap);
    }

/** ******************************** Vue Watch **********************************/

    // Wait to get the list of profiles before setting a new one
    @Watch('hasProfile') setProfile () {
        // If there is a profile ID
        if (this.isProfileID) {
            this.$store.dispatch('profiles/setActiveByID', { id: this.$route.params.id })
        }
    }

    // Watch/Listen to see if the message box gets updated
    @Watch('messageBox') watchMessageBox () {
        this.$refs.termMessageBoxModal.show()
    }

    // Watch/Listen to see if the clipboard changes
    @Watch('getClipboardTerm') clipboardTermHandle (val: any) {
        this.handleCopy(val.term);
    }

}
