











































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// Types
import { IGUIEvent } from './../../../types';
import { GUIObjectType, GridProps, GridRowProps, GUIGridColType, GridItem, GUIAlignment, GUIEventCode, GUIScaleMode, GUIGridStyle,} from './../../../types/enums';
import { UtilsType } from './../../../types/utils';
// Utilities 
import Utils from './../../../utils/index';
// Components
import GridLabel from './GridLabel.vue'
import GridEdit from './GridEdit.vue';
import GridCheckbox from './GridCheckbox.vue';
import GridDropDown from './GridDropDown.vue';
import GridGeneralDropDown from './DropDown.vue'

@Component({
    name: 'Grid',
    components: { GridLabel, GridEdit, GridCheckbox, GridDropDown, GridGeneralDropDown }
})

export default class Grid extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new GridProps()) }) private props!: GridProps;

  /** ******************************** Vue Data **********************************/
  public utils: UtilsType = new Utils();
  public activated: boolean = false;
  private prevActiveCol = 0;
  private prevActiveRow = 0;
  // When grid is activated by mouse click, the 'focusin' event saves the
  // relatedTarget control ID, which is used by mouse click handler
  // (innerClickHandler) to include in cell & row activate events. The
  // relatedTarget property in the MouseEvent object does not indicate the
  // control that lost focus.
  private mouseActivate = false;
  private mouseActivateRelatedTargetId: string | null = null;

  // Field Types
  public fieldTypes = [GridLabel, GridEdit, GridCheckbox, GridDropDown, GridDropDown, GridLabel, GridLabel, GridLabel]

  // Drop Down
  public showDropDown: boolean = false;
  public dropDownItem: GridItem = new GridItem(); 
  public scrollTop: number = 0; 
  public gridItems: any = [];
  public currentPage: number = 1;
  public paginate: boolean = false;
  public perPage: number = this.paginate? 100 : this.gridItems.length;
  /** ******************************** Vue Computed **********************************/
  get focused(): string { return this.$store.getters['guiGuis/getFocusedControl']; }
  get enabled (): boolean { return this.props.gpEnabled }
  get visible (): boolean { return this.props.gpVisible }
  get gpDataType (): number {return this.props.gpDataType }

  get isInValidInput(): boolean {  return this.$store.getters['guiGuis/getIsInvalidInput']; }

  get autoExtend(): boolean { return this.props.gpStyle === 1}

  get fontSize():number {
    return this.utils.pixelConversion(this.props.gpFontSize, "v", GUIScaleMode.gsmPoints)
  }

  get dropDownID (): string { return this.id + "-drop-down"}

  //style for the container Div
  get containerStyle(): Array<Partial<CSSStyleDeclaration>> {
    let style: Array<Partial<CSSStyleDeclaration>> = []  
    const positioning = this.utils.controlPositionCSS(this.props.gpTop, this.props.gpLeft);
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight);

    style.push(positioning, size)  
    return style
  }

  get tableContainerStyle(): Array<Partial<CSSStyleDeclaration>> {
    let style: Array<Partial<CSSStyleDeclaration>> = []  
    const size = this.utils.controlSize(this.props.gpWidth, this.props.gpHeight);
    const componentCSS = this.utils.componentCSS(this.props)

    style.push(componentCSS, size)  
    return style
  }

  get isCombo () {
    return this.dropDownItem.columnInfo.fieldType == 4;
  }

  //style for the bootstrap table component
  get tableStyle(): Array<Partial<CSSStyleDeclaration>> { 
    let style: Partial<CSSStyleDeclaration>[] = []
    const font = this.utils.controlFont(this.props)
    const border = this.utils.controlBorder(this.props) 
    const marginBottom: Partial<CSSStyleDeclaration> = { marginBottom: "0px" }

    style.push(font, border, marginBottom)
    return style;        
  }

    //style for the bootstrap table component
  get rowStyle(): Array<Partial<CSSStyleDeclaration>> { 
    let style: Array<Partial<CSSStyleDeclaration>> = []

    style.push({width: "100%"})

    return style;        
  }

  setSelected() { 
    if(typeof(this.props.gpSelStart) === 'object' && 
       typeof(this.props.gpSelLength) === 'object' &&
       typeof(this.props.gpSelRange) === 'object') {
      let [colStart, rowStart] = this.props.gpSelStart;
      let [numCols, numRows] = this.props.gpSelLength;
      let [upperLeftCol, upperLeftRow, lowerRightCol, lowerRightRow] = this.props.gpSelRange;
    } 
  }

  get selectedRange(): Array<number> { return this.props.gpSelRange}

  get AllGridLines (): boolean { return (this.props.gpGridLines === 2 || this.props.gpGridLines === 3) }

  // there is no equivalent to this in b-table
  // get VerticalGridLines (): boolean { return (this.props.gpGridLines === 2)}

  // with b-table this is an equivalent to bordered = false
  // get HorizontalGridLines (): boolean { return (this.props.gpGridLines === 1)}

  get NoGridLines (): boolean { return (this.props.gpGridLines === 0) }

  get id(): string { return this.props.id }

  get editable(): boolean { return this.props.type === GUIObjectType.gxGridEditable }

  get fields () { 
    return this.props.fields
  }

  get items () {
    return this.props.items
  }

  /** ******************************** Vue Methods **********************************/

  pastable(val: number): boolean {
    return (val === 1 || val === 2 || val === 4)
  }

      //style for the bootstrap table component
  colStyle(col: any): Array<Partial<CSSStyleDeclaration>> { 
    let style: Array<Partial<CSSStyleDeclaration>> = []
    if(this.activated && col.focused) {
      switch(this.props.gpFocusStyle){
        case 0: style.push({"border": "none"})
          break;
        case 1: style.push({"border": "dotted .25px blue"})
          break;
        case 2: style.push({"border": "dotted 2.5px blue"})
          break;
        case 3: style.push({"border": "solid 1px blue"})
          break;
      }
    } else {
      style.push({"border": "none"})
    }
 
    style.push({width: "100%"})

    if(this.props.gpWordWrap) { 
      style.push({whiteSpace: "wrap"}) 
    } else {
      style.push({whiteSpace: "nowrap"}) 
    }

    // Height for columns based on size of font
    style.push({"height": this.fontSize  * 1.75 + "px"})

    return style;        
  }


  focus() {
    if (this.focused === this.props.id && this.enabled && this.visible) {
      try {
        // get the grid container DIV element
        const elem: any = this.$refs[this.id];
        // set focus to the DIV - must have tabindex to accept focus!
        (elem as HTMLElement).focus();
console.log('Grid Focus Me ' + this.props.id);
      } catch(e) {
console.log('Grid Focus Me error: ' + e);
      }
    }
  }

  activateHandler(e: any) {
    if (!this.activated) {
      this.activated = true;
      let prevControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
      if (!prevControlID) {
        // handle the deferred deactivate event
        prevControlID = this.utils.handleDeferredCtlDeactivate(this.props.id);
      }
      if (this.props.id === prevControlID) {
        this.mouseActivate = false;
        this.mouseActivateRelatedTargetId = null;
console.log('   skipping Grid activate ' + this.props.id + ' - self');
      } else {
        if (prevControlID && this.mouseActivate) {
          this.mouseActivateRelatedTargetId = prevControlID; // save the previous control ID (not available in MouseEvent)
          this.mouseActivate = false;
        } else {
          this.mouseActivateRelatedTargetId = null;
        }
console.log('Grid activate ' + this.props.id + ' from ' + prevControlID);
        if (this.props.gpEventMask & GUIEventCode.geActivate) {
          const activateEvent: IGUIEvent = { event: GUIEventCode.geActivate, id: this.props.id, args: [prevControlID] }
          this.$store.dispatch('guiGuis/addEvent', activateEvent);
        }
        // Clear the changed property
        this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: false })
      }
      // Set focused in store and in parent form
      this.$store.dispatch('guiGuis/setFocused', { id: this.props.id })
      this.$store.dispatch('guiGuis/updateProperty', {id: this.props.form!.id, property: 'focused', value: this.props.id })
    } 
  }

  activateCell(col: number, row: number, prevCol: number | null, prevRow: number | null, previousActiveControl: string | null) {

    if (this.props.gpEventMask & GUIEventCode.geActivateCell) {
      const args: Array<number | string | null> = [col, row, prevCol, prevRow];
      if (previousActiveControl) {
        args.push(previousActiveControl);
      }
      const activateEvent: IGUIEvent = { event: GUIEventCode.geActivateCell, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', activateEvent);
    }
    this.prevActiveCol = col;
    this.prevActiveRow = row;

    console.log('Activate Cell <'+ col +','+ row +'> Previous <'+ prevCol +','+ prevRow +'>', previousActiveControl)
  }

  activateRow(row: number, prevRow: number | null, previousActiveControl: string | null) {

    if (this.props.gpEventMask & GUIEventCode.geActivateRow) {
      const args: Array<number | string | null> = [row, prevRow];
      if (previousActiveControl) {
        args.push(previousActiveControl);
      }
      const activateEvent: IGUIEvent = { event: GUIEventCode.geActivateRow, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', activateEvent);
    }

    console.log('Activate Row', row, 'Previous Row', prevRow, previousActiveControl)
  }

  deactivateHandler(e: any) {   
    if (this.activated) {
      // if the current target doesn't contain the related
      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.activated = false;
        if (this.showDropDown) {
          this.toggleDropDown(null, null); 
        }
        const nextControlID = (e.relatedTarget && this.utils.getRelatedTargetID(e.relatedTarget, '', 'controlID')) || null;
        if (nextControlID) {
          this.handleDeactivateEvents(nextControlID);
        } else {
          this.$store.dispatch('guiGuis/setDeferredCtlDeactivate', this.handleDeactivateEvents.bind(this));
console.log('   deferring Grid deactivate ' + this.props.id + ' related = null');
        }
      }
    }
  }

  handleDeactivateEvents(nextControlID: string): string {
    if (nextControlID !== this.props.id) {
console.log('Grid deactivate ' + this.props.id + ' to ' + nextControlID);
      if (this.props.gpColumn !== 0 && this.props.gpRow !== 0 && !this.isInValidInput) {
        this.cellValidate(this.props.gpColumn, this.props.gpRow, null, null, nextControlID);
        this.deactivateCell(this.props.gpColumn, this.props.gpRow, null, null, nextControlID);
        this.rowValidate(this.props.gpRow, null, nextControlID);
        this.deactivateRow(this.props.gpRow, null, nextControlID);
        //this.focusCell(0, 0); // Reset
      } else if (this.props.gpRow !== 0) {
        this.deactivateRow(this.props.gpRow, null, nextControlID);
      }

      if (this.props.gpEventMask & GUIEventCode.geValidate && this.props.changed) {
        const validateEvent: IGUIEvent = { event: GUIEventCode.geValidate, id: this.props.id, args: [nextControlID], value: this.props.gpValue }
        this.$store.dispatch('guiGuis/addEvent', validateEvent);
      }

      if (this.props.gpEventMask & GUIEventCode.geDeactivate) {
        const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivate, id: this.props.id, args: [nextControlID] }
        this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
      }
    } else console.log('   skipping Grid deactivate ' + this.props.id + ' - self')
    return this.props.id;
  }

  cellValidate(col: number, row: number, nextCol: number | null, nextRow: number | null, nextControl: string | null) {

    let changed = this.props.rowInfo[row - 1] ? true : false;
    changed = changed && this.props.rowInfo[row - 1].cellChanged[col - 1] ? this.props.rowInfo[row - 1].cellChanged[col - 1] : false
    
    if (changed && this.props.gpEventMask & GUIEventCode.geValidateCell) {
      const args: Array<number | string | null> = [col, row, nextCol, nextRow];
      if (nextControl) {
        args.push(nextControl);
      }
      const value = this.props.gpValue[row - 1][col - 1];
      const validateEvent: IGUIEvent = { event: GUIEventCode.geValidateCell, id: this.props.id, args: args, value: value }
      this.$store.dispatch('guiGuis/addEvent', validateEvent);
    }

    //console.log('Validate Cell <'+ col +','+ row +'> Next <'+ nextCol +','+ nextRow +'>', value)
  }

  rowValidate(row: number, nextRow: number | null, nextControl: string | null) {
    const changed = this.props.rowInfo[row - 1] ? this.props.rowInfo[row - 1].rowChanged : false;
    if (changed && this.props.gpEventMask & GUIEventCode.geValidateRow) {
      const args: Array<number | string | null> = [row, nextRow];
      if (nextControl) {
        args.push(nextControl);
      }
    const value = this.props.gpValue[row - 1]
      const validateEvent: IGUIEvent = { event: GUIEventCode.geValidateRow, id: this.props.id, args: args, value: value }
      this.$store.dispatch('guiGuis/addEvent', validateEvent);
    }

    //console.log('Validate Row', row, value)
  }

  deactivateCell(col: number, row: number, nextCol: number | null, nextRow: number | null, previousActiveControl: string | null) {

    if (this.props.gpEventMask & GUIEventCode.geDeactivateCell) {
      const args: Array<number | string | null> = [col, row, nextCol, nextRow];
      if (previousActiveControl) {
        args.push(previousActiveControl);
      }
      const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivateCell, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
    }
    this.prevActiveCol = 0;
    this.prevActiveRow = 0;

    //console.log('Deactivate Cell <'+ col +','+ row +'> Next <'+ nextCol +','+ nextRow +'>', previousActiveControl)
  }

  deactivateRow(row: number, nextRow: number | null, previousActiveControl: string | null) {
    const args: Array<number | string | null> = [row, nextRow, previousActiveControl];

    if (this.props.gpEventMask & GUIEventCode.geDeactivateRow) {
      const deactivateEvent: IGUIEvent = { event: GUIEventCode.geDeactivateRow, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', deactivateEvent);
    }

    //console.log('Deactivate Row', row, 'Next Row', nextRow, previousActiveControl)
  }

  updateValue(val: string, col: number, row: number): void {
    let newValue = this.props.gpValue

    newValue[row][col] = val
    // update row info for changed row and changed column
    let newRowInfo: Array<GridRowProps> = this.props.rowInfo;

    if (row >= this.props.rowInfo.length || this.props.rowInfo[row] === undefined) {
      newRowInfo[row] = new GridRowProps();
    }

    newRowInfo[row].rowChanged = true;
    newRowInfo[row].cellChanged[col] = true;

    // Fire geChange event when value is updated. Note: this is "noisier" than desktop
    // version which only fires when the changed value is "committed" - like when an
    // edit cell exits from "edit mode" back to "display mode", or a list item is
    // selected.
    if (this.props.gpEventMask & GUIEventCode.geChange) {
      const changeEvent: IGUIEvent = { event: GUIEventCode.geChange, id: this.props.id, args: [col + 1, row + 1], value: val }
      this.$store.dispatch('guiGuis/addEvent', changeEvent);
    }
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'changed', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpChanged', value: true })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'rowInfo', value: newRowInfo }) 
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpValue', value: newValue }) 
  }

  keyHandler(e: KeyboardEvent) {
    const focusable: Array<GUIGridColType> = [GUIGridColType.gctEdit, GUIGridColType.gctCheck]; // Focusable Field Types: Edit, Checkbox (without drop downs)
    const focusableDropDowns: Array<GUIGridColType> = [GUIGridColType.gctList, GUIGridColType.gctCombo]; // Focusable Field Types: Drop Down, Drop Down Combo (only drop downs)
    const col = this.props.gpColumn === 0 ? 1 : this.props.gpColumn;
    const row = this.props.gpRow === 0 ? 1 : this.props.gpRow;
    const id = this.props.id +'-col-'+ col +'-row-'+ row;
    const item = this.items[row - 1][col - 1];

    let nextCol = col;
    let nextRow = row;
    // Rough calculation of how many visible rows there are in a grid
    let viewableRowsCount = Math.floor(this.props.gpHeight / (this.fontSize * 2));
    switch (e.key) {
      case ("ArrowUp"):
        e.preventDefault(); 
        if (row > 1) {
          nextRow = row - 1
        }
        if (this.showDropDown) {
          this.toggleDropDown(null, null); 
        }
      break;
      case ("ArrowDown"): 
        e.stopPropagation();
        e.preventDefault();
        // less than max rows
        if (row < this.props.gpRows) {
          nextRow = row + 1 
        }
        if (this.showDropDown) {
          this.toggleDropDown(null, null); 
        }
      break;
      case ("ArrowRight"):
        // less than max columns 
        if (col < this.props.gpColumns) {
          nextCol = col + 1
        }
        if (this.showDropDown) {
          this.toggleDropDown(null, null); 
        }
      break;
      case ("ArrowLeft"): 
        if (col > 1) {
          nextCol = col - 1
        }
        if (this.showDropDown) {
          this.toggleDropDown(null, null); 
        }
      break;
      case ("Home"): 
        nextCol = 1
        if (this.showDropDown) {
          this.toggleDropDown(null, null); 
        }
      break;
      case ("End"): 
        nextCol = this.props.gpColumns
        if (this.showDropDown) {
          this.toggleDropDown(null, null); 
        }
      break;
      case ("PageDown"): 
        if (row + viewableRowsCount <= this.props.gpRows) {
          nextRow = row + viewableRowsCount;
        } else if (row < this.props.gpRows) {
          nextRow = this.props.gpRows;
        }
      break;
      case ("PageUp"): 
        if (row - viewableRowsCount >= 1) {
          nextRow = row - viewableRowsCount;
        } else if (row > 1) {
          nextRow = 1;
        }
      break;
      case ("Enter"): 
        let fieldType: GUIGridColType = this.props.columnInfo[this.props.gpColumn - 1].fieldType; // Column Field Type
        if (focusable.includes(item.columnInfo.fieldType)) {
          this.focusInner(id, e.key);
        } else if (focusableDropDowns.includes(item.columnInfo.fieldType)) {
          this.toggleDropDown(col - 1, row - 1); 
        }
      break;
      case ("Tab"):
        if(e.shiftKey){
          let parentEle = this.props.parent;
          if(parentEle != null) {
            this.$store.dispatch('guiGuis/setFocused', { id: parentEle.parentID, typeFamily: this.props.typeFamily });
          }
          break;
        }
        // Need to match focus with tab
        let elem = (e.target as HTMLElement);
        elem = elem.parentElement!
        this.focusInner(id, e.key);
        let cell = this.$refs[elem.id + "-component"] as GridEdit | GridCheckbox | GridGeneralDropDown;

        if(cell) {
            nextCol = cell.col + 1
            nextRow = cell.row + 1
        }
      break;
      case ("Shift"):
        // no op
      break;
      case ('Control'):
        this.handlePaste(this.props.gpRow, this.props.gpColumn)
      break;
      default:
        if (focusableDropDowns.includes(item.columnInfo.fieldType)) {
          this.toggleDropDown(col - 1, row - 1); 
        } else if (focusable.includes(item.columnInfo.fieldType)) {
          console.log(e.key)
          this.focusInner(id, e.key);
        } 
        break;
    }

    const newCol: boolean = nextCol !== col;
    const newRow: boolean = nextRow !== row;

    // If the column or row changed
    if ((newCol || newRow) && !this.isInValidInput) {
      const nextId = this.props.id +'-col-'+ nextCol +'-row-'+ nextRow;
      
      this.cellValidate(col, row, nextCol, nextRow, null);
      this.deactivateCell(col, row, nextCol, nextRow, null);

      if (newRow) {
        this.rowValidate(row, nextRow, null);
        this.deactivateRow(row, nextRow, null);
        this.activateRow(nextRow, row, null);
      }

      this.activateCell(nextCol, nextRow, col, row, null);

      this.focusCell(nextCol, nextRow);
    } else {
      (this.$refs[id] as HTMLDivElement).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }
  }

  focusCell (col: number, row: number) {
    const id = this.props.id +'-col-'+ col +'-row-'+ row;
    // Make sure this isn't a reset
    if (col !== 0 && row !== 0) {
      (this.$refs[id] as HTMLDivElement).scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    }

    const gridComponent = (this.$refs[id + "-component"] as GridEdit | GridDropDown | GridLabel | GridCheckbox);
    if(gridComponent) {
      gridComponent.focusInner("");
    }
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpRow', value: row })
    this.$store.dispatch('guiGuis/updateProperty', {id: this.props.id, property: 'gpColumn', value: col }) 
  }

  headClickHandler (key: string, field: any, e: MouseEvent) {
    e.stopPropagation();
    // Current position starting at 1,1 and 0,0 means nothing was selected
    const currentCol = this.props.gpColumn;
    const currentRow = this.props.gpRow;

    const column = parseInt(key) + 1;

    // Validate only if column changed
    if (currentCol !== column) {
    if (currentCol !== 0 && currentRow !== 0 && !this.isInValidInput) {
        this.cellValidate(currentCol, currentRow, column, currentRow, null);
        this.deactivateCell(currentCol, currentRow, column, currentRow, null);
      }
      if (currentRow !== 0) {
        this.activateCell(column, currentRow, currentCol, currentRow, null);
        this.focusCell(column, currentRow); // Reset
      }               
    }

    let className = (e.target as HTMLDivElement).className;

    if ((this.props.gpEventMask & GUIEventCode.geColClick) && className != "resizer") {
      // send this event to the server
      const args: Array<Number> = [column]
      const colClickEvent: IGUIEvent = { event: GUIEventCode.geColClick, id: this.props.id, args: args }
      this.$store.dispatch('guiGuis/addEvent', colClickEvent);
    }
    // Update mouse position in case of a pop up menu
    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id));

    // clear drop down
    if (this.showDropDown) {
      this.toggleDropDown(null, null); 
    }

    (this.$parent as any).clickHandler(e); // close pop up if exists
  }

  singleClick (e: MouseEvent, col: number, row: number) {
    e.stopPropagation();
   
    this.innerClickHandler(GUIEventCode.geClick, col, row);
    // Update mouse position in case of a pop up menu
    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }


  doubleClick (e: MouseEvent, col: number, row: number) {
    e.stopPropagation();
    
    this.innerClickHandler(GUIEventCode.geDblClick, col, row);
  }

  rightClickHandler(e: MouseEvent, col: number, row: number) {
    e.preventDefault();
    e.stopPropagation();
    this.innerClickHandler(GUIEventCode.geContext, col, row);
    // Update mouse position in case of a pop up menu
    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  menuContextClickHandler(e: MouseEvent, col: number) {
    this.innerClickHandler(GUIEventCode.geContext, col, 0);
    // Update mouse position in case of a pop up menu
    this.$store.dispatch('guiGuis/updateMousePosition', this.utils.getMousePositionRelativeToParent(e, this.props.form!.id))
  }

  innerClickHandler(acEvent: GUIEventCode, col: number, row: number) {

    let obj = this.$store.getters['guiGuis/getNewlyAddedValue'];
    this.updateValue(obj.value, obj.col, obj.row)

    // New position starting at 1,1
    const nextCol = col + 1
    const nextRow = row + 1

    // Previously active position
    const prevCol = this.prevActiveCol;
    const prevRow = this.prevActiveRow;
    // Changed?
    const newCol: boolean = nextCol !== prevCol;
    const newRow: boolean = nextRow !== prevRow;
    // Handle validate & deactivate events
    if (prevCol !== 0 && prevRow !== 0) {
      // Validate cell if col or row changed
      if ((newCol || newRow) && !this.isInValidInput) {
        this.cellValidate(prevCol, prevRow, nextCol, nextRow, null);
        this.deactivateCell(prevCol, prevRow, nextCol, nextRow, null);

        // Validate row if it changed
        if (newRow) {
          this.rowValidate(prevRow, nextRow, null);
          this.deactivateRow(prevRow, nextRow, null);
        }
      }
    }
    // Activate new col and row
    if (newRow) {
      if (this.mouseActivateRelatedTargetId) {
        this.activateRow(nextRow, null, this.mouseActivateRelatedTargetId);
      } else {
        this.activateRow(nextRow, prevRow, null);
      }
    }

    if (newCol || newRow) {
      if (this.mouseActivateRelatedTargetId) {
        this.activateCell(nextCol, nextRow, null, null, this.mouseActivateRelatedTargetId);
      } else {
        this.activateCell(nextCol, nextRow, prevCol, prevRow, null);
      }
    }
    
    this.mouseActivateRelatedTargetId = null; // one shot

    this.focusCell(nextCol, nextRow)

    let args: Array<Number> = [nextCol, nextRow];

    const iGUIEvent: IGUIEvent = { event: acEvent, id: this.props.id, args: args }

    if (this.props.gpEventMask & acEvent) {
      this.$store.dispatch('guiGuis/addEvent', iGUIEvent);
    }

    // clear drop down
    const dropDownTypes: Array<GUIGridColType> = [GUIGridColType.gctList, GUIGridColType.gctCombo];  
    
    // if a drop down is showing and the just clicked cell isn't a drop down itself
    if (this.showDropDown && !dropDownTypes.includes(this.items[row][col].columnInfo.fieldType)) {
      this.toggleDropDown(null, null); 
    }
  }

  async handlePaste(_row: number, _col: number) {
    let values: Array<Array<string>> = [[]];
    values = await navigator.clipboard.readText().then(text => {
      text = text.trimEnd();
      if(text.includes('\r\n')){ 
        values = text.split('\r\n').map((arr) => arr.split("\t"));
      } else {
        values = [text.split('\t')]
      }
      return values;
    })

    if(this.props.gpPasteMode === 0) { // Single paste mode
      const item = this.props.items[_row - 1][_col - 1];
      if(item.editable && this.pastable(item.columnInfo.fieldType)) {
        Vue.set(this.props.items[_row - 1][_col - 1], "value", values[0][0])
      }
    } else if(this.props.gpPasteMode === 1) { // Multi-cell 
      values.forEach( (rows, row) => {
        rows.forEach( (data, col) => {
          const item = this.props.items[_row + row][_col + col];
          if(item.editable && this.pastable(item.columnInfo.fieldType)) {
            Vue.set(this.props.items[_row + row][_col + col], "value", data)
          }
        })
      })
    }
  }

  mousedownhandler() {
    if (!this.activated) {
      this.mouseActivate = true; // grid being activated by mouse click
    }
  }

  mouseuphandler() {
    this.mouseActivate = false;
  }

  scrollHandler(e: any) {
    this.scrollTop = e.target.scrollTop;
  }

  toggleDropDown (col: number | null, row: number | null): void {
    if (col !== null && row !== null) {
      this.showDropDown = true;
      this.dropDownItem = this.items[row][col];
      (this.$refs[`${this.id}-col-${col+1}-row-${row+1}-component`] as GridDropDown).focusInner();
    } else {
      this.showDropDown = false;
      this.dropDownItem = new GridItem();
    }
  }

  focusDropDown() {
    (this.$refs[this.dropDownID] as GridGeneralDropDown).focusDropDown()
  }

  setValueFromDropDown() {
    (this.$refs[this.dropDownID] as GridGeneralDropDown).setValueFromDropDown()
  }

  selectRowByValue(value: string) {
    (this.$refs[this.dropDownID] as GridGeneralDropDown).selectRowByValue(value);
  }

  focusInner(id: string, val: string) { 
    let compName = (this.$refs[id + "-component"] as Vue).$options.name
    if(compName === 'GridEditComponent' || compName === 'GridDropDownComponent') {
      (this.$refs[id + "-component"] as GridEdit | GridDropDown).focusInner(val) 
    } else {
      (this.$refs[id + "-component"] as GridLabel | GridCheckbox).focusInner();
    }
  }

  /** ******************************** Vue Life Cycle! **********************************/
  mounted () {
    // Creating context-menu listeners for table row headers. Should be replaced 
    // by 'head-contextmenu' when integrated into bootstrap-vue
    let th = (this.$refs[this.id] as HTMLDivElement).getElementsByTagName("th");
    for(let i = 0; i < th.length; i++) {
      th[i].addEventListener('contextmenu', (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.menuContextClickHandler(e, i);
        (this.$parent as any).clickHandler(e);
      })
    }

    this.$nextTick(() => { this.focus(); });
    let that = this; // need for resize event
    let newWidth = 0;
    let columnIndex: number;
    Array.prototype.forEach.call(
      document.querySelectorAll("div.grid-container table th.sizable"),
      function (column: any) {
        const resizer = document.createElement('div');
        resizer.classList.add('resizer');
        resizer.style.height = `${column.offsetParent.offsetHeight}px`;
        column.appendChild(resizer);

        createResizableColumn(column, resizer)
    })

    function createResizableColumn(column: any, resizer: any) {
      // Track the current position of mouse
      let x: number = 0
      let w: number = 0

      const mouseDownHandler = function(e: any) {
          // Get the current mouse position
          x = e.clientX

          // Calculate the current width of column
          const styles = window.getComputedStyle(column)
          w = parseInt(styles.minWidth, 10)

          // Attach listeners for document's events
          document.addEventListener('mousemove', mouseMoveHandler)
          document.addEventListener('mouseup', mouseUpHandler)

          resizer.classList.add('resizing')
      }

      const mouseMoveHandler = function(e: any) {
          // Determine how far the mouse has been moved
          const dx = e.clientX - x
          const range = document.createRange()
          range.selectNodeContents(column.querySelector('div'))
          const minWidth = range.getBoundingClientRect().width * 1.1

          // Update the width of column
          newWidth = w + dx > minWidth ? w + dx : minWidth
          column.style.minWidth = `${newWidth}px`

          columnIndex = column.getAttribute('aria-colindex')
          column.parentElement.parentElement.parentElement
            .querySelectorAll('tr td.sizable').forEach((detailColumn: any) => {
              if (detailColumn.getAttribute('aria-colindex') === columnIndex) {
                detailColumn.style.width = `${newWidth}px`
              }
            })
      }

      // When user releases the mouse, remove the existing event listeners
      const mouseUpHandler = function() {
          document.removeEventListener('mousemove', mouseMoveHandler)
          document.removeEventListener('mouseup', mouseUpHandler)
          // Fire the resize event
          if (that.props.gpEventMask & GUIEventCode.geResize) {
            const args = [that.utils.scaleConversion(newWidth, 'h', that.utils.getScale(that.props)), 0, columnIndex];
            const resizeEvent: IGUIEvent = { event: GUIEventCode.geResize, id: that.props.id, args: args }
            that.$store.dispatch('guiGuis/addEvent', resizeEvent);
          }

          resizer.classList.remove('resizing')
      }

      resizer.addEventListener('mousedown', mouseDownHandler)
    }

    this.$nextTick( () => {
      this.props.items = this.utils.setGridItems(this.props);
      this.props.fields = this.utils.setGridFields(this.props);
    });
  }

  updated() {
    const items = this.utils.setGridItems(this.props);
    if(items !== this.props.items){
      this.focusCell(this.props.gpColumn, this.props.gpRow)
    }
  }

/** ******************************** Vue Watch and Emit Events! **********************************/

  @Watch('focused') passRequest () { 
    this.focus()
  }

  @Watch('props.gpValue', {deep: true}) watchgpValue () { 
    this.props.items = this.utils.setGridItems(this.props);
  }

  @Watch('props.gpRow') watchRow () { 
    this.props.items = this.utils.setGridItems(this.props);
  }

  @Watch('props.gpColumn') watchCol () { 
    this.props.items = this.utils.setGridItems(this.props);
  }
}
