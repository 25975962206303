import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
// Types
import { RootState } from '@/types/store/'
import { ProfilesState } from '@/types/store/profiles'
import Profile from '@/types'
import { actionErrorObject } from '@/types/store/errors'
// Services
import ProfilesServiceType from '@/types/services/profiles'
import ProfilesService from '@/services/profiles'

// State
export const state: ProfilesState = {
    profiles: [],
    active: {
        index: 0,
        id: null,
        profile: null
    },
    hasActiveProfile: false,
    hasProfile: false,
    hasProfileParam: false,
    profileService: null,
    loading: {
        service: false,
        profiles: false,
        activeProfile: false
    }
}

// Namespace
const namespaced: boolean = true

// Getters
export const getters: GetterTree<ProfilesState, RootState> = {
    getActiveProfile (state) { return state.active.profile },
    getActiveIndex (state) { return state.active.index },
    getHasActiveProfile (state) { return state.hasActiveProfile },
    getHasProfile (state) { return state.hasProfile },
    getHasProfileParam (state) { return state.hasProfileParam },
    getLoading (state) { return state.loading },
    getProfiles (state) { return state.profiles },
    getProfileService (state) { return state.profileService }
}

// Mutations
export const mutations: MutationTree<ProfilesState> = {
    SET_ACTIVE_PROFILE (state, profile) { state.active.profile = { ...profile } },
    SET_ACTIVE_ID (state, id) { state.active.id = id },
    SET_ACTIVE_INDEX (state, index) { state.active.index = index },
    SET_HAS_PROFILE (state, hasProfile) { state.hasProfile = hasProfile },
    SET_HAS_ACTIVE_PROFILE (state, hasActiveProfile) { state.hasActiveProfile = hasActiveProfile },
    SET_HAS_PROFILE_PARAM (state, hasProfileParam) { state.hasProfileParam = hasProfileParam },
    SET_LOADING (state, { process, toggle }) {
        let loading = { ...state.loading }
        loading[process] = toggle
        state.loading = loading
    },
    SET_PROFILES (state, profiles) { state.profiles = [...profiles] },
    SET_PROFILE_SERVICE (state, ps) { state.profileService = ps }
}

// Actions
export const actions: ActionTree<ProfilesState, RootState> = {
    setActiveByID ({ dispatch, state }, { id }) {
        let index = state.profiles.findIndex(value => value.id === id)
        // index can return 0 (array index)
        if (index !== -1) {
            dispatch('setActiveByIndex', { index: index })
        } else {
            let logError: actionErrorObject = { type: 'profile', show: true, message: 'No profile exists', friendly: 'We were not able to set your active AccuTerm profile', script: 'store/profiles/index.ts' }
            dispatch('errors/addError', { ...logError }, { root: true })
        }
    },
    setActiveByIndex ({ commit, dispatch, state }, { index, hasProfileParam }) {
        if (index >= 0 && state.profiles.length > index) {
            let id = state.profiles[index].id
            commit('SET_ACTIVE_INDEX', index)
            commit('SET_ACTIVE_ID', id)
            dispatch('setActiveProfile', { profileID: id })
            commit('SET_HAS_PROFILE', true)
            commit('SET_HAS_PROFILE_PARAM', !!hasProfileParam)
        } else {
            commit('SET_HAS_PROFILE', false)
            commit('SET_LOADING', { process: 'activeProfile', toggle: true })
        }
    },
    setActiveProfile ({ commit, dispatch, rootGetters, state }, { profileID }) {

        const authToken = rootGetters['user/getAuthToken'];
        let logError: actionErrorObject = { type: 'profile', show: true, message: '', friendly: 'We were not able to set your active AccuTerm profile', script: 'store/profiles/index.ts' }
        if (state.profileService) {
            state.profileService.getProfile(profileID, authToken).then((data: Profile) => {
                commit('SET_ACTIVE_PROFILE', { ...data })
                commit('SET_ACTIVE_ID', profileID)
                commit('SET_HAS_ACTIVE_PROFILE', true)
                commit('SET_LOADING', { process: 'activeProfile', toggle: true })
            }).catch((error: any) => {
                logError.message = 'Unable to fetch active profile - ' + error.message
                dispatch('errors/addError', { ...logError }, { root: true })
            })

        } else {
            logError.message = 'Profile service is not connecting while setting active profile'
            dispatch('errors/addError', { ...logError }, { root: true })
        }
    },
    setProfiles ({ commit, dispatch, state }, { authToken, profileID }) {
        let logError: actionErrorObject = { type: 'profile', show: true, message: '', friendly: 'We are unable to retrieve your AccuTerm profiles', script: 'store/profiles/index.ts' }

        if (state.profileService) {
            state.profileService.getProfiles(authToken).then((data: any) => {
                // if the auth token isn't null, then the data comes from the azure functions
                // if it is null, then the data comes from accuterm-server (enterprise)
                const profiles: Array<Profile> = (authToken) && (data.profiles === undefined) ? data : data.profiles
                // Confirm the response has profile(s)
                if (profiles.length !== 0) {
                    commit('SET_LOADING', { process: 'profiles', toggle: true })
                    commit('SET_PROFILES', [...profiles])
                    // If the response array's element does not exist, then use the first element
                    // let activeId = state.active.id? profiles.findIndex((profile) => profile.id)
                    let activeIndex = 0;
                    let hasProfileParam = false;
                    if (profileID) {
                        activeIndex = profiles.findIndex((profile) => profile.id === profileID);
                        if (activeIndex >= 0) {
                            hasProfileParam = true;
                        } else {
                            hasProfileParam = false;
                        }
                    } else {
                        activeIndex = (typeof profiles[state.active.index] === 'undefined') ? 0 : state.active.index
                    }
                    dispatch('setActiveByIndex', { index: activeIndex, hasProfileParam: hasProfileParam })
                } else {
                    logError.message = 'Able to fetch profiles array, but it is empty'
                    logError.friendly = 'No profiles have been created. Please login to Portal and create one.'
                    dispatch('errors/addError', { ...logError }, { root: true })
                }

            }).catch((error: any) => {
                logError.message = error
                dispatch('errors/addError', { ...logError }, { root: true })
            })
        } else {
            logError.message = 'Profile service is not connecting while setting profiles'
            dispatch('errors/addError', { ...logError }, { root: true })
        }
    },
    setProfileService ({ commit, dispatch }, endpoint?: string) {
        let logError: actionErrorObject = { type: 'profile', show: true, message: '', friendly: 'We are currently unable to retrieve your AccuTerm profiles', script: 'store/profiles/index.ts' }

        const ep = endpoint || process.env.VUE_APP_SERVICE_URL

        try {
            let ps: ProfilesServiceType = new ProfilesService(ep)
            commit('SET_PROFILE_SERVICE', ps)
            commit('SET_LOADING', { process: 'service', toggle: true })
        } catch (error) {
            logError.message = 'Unable to set profile service - ' + error
            dispatch('errors/addError', { ...logError }, { root: true })
        }
    }
}

export const profiles: Module<ProfilesState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
