



















import { Component, Prop, Vue } from 'vue-property-decorator';
// Types
import { IGUIEvent } from './../../types'
import { PopupProps, GUIEventCode } from './../../types/enums'
import { CharacterUnderlineProps } from './../../types/components'
import { UtilsType } from './../../types/utils'
import { GuisStdMenuType } from '../../types/services/guis'
// Utilities 
import Utils from './../../utils/index';
// Components
import CharacterUnderline from './../layout/CharacterUnderline.vue';
import Icon from './../layout/Icon.vue';
// Services
import GuisStdMenu from '../../services/guis/stdmenu'

@Component({
  name: 'gxPopupComponent',
  components: { CharacterUnderline, Icon }
})

export default class gxPopup extends Vue {
  /** ******************************** Vue Props **********************************/

  @Prop({ default: () => (new PopupProps()) }) private props!: PopupProps;

  /** ******************************** Vue Data **********************************/

    public utils: UtilsType = new Utils();

    public classes: Array<string> = ['popUp']

  /** ******************************** Vue Computed **********************************/

    get stdMenuService(): GuisStdMenuType { return new GuisStdMenu() }
    
    get mousePosition(): Partial<CSSStyleDeclaration> { 
        const mp = this.$store.getters['guiGuis/getMousePosition'];
    
        return { top: mp.y +'px', left: mp.x +'px' }
    }

    get items () { return this.props.gpItems }

    get tabindex(): number { return this.props.tabindex }  
    
    get style () {
        let style: Partial<CSSStyleDeclaration>[] = [];

        const font = this.utils.controlFont(this.props);
        const alignment = this.utils.controlAlign(this.props)

        const containerCSS: Partial<CSSStyleDeclaration> = {
            position: 'absolute',
            zIndex: '10000',
            minWidth: '100px',
            background: this.props.parent!.gpBackColor,
            boxShadow: '2px 2px grey'
        };

        style.push(font, alignment, containerCSS);

        return style;
    }

    get visible () { return this.props.gpVisible }
    set visible (val: boolean) {
        this.$store.dispatch('guiGuis/updateProperty', { id: this.props.id, property: 'gpVisible', value: val }) 
    }

    get enabled (): boolean { return this.props.gpEnabled }

    get visibleAndEnabled(): boolean {
        const ve = this.visible && this.enabled
        
        if (ve) {
            this.classes.push('active');
        } else {
            this.classes = this.classes.filter(c => c !== 'active');
        }

        return ve
    }

    get cuProps(): CharacterUnderlineProps {
        let form = this.props.form ? this.props.form.id : null;
        return { app: this.props.app!.id, form: form, control: this.props.id, type: this.props.type }
    }

  /** ******************************** Vue Methods **********************************/

    handleClick(e: MouseEvent, item?: any) {
        if (item.enabled === true){
            e.preventDefault()

            this.classes = this.classes.filter(c => c !== 'active');

            if (item.id.substring(0, 1) === '*') {
              // handle built-in menu functions
              this.stdMenuService.StdMenuFunction(this.props, item.id);
            } else {

                let args: Array<string> = []

                if (item.id) {
                    args.push(item.id)
                }

                if (this.props.gpEventMask & GUIEventCode.geClick) {
                    // send this event to the server
                    const guiEvent: IGUIEvent = { event: GUIEventCode.geClick, id: this.props.id, args: args }
                    this.$store.dispatch('guiGuis/addEvent', guiEvent);
                }
            }
            this.visible = false;
        }
    }

    getIconName (value: string) { return this.utils.getIcon(value) };

    getInnerClass(enabled: boolean) {
        return { 'pointer': enabled }
    }

    checkDisabledState (enabled: boolean) {
        let style: any  = []
        if (enabled){
            style.push({color: this.utils.getHexColor('MENUTEXT')})
        } else {
            style.push({color:  this.utils.getHexColor('GRAYTEXT') })
            style.push({ background: this.utils.getHexColor('MENU') })
        }
        return style;
    }

}
