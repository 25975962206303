import { TSMap } from 'typescript-map'

/**
 * The enumeration for how to render the background image of a profile
 */
export enum BackgroundImageMode {
    Stretch, Fit, Crop
}

/**
 * The representation of an AccuTerm profile
 */
export default class Profile {
    id: string = '00000000-0000-0000-0000-000000000000';
    name: string = '';
    activeProfile: boolean = true;
    allowBlinking: boolean = true;
    allowUnderline: boolean = true;
    autoPrintLikeVT: boolean = false;
    autoWrap: boolean = true;
    backgroundPictureAlpha: number = 25;
    backgroundPictureMode: BackgroundImageMode = BackgroundImageMode.Fit;
    backgroundPicturePath: string | undefined = undefined;
    bkspSendsDel: boolean = false;
    charSet: string = 'NATIVE';
    connection: string = 'WEBSOCKET';
    cursorStyle: string = 'underline';
    disconnectWarn: boolean = true;
    duplex: string = 'FULL';
    euroChar: string = '';
    extCols: number = 132;
    extRows: number = 26;
    fontName: string = 'DejaVu Sans Mono';
    fontSize: string = 'auto';
    historyRows: number = 1000;
    host: string = '';
    hosttermtype: string = 'wyse60';
    hosttype: string = 'LINUX';
    keepaliveSec: number = 25;
    keyboard: TSMap<string, string> = new TSMap<string, string>();
    lastModified: Date = new Date(1, 1, 1);
    mouseInput: boolean = true;
    normCols: number = 80;
    normRows: number = 24;
    pasteLine: string = 'CRLF';
    pasteLineEnd: boolean = false;
    pasteLineUser: string = '0';
    pasteText: string = 'EOF';
    pasteTextUser: string = '0';
    port: number = 4380;
    poundChar: string = '';
    profileVersion: number = 1;
    screenPages: number = 2;
    screenSize: string = 'NORMAL';
    selectionCopy: string = 'copy';
    statusLine: boolean = true;
    terminal8Bit: boolean = false;
    terminalAnswerBack: string = '';
    terminalAppMode: boolean = false;
    terminalBell: boolean = true;
    terminalCursorCodes: boolean = false;
    terminalKeypadCodes: boolean = false;
    termtype: string = 'WYSE60';
    themeStyle: string = 'vscode';
    timeout: number = 15;
}
