












// @ts-ignore
import Dropdown from 'vue-simple-search-dropdown'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { DialogState } from '../../../types/store/guis'
import { IGUIFontObject } from '../../../types'
// Types

@Component({
  name: 'FontDialogComponent',
  components: {Dropdown}
})
export default class FontDialog extends Vue {
  
/** ******************************** Vue Data **********************************/

  @Prop() public dialogBox: any
  public selected: any = { name: 'Arial', size: 8, bold: false, italic: false, underline: false }
  public fontBox: DialogState = {
            type: 3,
            visible: true,
            title: null,
            message: null,
            buttons: 1,
            defBtn: 0,
            answer: null,
            icon: null,
            extFilter: null,
            fileStyle: null,
            font: { name: '', size: 8, bold: false, italic: false, underline: false },
            color: null,
            about: null
  }
  public options = [
    {id:'1', name: 'Arial'}, {id:'2', name: 'Bookman Old Style'}, {id:'3', name: 'Calibri'}, {id:'4', name: 'Century Gothic'}, {id:'5', name: 'DengXian'}, {id:'6', name: 'Open Sans'}, {id:'7', name: 'Lato'},
    {id:'8', name: 'Oswald'}, {id:'9', name: 'Slabo 27 px'}, {id:'10', name: 'Roboto Condensed'}, {id:'11', name: 'Montserrat'}, {id:'12', name: 'Source Sans Pro'}, {id:'13', name: 'Times New Roman'}, {id:'14', name: 'Verdana'}
  ]
  public sizeOptions = [
    { name: "1", id: "1" }, { name: "2", id: "2" }, { name: "3", id: "3" }, { name: "4", id: "4" }, { name: "5", id: "5" }, { name: "6", id: "6" }, { name: "7", id: "7" }, { name: "8", id: "8" }, { name: "9", id: "9" },
    { name: "10", id: "10" }, { name: "11", id: "11" }, { name: "12", id: "12" }, { name: "14", id: "14" }, { name: "16", id: "16" }, { name: "18", id: "18" }, { name: "20", id: "20" }, { name: "22", id: "22" },
    { name: "24", id: "24" }, { name: "26", id: "26" }, { name: "28", id: "28" }, { name: "36", id: "36" }, { name: "48", id: "48" }, { name: "72", id: "72" }
  ]
  public styleOptions = [
      {id:'1', name: 'Regular'}, {id:'2', name: 'Bold'}, {id:'3', name: 'Italic'}, 
      {id:'4', name: 'Underline'}, {id:'5', name: 'Bold & Italic'} 
  ]

  validateSelection(selection: IGUIFontObject)
  {
      this.selected = selection;
      if(this.fontBox.font != null)
        this.fontBox.font.name = selection.name
      this.updateValues()
  }
  validateSelectionSize(selection: IGUIFontObject)
  {
      this.selected = selection;
      if(this.fontBox.font != null)
        this.fontBox.font.size = parseInt(selection.name)
      this.updateValues()
  }
  validateSelectionStyle(selection: IGUIFontObject)
  {
      this.selected = selection;
      let fontStyle = this.fontBox.font
      if(fontStyle != null){
        if(selection.name === 'bold')
            fontStyle.bold = true
        else if(selection.name === 'italic')
            fontStyle.italic = true
        else if(selection.name === 'underline')
            fontStyle.underline = true
        else if(selection.name === 'bold & italic'){
            fontStyle.bold = true
            fontStyle.italic = true
        }
        this.updateValues()
      }
  }
 
  updateValues()
  {
    this.$store.dispatch('guiGuis/setDialogBox', this.fontBox)
  }

}
